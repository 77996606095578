<template>
	<g>
		<transition name="quickfade">
			<foreignObject x="-400" y="-400" width="800" height="800">
				<div class="questionbox">
					{{this.text.question}}
					<div
						class="button"
						style="background-color: #44FF4433;"
						v-on:click="button_clicked('mental_fatigue', 1)">
						{{this.text.a1}}
					</div>
					<div
						class="button"
						style="background-color: #66AA4433;"
						v-on:click="button_clicked('mental_fatigue', 2)">
						{{this.text.a2}}
					</div>
					<div
						class="button"
						style="background-color: #AAAA8833;"
						v-on:click="button_clicked('mental_fatigue', 3)">
						{{this.text.a3}}
					</div>
					<div
						class="button"
						style="background-color: #BB664433;"
						v-on:click="button_clicked('mental_fatigue', 4)">
						{{this.text.a4}}
					</div>
					<div
						class="button"
						style="background-color: #FF444433;"
						v-on:click="button_clicked('mental_fatigue', 5)">
						{{this.text.a5}}
					</div>
				</div>
			</foreignObject>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/form_mental_fatigue/lang.js";
import persist from "@/persist.js";

export default {
	name: "form_mental_fatigue",
	//genväg-alias:
	local: store.state.form_mental_fatigue,
	components: {
	},
	data: () => { return {
		answers: [],
		show_form: false,
	}},
	//props: ['stim_param'],
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	methods: {
		button_clicked(question, answer) {
			this.answers.push({question, answer});
			persist.log('mental_fatigue', {a: this.answers[0]});
			this.$store.commit('set_mental_fatigue_done');
			this.show_form = false;
			setTimeout(() => {
				store.dispatch('gotoModule', 'form_concentration');
			}, 300);
		},
	},
	mounted() {
		setTimeout(() => {
			this.show_form = true;
		}, 100);
	}
};
</script>

<style lang="stylus">
.questionbox {
	cursor: default;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 3px;
	background-color: #44444466;
	border-radius: 25px;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 32px;
	font-family: sans-serif;
	color: #FFCC66;
	text-shadow: -1px -1px 0 #666, 0 -1px 0 #666, 1px -1px 0 #666, 1px 0 0 #666, 1px 1px 0 #666, 0 1px 0 #666, -1px 1px 0 #666, -1px 0 0 #666, -1px -1px 12px #666;
}

.button {
	cursor: pointer;
	text-align: left;
	margin: 0.25em;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 100px;
	margin-right: 100px;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #FFFFFF22;
	border-radius: 25px;
	border: 8px solid #FFFFFF;
	user-select: none;
	font-size: 30px;
	font-family: sans-serif;
	color: #FFFFFF;
	letter-spacing: 0.05em;
	text-shadow: -1px -1px 0 #aaa, 0 -1px 0 #aaa, 1px -1px 0 #aaa, 1px 0 0 #aaa, 1px 1px 0 #aaa, 0 1px 0 #aaa, -1px 1px 0 #aaa, -1px 0 0 #aaa, -1px -1px 12px #aaa;
}
</style>
