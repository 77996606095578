<template>
	<g>
		<box
			v-for="(value, index) in this.numCategories"
			:key="index"
			:y="-60"
			:x="boxPos(index)"
			:name="categoryNames[index]"
			:boxIndex="index"
		/>
		<currentWord :x="0" :y="-300" />
		<transition name="fade">
			<foreignObject
				v-if="
					this.$store.state.taskContainer.taskMode == 'playing' &&
					this.$store.state.taskContainer.taskPhase == 3
				"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span
						v-html="
							$parent.text.play.respond(this.categoryNames.length)
						"
					/>
				</div>
			</foreignObject>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import Timer from "advanced-timer";
import gsap from "gsap";
import box from "@/v/test_keep_track/box.vue";
import currentWord from "@/v/test_keep_track/currentWord.vue";
import persist from "@/persist.js";

var sequenceTimer;

export default {
	name: "play",
	components: {
		box,
		currentWord,
	},
	data: () => {
		return {
			num_categories: 0,
			num_targets: 0,
			num_distractors: 0,
			//-----
			start_time: 0,
			level: 0,
			round: 0,
			total_num_correct: 0,
			round_num_correct: [],
			round_box_correct: [],
			round_box_distance: [],
			round_ans_words: [],
			round_cor_words: [],
			round_time: [],
			round_cats: [],
			round_list: [],
			num_rounds_correct: 0,
			//-----
			round_answer_start_time: 0,
			currentWord: "",
			categoryNames: ["a", "b", "c", "d", "e"],
			currentList: [],
			// Box-states: 'new', 'visible', 'hidden', 'matching', 'secret', 'cheating', 'asking', 'answered', 'reveal', 'correct', 'incorrect', 'inactive'
			boxes: [{}], // { category: '', lastWord: '', typedWord: '', state: 'new'}
			box1: { category: "", lastWord: "", typedWord: "", state: "new" },
			//box2: { category: '', lastWord: '', typedWord: '', state: 'new'},
			box3: { category: "", lastWord: "", typedWord: "", state: "new" },
			box4: { category: "", lastWord: "", typedWord: "", state: "new" },
			box5: { category: "", lastWord: "", typedWord: "", state: "new" },
		};
	},
	computed: {
		phase: function () {
			return this.$store.state.taskContainer.taskPhase;
		},
		numCategories() {
			return this.categoryNames.length;
		},
	},
	watch: {
		phase: function (/*newPhase*/) {
			this.update();
		},
	},
	methods: {
		init_list(catNames, list) {
			const num = catNames.length;
			var boxes = [];
			for (var index = 0; index < num; index++) {
				boxes.push({
					category: catNames[index],
					lastWord: "",
					typedWord: "",
					state: "visible",
				});
			}
			this.categoryNames = catNames.slice(0);
			this.currentList = list.slice(0);
			this.boxes = boxes;
		},
		/*present_item_original(item) {
			var boxes = this.boxes.slice(0);
			var currentBox = item.category;
			var currentWord = item.word;
			for (var boxIndex = 0; boxIndex < boxes.length; boxIndex++) {
				if (boxIndex == currentBox) {
					boxes[boxIndex].state = 'matching';
					boxes[boxIndex].lastWord = currentWord;
				}
				else
					boxes[boxIndex].state = 'visible';
			}
			this.boxes = boxes;
			this.currentWord = currentWord;
		},*/

		// Visar ej vilken låda ordet tillhör:
		present_item_no_help(item) {
			var currentBox = item.category;
			var currentWord = item.word;
			this.currentWord = currentWord;
			for (var boxIndex = 0; boxIndex < this.boxes.length; boxIndex++) {
				if (boxIndex == currentBox) {
					//this.boxes[boxIndex].state = 'matching';
					this.boxes[boxIndex].state = "visible";
					this.boxes[boxIndex].lastWord = currentWord;
				} else {
					this.boxes[boxIndex].state = "visible";
				}
			}
			gsap.fromTo(
				"#currentWord",
				{
					x: 0,
					y: 0,
					opacity: 0,
				},
				{
					opacity: 1,
					x: 0,
					y: 0,
					duration: 0.1,
				}
			);
		},
		present_item(item) {
			var currentBox = item.category;
			var currentWord = item.word;
			this.currentWord = currentWord;
			for (var boxIndex = 0; boxIndex < this.boxes.length; boxIndex++) {
				if (boxIndex == currentBox) {
					this.boxes[boxIndex].state = "matching";
					this.boxes[boxIndex].lastWord = currentWord;
				} else {
					this.boxes[boxIndex].state = "visible";
				}
			}
			gsap.fromTo(
				"#currentWord",
				{
					x: 0,
					y: 0,
					opacity: 0,
				},
				{
					opacity: 1,
					x: 0,
					y: 0,
					duration: 0.1,
				}
			);
		},
		animate_word(item) {
			var boxNum = item.category;
			if (boxNum < this.numCategories) {
				gsap.fromTo(
					"#currentWord",
					{
						x: 0,
						y: 0,
						opacity: 1,
					},
					{
						opacity: 0,
						x: this.boxPos(boxNum),
						y: 280,
						duration: 1,
						ease: "power3.inOut",
					}
				);
			} else {
				gsap.fromTo(
					"#currentWord",
					{
						x: 0,
						y: 0,
						opacity: 1,
					},
					{
						opacity: 0,
						x: 0,
						y: -30,
						duration: 1,
						ease: "power3.inOut",
					}
				);
			}
		},
		set_cats_state(newState) {
			var boxes = this.boxes.slice(0);
			for (var index = 0; index < boxes.length; index++) {
				boxes[index].state = newState;
			}
			this.boxes = boxes;
		},
		update() {
			if (this.$store.state.taskContainer.taskMode != "playing") return;
			var phase = this.$store.state.taskContainer.taskPhase;
			//console.log("phase: " + phase);
			switch (phase) {
				case 0:
					store.commit("showContinueButton");
					this.set_cats_state("visible");
					this.currentWord = "";
					break;
				case 1:
					this.set_cats_state("visible");
					this.$parent.$parent.$refs.countDown.play(() =>
						store.dispatch("nextTaskPhase")
					);
					break;
				case 2:
					this.currentWord = "";
					// Presentera listan
					var list = this.currentList;
					sequenceTimer = new Timer(4000)
						.repeat(list.length + 1)
						.action((t) => {
							if (t.currentCycle <= list.length) {
								const i = t.currentCycle - 1;
								this.present_item_no_help(list[i]);
								gsap.fromTo(
									"#currentWord",
									{
										x: 0,
										y: 0,
										opacity: 1,
									},
									{
										delay: 1.75,
										opacity: 0,
										x: 0,
										y: -5,
										duration: 0.25,
										ease: "power3.inOut",
									}
								);

								/*
						// animera ordet:
						var boxNum = list[i].category;
						if (boxNum < this.numCategories) {
							gsap.fromTo(
								"#currentWord",
								{
									x: 0,
									y: 0,
									opacity: 1,
								},
								{
									delay: 2,
									opacity: 0,
									x: this.boxPos(boxNum),
									y: 280,
									duration: 1,
									ease: "power3.inOut",
								}
							);
						} else {
							gsap.fromTo(
								"#currentWord",
								{
									x: 0,
									y: 0,
									opacity: 1,
								},
								{
									delay: 2,
									opacity: 0,
									x: 0,
									y: -30,
									duration: 1,
									ease: "power3.inOut",
								}
							);
						}*/
							} else {
								this.set_cats_state("visible");
								store.dispatch("nextTaskPhase", 2000);
							}
						});
					sequenceTimer.start();
					break;
				case 3:
					this.round_answer_start_time = Date.now();
					this.set_cats_state("asking");
					setTimeout(() => {
						document.getElementById("input0").focus();
						this.addKeyboardListeners();
					}, 200);
					store.commit("showContinueButton");
					break;
				case 4:
					store.commit("hideContinueButton");
					// Rätta svaren
					this.removeKeyboardListeners();
					var words = [];
					var wordInputs =
						document.getElementsByClassName("wordInput");
					for (const key in wordInputs) {
						if (key < this.numCategories) {
							words.push(wordInputs[key].value);
						}
					}
					this.process_input(words);
					store.dispatch("nextTaskPhase", 4000);
					break;
				case 5:
					this.set_cats_state("hidden");
					setTimeout(() => store.commit("taskPhase", 100), 1000);
					break;
				case 100:
					// Listan är klar! Ny lista eller avsluta?
					this.round += 1;
					if (this.round < 10) {
						this.nextList();
						//store.commit('setTaskMode', 'ready');
						store.commit("showContinueButton");
						store.commit("taskPhase", 0);
					} else {
						// Klart!
						store.commit("hideContinueButton");
						store.commit("taskPhase", 999);
					}
					break;
				case 999: // done!
					this.saveSessionResults();
					//store.commit("setTaskMode", "sessionScore");
					break;
			}
		},
		process_input(words) {
			var boxes = this.boxes.slice(0);
			var ans;
			var cor;
			var nc = 0;
			var boxCorr = [];
			var boxDist = [];
			var cor_words = [];
			var ans_words = [];
			const rtime = Date.now() - this.round_answer_start_time;
			this.round_time.push(rtime);
			for (var boxIndex = 0; boxIndex < boxes.length; boxIndex++) {
				var word = words[boxIndex];
				if (word == null) word = "";
				this.boxes[boxIndex].typedWord = word;
				ans = this.boxes[boxIndex].typedWord.toLowerCase().trim();
				cor = this.boxes[boxIndex].lastWord.toLowerCase().trim();
				ans_words.push(ans);
				cor_words.push(cor);
				//if (ans == cor) {
				const distance = this.levenshteinDistance(ans, cor);
				if (distance < 3) {
					boxes[boxIndex].state = "correct";
					nc++;
					boxCorr.push(1);
				} else {
					boxes[boxIndex].state = "incorrect";
					boxCorr.push(0);
				}
				boxDist.push(distance);
			}
			this.boxes = boxes;
			this.currentWord = "";

			if (nc == this.numCategories) {
				this.num_rounds_correct++;
			}
			this.total_num_correct += nc;
			this.round_num_correct.push(nc);
			this.round_box_correct.push(boxCorr);
			this.round_box_distance.push(boxDist);
			this.round_cor_words.push(cor_words);
			this.round_ans_words.push(ans_words);
			this.round_cats.push(this.categoryNames.slice());
			this.round_list.push(this.currentList.slice());
		},
		levenshteinDistance(s1, s2) {
			var m = s1.length;
			var n = s2.length;
			var matrix = new Array();
			var line;
			var i;
			var j;
			for (i = 0; i <= m; ++i) {
				line = new Array();
				for (j = 0; j <= n; ++j) {
					if (i != 0) line.push(0);
					else line.push(j);
				}
				line[0] = i;
				matrix.push(line);
			}
			var cost;
			for (i = 1; i <= m; ++i)
				for (j = 1; j <= n; ++j) {
					if (s1.charAt(i - 1) == s2.charAt(j - 1)) cost = 0;
					else cost = 1;
					matrix[i][j] = Math.min(
						matrix[i - 1][j] + 1,
						matrix[i][j - 1] + 1,
						matrix[i - 1][j - 1] + cost
					);
				}
			return matrix[m][n];
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			persist.set_progress_data("keep_track", 1);

			persist.log("results", {
				sum_correct: this.total_num_correct,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				round_time: this.round_time,
				round_correct: this.round_num_correct,
				correct: this.round_box_correct,
				distance: this.round_box_distance,
				answer: this.round_ans_words,
				num_rounds_correct: this.num_rounds_correct,
			});

			setTimeout(() => {
				store.dispatch("gotoModule", "home");
			}, 5000);
		},
		addKeyboardListeners() {
			const wordInputs = document.getElementsByClassName("wordInput");
			for (const key in wordInputs) {
				if (key < this.numCategories) {
					wordInputs[key].addEventListener(
						"keyup",
						this.enterKeyPushed.bind(this)
					);
				}
			}
		},
		removeKeyboardListeners() {
			const wordInputs = document.getElementsByClassName("wordInput");
			for (const key in wordInputs) {
				if (key < this.numCategories) {
					wordInputs[key].removeEventListener(
						"keyup",
						this.enterKeyPushed.bind(this)
					);
				}
			}
		},
		enterKeyPushed: function (e) {
			if (e.key === "Enter") {
				this.enterWord();
			}
		},
		enterWord: function () {
			var wordInputs = document.getElementsByClassName("wordInput");
			for (const key in wordInputs) {
				if (key < this.numCategories) {
					var val = wordInputs[key].value;
					if (val == null || val == "") {
						wordInputs[key].focus();
						return;
					}
				}
			}
			store.dispatch("nextTaskPhase");
		},
		/*nextList() {
			switch (this.round) {
				// ----------- DEMO ----------
				case 1:
					numCategories = 2;
					numTargets = 2;
					numDistractors = 1;
					break;
				case 2:
					numCategories = 2;
					numTargets = 3;
					numDistractors = 1;
					break;
				case 3:
					numCategories = 3;
					numTargets = 4;
					numDistractors = 2;
					break;
				case 4:
					numCategories = 3;
					numTargets = 8;
					numDistractors = 2;
					break;
				case 5:
					numCategories = 3;
					numTargets = 15;
					numDistractors = 2;
					break; // ----------- TEST ----------
				case 6:
					numCategories = 5;
					numTargets = 6;
					numDistractors = 5;
					break;
				case 7:
					numCategories = 5;
					numTargets = 8;
					numDistractors = 5;
					break;
				case 8:
					numCategories = 5;
					numTargets = 10;
					numDistractors = 5;
					break;
				case 9:
					numCategories = 5;
					numTargets = 5;
					numDistractors = 5;
					break;
				case 10:
					numCategories = 5;
					numTargets = 15;
					numDistractors = 5;
					break;
			}
		},*/
		nextList() {
			var list = [];
			var catNames = [];
			switch (this.round) {
				// ----------- DEMO ----------
				case 0:
					// num_categories = 2
					// num_targets = 2
					// num_distractors = 1
					catNames = ["Djur", "Växter"];
					list = [
						{ category: 0, word: "hund" },
						{ category: 2, word: "sax" },
						{ category: 1, word: "gran" },
					];
					break;
				case 1:
					// num_categories = 2
					// num_targets = 3
					// num_distractors = 1
					catNames = ["Kläder", "Släktingar"];
					list = [
						{ category: 0, word: "byxor" },
						{ category: 1, word: "bror" },
						{ category: 0, word: "kjol" },
						{ category: 2, word: "telefon" },
					];
					break;
				case 2:
					// num_categories = 3
					// num_targets = 4
					// num_distractors = 2
					catNames = ["Sport", "Yrken", "Möbler"];
					list = [
						{ category: 2, word: "bord" },
						{ category: 0, word: "fotboll" },
						{ category: 1, word: "lärare" },
						{ category: 3, word: "syster" },
						{ category: 3, word: "hatt" },
						{ category: 1, word: "polis" },
					];
					break;
				case 3:
					// num_categories = 3
					// num_targets = 8
					// num_distractors = 2
					catNames = ["Fordon", "Instrument", "Verktyg"];
					list = [
						{ category: 1, word: "trumpet" },
						{ category: 2, word: "såg" },
						{ category: 0, word: "buss" },
						{ category: 1, word: "piano" },
						{ category: 2, word: "hammare" },
						{ category: 0, word: "moped" },
						{ category: 3, word: "sotare" },
						{ category: 0, word: "cykel" },
						{ category: 3, word: "soffa" },
						{ category: 0, word: "ambulans" },
					];
					break;
				case 4:
					// num_categories = 3
					// num_targets = 15
					// num_distractors = 2
					catNames = ["Byggnader", "Djur", "Släktingar"];
					list = [
						{ category: 0, word: "kiosk" },
						{ category: 2, word: "bror" },
						{ category: 1, word: "elefant" },
						{ category: 0, word: "stuga" },
						{ category: 1, word: "kamel" },
						{ category: 2, word: "syster" },
						{ category: 2, word: "morbror" },
						{ category: 0, word: "kyrka" },
						{ category: 1, word: "orm" },
						{ category: 2, word: "pappa" },
						{ category: 1, word: "apa" },
						{ category: 0, word: "koja" },
						{ category: 3, word: "brevbärare" },
						{ category: 2, word: "mormor" },
						{ category: 3, word: "ambulans" },
						{ category: 1, word: "kanin" },
						{ category: 2, word: "faster" },
					];
					break;
				case 5:
					// ----------- TEST ----------
					// num_categories = 5
					// num_targets = 6
					// num_distractors = 5
					catNames = [
						"Instrument",
						"Verktyg",
						"Växter",
						"Sport",
						"Fordon",
					];
					list = [
						{ category: 0, word: "flöjt" },
						{ category: 5, word: "lampa" },
						{ category: 2, word: "blåsippa" },
						{ category: 4, word: "taxi" },
						{ category: 5, word: "kofta" },
						{ category: 1, word: "kniv" },
						{ category: 5, word: "diskbänk" },
						{ category: 1, word: "mejsel" },
						{ category: 5, word: "tröja" },
						{ category: 3, word: "slalom" },
						{ category: 5, word: "lärare" },
					];
					break;
				case 6:
					// num_categories = 5
					// num_targets = 8
					// num_distractors = 5
					catNames = [
						"Växter",
						"Yrken",
						"Kläder",
						"Släktingar",
						"Instrument",
					];
					list = [
						{ category: 0, word: "brännässla" },
						{ category: 3, word: "moster" },
						{ category: 5, word: "fotboll" },
						{ category: 1, word: "bagare" },
						{ category: 0, word: "rönn" },
						{ category: 5, word: "pärm" },
						{ category: 5, word: "rally" },
						{ category: 2, word: "luva" },
						{ category: 4, word: "banjo" },
						{ category: 5, word: "katt" },
						{ category: 2, word: "keps" },
						{ category: 5, word: "minigolf" },
						{ category: 3, word: "farfar" },
					];
					break;
				case 7:
					// num_categories = 5
					// num_targets = 10
					// num_distractors = 5
					catNames = ["Möbler", "Sport", "Yrken", "Fordon", "Växter"];
					list = [
						{ category: 4, word: "tall" },
						{ category: 0, word: "bord" },
						{ category: 3, word: "spårvagn" },
						{ category: 4, word: "ljung" },
						{ category: 5, word: "saxofon" },
						{ category: 0, word: "skåp" },
						{ category: 5, word: "trumma" },
						{ category: 3, word: "traktor" },
						{ category: 2, word: "optiker" },
						{ category: 5, word: "slips" },
						{ category: 4, word: "näckros" },
						{ category: 5, word: "halsduk" },
						{ category: 1, word: "golf" },
						{ category: 0, word: "fåtölj" },
						{ category: 5, word: "handskar" },
					];
					break;
				case 8:
					// num_categories = 5
					// num_targets = 5
					// num_distractors = 5
					catNames = [
						"Kläder",
						"Växter",
						"Instrument",
						"Verktyg",
						"Byggnader",
					];
					list = [
						{ category: 3, word: "hyvel" },
						{ category: 2, word: "orgel" },
						{ category: 5, word: "åsna" },
						{ category: 5, word: "handboll" },
						{ category: 4, word: "igloo" },
						{ category: 1, word: "kaktus" },
						{ category: 5, word: "igelkott" },
						{ category: 5, word: "byrå" },
						{ category: 0, word: "overall" },
						{ category: 5, word: "älg" },
					];
					break;
				case 9:
					// num_categories = 5
					// num_targets = 15
					// num_distractors = 5
					catNames = ["Yrken", "Fordon", "Djur", "Möbler", "Sport"];
					list = [
						{ category: 4, word: "hockey" },
						{ category: 2, word: "björn" },
						{ category: 0, word: "pilot" },
						{ category: 3, word: "stol" },
						{ category: 2, word: "gris" },
						{ category: 4, word: "bandy" },
						{ category: 5, word: "mössa" },
						{ category: 0, word: "frisör" },
						{ category: 1, word: "bandvagn" },
						{ category: 2, word: "lejon" },
						{ category: 1, word: "fyrhjuling" },
						{ category: 0, word: "målare" },
						{ category: 5, word: "byxor" },
						{ category: 4, word: "tennis" },
						{ category: 5, word: "klarinett" },
						{ category: 3, word: "bänk" },
						{ category: 5, word: "sågverk" },
						{ category: 3, word: "skrivbord" },
						{ category: 5, word: "fabrik" },
						{ category: 1, word: "lastbil" },
					];
					break;
			}

			/*console.log("* * * * * * * * * * * * *");
			console.log("--- round: " + this.round);
			console.log("--- catNames:");
			console.log(catNames);
			console.log("--- list:");
			console.log(list);
			console.log("---");*/

			this.init_list(catNames, list);

			//console.log('NEXT LIST: ' + numCats + ' ' + numTargets + '' + numDistractors);
		},
		boxPos(index) {
			return (
				-800 +
				800 / this.numCategories +
				(1600 / this.numCategories) * index
			);
		},
		start() {
			this.set_cats_state("visible");
			this.start_time = Date.now();
			//this.level = this.$store.state.progress.keep_track_level || 0;
			this.round = 0;
			this.nextList();
			store.dispatch("nextTaskPhase");
			this.currentWord = "";
			this.update();
		},
	},
	mounted() {
		this.set_cats_state("visible");
		this.init_list(["Djur", "Växter"], []);
		//this.start_time = Date.now();
		//this.round = 0;
		//this.nextList();
		//this.update();
	},
};
</script>
