<template>
	<g>

		<transition name="quickfade">
			<g v-if="show_place">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-500" y="-360"
					width="1000" height="120"
					rx="15"
				/>

				<text
					x="-100"
					y="-295"
					class="txt"
					text-anchor="start"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#000000"
				>{{place_name}}</text>
			</g>
		</transition>

		<transition name="fade">
			<g v-if="feedback_colours_correct > -1 && feedback_objects_correct > -1">
				<text
					x="0"
					y="-50"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#000000"
				>{{this.text.training.colours_correct(feedback_colours_correct)}}</text>
				<text
					x="0"
					y="50"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#000000"
				>{{this.text.training.objects_correct(feedback_objects_correct)}}</text>
			</g>
		</transition>



		<transition name="quickfade">
			<rect
				v-if="show_colour1"
				v-on:click="select_colour(1)"
				stroke="#00000020"
				stroke-width="10"
				:fill=this.colour1
				x="-500" y="-200"
				width="200" height="120"
				rx="15"
			/>
		</transition>
		<transition name="quickfade">
			<rect
				v-if="show_colour2"
				v-on:click="select_colour(2)"
				stroke="#00000020"
				stroke-width="10"
				:fill=this.colour2
				x="-500" y="-50"
				width="200" height="120"
				rx="15"
			/>
		</transition>
		<transition name="quickfade">
			<rect
				v-if="show_colour3"
				v-on:click="select_colour(3)"
				stroke="#00000020"
				stroke-width="10"
				:fill=this.colour3
				x="-500" y="100"
				width="200" height="120"
				rx="15"
			/>
		</transition>
		<transition name="quickfade">
			<rect
				v-if="show_colour4"
				v-on:click="select_colour(4)"
				stroke="#00000020"
				stroke-width="10"
				:fill=this.colour4
				x="-500" y="250"
				width="200" height="120"
				rx="15"
			/>
		</transition>

		<transition name="quickfade">
			<g v-if="show_object1" v-on:click="select_object(1)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#00000088"
					x="-200" y="-200"
					width="700" height="120"
					rx="15"
				/>
				<text
					x="-100"
					y="-135"
					class="txt"
					text-anchor="start"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
				>{{object_name1}}</text>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="show_object2" v-on:click="select_object(2)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#00000088"
					x="-200" y="-50"
					width="700" height="120"
					rx="15"
				/>
				<text
					x="-100"
					y="15"
					class="txt"
					text-anchor="start"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
				>{{object_name2}}</text>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="show_object3" v-on:click="select_object(3)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#00000088"
					x="-200" y="100"
					width="700" height="120"
					rx="15"
				/>
				<text
					x="-100"
					y="165"
					class="txt"
					text-anchor="start"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
				>{{object_name3}}</text>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="show_object4" v-on:click="select_object(4)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#00000088"
					x="-200" y="250"
					width="700" height="120"
					rx="15"
				/>
				<text
					x="-100"
					y="315"
					class="txt"
					text-anchor="start"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
				>{{object_name4}}</text>
			</g>
		</transition>

		<timeline v-bind:stages="12" v-bind:pos="timeline_item" />

		<colour_test v-if="show_colour_test" />

		<transition name="fade">
			<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
//import stat from "@/stat.js";
import gsap from "gsap";
import lang from "@/v/mind_ad/episodic_memory_relational/lang.js";
import instructions from "@/v/mind_ad/episodic_memory_relational/instructions.vue";
import colour_test from "@/v/mind_ad/episodic_memory_relational/colour_test.vue";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";


export default {
	name: "mind_ad_episodic_memory_relational",
	//genväg-alias:
	local: store.state.episodic_memory_relational,
	components: {
		timeline,
		instructions,
		colour_test,
	},
	//props: ['stim_param'],
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
		playing() {
			return this.$store.state.taskContainer.taskPlaying;
		},
		/*item() {
			return this.items[this.item_n] || {
				p: '',      // place
				co: 0,     // correct object
				cc: 0, // correct colour
				so: 0, // selected object
				sc: 0, // selected colour
				ort: 0, // object response time
				crt: 0, // colour response time
				n: 1, // test position
				// d: 0, // delay (ms) since presentation
			};
		},*/
	},
	watch: {
		playing: function(active) {
			//console.log("---- playing ---- ", active);
			if (!active)
				return;

			const done_colour_test = this.$store.state.progress.emr_colours || 0;
			if (done_colour_test > 0)
				this.begin();
			else {
				setTimeout(() => { this.show_colour_test = true; }, 2000);
			}
		},
	},
	data: () => { return {
		item_n: 0,
		test_item_n: 0,
		item_start_time: 0,
		items: [],
		first_item: 0,
		start_time: 0,
		accept_colour_selection: false,
		accept_object_selection: false,
		feedback_colours_correct: -1,
		feedback_objects_correct: -1,
		tot_correct_colours: 0,
		tot_correct_objects: 0,
		presentation_phase: true,
		timeline_item: -1,
		test_phase: false,
		place_name: '',
		object_name1: '',
		object_name2: '',
		object_name3: '',
		object_name4: '',
		colour1: '#FE1',
		colour2: '#E00',
		colour3: '#6F6',
		colour4: '#8AF',
		current_colours: [],
		current_objects: [],
		current_set_of_objects: [],
		show_place: false,
		show_object1: !false,
		show_object2: !false,
		show_object3: !false,
		show_object4: !false,
		show_colour1: false,
		show_colour2: false,
		show_colour3: false,
		show_colour4: false,
		colours: [
			'#F00', '#F90', '#FE1', '#FCC', '#6F6', '#282',
			'#8BF', '#00B', '#A5F', '#FFF', '#888', '#000',
		],
		show_colour_test: false,
	}},
	methods: {
		begin() {
			if (this.start_time == 0)
				this.start_time = Date.now();

			var level_duration = Math.min(10, Math.max(2, 10 - 2 * this.level));
			//level_duration = 1;

			this.timeline_item = 0;
			this.feedback_colours_correct = -1;
			this.feedback_objects_correct = -1;

			this.presentation_items = this.items.slice(this.first_item, this.first_item + 12);
			this.presentation_item_n = 0;
			this.shuffle(this.presentation_items, 0, 12);

			this.item_n = this.first_item;
			this.test_item_n = this.first_item;
			this.$parent.$refs.countDown.play(() => {
				var t = gsap.timeline();
				var n;
				for (n = 0; n < 12; ++n) {
					t.to(this, { onStart: () => { this.present_next_item(); }, duration: level_duration });
					t.to(this, { onStart: () => { this.clear_presentation(); }, duration: 1 });
				}
				//console.log('---');
				t.to(this, { onStart: () => {
					this.timeline_item = 0;
					this.test_current_item();
				}, duration: 1 });
			}, 1, 1);
		},
		present_next_item() {
			this.accept_colour_selection = false;
			this.accept_object_selection = false;

			var n = this.presentation_item_n++; //this.item_n++;
			this.timeline_item++;
			this.place_name = this.text.places[this.presentation_items[n].p];
			this.colour1 = this.colours[this.presentation_items[n].pc];
			this.object_name1 = this.text.objects[this.presentation_items[n].po];
			this.show_place = true;
			this.show_colour1 = true;
			this.show_object1 = true;
			//console.log(n + ": " + this.place_name + " + " + this.colour1 + " = " + this.object_name1)
		},
		clear_presentation() {
			this.show_colour1 = false;
			this.show_colour2 = false;
			this.show_colour3 = false;
			this.show_colour4 = false;
			this.show_object1 = false;
			this.show_object2 = false;
			this.show_object3 = false;
			this.show_object4 = false;
			this.show_place = false;
		},
		test_current_item() {
			//console.log('test item ' + this.test_item_n);
			this.accept_colour_selection = true;
			this.accept_object_selection = true;

			this.item_start_time = Date.now();

			this.item_n = this.test_item_n; // så att rätt item visas i timeline

			var n = this.test_item_n;
			var rnd, rnd_obj;
			var cols = [this.items[n].pc];
			var x;
			for (x = 0; x < 3; ++x) {
				do {
					rnd = Math.floor(Math.random()*12);
				} while(cols.includes(rnd));
				cols.splice(Math.floor(Math.random() * (1 + cols.length)), 0, rnd);
			}

			var obs = [this.items[n].po];
			for (x = 0; x < 3; ++x) {
				do {
					rnd = this.first_item + Math.floor(Math.random()*12);
					rnd_obj = this.current_set_of_objects[rnd];
				} while(obs.includes(rnd_obj));
				obs.splice(Math.floor(Math.random() * (1 + obs.length)), 0, rnd_obj);
			}

			// TODO: för objects -- inte de 12 första utan de 12 som ingår i aktuell round.

			// -1 == ej vald:
			this.items[this.test_item_n].sc = -1;
			this.items[this.test_item_n].so = -1;

			this.current_colours = cols;
			this.current_objects = obs;
			this.place_name = this.text.places[this.items[n].p];
			this.colour1 = this.colours[cols[0]];
			this.colour2 = this.colours[cols[1]];
			this.colour3 = this.colours[cols[2]];
			this.colour4 = this.colours[cols[3]];
			this.object_name1 = this.text.objects[obs[0]];
			this.object_name2 = this.text.objects[obs[1]];
			this.object_name3 = this.text.objects[obs[2]];
			this.object_name4 = this.text.objects[obs[3]];
			this.show_place = true;
			this.show_colour1 = true;
			this.show_colour2 = true;
			this.show_colour3 = true;
			this.show_colour4 = true;
			this.show_object1 = true;
			this.show_object2 = true;
			this.show_object3 = true;
			this.show_object4 = true;
		},
		show_feedback(first) {
			var objects_correct = 0;
			var colours_correct = 0;
			for (var i = first; i < first + 12; ++i) {
				if (this.items[i].so == this.items[i].po) ++objects_correct;
				if (this.items[i].sc == this.items[i].pc) ++colours_correct;
				//console.log(this.items[i]);
			}
			this.feedback_objects_correct = objects_correct;
			this.feedback_colours_correct = colours_correct;
		},
		create_lists() {
			//console.log(this.text.places);

			
			// Initiera tom lista
			this.items = [];
			this.first_item = 0;

			const num_rounds = 2;

			var i, i2, found, item, po, p, round;
			for (round = 0; round < num_rounds; ++round) {
				for (i = 0; i < 12; ++i) {
					item = {};
					item.pc = i;
					// lägg till place
					do {
						found = false;
						p = Math.floor(Math.random() * this.text.places.length);
						for (i2 = 0; i2 < this.items.length; ++i2) {
							if (this.items[i2].p == p) {
								found = true;
								//console.log("found places " + this.text.places[p]);
								break;
							}
						}
					} while(found == true);
					item.p = p;
					
					// lägg till object
					do {
						found = false;
						po = Math.floor(Math.random() * this.text.objects.length);
						for (i2 = 0; i2 < this.items.length; ++i2) {
							if (this.items[i2].po == po) {
								found = true;
								//console.log("found object " + this.text.objects[po]);
								break;
							}
						}
					} while(found == true);
					item.po = po;
					this.items.push(item);
				}
			}

			this.current_set_of_objects = [];
			for(i = 0; i < this.items.length; ++i) {
				this.current_set_of_objects.push(this.items[i].po);
				//console.log(this.text.objects[this.items[i].po]);
			}

			// lägg till alla färger
			for (i = 0; i < this.items.length; ++i) {
				this.items[i].pc = i % 12;
			}
			
			// kasta om ordningen inom varje block om 12
			for (var r = 0; r < num_rounds; ++r)
				this.shuffle(this.items, r * 12, 12);

			// skriv hela listan
			for (i = 0; i < this.items.length; ++i) {
				item = this.items[i];
				//if (i % 12 == 0) console.log('----------');
				//console.log(i % 12 + '   ' +item.pc + " -- p: " + item.p + " = " + this.text.places[item.p] + " -- o: " + item.po + " = " + this.text.objects[item.po]);
			}
		},
		shuffle(array, start, len) {
			var m = len,
				t, i;

			while (m) {
				i = Math.floor(Math.random() * m--);
				t = array[start + m];
				array[start + m] = array[start + i];
				array[start + i] = t;
			}

			//return array;
		},
		select_colour(col) {
			if (!this.accept_colour_selection) return;
			this.accept_colour_selection = false;

			if (col != 1) this.show_colour1 = false;
			if (col != 2) this.show_colour2 = false;
			if (col != 3) this.show_colour3 = false;
			if (col != 4) this.show_colour4 = false;

			const n = this.test_item_n;
			const sc = this.current_colours[col - 1];

			//console.log('select colour ' + col);

			this.items[n].sc = sc; //this.colours[this.cols[col-1]];
			this.items[n].crt = Date.now() - this.item_start_time;

			//console.log('#' + n + ',   sc: ' + this.items[n].sc + ',   cc: ' + this.items[n].pc);

			if (this.items[n].sc == this.items[n].pc) {
				++this.tot_correct_colours;
				this.items[n].cc = 1;
			}
			else {
				this.items[n].cc = 0;
			}

			//this.feedback_colours_correct = this.tot_correct_colours;

			this.are_we_done();
		},
		select_object(obj) {
			if (!this.accept_object_selection) return;

			this.accept_object_selection = false;

			if (obj != 1) this.show_object1 = false;
			if (obj != 2) this.show_object2 = false;
			if (obj != 3) this.show_object3 = false;
			if (obj != 4) this.show_object4 = false;

			const n = this.test_item_n;
			const so = this.current_objects[obj - 1];

			//console.log('select colour ' + obj);
			this.items[n].so = so; //this.colours[this.objs[obj-1]];
			this.items[n].ort = Date.now() - this.item_start_time;

			//console.log('#' + n + ',   so: ' + this.items[n].so + ',   co: ' + this.items[n].po);

			if (this.items[n].so == this.items[n].po) {
				++this.tot_correct_objects;
				this.items[n].co = 1;
			}
			else {
				this.items[n].co = 0;
			}

			//this.feedback_objects_correct = this.tot_correct_objects;

			this.are_we_done();
			//this.item.obj = obj;
			//this.item.ort = Date.now() - this.item_start_time;
			//this.item.oc = (this.item.o == this.item.os)? 1 : 0;
		},
		are_we_done() {
			if (
				this.items[this.test_item_n].sc >= 0 &&
				this.items[this.test_item_n].so >= 0
			) {
				this.test_item_n++;
				this.timeline_item++;
				if (this.timeline_item > 12)
					this.timeline_item = -1;
				setTimeout(
					() => {
						this.show_colour1 = false;
						this.show_colour2 = false;
						this.show_colour3 = false;
						this.show_colour4 = false;
						this.show_object1 = false;
						this.show_object2 = false;
						this.show_object3 = false;
						this.show_object4 = false;
						this.show_place = false;
					}, 1000
				);

				setTimeout(
					() => {
						if (this.test_item_n == 12) {
							this.show_feedback(0);
							this.first_item = 12;
							store.commit('setTaskMode', 'readyForNextRound');
							store.commit("taskPhase", 0);
						}
						else if (this.test_item_n >= 24) {
							this.show_feedback(12);
							this.end_test();
						}
						else {
							this.test_current_item();
						}
					}, 2000
				);
			}
		},
		end_test() {
			setTimeout(() => {
				store.commit('setTaskMode', 'clickToExit');
				this.saveSessionResults();
			}, 10000);
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			this.session_score = this.tot_correct_objects + this.tot_correct_colours;

			const done_num = this.$store.state.progress.emr_num || 0;
			persist.set_progress_data('emr_num', done_num + 1);

			persist.log('results', {
				score: this.session_score,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				correct_objects: this.tot_correct_objects,
				correct_colours: this.tot_correct_colours,
				items: this.items,
				level: this.level,
			});

			// ny level?
			if (this.session_score >= 38 && this.level < 4)
				persist.set_progress_data('emr_level', this.level + 1);
			else if (this.session_score <= 10 && this.level > 0)
				persist.set_progress_data('emr_level', this.level - 1);


			persist.addToHistory('score', this.session_score).then(history => {
				store.commit("setSessionScores", history);
				store.commit('setTaskMode', 'sessionScore');
			});
		},
		setup() {
			this.create_lists();

			/*
			this.show_colour1 = true;
			this.show_colour2 = true;
			this.show_colour3 = true;
			this.show_colour4 = true;
			this.show_object1 = true;
			this.show_object2 = true;
			this.show_object3 = true;
			this.show_object4 = true;
			*/

			this.show_colour1 = false;
			this.show_colour2 = false;
			this.show_colour3 = false;
			this.show_colour4 = false;
			this.show_object1 = false;
			this.show_object2 = false;
			this.show_object3 = false;
			this.show_object4 = false;
			this.show_place = false;
			this.feedback_colours_correct = -1;
			this.feedback_objects_correct = -1;
			this.timeline_item = -1;
			this.show_colour_test = false;

			//store.commit('setTaskMode', 'playing');
			store.commit('setTaskMode', 'ready');
			store.commit("taskPhase", 0);
		}
	},
	mounted() {
		this.initial_level = this.$store.state.progress.emr_level || 0;
		this.level = this.initial_level;
		//console.log("level: " + this.level);
		this.setup();
	}
};
</script>
