<template>
	<g>
		<defs>
			<path
				id="playButtonArrow"
				d="M 0 -100 Q 0 -130, 30 -112 L 178 -9 Q 190 0, 178 9 L 30 112 Q 0 130 0 100 L 0 55 Q 0 40, -15 40 L -17 37 L -17 -37 L -15 -40 Q 0 -40, 0 -55 L 0 -100"
				fill="#FFFFFF55"
				stroke="#00000055"
				stroke-width="6"
				transform-origin="top center"
			/>
		</defs>
		<g id="playButton" opacity="1">
			<use x="-780" y="-50" xlink:href="#playButtonArrow" />
			<text
				x="-710"
				y="-49"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="28"
				fill="#000000CC"
			>{{$shared.text.taskUI.start}}</text>
		</g>
	</g>
</template>

<script>
import gsap from "gsap";

export default {
	//d="M 0 -100 Q 0 -130, 30 -112 L 178 -9 Q 190 0, 178 9 L 30 112 Q 0 130 0 100 L 0 55 Q 0 40, -20 40 L -20 -40 Q 0 -40, 0 -55 L 0 -100"
	//d="M 0 -100 Q 0 -130, 30 -112 L 178 -9 Q 190 0, 178 9 L 30 112 Q 0 130 0 100 L 0 40 L -25 40 L -25 -40 L 0 -40 L 0 -100"
	name: "playButton",
	idleTween: null,
	mounted() {
		const arrow = "#playButtonArrow";
		if (this.idleTween != null) {
			this.idleTween.kill();
			this.idleTween = null;
		}

		this.idleTween = gsap.timeline({ repeat: 10, repeatDelay: 4 });

		this.idleTween.to(arrow, {
			x: 12,
			y: -3,
			scaleX: 1.02,
			scaleY: 1.02,
			duration: 1
		});
		this.idleTween.to(arrow, {
			x: 0,
			y: 0,
			scaleX: 1,
			scaleY: 1,
			duration: 1
		});
	}
};
</script>
