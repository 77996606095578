export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
			default:
				return {
					taskName: "Skifta",
					instructions: {
						a: 'Instruktioner...',
						b: "Tryck på den stora pilen för att börja!",
					},
				}
			}
		}
	}
}
