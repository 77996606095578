<template>
	<g :id='"box"+boxIndex' opacity="1">
		<rect
			rx="10"
			fill="#CC8822"
			stroke="#BB7711"
			stroke-width="6"
			:x="x-130"
			:y="y-30"
			width="260"
			height="40"
		/>
		<path
			:display='boxState == "matching"? "inherit": "none"'
			:id='"boxRay"+boxIndex' opacity="0"
			:d='"M 0 -300 L "+(x)+" "+(y+20)'
			fill="none"
			stroke="#FFFFFF44"
			stroke-width="36"
			stroke-linecap="round"/>
		<path
			:d='"M "+(x-138)+" "+(y+5)+" l 9 -30 M "+(x+138)+" "+(y+5)+" l -9 -30"'
			fill="none"
			stroke="#BB7711"
			stroke-width="6"/>
		<rect
			rx="10"
			fill="#DD9933"
			stroke="#BB7711"
			stroke-width="6"
			:x="x-140"
			:y="y"
			width="280"
			height="140"
		/>
		<text
			:y="y+75"
			:x="x"
			class="skyword"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="30"
			fill="#000000"
		>{{name}}</text>
		<text
			v-if='boxState=="correct" || boxState=="incorrect"'
			:y="y-70"
			:x="x"
			class="skyword"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="30"
			fill="#000000"
		>{{boxData.lastWord}}</text>
		<path
			v-if="isInputVisible"
			:d='"M "+(x-20)+" "+(y-120)+" l 20 20 L "+(x+20)+" "+(y-120)'
			fill="#FFFFFF"
			stroke="#00000011"
			stroke-width="10"/>
		<foreignObject v-if="isInputVisible" :x="x-140" :y="y-200" width="280" height="80">
			<div class="inputBox">
				<input
					type="text"
					:id='"input"+boxIndex'
					:namn='"input"+boxIndex'
					:class='"wordInput" + (boxState=="correct"?" correctWord":(boxState=="incorrect"?" incorrectWord":""))'
					:disabled='boxState!="asking"'
					placeholder=""
					spellcheck="false"
					autocomplete="off"
					autocorrect="off"
					enterkeyhint="next"
					mozactionhint="next"
					:autofocus='boxIndex==0'
				/>
			</div>
		</foreignObject>

		<g v-if='boxState=="correct"'>
			<path :d='"M"  + (x+19) + " -350 c 57 1 57 1 14 36 c 17 55 17 55 -30 22 c -47 33 -47 33 -30 -22 c -46 -35 -46 -35 11 -36 c 19 -54 19 -54 38 0"' stroke="#00000022" stroke-width="12" fill="#FFCC66" />
			<path :d='"M"  + (x-17) + " -350 c 19 -54 19 -54 38 0 c 57 1 57 1 14 36"' stroke="none" fill="#FFFFAA18" />
			<path :d='"M"  + (x+3) + " -292 c -47 33 -47 33 -30 -22 c -46 -35 -46 -35 11 -36"' stroke="none" fill="#00000005" />
		</g>

	</g>
</template>

<script>
import gsap from "gsap";

export default {
	name: 'box',
	props: ['x', 'y', 'name', 'boxIndex'],
	computed: {
		boxData() {
			return this.$parent.boxes[this.boxIndex] || { category: '', lastWord: '', typedWord: '', state: 'new'};
			//return this.$store.state.ipark_keep_track.boxes[this.boxIndex];
		},
		boxState() {
			return this.boxData.state;
		},
		isInputVisible() {
			const s = this.boxState;
			return s == 'asking' || s == 'answered' || s == 'correct' || s == 'incorrect';
		},
		numCategories() {
			return this.$parent.categoryNames.length;
		}
	},
	watch: {
		boxState: function(newState, prevState) {
			var boxId = "#box" + this.boxIndex;

			if (prevState == 'hidden' && newState == 'visible') {
				gsap.fromTo(
					boxId,
					{
						scale: 0.75,
						opacity: 0.1,
					},
					{
						opacity: 1,
						scale: 1,
						duration: 2,
						ease: "power3.inOut",
					}
				);
			}
			else if (newState == 'matching') {
				var boxRay = "#boxRay" + this.boxIndex;
				var tl;
				tl = gsap.timeline();
				tl.fromTo(
					boxRay,
					{
						opacity: 0
					},
					{
						delay: 2,
						duration: 0.25,
						opacity: 1,
						ease: "power3.inOut",
					}
				);
				tl.to(boxRay, {
					duration: 0.5,
					opacity: 0,
					ease: "power3.inOut",
				});
			} else if (newState == 'hidden') {
				gsap.to(boxId, {opacity: 0.0, scale: 0.75, duration: 0.5});
			}
		}
	}
}
</script>

<style lang="stylus">
.inputBox {
	cursor: default;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	background-color: #00000011;
	border-radius: 25px;
	text-align: center;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.wordInput {
	cursor: text;
	font-family: sans-serif;
	font-size: 30px;
	color: #000000;
	text-align: center;
	text-text-justify: auto;
	display: inline-block;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #FFFFFF;
	border-radius: 20px;
	border: 4px solid white;
	width: 90%;
	height: 40%;
	margin: 7px 0px 0px 1px;
}

.correctWord {
	background-color: #88FF88;
}

.incorrectWord {
	background-color: #FF4444;
}

</style>
