export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						odd_or_even: "Udda eller jämn?",
						low_or_high: "Låg eller hög?",
						odd: "Udda",
						even: "Jämn",
						low: "Låg",
						high: "Hög",
						seconds: "sekunder", //  123 seconds
						correct: "rätt", //  5/32 correct
						instructions: {
							t0_button: 'Tryck här för att se en instruktion ➤',
							t1: 'Siffror kommer visas, en i taget, i dessa fyra rutor',
							t2: 'Använd de här två knapparna på skärmen för att svara',
							t3: 'Om du har ett tangentbord så använd tangenterna F och J',
							t4: 'Olika svar behövs beroende på var siffran visas',
							t5: 'Om en siffra visas i någon av de två övre rutorna så <br>ska du avgöra om siffran är <font color="#000000"><b>udda</b></font> eller <font color="#000000"><b>jämn</b></font>',
							t6: 'Tryck på vänstra knappen om siffran är udda (1, 3, 7, 9)',
							t7: 'Tryck på högra knappen om siffran är jämn (2, 4, 6, 8)',
							t8: 'Om en siffra visas i någon av de två nedre rutorna så <br>ska du avgöra om siffran är <font color="#000000"><b>låg</b></font> eller <font color="#000000"><b>hög</b></font>',
							t9: 'Tryck på vänstra knappen om siffran är låg (1, 2, 3, 4)',
							t10: 'Tryck på högra knappen om siffran är hög (6, 7, 8, 9)',
							t11: 'Kom ihåg att trycka på rätt knapp när uppgiften skiftar:',
							t12: 'Försök svara så snabbt och korrekt som möjligt',
						},
					}
			}
		}
	}
}
