<template>
	<g>
		<circle
			v-for="n in stages"
			:key="n"
			:fill="n<=pos?'#FFFFFF':'none'"
			stroke="#FFFFFF77"
			stroke-width="7"
			:cx="(n-stages/2)*30-24"
			cy="480"
			r="8"
			opacity="0.50"
		/>
		<circle v-if="pos>0" fill="#FFFFFF" :cx="(pos-stages/2)*30-24" cy="480" r="16" opacity="0.5" />
	</g>
</template>

<script>
export default {
	name: "timeline",
	props: {
		stages: Number,
		pos: Number
	}
};
</script>
