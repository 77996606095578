<template>
	<g>
		<g id="itemSet">
			<sym ref="sym0" id="sym0" :idx="0" transform="translate(0, 0)" visibility="hidden" />
			<sym ref="sym1" id="sym1" :idx="1" transform="translate(0, 0)" visibility="hidden" />
			<sym ref="sym2" id="sym2" :idx="2" transform="translate(0, 0)" visibility="hidden" />
			<sym ref="sym3" id="sym3" :idx="3" transform="translate(0, 0)" visibility="hidden" />
			<sym ref="sym4" id="sym4" :idx="4" transform="translate(0, 0)" visibility="hidden" />

			<sym ref="alt0" id="alt0" :idx="5" transform="translate(-390, 350)" visibility="hidden" />
			<sym ref="alt1" id="alt1" :idx="6" transform="translate(-130, 350)" visibility="hidden" />
			<sym ref="alt2" id="alt2" :idx="7" transform="translate(130, 350)" visibility="hidden" />
			<sym ref="alt3" id="alt3" :idx="8" transform="translate(+390, 350)" visibility="hidden" />

			<transition name="quickfade">
				<sym
					id="re0"
					ref="re0"
					:idx="9"
					transform="translate(-390, -70) scale(0.75)"
					v-show="this.$store.state.finger_updating_digits.visItems[9]!=null"
				/>
			</transition>
			<sym
				id="re1"
				ref="re1"
				:idx="10"
				transform="translate(-130, -70) scale(0.75)"
				v-show="this.$store.state.finger_updating_digits.visItems[10]!=null"
			/>
			<sym
				id="re2"
				ref="re2"
				:idx="11"
				transform="translate(130, -70) scale(0.75)"
				v-show="this.$store.state.finger_updating_digits.visItems[11]!=null"
			/>
			<sym
				id="re3"
				ref="re3"
				:idx="12"
				transform="translate(390, -70) scale(0.75)"
				v-show="this.$store.state.finger_updating_digits.visItems[12]!=null"
			/>
		</g>
	</g>
</template>

<script>
import { store } from "@/store.js";
import sym from "@/v/finger/updating_digits/symbol.vue";
import gsap from "gsap";
import Timer from "advanced-timer";

var sequenceTimer;

export default {
	name: "itemSet",
	components: {
		sym
	},
	methods: {
		presentList(list, callback) {
			store.commit("finger_updating_digits_markItem", { idx: 8, value: '' });
			store.commit("finger_updating_digits_markItem", { idx: 9, value: '' });
			store.commit("finger_updating_digits_markItem", { idx: 10, value: '' });
			store.commit("finger_updating_digits_markItem", { idx: 11, value: '' });
			store.commit("finger_updating_digits_markItem", { idx: 12, value: '' });
			gsap.set("#alt0", { visibility: "hidden" });
			gsap.set("#alt1", { visibility: "hidden" });
			gsap.set("#alt2", { visibility: "hidden" });
			gsap.set("#alt3", { visibility: "hidden" });

			store.commit("finger_updating_digits_resetitemSet");
			store.commit("finger_updating_digits_setList", list);
			gsap.to("#sym0", {
				duration: 0.25,
				visibility: "hidden",
				transformOrigin: "50% 50%",
				scale: 0.5,
				x: +150,
				y: 0
			});

			gsap.to("#sym1", {
				duration: 0.25,
				visibility: "visible",
				transformOrigin: "50% 50%",
				scale: 0.5,
				x: +150,
				y: 0
			});
			gsap.to("#sym2", {
				duration: 0.25,
				visibility: "visible",
				transformOrigin: "50% 50%",
				scale: 0.5,
				x: +0,
				y: 0
			});
			gsap.to("#sym3", {
				duration: 0.25,
				visibility: "visible",
				transformOrigin: "50% 50%",
				scale: 0.5,
				x: -150,
				y: 0
			});
			gsap.to("#sym4", {
				duration: 0.25,
				visibility: "visible",
				transformOrigin: "50% 50%",
				scale: 0.5,
				x: -300,
				y: 0
			});

			sequenceTimer = new Timer(2000).repeat(list.length).action(t => {
				if (t.currentCycle < list.length) {
					this.presentItem(list, t.currentCycle);
				} else {
					this.$refs.sym1.changeState(0);
					this.showResponseItems();

					if (callback != undefined) {
						callback();
					}
				}
			});

			setTimeout(() => {
				this.presentItem(list, 0);
				sequenceTimer.start();
			}, 1000);
		},
		presentItem(list, i) {
			var t0 = gsap.timeline();
			t0.set("#sym0", {
				transformOrigin: "50% 50%",
				x: 200,
				scale: 0,
				visibility: "visible",
				onComplete: () => {
					store.commit("finger_updating_digits_setVisItem", { idx: 1, value: "" });
					this.$refs.sym1.changeState(0);
				}
			});
			t0.to("#sym0", {
				duration: 0.5,
				scale: 1.0,
				opacity: 1,
				x: 200,
				onComplete: () => {
					//this.$refs.sym1.changeState(1);
					store.commit("finger_updating_digits_setVisItem", { idx: 0, value: "" });
					store.commit("finger_updating_digits_setVisItem", {
						idx: 1,
						value: list[i]
					});
					this.$refs.sym0.changeState(0);
					// todo: ta bort
					/*if (this.$store.state.finger_updating_digits.stimulusMaterial == 2) {
						this.$refs.sym1.changeState(this.$store.state.finger_updating_digits.visItems[1]);
					}*/
				}
			});
			t0.to(
				"#sym1",
				{ duration: 0.5, scale: 0.5, opacity: 1, x: 0 },
				"-=0.5"
			);
			t0.to(
				"#sym2",
				{ duration: 0.5, scale: 0.5, opacity: 1, x: -150 },
				"-=0.5"
			);
			t0.to(
				"#sym3",
				{ duration: 0.5, scale: 0.5, opacity: 1, x: -300 },
				"-=0.5"
			);
			t0.to(
				"#sym4",
				{ duration: 0.5, scale: 0.5, opacity: 0, x: -450 },
				"-=0.5"
			);
			t0.set("#sym1", { transformOrigin: "50% 50%", scale: 1.0, x: +200 });
			t0.set("#sym2", { transformOrigin: "50% 50%", scale: 0.5, x: 0 });
			t0.set("#sym3", { transformOrigin: "50% 50%", scale: 0.5, x: -150 });
			t0.set("#sym4", {
				transformOrigin: "50% 50%",
				scale: 0.5,
				x: -300,
				opacity: 1
			});
		},
		showResponseItems() {
			store.commit("finger_updating_digits_setVisItem", { idx: 0, value: "" });
			store.commit("finger_updating_digits_setVisItem", { idx: 1, value: "?" });
			store.commit("finger_updating_digits_setVisItem", { idx: 2, value: "?" });
			store.commit("finger_updating_digits_setVisItem", { idx: 3, value: "?" });
			store.commit("finger_updating_digits_setVisItem", { idx: 4, value: "?" });

			this.$refs.re0.changeState(0);
			this.$refs.re1.changeState(0);
			this.$refs.re2.changeState(0);
			this.$refs.re3.changeState(0);
			var numToRecall = 4;

			var offset = (numToRecall - 4) * 260;
			gsap.set("#sym0", {
				duration: 2,
				scale: 0.5,
				opacity: 0,
				x: 300 + offset,
				y: -300
			});
			var t0 = gsap.timeline();
			t0.to("#sym1", {
				duration: 0.5,
				scale: 0.65,
				opacity: 1,
				x: +390 + offset,
				y: -300
			});
			if (numToRecall < 2) {
				t0.to(
					"#sym2",
					{
						duration: 0.5,
						scale: 0.0,
						opacity: 1,
						x: +130 + offset,
						y: -300
					}, //100
					"-=0.45"
				);
			} else {
				t0.to(
					"#sym2",
					{
						duration: 0.5,
						scale: 0.65,
						opacity: 1,
						x: +130 + offset,
						y: -300
					},
					"-=0.45"
				);
			}

			if (numToRecall < 3) {
				t0.to(
					"#sym3",
					{
						duration: 0.5,
						scale: 0.0,
						opacity: 1,
						x: -130 + offset,
						y: -300
					},
					"-=0.40"
				);
			} else {
				t0.to(
					"#sym3",
					{
						duration: 0.5,
						scale: 0.65,
						opacity: 1,
						x: -130 + offset,
						y: -300
					},
					"-=0.40"
				);
			}
			if (numToRecall < 4) {
				t0.to(
					"#sym4",
					{
						duration: 0.5,
						scale: 0.0,
						opacity: 1,
						x: -390 + offset,
						y: -300
					},
					"-=0.35"
				);
			} else {
				t0.to(
					"#sym4",
					{
						duration: 0.5,
						scale: 0.65,
						opacity: 1,
						x: -390 + offset,
						y: -300
					},
					"-=0.35"
				);
			}
			t0.set("#alt0", { visibility: "visible", opacity: 0, x: 0, y: 350 });
			t0.set("#alt1", { visibility: "visible", opacity: 0, x: 0, y: 350 });
			t0.set("#alt2", { visibility: "visible", opacity: 0, x: 0, y: 350 });
			t0.set("#alt3", { visibility: "visible", opacity: 0, x: 0, y: 350 });

			/*if (this.$store.state.finger_updating_digits.stimulusMaterial == 2) {
				this.$refs.alt0.changeState('white');
				this.$refs.alt1.changeState('black');
				this.$refs.alt2.changeState('orange');
				this.$refs.alt3.changeState('blue');
			} else {*/
			this.$refs.alt0.changeState(6);
			this.$refs.alt1.changeState(6);
			this.$refs.alt2.changeState(6);
			this.$refs.alt3.changeState(6);
			//}

			t0.to("#alt0", {
				duration: 0.5,
				scale: 1,
				opacity: 1,
				x: -390,
				y: 350
			});
			t0.to(
				"#alt1",
				{ duration: 0.5, scale: 1, opacity: 1, x: -130, y: 350 },
				"-=0.45"
			);
			t0.to(
				"#alt2",
				{ duration: 0.5, scale: 1, opacity: 1, x: +130, y: 350 },
				"-=0.40"
			);
			t0.to(
				"#alt3",
				{ duration: 0.5, scale: 1, opacity: 1, x: +390, y: 350 },
				"-=0.35"
			);
		},
		evalResponse(roboCall = false) {
			var numToRecall = 4;
			var t0 = gsap.timeline();
			t0.to("#alt0", {
				duration: 0.5,
				visibility: "visible",
				opacity: 0,
				x: 0,
				y: 350
			});
			t0.to(
				"#alt1",
				{
					duration: 0.5,
					visibility: "visible",
					opacity: 0,
					x: 0,
					y: 350
				},
				"-=0.45"
			);
			t0.to(
				"#alt2",
				{
					duration: 0.5,
					visibility: "visible",
					opacity: 0,
					x: 0,
					y: 350
				},
				"-=0.40"
			);
			t0.to(
				"#alt3",
				{
					duration: 0.5,
					visibility: "visible",
					opacity: 0,
					x: 0,
					y: 350,
					onComplete: () => {
						t0.set("#alt0", {
							visibility: "hidden",
							opacity: 0,
							x: 0,
							y: 350
						});
						t0.set("#alt1", {
							visibility: "hidden",
							opacity: 0,
							x: 0,
							y: 350
						});
						t0.set("#alt2", {
							visibility: "hidden",
							opacity: 0,
							x: 0,
							y: 350
						});
						t0.set("#alt3", {
							visibility: "hidden",
							opacity: 0,
							x: 0,
							y: 350
						});
					}
				},
				"-=0.35"
			);

			const response = store.state.finger_updating_digits.visItems.slice(9);
			const full_list = store.state.finger_updating_digits.list.slice();
			const list = full_list.slice(-numToRecall);
			const llen = list.length;

			var responses = [];
			var correct = [];

			var numCorrect = 0;

			if (list.length >= 1 && response.length >= 1) {
				if (list[0] == response[0]) {
					numCorrect++;
					//store.commit("finger_updating_digits_markItem", { idx: 9, value: 'star' });
					this.$refs.re0.changeState(4);
					correct.push(1);
				} else {
					this.$refs.re0.changeState(5);
					correct.push(0);
				}
				//store.commit("finger_updating_digits_setVisItem", { idx: 3, value: list[0] });
				store.commit("finger_updating_digits_setVisItem", { idx: 1, value: list[llen-1] });
				responses.push(response[0]);
			}
			if (list.length >= 2 && response.length >= 2) {
				if (list[1] == response[1]) {
					numCorrect++;
					//store.commit("finger_updating_digits_markItem", { idx: 10, value: 'star' });
					this.$refs.re1.changeState(4);
					correct.push(1);
				} else {
					this.$refs.re1.changeState(5);
					correct.push(0);
				}
				//store.commit("finger_updating_digits_setVisItem", { idx: 2, value: list[1] });
				store.commit("finger_updating_digits_setVisItem", { idx: 2, value: list[llen-2] });
				responses.push(response[1]);
			}
			if (list.length >= 3 && response.length >= 3) {
				if (list[2] == response[2]) {
					numCorrect++;
					//store.commit("finger_updating_digits_markItem", { idx: 11, value: 'star' });
					this.$refs.re2.changeState(4);
					correct.push(1);
				} else {
					this.$refs.re2.changeState(5);
					correct.push(0);
				}
				//store.commit("finger_updating_digits_setVisItem", { idx: 1, value: list[2] });
				store.commit("finger_updating_digits_setVisItem", { idx: 3, value: list[llen-3] });
				responses.push(response[2]);
			}
			if (list.length >= 4 && response.length >= 4) {
				if (list[3] == response[3]) {
					numCorrect++;
					//store.commit("finger_updating_digits_markItem", { idx: 12, value: 'star' });
					this.$refs.re3.changeState(4);
					correct.push(1);
				} else {
					this.$refs.re3.changeState(5);
					correct.push(0);
				}
				//store.commit("finger_updating_digits_setVisItem", { idx: 0, value: list[3] });
				store.commit("finger_updating_digits_setVisItem", { idx: 4, value: list[llen-4] });
				responses.push(response[3]);
			}

			store.commit("finger_updating_digits_setNumCorrect", numCorrect);

			if (roboCall) {
				return;
			}

			return {
				len: full_list.length,
				list: full_list,
				resp: responses,
				corr: correct,
				numC: numCorrect,
			};
		}
	},
	computed: {
		visItems() {
			return store.state.finger_updating_digits.visItems;
		}
	},
	watch: {
		visItems: function(newVisItems, prevVisItems) {
			if (this.$store.state.finger_updating_digits.stimulusMaterial == 2) {
				for (var idx = 0; idx < newVisItems.length; ++idx) {
					if (newVisItems[idx] != prevVisItems[idx]) {
						if (idx == 1) this.$refs.sym1.changeState(newVisItems[idx]);
						if (idx == 2) this.$refs.sym2.changeState(newVisItems[idx]);
						if (idx == 3) this.$refs.sym3.changeState(newVisItems[idx]);
						if (idx == 4) this.$refs.sym4.changeState(newVisItems[idx]);

						if (idx == 9) this.$refs.re0.changeState(newVisItems[idx]);
						if (idx == 10) this.$refs.re1.changeState(newVisItems[idx]);
						if (idx == 11) this.$refs.re2.changeState(newVisItems[idx]);
						if (idx == 12) this.$refs.re3.changeState(newVisItems[idx]);
					}
				}
			}
		}
	},
	mounted() {
		store.commit("finger_updating_digits_resetitemSet");
	}
};
</script>
