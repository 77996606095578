export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						taskName: "Rutan",
						instructions: {
							a: (tot) => {
								if (tot == 1) return `Här visas 16 bollar. Några av dem kommer att <u>blinka</u>. Din uppgift är att minnas den <font color="#FFCC66">sista</font> bollen som blinkade.`;
								else return `Här visas 16 bollar. Några av dem kommer att <u>blinka</u>. Din uppgift är att minnas de <font color="#FFCC66">${tot} sista</font> bollarna som blinkade och i vilken ordning de blinkade.`;
							},
							b: "Tryck här för att se en instruktion ➤",
							c: "Nu ska jag visa!",
							d1: 'I det här exemplet ska <font color="#FFCC66">4 bollar</font> återges',
							d2: "Snart kommer ett antal bollar att blinka",
							d3: `Uppgiften är att återge de <font color="#FFCC66">4 sista</font>`,
							e: "4 rätt!",
							f: "Tryck på den stora pilen för att börja!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Kom ihåg den <font color="#FFCC66">sista</font> bollen som blinkar';
								else if (num == 2) return 'Kom ihåg de <font color="#FFCC66">4 sista</font> bollarna som blinkar';
								else return `Kom ihåg i vilken ordning de <font color="#FFCC66">${num} sista</font> bollarna blinkar`;
							},
							respond: (num) => {
								if (num == 1) return 'Tryck på bollen som blinkade sist';
								else return `Tryck på de <font color="#FFCC66">${num} sista</font> bollarna som blinkade, i samma ordning`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt boll';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Omgång ${num} av ${tot}`;
							}
						}
					};
			}
		}
	}
}
