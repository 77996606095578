<template>
	<g>
		<g v-if="this.$store.state.taskContainer.showSessionScore">
				<scoreHistory />
		</g>
		<g v-else>
			<slot></slot>
			<countDown ref="countDown" />
			<g v-on:mousedown="play_clicked">
				<playButton
					v-if="this.$store.state.taskContainer.showPlayButton"
					transform="translate(7, 200)" />
			</g>
			<g v-on:mousedown="continue_clicked">
				<continueButton
					v-if="this.$store.state.taskContainer.showContinueButton"
					transform="translate(7, 200)" />
			</g>
		</g>
		<exitButton
			v-if="this.$store.state.taskContainer.showExitButton"
			transform="translate(670, -490)"
		/>
	</g>
</template>


<script>
import playButton from "@/v/playButton.vue";
import continueButton from "@/v/continueButton.vue";
import exitButton from "@/v/exitButton.vue";
import scoreHistory from "@/v/scoreHistory.vue";
import countDown from "@/v/countDown.vue";
import { store } from "@/store.js";

export default {
	name: "taskContainer",
	data: () => { return {
		playClickCallback: null,
	}},
	methods: {
		play_clicked: function(/*event*/) {
			store.commit('setTaskMode', 'playing');
			if (this.playClickCallback != null) {
				this.playClickCallback();
				this.playClickCallback = null;
			}
		},
		continue_clicked: function(/*event*/) {
			store.commit('hideContinueButton');
			store.commit('nextTaskPhase');
			if (this.playClickCallback != null) {
				this.playClickCallback();
				this.playClickCallback = null;
			}
		},
		setPlayClickCallback: function(cb) {
			this.playClickCallback = cb;
		}
	},
	components: {
		playButton,
		continueButton,
		exitButton,
		scoreHistory,
		countDown
	}
};

// Hantera även countDown
</script>
