export default {
	methods: {
		getLocalisedText() {
			//console.log("login lang: " + this.$store.state.language);
			switch (this.$store.state.language) {
				case "sv":
					return {
						welcome: "Välkommen!",
						login: "Logga in",
						logout: "Logga ut",
						your_username: "Ditt användarnamn här",
						your_password: "Ditt lösenord här",
						wrong_password: "Felaktigt användarnamn eller lösenord",
						taskUI: {
							start: "Starta",
							continue: "Fortsätt",
							exit: "Avsluta",
							todaysScore: (score) => {
								return `Dina poäng idag: ${score}`;
							},
						},
					};
				case "fi":
					return {
						welcome: "Tervetuloa!",
						login: "Kirjaudu sisään",
						logout: "Kirjaudu ulos",
						your_username: "Käyttäjänimesi",
						your_password: "Salasanasi",
						wrong_password: "Väärä salasana tai käyttäjänimi",
						taskUI: {
							start: "Aloita",
							continue: "Jatka",
							exit: "Poistu",
							todaysScore: (score) => {
								return `Päivän tulos: ${score}`;
								// Virheiden määrä tänään
								// Mitä vähemmän sen parempi
								// Mitä korkeampi sen parempi
							},
						},
					};
				case "zh":
					return {
						welcome: "欢迎",
						login: "登入",
						logout: "退出",
						your_username: "输入户口名称",
						your_password: "输入密码",
						wrong_password: "输入的户口名称/密码不正确",
						taskUI: {
							start: "开始",
							continue: "继续",
							exit: "回到主页",
							todaysScore: (score) => {
								return `今天的分数：${score}`;
							},
						},
					};
				case "en":
				default:
					return {
						welcome: "Welcome!",
						login: "Log in",
						logout: "Log out",
						your_username: "Your username here",
						your_password: "Your password here",
						wrong_password: "Incorrect username or password",
						taskUI: {
							start: "Begin",
							continue: "Continue",
							exit: "Exit!",
							todaysScore: (score) => {
								return `Today's score: ${score}`;
							},
						},
					};
			}
		}
	}
}
