export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
					return {
						question: "Hur motiverad är du inför dagens träning?",
						a1: "1. Inte alls",
						a2: "2.",
						a3: "3.",
						a4: "4.",
						a5: "5. Mycket motiverad",
					}
				case "fi":
					return {
						question: "Kuinka motivoitunut olet tämän päiväiseen harjoitteluun?",
						a1: "1. En yhtään",
						a2: "2.",
						a3: "3.",
						a4: "4.",
						a5: "5. Erittäin motivoitunut",
					}
				case "zh":
					return {
						question: "您对今天的认知训练感到多积极？",
						a1: "1. 不积极",
						a2: "2.",
						a3: "3.",
						a4: "4.",
						a5: "5. 非常积极",
					}
				case "en":
				default:
					return {
						question: "How motivated are you for today's training?",
						a1: "1. Not at all",
						a2: "2.",
						a3: "3.",
						a4: "4.",
						a5: "5. Very motivated",
					}
			}
		}
	}
}
