<template>
	<g>
		<transition name="fade">
			<g transform="translate(670, -490)">
				<logoutButton />
			</g>
		</transition>
	</g>
</template>

<script>
/*
		<transition name="fade">
			<foreignObject
				v-if="true"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox" v-html="this.text.menu_help" />
			</foreignObject>
		</transition>

		<transition name="fade">
			<g
				transform="translate(-300, -140)"
				v-on:click="this.click_span_complex_a"
			>
				<span_complex_a_icon />
			</g>
		</transition>

		<transition name="fade">
			<g
				transform="translate(-300, 140)"
				v-on:click="this.click_span_complex_b"
			>
				<span_complex_b_icon />
			</g>
		</transition>

*/

import { store } from "@/store.js";
import lang from "@/v/nfl2/menu/lang.js";
//import persist from "@/persist.js";

//import span_complex_a_icon from "@/v/taskMenu/icons/span_complex_a_icon.vue";
//import span_complex_b_icon from "@/v/taskMenu/icons/span_complex_b_icon.vue";

//import span_complex_math_a_icon from "@/v/taskMenu/icons/span_complex_math_a_icon.vue";
//import span_complex_math_b_icon from "@/v/taskMenu/icons/span_complex_math_b_icon.vue";

//import spatialSpanIcon from "@/v/taskMenu/icons/spatialSpanIcon.vue";
//import lettersUpdatingIcon from "@/v/taskMenu/icons/lettersUpdatingIcon.vue";

//import wm_maintenance_icon from "@/v/taskMenu/icons/wm_maintenance_icon.vue";
//import keepTrackIcon from "@/v/taskMenu/icons/keepTrackIcon.vue";

import logoutButton from "@/v/taskMenu/logoutButton.vue";
//import persist from "@/persist.js";

/*
		<transition name="fade">
			<g transform="translate(0, -140)" v-on:click="this.click_span_complex_math_a">
				<span_complex_math_a_icon/>
			</g>
		</transition>

		<transition name="fade">
			<g transform="translate(0, 140)" v-on:click="this.click_span_complex_math_b">
				<span_complex_math_b_icon/>
			</g>
		</transition>

*/

/*
		<transition name="fade">
			<g transform="translate(0, -140)" v-on:click="this.click_span_spatial_linear">
				<spatialSpanIcon />
			</g>
		</transition>

		<transition name="fade">
			<g transform="translate(300, -140)" v-on:click="this.click_span_letters">
				<lettersUpdatingIcon/>
			</g>
		</transition>

*/

export default {
	name: "menu_nfl2",
	components: {
		//span_complex_a_icon,
		//span_complex_b_icon,
		//span_complex_math_a_icon,
		//span_complex_math_b_icon,
		//spatialSpanIcon,
		//lettersUpdatingIcon,
		//wm_maintenance_icon,
		logoutButton,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {};
	},
	methods: {
		/*
		click_span_complex_a() {
			store.commit("set_test_set", "A");
			store.dispatch("gotoModule", "test_operation_span_verbal");
		},
		click_span_complex_b() {
			store.commit("set_test_set", "B");
			store.dispatch("gotoModule", "test_operation_span_verbal");
		},
		click_span_complex_math_a() {
			store.commit("set_test_set", "A");
			store.dispatch("gotoModule", "test_operation_span_math");
		},
		click_span_complex_math_b() {
			store.commit("set_test_set", "B");
			store.dispatch("gotoModule", "test_operation_span_math");
		},
		click_complex_math() {
			store.dispatch("gotoModule", "test_operation_span_math");
		},
		click_span_spatial_linear() {
			store.dispatch("gotoModule", "test_span_spatial_linear");
		},
		click_span_letters() {
			store.dispatch("gotoModule", "test_span_letters");
		},
		*/
		//click_updating_letters() {
		//	if (this.show_updating_letters) store.dispatch("gotoModule", "reco_updating_letters")},
		//click_wm_maintenance() {
		//	if (this.show_wm_maintenance) store.dispatch("gotoModule", "reco_wm_maintenance")},
	},
	mounted() {
		//persist.set_progress_data('verbal', 1);

		//store.dispatch("gotoModule", "test_episodic_mal");

		const done_ravens = this.$store.state.progress.ravens || 0;
		const done_ospan = this.$store.state.progress.ospan || 0;
		const done_keep_track = this.$store.state.progress.keep_track || 0;
		const done_spatial = this.$store.state.progress.spatial || 0;
		const done_digits = this.$store.state.progress.digits || 0;
		const done_episodic = this.$store.state.progress.episodic || 0;
		//const done_episodic_words =
		//	this.$store.state.progress.episodic_words || 0;

		//console.log(this.$store.state.pwd);

		//store.dispatch("gotoModule", "test_span_spatial_linear");
		//store.dispatch("gotoModule", "test_span_digits");
		//store.dispatch("gotoModule", "test_operation_span_math_adult");
		//store.dispatch("gotoModule", "test_ravens");
		//store.dispatch("gotoModule", "test_keep_track");
		//if (Math.abs(-4) == 4) return;

		if (done_episodic != 1) {
			store.dispatch("gotoModule", "test_episodic_mal");
			return;
		}
		if (done_ravens != 1) {
			store.dispatch("gotoModule", "test_ravens");
			return;
		}
		if (done_keep_track != 1) {
			store.dispatch("gotoModule", "test_keep_track");
			return;
		}
		if (done_ospan != 1) {
			store.dispatch("gotoModule", "test_operation_span_math_adult");
			return;
		}
		if (done_spatial != 1) {
			store.dispatch("gotoModule", "test_span_spatial_linear");
			return;
		}
		if (done_digits != 1) {
			store.dispatch("gotoModule", "test_span_digits");
			return;
		}
	},
};
</script>
