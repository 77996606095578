<template>
	<g>
		<circle
			v-for="n in num_items"
			:key="n"
			:fill="n<=n_tier3?'#FFFFFF':n<=n_tier2?'#000000':n<=n_tier1?'#FFB55F':n<=n_tier0?'#FFFFFF77':'none'"
			stroke-width="1"
			stroke="#888888"
			:cx="(n-num_items/2)*20-10"
			:cy="480"
			r="6"
		/>
		<circle stroke="#FFFFFF" stroke-width="3" fill="#FFFFFF55" :cx="(pos-num_items/2)*20-10" cy="480" r="10" />
	</g>
</template>

<script>
export default {
	name: "session_timeline",
	props: {
		sessions: Number,
		current_session: Number
	},
	data: () => { return {
		num_items: 36,
		n_tier0: -1,
		n_tier1: -1,
		n_tier2: -1,
		n_tier3: -1,
		pos: 0,
	}},
	watch: {
		current_session: function(/*newPhase*/) {
			const ses = this.current_session;
			//console.log(">>>>>>> timeline: session " + ses);
			if (ses >= 3*36) {
				this.n_tier0 = 36;
				this.n_tier1 = 36;
				this.n_tier2 = 36;
				this.n_tier3 = ses % 36 + 1;
			}		
			else if (ses >= 2*36) {
				this.n_tier0 = 36;
				this.n_tier1 = 36;
				this.n_tier2 = ses % 36 + 1;
			}		
			else if (ses >= 36) {
				this.n_tier0 = 36;
				this.n_tier1 = ses % 36 + 1;
			}		
			else {
				this.n_tier3 = -1;
				this.n_tier2 = -1;
				this.n_tier1 = -1;
				this.n_tier0 = ses + 1;
			}
			
			/*
			this.n_tier3 = Math.max(0, ses - 3*this.num_items) +1;
			this.n_tier2 = Math.max(0, ses - 2*this.num_items) +1;
			this.n_tier1 = Math.max(0, ses - 1*this.num_items) +1;
			this.n_tier0 = ses +1;
			*/
			this.pos = ses % this.num_items + 1;
			//this.num_tiers = 4;
			//this.num_items = 36;
			//this.num_items = Math.ceil(this.sessions / 36);
		}
	},
};
</script>
