<template>
	<g>
		<g id="bartIcon" v-on:click="clicked">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"

			/>
			<ellipse cx="0" cy="56" rx="5" ry="5" fill="#F18D34" />
			<ellipse cx="0" cy="-5" rx="50" ry="60" fill="#F8A23C" />
			<ellipse cx="8" cy="-17" rx="25" ry="30" fill="#F9B361" />
			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
				>{{ $parent.text.bartLabel + ' ' + balloon_param }}</text
			>
		</g>
	</g>
</template>

<script>
import { store } from "@/store.js";

export default {
	name: "bartIcon",
	idleTween: null,
	props: ['balloon_param'],
	methods: {
		clicked: function (/*event*/) {
			store.commit('test_bart_params', this.balloon_param);
			store.dispatch("gotoModule", "test_bart");
		},
	},
};
</script>
