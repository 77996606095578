import Vue from 'vue'
import Vuex from 'vuex'

import { errorView } from '@/v/errorView/@'
import { taskContainer } from '@/v/taskContainer/@'
import { login } from '@/v/login/@'
import { spatialSpan } from '@/v/spatialSpan/@'

// ipark:
import { ipark_updating_letters } from '@/v/ipark/updating_letters/@'
import { ipark_updating_colours } from '@/v/ipark/updating_colours/@'
import { ipark_updating_spatial } from '@/v/ipark/updating_spatial/@'
// finger:
import { finger_updating_spatial } from '@/v/finger/updating_spatial/@'
import { finger_updating_digits } from '@/v/finger/updating_digits/@'
// itest:
import { test_bart } from '@/v/test_bart/_'
import { test_updating_digits } from '@/v/test_updating_digits/_'
// reco:
import { reco_updating_letters } from '@/v/reco/updating_letters/@'

//import { test_span_letters } from '@/v/test_span_letters/@'


Vue.use(Vuex);

// TODO? vuex.store har funktionen replaceState. Ska den användas när data laddas från servern?
// https://vuex.vuejs.org/api/#state-2

export const store = new Vuex.Store({
	state: {
		language: "sv",
		loginName: '',
		loginHash: '',
		pwd: '',
		session: 0,
		module: '',
		study: '',
		condition: '',
		group: '',
		progress: {},
		login_progress: {},
		script: {},
		mobileBrowser: false,
		motivation_done: false,
		concentration_done: false,
		health_done: false,
		mental_effort_done: false,
		mental_fatigue_done: false,
		test_set: '',
	},
	mutations: {
		app_init() {
		},
		setLoginName(state, name) {
			state.loginName = name;
		},
		setLoginHash(state, hash) {
			state.loginHash = hash;
		},
		setLanguage(state, lang) {
			/* eslint-disable no-console */
			//console.log('store.js setLanguage 1: ' + lang);
			var lx;
			if (lang == '??') {
				lx = (window.navigator.userLanguage || window.navigator.language).substring(0, 2);
				if (lx != 'en' && lx != 'zh') {
					lx = 'zh';
				}
			} else {
				lx = (lang || window.navigator.userLanguage || window.navigator.language || 'en').substring(0, 2);
			}
			//console.log('store.js setLanguage 2: ' + lx);
			state.language = lx;
			/* eslint-enable no-console */
		},
		setSession(state, session) {
			state.session = session;
		},
		setStudy(state, study) {
			state.study = study;
		},
		setPwd(state, pwd) {
			state.pwd = pwd;
		},
		setCondition(state, condition) {
			state.condition = condition;
		},
		setGroup(state, group) {
			state.group = group;
		},
		setProgress(state, obj) {
			//console.log('setProgress:');
			//console.log(obj);
			state.progress = { ...state.progress, ...obj };
		},
		setLoginProgressSnapshot(state, obj) {
			state.login_progress = { ...state.login_progress, ...obj };
		},
		setScript(state, script) {
			state.script = script;
		},
		setMobileBrowser(state, yesNo) {
			state.mobileBrowser = yesNo;
		},
		gotoModule(state, mod) {
			state.module = mod;
		},
		exitModule(state) {
			state.module = "home";
		},
		set_motivation_done(state) {
			state.motivation_done = true;
		},
		set_concentration_done(state) {
			state.concentration_done = true;
		},
		set_health_done(state) {
			state.health_done = true;
		},
		set_mental_effort_done(state) {
			state.mental_effort_done = true;
		},
		set_mental_fatigue_done(state) {
			state.mental_fatigue_done = true;
		},
		reset_forms(state) {
			state.motivation_done = false;
			state.concentration_done = false;
		},
		set_test_set(state, set) {
			state.test_set = set;
		}
	},
	actions: {
		gotoModule({ commit }, mod) {
			commit("gotoModule", mod);
		},
		exitModule({ dispatch }) {
			dispatch("gotoModule", "home");
		},
		storeModuleValue({ commit }, { key, value }) {
			commit("storeModuleValue", { key, value });
		},
		fakeLogin({ dispatch }) {
			dispatch("gotoModule", "home");
		},
		logout({ commit, dispatch }) {
			dispatch("gotoModule", "login");
			commit("setLoginHash", "");
			localStorage.removeItem('loginHash');
			commit("logout");
		},
	},
	modules: {
		errorView: errorView,
		taskContainer: taskContainer,
		login: login,
		// training:
		spatialSpan: spatialSpan,
		// finger:
		finger_updating_spatial: finger_updating_spatial,
		finger_updating_digits: finger_updating_digits,
		// recp:
		reco_updating_letters: reco_updating_letters,
		// ipark:
		ipark_updating_letters: ipark_updating_letters,
		ipark_updating_colours: ipark_updating_colours,
		ipark_updating_spatial: ipark_updating_spatial,
		// test:
		test_bart,
		//test_span_letters,
		test_updating_digits,
	}
})
