<template>
	<g>
		<countDown ref="countDownA" name="A" />
		<countDown ref="countDownB" name="B" />
		<transition name="quickfade">
			<g>
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-300"
					y="-420"
					width="600"
					height="110"
					rx="15"
				/>
				<text
					opacity="1.0"
					x="60"
					y="-330"
					class="txt"
					text-anchor="end"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ question }}</text
				>
				<text
					opacity="1.0"
					x="100"
					y="-330"
					class="txt"
					text-anchor="start"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ answer }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="condition == 'Active'">
				<g
					v-for="(v, idx) in button_pos"
					:key="idx"
					v-on:click="btn_click(idx)"
					v-on:mouseover="btn_mouse_over(idx)"
					v-on:mouseleave="btn_mouse_leave(idx)"
				>
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_colour(idx)"
						:x="v.x * 110 - 47"
						:y="v.y * 110 - 47 + 50"
						width="95"
						height="95"
						rx="15"
					/>
					<text
						:x="v.x * 110"
						:y="v.y * 110 + 56"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="50"
						fill="#FFFFFF"
						>{{ idx }}</text
					>
				</g>
				<g v-on:click="click_ok()">
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_ok_colour()"
						x="190"
						y="-80"
						width="120"
						height="120"
						rx="15"
					/>
					<text
						x="250"
						y="-17"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="40"
						fill="#FFFFFF"
						>OK</text
					>
				</g>
				<g v-on:click="click_erase()">
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_erase_colour()"
						x="190"
						y="60"
						width="120"
						height="120"
						rx="15"
					/>
					<text
						x="247"
						y="123"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="40"
						fill="#FFFFFF"
						>⌫</text
					>
				</g>
				<g v-if="item_feedback">
					<g
						v-if="feedback_response_correct"
						opacity="1.0"
						transform="scale(4.4, 4.4) translate(-90, -75)"
					>
						<path
							d="M 99 74 C 138 75 138 75 109 96 C 119 130 119 130 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74 C 90 40 90 40 102 74"
							stroke="#00000022"
							stroke-width="8"
							fill="#FFCC66"
						/>
						<path
							d="M 78 74 C 90 40 90 40 102 74 C 138 75 138 75 109 96"
							stroke="none"
							fill="#FFFFAA2C"
						/>
						<path
							d="M 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74"
							stroke="none"
							fill="#0000000C"
						/>
					</g>
					<circle v-else cx="0" cy="55" r="120" fill="#FF333388" />
					<text
						opacity="1.0"
						x="0"
						y="90"
						class="txt"
						text-anchor="middle"
						dominant-baseline="auto"
						font-family="sans-serif"
						font-size="96"
						fill="#000000DD"
						>{{ this.current_x * this.current_y }}</text
					>
				</g>
			</g>
		</transition>

		<transition name="quickfade">
			<g v-if="condition == 'Grid'">
				<g v-for="(vy, iy) in 12" :key="iy">
					<g
						v-for="(vx, ix) in 12"
						:key="ix"
						v-on:mousedown="grid_mousedown(vx, vy)"
						v-on:mouseup="grid_mouseup()"
						v-on:touchstart="grid_touchstart(vx, vy, $event)"
						v-on:touchend="grid_touchend($event)"
						v-on:mouseover="grid_mouse_over(vx, vy)"
						v-on:mouseleave="grid_mouse_leave(vx, vy)"
					>
						<rect
							stroke="#00000020"
							stroke-width="3"
							:fill="grid_colour(vx, vy)"
							:x="ix * 64 - 360 - 20"
							:y="iy * 60 - 260 - 20"
							width="60"
							height="55"
							rx="5"
						/>
						<text
							:x="ix * 64 - 351"
							:y="iy * 60 - 250"
							class="txt"
							text-anchor="middle"
							dominant-baseline="middle"
							font-family="sans-serif"
							font-size="32"
							fill="#FFFFFF"
							>{{ vx * vy }}</text
						>
						<!-- grid_colour(vx, vy) -->
						<!-- {{ vx * vy }} -->
					</g>
				</g>
				<g v-if="item_feedback">
					<g
						v-if="feedback_response_correct"
						opacity="1.0"
						:transform="
							'scale(1.2, 1.2) translate(' +
							String(-433 + this.current_x * 53) +
							',' +
							String(-350 + this.current_y * 50) +
							')'
						"
					>
						<path
							d="M 99 74 C 138 75 138 75 109 96 C 119 130 119 130 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74 C 90 40 90 40 102 74"
							stroke="#00000022"
							stroke-width="8"
							fill="#FFCC66"
						/>
						<path
							d="M 78 74 C 90 40 90 40 102 74 C 138 75 138 75 109 96"
							stroke="none"
							fill="#FFFFAA2C"
						/>
						<path
							d="M 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74"
							stroke="none"
							fill="#0000000C"
						/>
					</g>
					<circle
						v-else
						:cx="-414 + this.current_x * 64"
						:cy="-312 + this.current_y * 60"
						r="35"
						fill="#FF333388"
					/>
					<text
						:x="-415 + this.current_x * 64"
						:y="-310 + this.current_y * 60"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="32"
						fill="#000000"
						>{{ this.current_x * this.current_y }}</text
					>
				</g>
				<!-- grid_colour(vx, vy) -383 -280 -->
				<!-- {{ vx * vy }} -->
			</g>
		</transition>

		<transition name="fade">
			<g v-if="condition == 'Result'">
				<foreignObject x="-605" y="-300" width="600" height="700">
					<div class="txtbox">
						<span v-html="this.result_text1" />
					</div>
				</foreignObject>
				<foreignObject x="5" y="-300" width="600" height="700">
					<div class="txtbox">
						<span v-html="this.result_text2" />
					</div>
				</foreignObject>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import persist from "@/persist.js";
import countDown from "@/v/countDown2.vue";
import * as cards from "./cards.js";
import stat from "@/stat.js";

/*const set_a = [{"x":2,"y":4},{"x":2,"y":6},{"x":2,"y":8},{"x":2,"y":10},{"x":2,"y":12},{"x":3,"y":3},{"x":3,"y":5},{"x":3,"y":7},{"x":3,"y":9},{"x":3,"y":11},{"x":4,"y":2},{"x":4,"y":4},{"x":4,"y":6},{"x":4,"y":8},{"x":4,"y":10},{"x":4,"y":12},{"x":5,"y":3},{"x":5,"y":5},{"x":5,"y":7},{"x":5,"y":9},{"x":5,"y":11},{"x":6,"y":2},{"x":6,"y":4},{"x":6,"y":6},{"x":6,"y":8},{"x":6,"y":10},{"x":6,"y":12},{"x":7,"y":3},{"x":7,"y":5},{"x":7,"y":7},{"x":7,"y":9},{"x":7,"y":11},{"x":8,"y":2},{"x":8,"y":4},{"x":8,"y":6},{"x":8,"y":8},{"x":8,"y":10},{"x":8,"y":12},{"x":9,"y":3},{"x":9,"y":5},{"x":9,"y":7},{"x":9,"y":9},{"x":9,"y":11},{"x":10,"y":2},{"x":10,"y":4},{"x":10,"y":6},{"x":10,"y":8},{"x":10,"y":10},{"x":10,"y":12},{"x":11,"y":3},{"x":11,"y":5},{"x":11,"y":7},{"x":11,"y":9},{"x":11,"y":11},{"x":12,"y":2},{"x":12,"y":4},{"x":12,"y":6},{"x":12,"y":8},{"x":12,"y":10},{"x":12,"y":12}];

const set_b = [{"x":2,"y":3},{"x":2,"y":5},{"x":2,"y":7},{"x":2,"y":9},{"x":2,"y":11},{"x":3,"y":2},{"x":3,"y":4},{"x":3,"y":6},{"x":3,"y":8},{"x":3,"y":10},{"x":3,"y":12},{"x":4,"y":3},{"x":4,"y":5},{"x":4,"y":7},{"x":4,"y":9},{"x":4,"y":11},{"x":5,"y":2},{"x":5,"y":4},{"x":5,"y":6},{"x":5,"y":8},{"x":5,"y":10},{"x":5,"y":12},{"x":6,"y":3},{"x":6,"y":5},{"x":6,"y":7},{"x":6,"y":9},{"x":6,"y":11},{"x":7,"y":2},{"x":7,"y":4},{"x":7,"y":6},{"x":7,"y":8},{"x":7,"y":10},{"x":7,"y":12},{"x":8,"y":3},{"x":8,"y":5},{"x":8,"y":7},{"x":8,"y":9},{"x":8,"y":11},{"x":9,"y":2},{"x":9,"y":4},{"x":9,"y":6},{"x":9,"y":8},{"x":9,"y":10},{"x":9,"y":12},{"x":10,"y":3},{"x":10,"y":5},{"x":10,"y":7},{"x":10,"y":9},{"x":10,"y":11},{"x":11,"y":2},{"x":11,"y":4},{"x":11,"y":6},{"x":11,"y":8},{"x":11,"y":10},{"x":11,"y":12},{"x":12,"y":3},{"x":12,"y":5},{"x":12,"y":7},{"x":12,"y":9},{"x":12,"y":11}];
*/

var session_results = {
	active_rts: [],
	active_corr_rts: [],
	active_err_rts: [],
	//
	grid_rts: [],
	grid_corr_rts: [],
	grid_err_rts: [],
};

var mouse_used = false;

var grid_cross_timeout = 0;
var ok_btn_timeout = 0;

export default {
	name: "choke_practice_multiplication",
	//props: ['stim_param'],
	//mixins: [lang],
	computed: {
		text() {
			return 0;
			//return this.getLocalisedText();
		},
		button_pos() {
			return [
				{ x: 0, y: 2 },
				{ x: -1, y: -1 },
				{ x: 0, y: -1 },
				{ x: 1, y: -1 },
				{ x: -1, y: 0 },
				{ x: 0, y: 0 },
				{ x: 1, y: 0 },
				{ x: -1, y: 1 },
				{ x: 0, y: 1 },
				{ x: 1, y: 1 },
			];
		},
	},
	data: () => {
		return {
			start_time: 0,
			show_buttons: true,
			input_disabled: false,
			items: [],
			condition: "",
			btn_ok_down: false,
			btn_erase_down: false,
			btn_hover: -1,
			question: "",
			answer: "",
			grid_hover: [-1, -1],
			show_grid_answer: false,
			current_x: 1,
			current_y: 1,
			active_deck: [],
			grid_deck: [],
			active_set: "",
			grid_set: "",
			item_feedback: false,
			result_text1: "",
			result_text2: "",
			feedback_response_correct: false,
		};
	},
	methods: {
		btn_mouse_over(idx) {
			if (this.input_disabled) return;
			this.btn_hover = idx;
		},
		btn_mouse_leave(idx) {
			if (this.btn_hover == idx) this.btn_hover = -1;
		},
		btn_click(idx) {
			if (this.input_disabled) return;
			if (this.answer.length == 0 && idx == 0) return;

			if (
				this.answer.length < 2 ||
				(this.answer.length == 2 && this.answer[0] == "1")
			) {
				this.answer = this.answer + idx;
				//this.$set(this.btn_state, idx, true);
				//setTimeout(() => {
				//	this.$set(this.btn_state, idx, false);
				//}, 100);
			}
		},
		click_ok() {
			if (this.input_disabled) return;
			this.input_disabled = true;

			if (!this.btn_ok_down) {
				this.btn_ok_down = true;
				clearTimeout(ok_btn_timeout);
				ok_btn_timeout = setTimeout(() => {
					this.btn_ok_down = false;
				}, 100);
				var corr = this.answer == this.current_x * this.current_y;
				this.feedback_response_correct = corr;

				this.item_feedback = true;
				setTimeout(() => {
					this.item_feedback = false;
					this.log_active_response();
				}, 2000);
			}
		},
		click_erase() {
			if (this.input_disabled) return;

			if (!this.btn_erase_down) {
				if (this.answer.length > 0) {
					this.answer = this.answer.substring(
						0,
						this.answer.length - 1
					);

					this.btn_erase_down = true;
					setTimeout(() => {
						this.btn_erase_down = false;
					}, 100);
				}
			}
		},
		new_active_item() {
			if (this.condition != "Active") return;
			this.item_feedback = false;
			//console.log("new_active_item() ---");
			this.item_start_time = Date.now();
			var item = cards.get_current_item(
				this.active_deck,
				this.active_set
			);
			this.current_x = item.x;
			this.current_y = item.y;
			this.question = item.y + "·" + item.x + " =";
			this.answer = "";
			this.show_grid_answer = false;
			this.input_disabled = false;
			clearTimeout(grid_cross_timeout);
			clearTimeout(ok_btn_timeout);
		},
		new_grid_item() {
			if (this.condition != "Grid") return;
			this.item_feedback = false;
			this.item_start_time = Date.now();
			var item = cards.get_current_item(this.grid_deck, this.grid_set);
			this.current_x = item.x;
			this.current_y = item.y;
			this.question = item.y + "·" + item.x + " =";

			this.answer = item.x * item.y;
			this.show_grid_answer = false;
			clearTimeout(grid_cross_timeout);
			grid_cross_timeout = setTimeout(() => {
				if (this.condition == "Grid") {
					this.show_grid_answer = true;
				}
			}, 10000);
			this.input_disabled = false;
			clearTimeout(ok_btn_timeout);
		},
		grid_colour(vx, vy) {
			if (this.grid_hover[0] == vx && this.grid_hover[1] == vy) {
				return "#FF777788";
			}
			if (this.show_grid_answer == false) {
				if (vx == 1 || vy == 1) {
					return "#77777722";
				} else {
					return "#777777FF";
				}
			} else {
				if (vx == 1 || vy == 1) {
					if (vx == this.current_x || vy == this.current_y) {
						return "#77CC77FF";
					} else {
						return "#77777722";
					}
				} else {
					if (vx == this.current_x && vy == this.current_y) {
						return "#88DD88FF";
					} else if (vx == this.current_x || vy == this.current_y) {
						return "#778477FF";
					} else {
						return "#777777FF";
					}
				}
			}
		},
		grid_mouse_over(vx, vy) {
			if (vx > 1 && vy > 1) this.grid_hover = [vx, vy];
		},
		grid_mouse_leave(vx, vy) {
			if (this.grid_hover[0] == vx && this.grid_hover[1] == vy) {
				this.grid_hover = [-1, -1];
			}
		},
		grid_mousedown(vx, vy) {
			if (this.input_disabled) return;
			this.log_grid_response(vx, vy, true);
		},
		grid_mouseup() {},
		grid_touchstart(vx, vy, event) {
			if (event) {
				event.preventDefault();
			}
			if (this.input_disabled) return;
			this.log_grid_response(vx, vy, false);
		},
		grid_touchend(event) {
			if (event) {
				event.preventDefault();
			}
		},
		log_grid_response(vx, vy, mouse_tap) {
			this.input_disabled = true;

			//var rt = ((Date.now() - this.item_start_time) / 1000.0).toFixed(3);
			var rt = (Date.now() - this.item_start_time) / 1000.0;
			var corr = vx * vy == this.current_x * this.current_y;
			this.feedback_response_correct = corr;
			this.item_feedback = true;

			/*console.log(
				"log: " +
					this.condition +
					", " +
					mouse_tap +
					", " +
					vx +
					"·" +
					vy +
					", corr: " +
					corr +
					", rt: " +
					rt
			);*/
			mouse_used |= mouse_tap;
			session_results.grid_rts.push(rt);
			if (corr) session_results.grid_corr_rts.push(rt);
			else session_results.grid_err_rts.push(rt);
			cards.update(this.grid_deck, corr, rt);

			setTimeout(() => {
				this.item_feedback = false;
				this.new_grid_item();
			}, 2000);
		},
		log_active_response() {
			//var rt = ((Date.now() - this.item_start_time) / 1000.0).toFixed(3);
			var rt = (Date.now() - this.item_start_time) / 1000.0;
			var corr = this.answer == this.current_x * this.current_y;
			/*console.log(
				"log active: " + this.answer + ", corr: " + corr + ", rt: " + rt
			);*/

			session_results.active_rts.push(rt);
			if (corr) session_results.active_corr_rts.push(rt);
			else session_results.active_err_rts.push(rt);
			cards.update(this.active_deck, corr, rt);

			//var save = cards.save_deck(this.active_deck);
			//console.log(JSON.stringify(save));

			this.new_active_item();
		},
		btn_ok_colour() {
			if (this.answer.length == 0) return "#66BB6622";
			return this.btn_ok_down ? "#99EE99FF" : "#66BB66FF";
		},
		btn_erase_colour() {
			if (this.answer.length == 0) return "#BB666622";
			return this.btn_erase_down ? "#EE9999FF" : "#BB6666FF";
		},
		btn_colour(idx) {
			if (
				this.answer.length == 3 ||
				(this.answer.length == 2 && this.answer[0] != "1")
			)
				return "#77777722";

			//if (this.btn_down == idx) return "#ccccff99";
			//else
			if (this.btn_hover == idx) return "#FF777788";
			else return "#77777788";
		},
		end_test() {
			this.show_buttons = false;
			this.input_disabled = true;

			var num_active_corr = session_results.active_corr_rts.length;
			var num_active_err = session_results.active_err_rts.length;
			var num_active = session_results.active_rts.length;

			var num_grid_corr = session_results.grid_corr_rts.length;
			var num_grid_err = session_results.grid_err_rts.length;
			var num_grid = session_results.grid_rts.length;

			const [active_rt_median, active_rt_mad] =
				stat.mut_array_median_absolute_deviation(
					session_results.active_rts
				);

			const [active_corr_rt_median, active_corr_rt_mad] =
				stat.mut_array_median_absolute_deviation(
					session_results.active_corr_rts
				);

			const [active_err_rt_median, active_err_rt_mad] =
				stat.mut_array_median_absolute_deviation(
					session_results.active_err_rts
				);

			const [grid_rt_median, grid_rt_mad] =
				stat.mut_array_median_absolute_deviation(
					session_results.grid_rts
				);

			const [grid_corr_rt_median, grid_corr_rt_mad] =
				stat.mut_array_median_absolute_deviation(
					session_results.grid_corr_rts
				);

			const [grid_err_rt_median, grid_err_rt_mad] =
				stat.mut_array_median_absolute_deviation(
					session_results.grid_err_rts
				);

			/*
			console.log("--- active ---");
			console.log(
				"active_rt_median:      " + active_rt_median.toFixed(3)
			);
			console.log("active_rt_mad:         " + active_rt_mad.toFixed(3));
			console.log();
			console.log(
				"active_corr_rt_median: " + active_corr_rt_median.toFixed(3)
			);
			console.log(
				"active_corr_rt_mad:    " + active_corr_rt_mad.toFixed(3)
			);
			console.log();
			console.log(
				"active_err_rt_median:  " + active_err_rt_median.toFixed(3)
			);
			console.log(
				"active_err_rt_mad:     " + active_err_rt_mad.toFixed(3)
			);
			console.log();
			console.log("num_active:            " + num_active);
			console.log("num_active_corr:       " + num_active_corr);
			console.log("num_active_err:        " + num_active_err);
			console.log();
			console.log();

			console.log("--- grid ---");
			console.log("grid_rt_median:      " + grid_rt_median.toFixed(3));
			console.log("grid_rt_mad:         " + grid_rt_mad.toFixed(3));
			console.log();
			console.log(
				"grid_corr_rt_median: " + grid_corr_rt_median.toFixed(3)
			);
			console.log("grid_corr_rt_mad:    " + grid_corr_rt_mad.toFixed(3));
			console.log();
			console.log(
				"grid_err_rt_median:  " + grid_err_rt_median.toFixed(3)
			);
			console.log("grid_err_rt_mad:     " + grid_err_rt_mad.toFixed(3));
			console.log();
			console.log("num_grid:            " + num_grid);
			console.log("num_grid_corr:       " + num_grid_corr);
			console.log("num_grid_err:        " + num_grid_err);
			console.log();
			*/
			this.result_text1 =
				"--- active ---" +
				"<br>" +
				"active_rt_median:      " +
				active_rt_median.toFixed(3) +
				"<br>" +
				"active_rt_mad:         " +
				active_rt_mad.toFixed(3) +
				"<br><br>" +
				"active_corr_rt_median: " +
				active_corr_rt_median.toFixed(3) +
				"<br>" +
				"active_corr_rt_mad:    " +
				active_corr_rt_mad.toFixed(3) +
				"<br><br>" +
				"active_err_rt_median:  " +
				active_err_rt_median.toFixed(3) +
				"<br>" +
				"active_err_rt_mad:     " +
				active_err_rt_mad.toFixed(3) +
				"<br><br>" +
				"num_active:            " +
				num_active +
				"<br>" +
				"num_active_corr:       " +
				num_active_corr +
				"<br>" +
				"num_active_err:        " +
				num_active_err;

			this.result_text2 =
				"--- grid ---<br>" +
				"grid_rt_median:      " +
				grid_rt_median.toFixed(3) +
				"<br>" +
				"grid_rt_mad:         " +
				grid_rt_mad.toFixed(3) +
				"<br><br>" +
				"grid_corr_rt_median: " +
				grid_corr_rt_median.toFixed(3) +
				"<br>" +
				"grid_corr_rt_mad:    " +
				grid_corr_rt_mad.toFixed(3) +
				"<br><br>" +
				"grid_err_rt_median:  " +
				grid_err_rt_median.toFixed(3) +
				"<br>" +
				"grid_err_rt_mad:     " +
				grid_err_rt_mad.toFixed(3) +
				"<br><br>" +
				"num_grid:            " +
				num_grid +
				"<br>" +
				"num_grid_corr:       " +
				num_grid_corr +
				"<br>" +
				"num_grid_err:        " +
				num_grid_err;
			if (mouse_used == false) this.result_text2 += "<br> touch screen	";
			//onsole.log("mouse used: " + mouse_used);
			/*setTimeout(() => {
				store.commit("setTaskMode", "clickToExit");
				this.saveSessionResults();
			}, 10000);*/
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			persist.set_progress_data("choke_multiple_choice_test", 1);

			persist.log("results", {
				score: 123,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
			});
		},
		setup() {
			store.commit("setTaskMode", "playing");
			//store.commit('setTaskMode', 'readyImmediateInstruct');
			//store.commit("setTaskMode", "ready_no_exit");
			store.commit("taskPhase", 0);
			this.$parent.show_buttons = false;
		},
		start_active_set() {
			this.condition = "Active";
			this.new_active_item();
		},
		start_grid_set() {
			this.condition = "Grid";
			this.new_grid_item();
		},
	},
	components: {
		countDown,
	},
	mounted() {
		this.setup();

		this.active_deck = cards.new_deck();
		this.grid_deck = cards.new_deck();

		this.active_set = "A";
		this.grid_set = "B";

		/*		cards.update(this.active_deck, true, 103);
		cards.update(this.active_deck, true, 102);
		cards.update(this.active_deck, true, 101);

		cards.update(this.active_deck, false, 103);
		cards.update(this.active_deck, false, 102);
		cards.update(this.active_deck, false, 101);

		console.log(JSON.stringify(this.active_deck));

		var save = cards.save_deck(this.active_deck);

		console.log(JSON.stringify(save));

		var load = cards.load_deck(save);

		console.log(JSON.stringify(load));*/

		//this.start_active_set();
		this.start_grid_set();

		this.$refs.countDownB.play(
			() => {
				//console.log("B Time up!");
				this.start_active_set();
			},
			0,
			120,
			600,
			-65
		);
		this.$refs.countDownA.play(
			() => {
				//console.log("A Time up!");
				this.condition = "Result";
				this.question = "";
				this.answer = "";
				this.item_feedback = false;
				this.input_disabled = true;
				this.end_test();
			},
			120,
			120,
			600,
			280
		);
	},
};
</script>
