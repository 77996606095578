export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						instructions: {
							a: 'Du kommer att få se 12 par av ord.<br><br>' +
								'Försök att minnas vilka ord som hör ihop.<br><br>',
							//'Efter det får du skriva ordet som saknas i varje par.',
							b: 'Tryck på "Fortsätt" för att se ett exempel',
							c: 'När du har fått se 12 sådana ordpar kommer det första ordet i varje par att visas igen. Din uppgift är då att skriva det andra ordet i varje par',
							d: "Du får då 7 sekunder på dig att skriva ordet.<br>Exempel:",
							e: 'Tryck på "Fortsätt" när du är redo att börja',
							f: 'Nu kommer du att få skriva in de ord som saknas...',
							l1: 'Nu kommer lista 1 av 2...',
							l2: 'Nu kommer lista 2 av 2...',
							pause: 'En liten paus...',
						},
						continue_: 'Fortsätt ➤',
						done: 'Klart!',
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt boll';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						word_pairs: [
							// list 1:
							[
								{ a: "metall", b: "järn", pos: 9, grp: 0 },
								{ a: "baby", b: "gråter", pos: 6, grp: 0 },
								{ a: "krossa", b: "mörker", pos: 11, grp: 1 },
								{ a: "norr", b: "söder", pos: 0, grp: 0 },
								{ a: "skola", b: "affär", pos: 10, grp: 1 },
								{ a: "golv", b: "glas", pos: 4, grp: 1 },
								{ a: "ros", b: "blomma", pos: 3, grp: 0 },
								{ a: "upp", b: "ned", pos: 7, grp: 0 },
								{ a: "hylla", b: "ljus", pos: 5, grp: 1 },
								{ a: "lyda", b: "centimeter", pos: 2, grp: 1 },
								{ a: "frukt", b: "äpple", pos: 1, grp: 0 },
								{ a: "kålhuvud", b: "penna", pos: 8, grp: 1 },
							],
							// list 2:
							[
								{ a: "himmel", b: "helvete", pos: 9, grp: 0 },
								{ a: "bestick", b: "gaffel", pos: 6, grp: 0 },
								{ a: "bäck", b: "stol", pos: 11, grp: 1 },
								{ a: "lax", b: "fisk", pos: 0, grp: 0 },
								{ a: "musik", b: "arg", pos: 10, grp: 1 },
								{ a: "springa", b: "gräs", pos: 4, grp: 1 },
								{ a: "hund", b: "katt", pos: 3, grp: 0 },
								{ a: "tobak", b: "snus", pos: 7, grp: 0 },
								{ a: "telefon", b: "sadel", pos: 5, grp: 1 },
								{ a: "land", b: "ring", pos: 2, grp: 1 },
								{ a: "student", b: "läser", pos: 1, grp: 0 },
								{ a: "bensin", b: "mamma", pos: 8, grp: 1 },
							],
						],
					};
			}
		}
	}
}
