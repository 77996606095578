export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						taskName: "Siffran",
						instructions: {
							a: 'Siffror kommer att visas, en i taget. Din uppgift är att minnas och återge de <font color="#FFCC66">4 sista</font> siffrorna.',
							b: "Tryck här för att se en instruktion ➤",
							c: "Här följer en demonstration",
							d1: 'I det här exemplet ska jag återge <font color="#FFCC66">4 siffror</font>',
							d2: "Snart kommer flera siffror visas",
							d3: `Men uppgiften är att bara återge de <font color="#FFCC66">4 sista</font>`,
							e: "4 rätt!",
							f: "Tryck på den stora pilen för att börja!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Kom ihåg den <font color="#FFCC66">sista</font> siffran';
								else return `Kom ihåg de <font color="#FFCC66">${num} sista</font> siffrorna`;
							},
							respond: (num) => {
								if (num == 1) return 'Vilken siffra var det som visades sist?';
								else return `Återge de <font color="#FFCC66">${num} sista</font> siffrorna, i samma ordning`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt siffra';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						}
					};
			}
		}
	}
}
