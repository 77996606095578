<template>
	<g>
		<defs>
			<clipPath id="cir_clip">
				<circle r="90" cx="90" cy="90" />
			</clipPath>

			<g id="cir" transform="scale(0.25)">
				<circle r="90" fill="#FF8800" cx="90" cy="90" stroke="#00000010" stroke-width="5" />
				<circle r="90" cx="120" cy="90" fill="#FFFFFF5F" clip-path="url(#cir_clip)" />
				<ellipse rx="100" ry="80" fill="#FFFFFF6F" cx="90" cy="45" clip-path="url(#cir_clip)" />
				<ellipse rx="84" ry="60" fill="#FFFFFF6F" cx="90" cy="20" clip-path="url(#cir_clip)" />
				<ellipse rx="60" ry="30" fill="#FFFFFF6F" cx="90" cy="20" clip-path="url(#cir_clip)" />
				<ellipse rx="25" ry="10" fill="#FFFFFF" cx="90" cy="10" clip-path="url(#cir_clip)" />
			</g>
		</defs>

		<g id="spatialUpdatingIcon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>
			<use xlink:href="#cir" x="-83" y="-83" />
			<use xlink:href="#cir" x="-23" y="-83" />
			<use xlink:href="#cir" x="37" y="-83" />
			<use xlink:href="#cir" x="-83" y="-23" />
			<use xlink:href="#cir" x="-23" y="-23" />
			<use xlink:href="#cir" x="37" y="-23" />
			<use xlink:href="#cir" x="-83" y="37" />
			<use xlink:href="#cir" x="-23" y="37" />
			<use xlink:href="#cir" x="37" y="37" />
			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.spatialUpdatingLabel}}</text>
		</g>
	</g>
</template>

<script>

export default {
	name: "spatialUpdatingIcon",
	idleTween: null,
};
</script>
