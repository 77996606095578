<template>
	<g>
		<text
			id = "currentWord"
			:y="y"
			:x="x"
			class="skyword"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="60"
			fill="#000000"
		>{{this.$parent.currentWord}}</text>
	</g>
</template>

<script>
export default {
	name: 'currentWord',
	props: ['x', 'y'],
}
</script>
