<template>
	<g>
		<transition name="fade"
			><g v-show="item == 1">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r01q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r01a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 2">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r02q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r02a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 3">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r03q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r03a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 4">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r04q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r04a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 5">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r05q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r05a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 6">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r06q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r06a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 7">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r07q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r07a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 8">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r08q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r08a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 9">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r09q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r09a.png"
				/> </g
		></transition>

		<transition name="fade"
			><g v-show="item == 10">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r10q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r10a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 11">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r11q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r11a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 12">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r12q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r12a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 13">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r13q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r13a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 14">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r14q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r14a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 15">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r15q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r15a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 16">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r16q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r16a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 17">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r17q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r17a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 18">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r18q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r18a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 19">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r19q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r19a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 20">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r20q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r20a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 21">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r21q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r21a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 22">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r22q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r22a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 23">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r23q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r23a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 24">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r24q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r24a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 25">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r25q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r25a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 26">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r26q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r26a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 27">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r27q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r27a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 28">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r28q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r28a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 29">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r29q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r29a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 30">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r30q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r30a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 31">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r31q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r31a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 32">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r32q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r32a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 33">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r33q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r33a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 34">
				<image
					x="-343"
					y="-450"
					width="686"
					height="444"
					xlink:href="/img/r34q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r34a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 35">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r35q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r35a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 36">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r36q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r36a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 37">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r37q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r37a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 38">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r38q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r38a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 39">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r39q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r39a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 40">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r40q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r40a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 41">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r41q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r41a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 42">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r42q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r42a.png"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 43">
				<image
					x="-464"
					y="-450"
					width="928"
					height="444"
					xlink:href="/img/r43q.png"
				/>
				<image
					x="-464"
					y="110"
					width="928"
					height="355"
					xlink:href="/img/r43a.png"
				/> </g
		></transition>

		<g v-if="active_buttons">
			<rect
				x="-465"
				y="110"
				width="220"
				height="155"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(1)"
				class="clickable"
			/>
			<rect
				x="-230"
				y="110"
				width="220"
				height="155"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(2)"
				class="clickable"
			/>
			<rect
				x="5"
				y="110"
				width="220"
				height="155"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(3)"
				class="clickable"
			/>
			<rect
				x="240"
				y="110"
				width="220"
				height="155"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(4)"
				class="clickable"
			/>
			<rect
				x="-465"
				y="310"
				width="220"
				height="155"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(5)"
				class="clickable"
			/>
			<rect
				x="-230"
				y="310"
				width="220"
				height="155"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(6)"
				class="clickable"
			/>
			<rect
				x="5"
				y="310"
				width="220"
				height="155"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(7)"
				class="clickable"
			/>
			<rect
				x="240"
				y="310"
				width="220"
				height="155"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(8)"
				class="clickable"
			/>
		</g>

		<hand v-show="handVisible" ref="hand" />

		<transition name="quickfade">
			<g v-if="this.ins1"
				><foreignObject x="-400" y="0" width="800" height="100"
					><div class="readbox">
						<span v-html="text.ins1" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins2"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.ins2" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins3"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.ins3" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins4"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.ins4" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins5"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.ins5" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.look"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.look" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.fail1"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.fail1" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.fail2"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.fail2" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.fail3"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.fail3" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.corr"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.corr" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.point"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.point" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.minutes_left"
				><foreignObject x="-400" y="0" width="800" height="200"
					><div class="readbox">
						<span v-html="text.minutes_left" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.done">
				<circle cx="0" cy="0" r="160" fill="#44AA44" stroke="none" />
				<text
					x="0"
					y="0"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="36"
					fill="#006600"
					>{{ text.done }}</text
				>
			</g>
		</transition>
		<transition name="fade">
			<g v-if="!done">
				<circle
					cx="700"
					cy="-300"
					r="80"
					fill="#00000022"
					stroke="none"
				/>
				<text
					x="700"
					y="-310"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="24"
					fill="#000000"
					>{{ text.items_left }}</text
				>
				<text
					x="700"
					y="-280"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="24"
					fill="#000000"
					>{{ items_left }}</text
				>
			</g>
		</transition>
		<path
			id="items_arc"
			opacity="0"
			fill="none"
			stroke="#000000"
			stroke-width="10"
		/>

		<transition name="fade">
			<g v-if="timer_enabled">
				<circle
					cx="700"
					cy="-100"
					r="80"
					fill="#00000022"
					stroke="none"
				/>
				<text
					x="700"
					y="-110"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="24"
					fill="#FFFFFF"
					>{{ text.time_left }}</text
				>
				<text
					x="700"
					y="-80"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="24"
					fill="#FFFFFF"
					>{{ time_left }}</text
				>
			</g>
		</transition>
		<path
			id="time_arc"
			opacity="0"
			fill="none"
			stroke="#FFFFFF"
			stroke-width="10"
		/>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_ravens/lang.js";
import hand from "@/v/hand.vue";
import persist from "@/persist.js";
import gsap from "gsap";

/*
		<filter id="blurMe">
			<feGaussianBlur in="SourceGraphic" stdDeviation="10" />
		</filter>
*/

var time_limit = 40; // 40 minuter
var timer = 0;
var correct_responses = new Array(
	-9999,
	8,
	4,
	5,
	1,
	2,
	5,
	6,
	/*real items: */ 5,
	1,
	7,
	4,
	3,
	1,
	6,
	1,
	8,
	4,
	5,
	6,
	2,
	1,
	2,
	4,
	6,
	7,
	3,
	8,
	8,
	7,
	6,
	3,
	7,
	2,
	7,
	5,
	6,
	5,
	4,
	8,
	5,
	1,
	3,
	2
);

export default {
	name: "test_ravens",
	//genväg-alias:
	local: store.state.test_ravens,
	components: { hand },
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {
			done: false,
			item: -100,
			handVisible: false,
			active_buttons: false,
			ins1: false,
			ins2: false,
			ins3: false,
			ins4: false,
			ins5: false,
			look: false,

			fail1: false,
			fail2: false,
			fail3: false,

			corr: false,
			point: false,
			minutes_left: false,

			a_opacity: 0,
			b_opacity: 0,
			a_anim: 0,
			b_anim: 0,

			item_start_time: 0,
			num_recently_failed_items: 0,

			items_left: "",
			time_left: "40:00",
			timer_enabled: false,

			results: {
				start_time: 0,
				total_time: 0,
				items: [],
				num_correct: 0,
				num_correct_total: 0,
			},
		};
	},
	watch: {
		item: function (/*new_item*/) {
			this.item_start_time = Date.now();
			this.active_buttons = false;
			this.handVisible = false;
			this.clearText();

			this.items_left = (correct_responses.length - this.item).toString();
			if (this.item < 1 || this.item >= correct_responses.length) {
				const arc = document.getElementById("items_arc");
				arc.setAttribute("opacity", 0);
			} else {
				var prop = (this.item - 0.99) / correct_responses.length;
				const arc = document.getElementById("items_arc");
				arc.setAttribute(
					"d",
					describeArc(700, -300, 80, prop * 360, 360)
				);
				arc.setAttribute("opacity", 1);
			}

			if (this.item == 1) {
				this.a_opacity = 1;
				this.b_opacity = 0.0;
				this.handVisible = true;
				this.$refs.hand.show(() => {
					var hand = gsap.timeline();
					hand.to("#hand", {
						delay: 0.5,
						duration: 2,
						x: -150,
						y: -320,
						ease: "power3.inOut",
						onComplete: () => {
							this.ins1 = true;
							//this.$refs.hand.click();
						},
					});
					hand.to("#hand", {
						duration: 1.5,
						x: 330,
						y: -320,
						ease: "power3.inOut",
					});
					hand.to("#hand", {
						duration: 1.5,
						x: -150,
						y: -70,
						ease: "power3.inOut",
					});
					hand.to("#hand", {
						duration: 1.5,
						x: 330,
						y: -70,
						ease: "power3.inOut",
					});
					hand.to("#hand", {
						duration: 1,
						onComplete: () => {
							this.ins1 = false;
							this.animateA(0.1);
							this.animateB(1);
						},
					});
					hand.to("#hand", {
						delay: 1,
						duration: 2,
						x: -300,
						y: 220,
						ease: "power3.inOut",
						onComplete: () => {
							this.ins2 = true;
						},
					});
					hand.to("#hand", {
						duration: 1.5,
						x: 450,
						y: 220,
						ease: "power3.inOut",
					});
					hand.to("#hand", {
						duration: 1.5,
						x: -300,
						y: 430,
						ease: "power3.inOut",
					});
					hand.to("#hand", {
						duration: 2,
						x: 450,
						y: 430,
						ease: "power3.inOut",
						onComplete: () => {},
					});
					hand.to("#hand", {
						duration: 2,
						onComplete: () => {
							this.ins2 = false;
							this.animateA(1);
							this.animateB(0.1);
						},
					});
					hand.to("#hand", {
						duration: 2,
						x: 290,
						y: -80,
						ease: "power3.inOut",
						onComplete: () => {
							this.ins3 = true;
							//this.animateA(1);
							//this.animateB(1);
						},
					});
					hand.to("#hand", {
						duration: 4,
						onComplete: () => {
							this.ins3 = false;
							this.$refs.hand.hide();
							this.active_buttons = true;
							this.item_start_time = Date.now();
							this.animateB(1);
						},
					});
				});
			} else if (this.item >= correct_responses.length) {
				this.clearText();
				this.test_is_over();
			} else {
				if (this.item == 2) {
					this.ins5 = true;
				} else if (this.item == 3) {
					this.clearText();
				} else if (this.item == 8) {
					this.start_test_timer(time_limit);
					this.minutes_left = true;
					setTimeout(() => {
						this.clearText();
					}, 5000);
				}
				this.a_opacity = 1;
				this.b_opacity = 1;
				setTimeout(() => {
					this.active_buttons = true;
					this.animateB(1);
				}, 1000);
			}
		},
	},

	methods: {
		animateA(to) {
			var step = (to - this.a_opacity) / 50;
			this.a_anim = setInterval(() => {
				if (Math.abs(to - this.a_opacity) >= Math.abs(step)) {
					this.a_opacity += step;
				} else {
					clearInterval(this.a_anim);
					this.a_opacity = to;
				}
			}, 20);
		},
		animateB(to) {
			var step = (to - this.b_opacity) / 50;
			this.b_anim = setInterval(() => {
				if (Math.abs(to - this.b_opacity) >= Math.abs(step)) {
					this.b_opacity += step;
				} else {
					clearInterval(this.b_anim);
					this.b_opacity = to;
				}
			}, 20);
		},
		clearText() {
			this.look = false;
			this.ins1 = false;
			this.ins2 = false;
			this.ins3 = false;
			this.ins4 = false;
			this.ins5 = false;
			this.look = false;

			this.fail1 = false;
			this.fail2 = false;
			this.fail3 = false;
			this.corr = false;
			this.point = false;
			this.minutes_left = false;
		},
		help_failed() {
			this.clearText();
			this.look = true;
			this.handVisible = true;
			this.$refs.hand.show(() => {
				var hand = gsap.timeline();
				hand.to("#hand", {
					delay: 2,
					duration: 1,
					x: 270,
					y: -370,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1,
					onComplete: () => {
						this.clearText();
						this.fail1 = true;
					},
				});
				hand.to("#hand", {
					delay: 0.5,
					duration: 6,
					x: 270,
					y: -100,
					ease: "power3.inOut",
				});
				hand.to("#hand", {
					duration: 1,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1,
					x: -250,
					y: -70,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.fail2 = true;
					},
				});
				hand.to("#hand", {
					delay: 0.5,
					duration: 6,
					x: 280,
					y: -70,
					ease: "power3.inOut",
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1.5,
					x: 450,
					y: 430,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.corr = true;
					},
				});
				hand.to("#hand", {
					delay: 4,
					duration: 2,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1,
					x: 290,
					y: -80,
					ease: "power3.inOut",
					onComplete: () => {
						this.point = true;
						//this.animateA(1);
						//this.animateB(1);
					},
				});
				hand.to("#hand", {
					duration: 5,
					onComplete: () => {
						this.clearText();
						this.fail3 = true;
						this.animateB(0);
					},
				});
				hand.to("#hand", {
					duration: 3,
					onComplete: () => {
						this.$refs.hand.hide();
						this.clearText();
						this.goto_next_item();
					},
				});
			});
		},

		clicked(r) {
			var item_rt = (Date.now() - this.item_start_time) / 1000;
			this.clearText();
			//console.log("clicked " + r);
			this.active_buttons = false;
			//this.log_question_response(true);
			var it = {
				rt: item_rt,
				r: r,
				c: 0,
			};

			if (r == correct_responses[this.item]) {
				/*console.log(
					"Item:  " +
						this.item +
						" -- Correct: " +
						correct_responses[this.item]
				);*/
				it.c = 1;
			} /*else {
				console.log(
					"Item: " +
						this.item +
						" -- Incorrect: " +
						correct_responses[this.item] +
						", not " +
						r
				);
			}*/

			this.results.num_correct_total =
				this.results.num_correct_total + it.c;
			if (this.item >= 8) {
				this.results.num_correct = this.results.num_correct + it.c;
			}

			if (this.item == 1) {
				if (it.c == 1) {
					this.ins4 = true;
					setTimeout(() => {
						this.clearText();
						this.goto_next_item();
					}, 2000);
				} else {
					this.help_failed();
				}
			} else {
				this.goto_next_item();
			}

			//console.log(it);
			this.results.items.push(it);
			//console.log("failed: " + this.num_recently_failed_items);

			//if (this.item > 7) {
			// Efter övningsuppgifterna:
			/*if (it.c == 0) {
					this.num_recently_failed_items += 1;
				} else {
					this.num_recently_failed_items = 0;
				}

				if (this.num_recently_failed_items >= 4) {
					this.test_is_over();
				}*/
			//}
		},
		goto_next_item() {
			this.item = this.item + 1;
		},
		test_is_over() {
			if (timer != 0) clearTimeout(timer);
			this.timer_enabled = false;
			this.active_buttons = false;
			this.handVisible = false;
			this.item = -1;
			this.clearText();
			this.done = true;

			const arc = document.getElementById("time_arc");
			arc.setAttribute("opacity", 0);
			const arc2 = document.getElementById("items_arc");
			arc2.setAttribute("opacity", 0);

			//console.log("DONE!" );

			this.saveSessionResults();
			setTimeout(() => {
				//store.dispatch("gotoModule", "test_span_spatial_linear");
				store.dispatch("gotoModule", "home");
			}, 5000);
		},
		saveSessionResults() {
			this.results.total_time = Math.trunc(
				Date.now() - this.results.start_time
			);
			persist.log("results", this.results);
			persist.set_progress_data("ravens", 1);
		},
		start_test_timer(minutes) {
			var start_time = Date.now();
			var end_time = start_time + minutes * 60000;
			var interval = end_time - start_time;

			const arc = document.getElementById("time_arc");
			arc.setAttribute("d", describeArc(700, -100, 80, 360, 360));
			arc.setAttribute("opacity", 1);
			this.timer_enabled = true;

			timer = setInterval(() => {
				var now = Date.now();
				if (now >= end_time) {
					this.timer_enabled = false;
					if (timer != 0) {
						clearInterval(timer);
						timer = 0;
						this.test_is_over();
					}
				} else {
					var left = end_time - now;
					var prop = 1 - left / interval;
					var t = Math.trunc(left / 1000);
					var show_minutes = Math.trunc(t / 60);
					var show_seconds = Math.trunc(t - 60 * show_minutes);

					var str_minutes = show_minutes.toString();
					if (show_minutes < 10) str_minutes = "0" + str_minutes;

					var str_seconds = show_seconds.toString();
					if (show_seconds < 10) str_seconds = "0" + str_seconds;

					var str = str_minutes + ":" + str_seconds;

					//console.log("tick: " + str);
					this.time_left = str;

					const arc = document.getElementById("time_arc");
					arc.setAttribute(
						"d",
						describeArc(700, -100, 80, prop * 360, 360)
					);
				}
			}, 1000);
		},
	},
	mounted() {
		this.item = 1;
		this.results.start_time = Date.now();
		//this.start_test_timer(0.5);
	},
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y,
	].join(" ");

	return d;
}
</script>