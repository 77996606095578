export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						see_colours: "Kan du skilja alla dessa färger åt?",
						yes: "Ja, inga problem",
						no: "Nej, inte så bra",
						instructions: {
							t0_button: 'Tryck här för att se en instruktion ➤',
							t1: 'Här nedanför ser du två ord och en färg',
							t2: 'Det första ordet är en plats (Skog)<br>och det andra ordet är ett föremål (Bok)',
							a_place: 'Skog',
							an_object: 'Bok',
							t3: 'Din uppgift är att komma ihåg vilket föremål och vilken färg som hör till platsen',
							t4: 'Du kan till exempel föreställa dig en stor gul bok mitt i skogen',
							t5: 'I det efterföljande testet kommer platsen att visas. Din uppgift är då att ange det föremål och den färg som hör till platsen'
						},
						training: {
							colours_correct: (num) => {
								return `Rätta färger: ${num}`;
							},
							objects_correct: (num) => {
								return `Rätta föremål: ${num}`;
							},
							pick_colour_and_object: 'Ange rätt färg och föremål',
						},
						places: [
							"Akvedukt",
							"Äldreboende",
							"Allrum",
							"Ambassad",
							"Äng",
							"Arena",
							"Arkiv",
							"Atrium",
							"Aula",
							"Aveny",
							"Bäck",
							"Backe",
							"Bageri",
							"Balkong",
							"Bänk",
							"Barnhem",
							"Barnkammare",
							"Barrikad",
							"Barstol",
							"Bastu",
							"Bensinmack",
							"Berg ",
							"Bibliotek",
							"Biltvätt",
							"Bio",
							"Bowlinghall",
							"Brandstation",
							"Bro",
							"Bukt",
							"Busshållplats",
							"Butik",
							"By",
							"Cykelbana",
							"Dal",
							"Datacenter",
							"Datorlabb",
							"Djungel",
							"Djuraffär",
							"Djurpark",
							"Dörr",
							"Entré",
							"Fabrik",
							"Fält",
							"Familjerum",
							"Fängelse",
							"Färjeterminal",
							"Flod",
							"Flygplats",
							"Fönster",
							"Fontän",
							"Församlingshus",
							"Förråd",
							"Fyr",
							"Gång",
							"Garage",
							"Garderob",
							"Gästrum",
							"Gatlykta",
							"Gräsmatta",
							"Grotta",
							"Gym",
							"Hall",
							"Halvö",
							"Hav",
							"Hemmabio",
							"Hemmakontor",
							"Hobbyrum",
							"Hög",
							"Holme",
							"Hörsal",
							"Hotell",
							"Hylla",
							"Igloo",
							"Järnhandel",
							"Kafé",
							"Kafeteria",
							"Källare",
							"Kanal",
							"Kapprum",
							"Kasino",
							"Klädbutik",
							"Klassrum ",
							"Klippa",
							"Kloster",
							"Koja",
							"Kök",
							"Konferensrum",
							"Konserthall",
							"Kontor",
							"Kontrollrum",
							"Köpcentrum",
							"Korridor",
							"Kraftverk",
							"Kulle",
							"Kyrka",
							"Laboratorium",
							"Låda",
							"Ladugård",
							"Lekrum",
							"Foajé",
							"Loft",
							"Mataffär",
							"Matbord",
							"Matkällare",
							"Matsal",
							"Militärbas",
							"Monument",
							"Museum",
							"Nattduksbord",
							"Nöjesfält ",
							"Observatorium",
							"Öken",
							"Oljedepå",
							"Omklädningsrum",
							"Pagod",
							"Palats",
							"Pariserhjul",
							"Park ",
							"Parkering",
							"Parlament ",
							"Pergola",
							"Personalrum",
							"Pir",
							"Polisstation",
							"Port",
							"Postkontor",
							"Postrum",
							"Pumphus",
							"Radhus",
							"Radiotorn",
							"Raffinaderi",
							"Rättssal",
							"Resort",
							"Restaurang",
							"Salong",
							"Säng",
							"Savann",
							"Simbassäng",
							"Sjö",
							"Sjöbod",
							"Sjukhus",
							"Skafferi ",
							"Skåp",
							"Skeppsvarv",
							"Skjul",
							"Skog",
							"Skola",
							"Skorsten",
							"Skrivbord ",
							"Skrivbordsstol",
							"Slott",
							"Soffa",
							"Soffbord",
							"Sovrum",
							"Spårvagn",
							"Sporthall",
							"Stad",
							"Stadshus",
							"Staffli",
							"Staket",
							"Stall",
							"Sten",
							"Stol",
							"Strand",
							"Studenthem",
							"Studio",
							"Stuga",
							"Svit",
							"Tåg",
							"Tågräls",
							"Tågstation",
							"Tak",
							"Tält",
							"Teater",
							"Tempel",
							"Terrass",
							"Toalett",
							"Torn",
							"Trädgårdsdamm",
							"Trappa",
							"Träsk",
							"Tronsal",
							"Trottoar",
							"Tunnel",
							"Tvättstuga",
							"Universitet",
							"Uteplats",
							"Utomhuskök ",
							"Utrustningsrum",
							"Väg",
							"Vägg",
							"Vandrarhem",
							"Väntrum",
							"Vardagsrum",
							"Vårdcentral",
							"Vattentorn",
							"Växthus",
							"Veranda",
							"Villa",
							"Vind",
							"Vindsrum",
							"Vinkällare",
							"Verkstad",
						],
						objects: [
							"Ägg",
							"Äggklocka",
							"Äggkopp",
							"Ål",
							"Älg",
							"Ambulans",
							"Anka",
							"Ansiktsmask",
							"Antilop",
							"Armband",
							"Åsna",
							"Babian",
							"Baddräkt",
							"Badrock",
							"Badsalt",
							"Balettskor",
							"Ballong",
							"Bältdjur",
							"Skärp",
							"Bandage",
							"Banjo",
							"Batteri",
							"Bäver",
							"Bestick",
							"Bikini",
							"Bil",
							"Bilnyckel",
							"Björn",
							"Bläckfisk",
							"Blöja",
							"Blomma",
							"Blomvas",
							"Blus",
							"Bok",
							"Boll",
							"Bomullspinnar",
							"Borr",
							"Brädspel",
							"Brandalarm",
							"Brandbil",
							"Brödkniv",
							"Brödkorg",
							"Burk",
							"Buss",
							"Byxor",
							"Cello",
							"Chinchilla",
							"Cykel",
							"Dagbok",
							"Dammtrasa",
							"Dammvippa",
							"Datormus",
							"Dator",
							"Deg",
							"Delfin",
							"Deodorant",
							"Diskhandduk ",
							"Docka",
							"Dockhus",
							"Domino",
							"Dörrmatta",
							"Duk",
							"Duva",
							"Ekorre",
							"Elefant",
							"Fläkt",
							"Elgitarr",
							"Eyeliner",
							"Fackla",
							"Fågel",
							"Falk",
							"Får",
							"Färja",
							"Fasan",
							"Finskor",
							"Fiol",
							"Fisk",
							"Fiskskål",
							"Fjäril",
							"Fladdermus",
							"Fläkt",
							"Flamingo",
							"Flaska",
							"Flasköppnare",
							"Flöjt",
							"Fluga",
							"Flygplan",
							"Förkläde",
							"Sladd",
							"Förstoringsglas",
							"Fotografi",
							"Frukost",
							"Frukt",
							"Gaffel",
							"Galje",
							"Gardin",
							"Garn",
							"Gås",
							"Gepard",
							"Get",
							"Geting",
							"Gibbon",
							"Giraff",
							"Gitarr",
							"Glas",
							"Glasögon",
							"Glass",
							"Golfbil",
							"Gorilla",
							"Gräshoppa",
							"Grill",
							"Gris",
							"Groda",
							"Gympaskor",
							"Häger",
							"Haj",
							"Halsband",
							"Halsduk",
							"Hammare",
							"Hamster",
							"Handduk",
							"Handkräm",
							"Handskar",
							"Handväska",
							"Hänglås",
							"Hårband",
							"Hårborste",
							"Hare",
							"Hårklämma",
							"Harpa",
							"Hårspray",
							"Häst",
							"Hatt",
							"Helikopter",
							"Hink",
							"Hjälm",
							"Hörlurar",
							"Hostmedicin ",
							"Humla",
							"Hund",
							"Hundhalsband",
							"Hyena",
							"Igelkott",
							"Insekt",
							"Isbjörn",
							"Jacka",
							"Jaguar",
							"Jeans",
							"Kackerlacka",
							"Kaffe",
							"Kaffefilter",
							"Kaffekanna",
							"Kaka",
							"Kaktus",
							"Kalender",
							"Kamel",
							"Kameleont",
							"Kamera",
							"Kam",
							"Känguru",
							"Kanin",
							"Kanna",
							"Kanot",
							"Kappa",
							"Karaff",
							"Kartong",
							"Katt",
							"Keps",
							"Kikare",
							"Kjol",
							"Klänning",
							"Klarinett",
							"Klocka",
							"Knapp",
							"Ko",
							"Koala",
							"Kobra",
							"Kofta",
							"Kompass",
							"Kompost",
							"Kontaktlins",
							"Kopp",
							"Kork",
							"Korkskruv",
							"Korsett",
							"Kortspel",
							"Kostym",
							"Krabba",
							"Kreditkort",
							"Kristallkrona",
							"Kritor",
							"Krokodil",
							"Krukväxt",
							"Kuvert",
							"Kvast",
							"Kyckling",
							"Lakan",
							"Läppglans",
							"Läppstift",
							"Larv",
							"Lastbil",
							"Lax",
							"Lejon",
							"Leksaksrobot",
							"Lim",
							"Linne",
							"Ljus",
							"Locktång",
							"Lokomotiv",
							"Luftskepp",
							"Madrass",
							"Målarbok",
							"Manet",
							"Marsvin",
							"Mascara",
							"Mask",
							"Måttband",
							"Medicin",
							"Middag",
							"Miniräknare",
							"Moped",
							"Mopp",
							"Morgonrock",
							"Motorbåt",
							"Motorcykel",
							"Mugg",
							"Mullvad",
							"Munspel",
							"Mus",
							"Myra",
							"Näbbdjur",
							"Nagellack",
							"Näktergal",
							"Näsduk",
							"Nattlinne",
							"Noshörning",
							"Nyckel",
							"Nyckelknippa",
							"Nyckelpiga",
							"Ödla",
							"Ögondroppar",
							"Orangutang",
							"Ordbok",
							"Örhängen",
							"Orm",
							"Örn",
							"Öronpropp",
							"Ost",
							"Ostron",
							"Påfågel",
							"Paket",
							"Panflöjt",
							"Papegoja",
							"Papper",
							"Paraply",
							"Parasoll",
							"Parfym",
							"Pass",
							"Pelikan",
							"Pengar",
							"Penna",
							"Piano",
							"Pikétröja",
							"Pil",
							"Piller",
							"Pincett",
							"Pingvin",
							"Plånbok",
							"Polisbil",
							"Portfölj",
							"Puma",
							"Racerbil",
							"Rådjur",
							"Räka",
							"Raket",
							"Rakhyvel",
							"Råtta",
							"Räv",
							"Regnjacka",
							"Rengöringsmedel",
							"Resårband",
							"Ring",
							"Ringklocka ",
							"Rivjärn",
							"Roddbåt",
							"Säckpipa",
							"Såg",
							"Sågfisk",
							"Säl",
							"Sardin",
							"Sax",
							"Saxofon",
							"Schampo",
							"Schimpans",
							"Sedel",
							"Segelbåt",
							"Sengångare",
							"Servett",
							"Köplista",
							"Sjöhäst",
							"Sjölejon",
							"Sjöstjärna",
							"Skål",
							"Skalbagge",
							"Skallerorm",
							"Skärbräda",
							"Skata",
							"Skateboard",
							"Sked",
							"Skjorta",
							"Sköldpadda",
							"Skor",
							"Skorpion",
							"Shorts",
							"Skoter",
							"Skrivbok",
							"Skunk",
							"Slips",
							"Snigel",
							"Sockar",
							"Solglasögon",
							"Solkräm",
							"Soptunna",
							"Sparv",
							"Spegel",
							"Spindel",
							"Sportskor",
							"Spruta",
							"Stege",
							"Stövlar",
							"Stråhatt",
							"Strumpbyxor",
							"Struts",
							"Strykjärn",
							"Sudd",
							"Surfbräda",
							"Svan",
							"Svävare",
							"Synål",
							"Synt",
							"Syrsa",
							"T-shirt",
							"Täcke",
							"Tallrik",
							"Tamburin",
							"Tandborste",
							"Tandkräm",
							"Tändsticka",
							"Tandtråd",
							"Tangentbord",
							"Tärningar",
							"Telefon",
							"Tidning",
							"Tiger",
							"Tonfisk",
							"Torkställning",
							"Traktor",
							"Trana",
							"Tratt",
							"Tröja",
							"Trollslända",
							"Trombon",
							"Trummor",
							"Trumpet",
							"Trumpinnar",
							"Tuggummi",
							"Tusenfoting",
							"Tvål",
							"Tvättkorg",
							"Tvättlapp",
							"U-båt",
							"Uggla",
							"Undertröja",
							"Väckarklocka",
							"Valross",
							"Vantar",
							"Varg",
							"Väst",
							"Växtkruka",
							"Vessla",
							"Vildhund",
							"Vildsvin",
							"Virknål",
							"Vykort",
							"Xylofon",
							"Zebra",
						]
					}
			}
		}
	}
}
