<template>
	<g>
		<defs>
			<g id="kt" transform="scale(0.45)">
				<path
					d="M 130 -30 L 59 -80 59 -30 "
					fill="#EE9000C0"
					stroke="#00000008"
					stroke-width="10"
				/>
				<path
					d="M 59 -80 L -150 -80 -90 -30 59 -30"
					fill="#CC7000C0"
					stroke="#00000008"
					stroke-width="10"
				/>
				<path
					d="M 130 -30 L -90 -30 -90 90 130 90"
					fill="#FFB020C0"
					stroke="#00000008"
					stroke-width="10"
				/>
				<path
					d="M -90 -30 L -150 -80 -150 30 -90 90"
					fill="#EE9000C0"
					stroke="#FFFFFF08"
					stroke-width="10"
				/>

				<text
					opacity="1.0"
					x="20"
					y="35"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="50"
					fill="#FFFFFF"
				>#%@!</text>
			</g>
		</defs>

		<g id="keepTrackIcon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<use xlink:href="#kt" x="0" y="0" />

			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.keepTrackLabel}}</text>
		</g>
	</g>
</template>

<script>
export default {
	name: "keepTrackIcon",
	idleTween: null,
};
</script>
