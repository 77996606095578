<template>
	<g id="grid">
		<defs>
			<radialGradient id="marbleGrad" cx="25%" cy="25%" r="50%" fx="25%" fy="25%">
				<stop offset="0%" style="stop-color:#FFFFFF" />
				<stop offset="50%" style="stop-color:#C8C8C8" />
				<stop offset="100%" style="stop-color:#646464" />
			</radialGradient>
		</defs>

		<sq ref="sq0" id="sq0" :idx="0" transform="translate(-400, -400)" />
		<sq ref="sq1" id="sq1" :idx="1" transform="translate(-200, -400)" />
		<sq ref="sq2" id="sq2" :idx="2" transform="translate(0, -400)" />
		<sq ref="sq3" id="sq3" :idx="3" transform="translate(+200, -400)" />

		<sq ref="sq4" id="sq4" :idx="4" transform="translate(-400, -200)" />
		<sq ref="sq5" id="sq5" :idx="5" transform="translate(-200, -200)" />
		<sq ref="sq6" id="sq6" :idx="6" transform="translate(0, -200)" />
		<sq ref="sq7" id="sq7" :idx="7" transform="translate(+200, -200)" />

		<sq ref="sq8" id="sq8" :idx="8" transform="translate(-400, 0)" />
		<sq ref="sq9" id="sq9" :idx="9" transform="translate(-200, -0)" />
		<sq ref="sq10" id="sq10" :idx="10" transform="translate(0, 0)" />
		<sq ref="sq11" id="sq11" :idx="11" transform="translate(+200, 0)" />

		<sq ref="sq12" id="sq12" :idx="12" transform="translate(-400, +200)" />
		<sq ref="sq13" id="sq13" :idx="13" transform="translate(-200, +200)" />
		<sq ref="sq14" id="sq14" :idx="14" transform="translate(0, +200)" />
		<sq ref="sq15" id="sq15" :idx="15" transform="translate(+200, +200)" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import sq from "@/v/ipark/updating_spatial/marble.vue";
import Timer from "advanced-timer";

var sequenceTimer;

export default {
	name: "grid",
	components: {
		sq
	},
	methods: {
		presentList(list, callback) {
			store.commit("ipark_updating_spatial_resetGrid");
			store.commit("ipark_updating_spatial_setList", list);
			sequenceTimer = new Timer(2000).repeat(list.length + 1).action(t => {
				if (t.currentCycle <= list.length) {
					const i = t.currentCycle - 1;
					store.commit("ipark_updating_spatial_presentItem", {
						index: i,
						item: list[i]
					});
				} else {
					if (callback != undefined) {
						callback();
					}
				}
			});
			sequenceTimer.start();
		},
		evalResponse() {
			var numToRecall = 4;
			const response = store.state.ipark_updating_spatial.response;
			const full_list = store.state.ipark_updating_spatial.list.slice();
			const list = full_list.slice(-numToRecall);
			var correct = [];
			var numCorrect = 0;
			for (var i = 0; i<list.length; ++i) {
				if (list[i] == response[i]) {
					correct.push(1);
					numCorrect++;
				} else {
					correct.push(0);
				}
			}
			sequenceTimer = new Timer(250)
				.repeat(response.length + 1)
				.action(t => {
					const i = t.currentCycle - 1;
					if (i < response.length) {
						const sq =
							store.state.ipark_updating_spatial.response[t.currentCycle - 1];
						if (list[i] == response[i]) {
							store.commit("ipark_updating_spatial_setGridItem", {
								item: sq,
								value: 4
							});
						} else {
							store.commit("ipark_updating_spatial_setGridItem", {
								item: sq,
								value: 5
							});
						}
					} else {
						store.commit("ipark_updating_spatial_setNumCorrect", numCorrect);
					}
				});
			sequenceTimer.start();
			return {
				len: full_list.length,
				list: full_list,
				resp: response,
				corr: correct,
				numC: numCorrect
			};
		}
	}
};
</script>
