<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="true" x="-600" y="-495" width="1200" height="99">
				<div class="txtbox">
					<span v-html="text.pageTitle" />
				</div>
			</foreignObject>
		</transition>
		<text
			x="-390"
			y="-380"
			class="txt"
			text-anchor="start"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="40"
			fill="#FFFFFF"
		>{{text.numTasksToday(4)}}</text>
		<transition name="fade">
			<g v-if="true" transform="translate(-600, -200)">
				<spatialSpanIcon />
			</g>
		</transition>
		<transition name="fade">
			<g v-if="true" transform="translate(-270, -200)">
				<spatialUpdatingIcon />
			</g>
		</transition>
		<transition name="fade">
			<g v-if="true" transform="translate(80, -200)">
				<lettersUpdatingIcon />
			</g>
		</transition>
		<transition name="fade">
			<g v-if="true" transform="translate(+410, -200)">
				<digitsUpdatingIcon />
			</g>
		</transition>
		<transition name="fade">
			<g v-if="true" transform="translate(-600, 100)">
				<coloursUpdatingIcon />
			</g>
		</transition>
		<transition name="fade">
			<g v-if="true" transform="translate(-270, 100)">
				<keepTrackIcon />
			</g>
		</transition>
		<transition name="fade">
			<g v-if="true" transform="translate(80, 100)">
				<bartIcon />
			</g>
		</transition>
		<timeline :stages="20" :pos="this.$store.state.sessionNumber" />
		<text
			x="-710"
			y="470"
			class="txt"
			text-anchor="start"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="35"
			fill="#FFFFFF"
		>{{text.currentSession(this.$store.state.sessionNumber)}}</text>
				<transition name="fade">
			<g
				transform="translate(670, -490)"

			>
				<logoutButton />
			</g>
		</transition>

	</g>
</template>

<script>
import lang from "@/v/taskMenu/lang.js";
import timeline from "@/v/timeline.vue";
import spatialSpanIcon from "@/v/taskMenu/icons/spatialSpanIcon.vue";
import coloursUpdatingIcon from "@/v/taskMenu/icons/coloursUpdatingIcon.vue";
import spatialUpdatingIcon from "@/v/taskMenu/icons/spatialUpdatingIcon.vue";
import lettersUpdatingIcon from "@/v/taskMenu/icons/lettersUpdatingIcon.vue";
import digitsUpdatingIcon from "@/v/taskMenu/icons/digitsUpdatingIcon.vue";
import keepTrackIcon from "@/v/taskMenu/icons/keepTrackIcon.vue";
import bartIcon from "@/v/taskMenu/icons/bartIcon.vue";
import logoutButton from "@/v/taskMenu/logoutButton.vue";

export default {
	name: "taskMenu",
	components: {
		spatialSpanIcon,
		coloursUpdatingIcon,
		spatialUpdatingIcon,
		lettersUpdatingIcon,
		digitsUpdatingIcon,
		keepTrackIcon,
		bartIcon,
		logoutButton,
		timeline
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	}
};
</script>
