<template>
	<g>
		<defs>
			<path
				id="logoutSign"
				d="M 20 0 L 50 30 80 0 100 20 70 50 100 80 80 100 50 70 20 100 0 80 30 50 0 20 20 0"
				fill="#00000022"
				stroke="#00000033"
				stroke-width="3"
				transform-origin="top center"
			/>
		</defs>
		<g id="logoutButton" v-on:click="clicked" opacity="1">
			<use x="0" y="0" xlink:href="#logoutSign" />
			<text
				x="0"
				y="120"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="30"
				fill="#00000077"
			>{{$shared.text.logout}}</text>
		</g>
	</g>
</template>

<script>
import persist from "@/persist.js";
import { store } from "@/store.js";
import gsap from "gsap";

export default {
	name: "logoutButton",
	idleTween: null,
	methods: {
		clicked: function(/*event*/) {
			//store.dispatch("logout");
			if (
				this.$store.state.study == 'ipark' &&
				this.$store.state.condition != 'itest' &&
				this.$store.state.concentration_done == false) {
				store.dispatch('gotoModule', 'form_concentration');
			} else if (
				this.$store.state.study == 'finger' &&
				this.$store.state.concentration_done == false) {
				store.dispatch('gotoModule', 'form_concentration');
			} else if (
				this.$store.state.study == 'reco' &&
				this.$store.state.mental_effort_done == false) {
				store.dispatch('gotoModule', 'form_mental_effort');
			} else {
				store.dispatch('gotoModule', 'login');
				persist.logout();
			}
		}
	},
	mounted() {
		const arrow = "#logoutButton";
		if (this.idleTween != null) {
			this.idleTween.kill();
			this.idleTween = null;
		}

		this.idleTween = gsap.timeline({ repeat: 10, repeatDelay: 4 });

		this.idleTween.to(arrow, {
			x: -6,
			y: -7,
			scaleX: 1.05,
			scaleY: 1.05,
			duration: 3
		});
		this.idleTween.to(arrow, {
			x: 0,
			y: 0,
			scaleX: 1,
			scaleY: 1,
			duration: 3
		});
	}
};
</script>
