<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="stage == 0" x="-600" y="-495" width="1200" height="160">
				<div class="txtbox">
					<div class="link" v-on:click="begin()">
						<span v-html="$parent.text.instructions.t0_button" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 1" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t1" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 2" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t2" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 3" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t3" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 4" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t4" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 5" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t5" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 6" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t6" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 7" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t7" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 8" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t8" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 9" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t9" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 10" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t10" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 11" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t11" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 12" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t12" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<circle
				v-if="hint_vc"
				stroke="#FFFFFF20"
				stroke-width="10"
				fill="#F4A03B"
				cx="0" cy="0"
				r="150"
			/>
		</transition>
		<transition name="fade">
			<rect
				v-if="hint_el"
				stroke="#FFFFFF20"
				stroke-width="10"
				fill="#3BA0F4"
				x="-150" y="-150"
				width="300" height="300"
				rx="15"
			/>
		</transition>
		<g v-if="show_keyboard">
			<text
				opacity="1.0"
				x="-550"
				y="-30"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="65"
				fill="#000000CC"
			>F</text>
			<text
				v-if="show_keyboard"
				opacity="1.0"
				x="550"
				y="-30"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="65"
				fill="#000000CC"
			>J</text>
		</g>
		<hand v-show="handVisible" ref="hand" />
		<hand2 v-show="handVisible" ref="hand2" />
	</g>
</template>

<script>
//import { store } from "@/store.js";
import hand from "@/v/hand.vue";
import hand2 from "@/v/hand2.vue";
import gsap from "gsap";

export default {
	name: "instructions",
	components: { hand, hand2 },
	data: function() {
		return {
			stage: 0,
			handVisible: false,
			hand: null,
			show_keyboard: false,
			hint_vc: false,
			hint_el: false,
		};
	},
	methods: {
		begin() {
			this.input_disabled = true; // förbjud input under instruktionen
			this.$store.commit('setTaskMode', 'instructions');	
			this.$store.commit('taskPhase', 1);
			this.handVisible = false;
			this.stage = -1;
			this.hint_vc = false;
			this.hint_el = false;

			this.$parent.items = [];
			/*this.$parent.items.push({ block: -2, stim: "A" });
			this.$parent.items.push({ block: -2, stim: 'B' });
			this.$parent.items.push({ block: -2, stim: 'E' });
			this.$parent.items.push({ block: -2, stim: 'D' });*/

			// --- Vocal ---
			this.$parent.items.push({ block: 0, stim: "A", task: "vc" });
			this.$parent.items.push({ block: 0, stim: 'E', task: "vc" });
			this.$parent.items.push({ block: 0, stim: 'U', task: "vc" });
			this.$parent.items.push({ block: 0, stim: 'Y', task: "vc" });

			// --- Consonant ---
			this.$parent.items.push({ block: 1, stim: 'B', task: "vc" });
			this.$parent.items.push({ block: 1, stim: 'D', task: "vc" });
			this.$parent.items.push({ block: 1, stim: 'X', task: "vc" });
			this.$parent.items.push({ block: 1, stim: 'Z', task: "vc" });

			// --- Early ---
			this.$parent.items.push({ block: 0, stim: "A", task: "el" });
			this.$parent.items.push({ block: 0, stim: 'B', task: "el" });
			this.$parent.items.push({ block: 0, stim: 'D', task: "el" });
			this.$parent.items.push({ block: 0, stim: 'E', task: "el" });

			// --- Late ---
			this.$parent.items.push({ block: 0, stim: 'U', task: "el" });
			this.$parent.items.push({ block: 0, stim: 'X', task: "el" });
			this.$parent.items.push({ block: 0, stim: 'Y', task: "el" });
			this.$parent.items.push({ block: 0, stim: 'Z', task: "el" });

			// --- All ---
			this.$parent.items.push({ block: 0, stim: "A", task: "vc" });
			this.$parent.items.push({ block: 0, stim: 'Z', task: "vc" });
			this.$parent.items.push({ block: 0, stim: 'X', task: "el" });
			this.$parent.items.push({ block: 0, stim: 'E', task: "el" });

			this.$parent.items.push({ block: 0, stim: 'X', task: "vc" });
			this.$parent.items.push({ block: 0, stim: 'B', task: "vc" });
			this.$parent.items.push({ block: 0, stim: 'B', task: "el" });
			this.$parent.items.push({ block: 0, stim: 'D', task: "el" });

			// -------

			this.$parent.trial = -1;
			this.$parent.item_visible = false;
			this.$parent.stim_visible = false;
			this.$parent.item_response_blocked = true;
			this.$parent.block_feedback = false;
			this.$parent.show_buttons = false;

			var t8 = gsap.timeline();
			t8.to(this, { delay: 2, onStart: () => { this.stage = 1;  }, duration: 4 });
			// Visa de fyra kvadranterna
			/*t8.to(this, { onStart: () => { this.$parent.trial++; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });*/
			//----------

			t8.to(this, { onStart: () => { this.stage = 2; this.$parent.show_buttons = true; }, duration: 2 });
			t8.to(this, {
				onStart: () => {
					this.handVisible = true;
					this.$refs.hand.show(() => {
						this.hand = gsap.timeline();
						this.hand.to("#hand", {
							duration: 1,
							x: 550,
							y: 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
							}
						});
					});

					this.$refs.hand2.show(() => {
						this.hand2 = gsap.timeline();
						this.hand2.to("#hand2", {
							duration: 1,
							x: -550,
							y: 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand2.click();
							}
						});
					});
				},
				duration: 12,
			});
			// Visa keyboard
			t8.to(this, { onStart: () => { this.stage = -1; this.show_keyboard = false; }, duration: 1 });
			t8.to(this, { onStart: () => { this.stage = 3;  this.show_keyboard = true; },  duration: 8, });
			t8.to(this, { onStart: () => { this.stage = -1; this.show_keyboard = false; }, duration: 2, });

			t8.to(this, { onStart: () => { this.stage = 4;  }, duration: 6 });
			t8.to(this, { onStart: () => { this.stage = -1; }, duration: 1 });


			// Vokal/Konsonant
			t8.to(this, { onStart: () => { this.stage = 5; this.hint_vc = true; }, duration: 12 });
			t8.to(this, { onStart: () => { this.stage = -1; }, duration: 1 });

			t8.to(this, { onStart: () => { this.stage = 6; this.hint_vc = false; }, duration: 4 });
			// Animera Vokal
			t8.to(this, { onStart: () => { this.$parent.trial = 0; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });


			t8.to(this, { onStart: () => { this.stage = 7;  }, duration: 4 });
			// Animera Konsonant
			t8.to(this, { onStart: () => { this.$parent.trial = 4; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });


			// Början/Slutet
			t8.to(this, { onStart: () => { this.stage = 8; this.hint_el = true; }, duration: 12 });
			t8.to(this, { onStart: () => { this.stage = -1; this.hint_el = false; }, duration: 1 });

			t8.to(this, { onStart: () => { this.stage = 9;  }, duration: 4 });
			// Animera Tidiga
			t8.to(this, { onStart: () => { this.$parent.trial = 8; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });


			t8.to(this, { onStart: () => { this.stage = 10;  }, duration: 4 });
			// Animera Sena
			t8.to(this, { onStart: () => { this.$parent.trial = 12; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });

			
			t8.to(this, { onStart: () => { this.stage = 11;  }, duration: 8 });
			// Animera alla
			t8.to(this, { onStart: () => { this.$parent.trial = 16; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });

			t8.to(this, { onStart: () => { this.$parent.trial++; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial++; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });

			
			t8.to(this, { onStart: () => { this.stage = 12; this.$refs.hand.hide(); this.$refs.hand2.hide(); }, duration: 7 });
			t8.to(this, { onStart: () => { this.stage = -1; this.handVisible = false; }, duration: 2 });

			t8.to(this, {
				onStart: () => {
					this.stage = -1;
					this.$parent.setup();
				},
				duration: 1,
			});
		},
	},
	mounted() {
		this.stage = 0;
	}
};

</script>
