<template>
	<g class="button" v-on:click="clicked">
		<rect rx="10" fill="#00000044" x="5" y="5" width="180" height="180" />
		<rect :id="'sq' + idx + 'bg'" rx="10" fill="#CCCCCC" x="0" y="0" width="180" height="180" />
		<rect rx="5" fill="#FFFFFF2a" x="5" y="5" width="15" height="165" />
		<rect rx="5" fill="#FFFFFF2a" x="5" y="5" width="150" height="15" />
		<rect rx="5" fill="#0000000a" x="160" y="10" width="15" height="165" />
		<rect rx="5" fill="#0000000a" x="25" y="160" width="150" height="15" />
		<text
			:id="'sq' + idx + 'star'"
			opacity="0.0"
			x="70"
			y="93"
			class="txt"
			text-anchor="center"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="40"
			fill="#FFFFFF"
		>⭐️</text>
	</g>
</template>

<script>
import { store } from "@/store.js";
import gsap from "gsap";

export default {
	name: "sq",
	props: {
		idx: {
			type: Number,
			default: 0
		}
	},
	methods: {
		clicked: function(/*event*/) {
			store.dispatch("spatialSpan_clickSquare", this.idx);
		},
		changeState: function(newState) {
			const sel = "#sq" + this.idx + "bg";
			const star = "#sq" + this.idx + "star";

			var tl;
			switch (newState) {
			case 1: // blink
				tl = gsap.timeline();
				tl.to(sel, { fill: "#CC8822", duration: 0.2 });
				tl.to(sel, { fill: "#99BBCC", delay: 0.6, duration: 0.2 });
				//tl.to(sel, { fill: "#CCCCCC", delay: 0.6, duration: 0.2 });
				break;
			case 2: // selected
				tl = gsap.timeline();
				tl.to(sel, { fill: "#55808E", duration: 0.2 });
				tl.to(sel, { fill: "#35606E", duration: 0.2 });
				break;
			case 3: // selected + inactive
				//gsap.to(sel, { fill: "#AAAAAA", duration: 1 });
				gsap.to(sel, { fill: "#35606E", duration: 1 });
				break;
			case 4: // correct
				tl = gsap.timeline();
				tl.to(sel, { fill: "#FFFFFF", duration: 0.1 });
				//tl.to(star, { opacity: "1", duration: 0.1 });
				//tl.to(sel, { fill: "#448844", delay: 0.1, duration: 2 });
				tl.to(sel, { fill: "#55AA55", delay: 0.1, duration: 2 });
				//tl.to(sel, { fill: "#55AA55", duration: 2 });
				gsap.to(star, { opacity: "1", delay: 0.2, duration: 1.5 });
				break;
			case 5: // incorrect
				tl = gsap.timeline();
				tl.to(sel, { fill: "#000000", duration: 0.1 });
				tl.to(sel, { fill: "#884444", delay: 0.1, duration: 0.5 });
				//gsap.to(sel, { fill: "#884444", duration: 2.2 });
				break;
			case 6: // clickable + non-selected
				gsap.to(sel, { fill: "#CCCCCC", duration: 0.1 });
				break;
			default:
				gsap.to(sel, { fill: "#99BBCC", duration: 0.2 });
				//gsap.to(sel, { fill: "#CCCCCC", duration: 0.2 });
				gsap.to(star, { opacity: "0", duration: 0.1 });
			}
		}
	},
	computed: {
		grid() {
			return store.state.spatialSpan.grid;
		}
	},
	watch: {
		grid: function(newGrid, prevGrid) {
			if (newGrid[this.idx] != prevGrid[this.idx]) {
				this.changeState(newGrid[this.idx], prevGrid[this.idx]);
			}
		}
	},
	mounted() {
		this.changeState(0, -1);
	}
};
</script>
