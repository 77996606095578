export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
			default:
				return {
					pageTitle: "Dagens träningsuppgifter",
					menu_help: "Se till att det är tyst och lugnt omkring dig innan du börjar. Det är viktigt att du gör ditt bästa och att du arbetar så noggrant och snabbt som möjligt.<br>Lycka till!",
					currentSession: (num) => { return `Session ${num}` },
					shifting_predictable_label: 'Skifta',
					shifting_unpredictable_label: 'Skifta',
					episodic_memory_relational_label: 'Tripplarna',
					lettersUpdatingLabel: 'Bokstaven',
					wm_maintenance_label: 'Rutan',
					keepTrackLabel: 'Håll kollen',
					episodic_memory_spatial_label: 'Memory',
				};
			}
		}
	}
}
