export const errorView = {
	state: {
		msg: "",
		visible: false
	},
	mutations: {
		showError(state, errorMessage) {
			state.msg = errorMessage;
			state.visible = true;
		},
		hideError(state) {
			state.visible = false;
		}
	}
}
