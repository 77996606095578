<template>
	<g>
		<transition name="quickfade">
			<g v-if="this.ins_a"
				><foreignObject x="-500" y="-250" width="1000" height="500"
					><div class="readbox">
						<span
							v-html="text.instructions.a"
						/></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins_b"
				><foreignObject x="-500" y="-150" width="1000" height="300"
					><div class="readbox">
						<span
							v-html="text.instructions.b"
						/></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins_c"
				><foreignObject x="-500" y="-250" width="1000" height="300"
					><div class="readbox">
						<span
							v-html="text.instructions.c"
						/></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins_d"
				><foreignObject x="-500" y="-450" width="1000" height="300"
					><div class="readbox">
						<span
							v-html="text.instructions.d"
						/></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins_e"
				><foreignObject x="-500" y="-150" width="1000" height="300"
					><div class="readbox">
						<span
							v-html="text.instructions.e"
						/></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins_f"
				><foreignObject x="-500" y="-150" width="1000" height="300"
					><div class="readbox">
						<span
							v-html="text.instructions.f"
						/></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.l1"
				><foreignObject x="-500" y="-150" width="1000" height="300"
					><div class="readbox">
						<span
							v-html="text.instructions.l1"
						/></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.l2"
				><foreignObject x="-500" y="-150" width="1000" height="300"
					><div class="readbox">
						<span
							v-html="text.instructions.l2"
						/></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.show_pause"
				><foreignObject x="-250" y="-20" width="500" height="200"
					><div class="readbox">
						<span
							v-html="text.instructions.pause"
						/></div></foreignObject
			></g>
		</transition>

		<g
			v-if="continue_button_visible"
			v-on:click="clicked_continue()"
			class="clickable"
		>
			<rect
				stroke="#00000020"
				stroke-width="10"
				fill="#FFFFFFCC"
				x="-150"
				y="375"
				width="300"
				height="100"
				rx="15"
			/>
			<text
				x="0"
				y="425"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="30"
				fill="#000000"
				>{{ this.text.continue_ }}</text
			>
		</g>

		<transition name="quickfade">
			<g v-if="this.done">
				<circle cx="0" cy="0" r="160" fill="#44AA44" stroke="none" />
				<text
					x="0"
					y="0"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="36"
					fill="#006600"
					>{{ text.done }}</text
				>
			</g>
		</transition>
		<path
			v-show="timer_enabled"
			id="time_arc"
			opacity="0"
			fill="none"
			stroke="#FFFFFF"
			stroke-width="20"
		/>

		<g v-if="word_a != ''">
			<rect
				rx="20"
				fill="#FFFFFFAA"
				stroke="none"
				stroke-width="0"
				x="-200"
				y="-150"
				width="400"
				height="100"
			/>
			<text
				x="0"
				y="-95"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="36"
				fill="#000000"
				>{{ this.word_a }}</text
			>
			<path
				d="M -40 0 L 0 30 40 0 20 0 20 -30 -20 -30 -20 0 -40 0"
				fill="#FFFFFF66"
				stroke="none"
				stroke-width="4"
			/>
		</g>
		<g v-if="word_b != ''">
			<rect
				rx="20"
				fill="#FFFFFFAA"
				stroke="none"
				stroke-width="0"
				x="-200"
				y="50"
				width="400"
				height="100"
			/>
			<text
				x="0"
				y="105"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="36"
				fill="#000000"
				>{{ this.word_b }}</text
			>
		</g>
		<g v-if="show_demo_input">
			<path
				d="M -20 180 l 20 -20 20 20 -40 0"
				fill="none"
				stroke="#00000066"
				stroke-width="2"
			/>
			<text
				x="0"
				y="200"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="24"
				fill="#00000088"
				>skriv det andra ordet</text
			>
			<foreignObject x="-200" y="50" width="400" height="100">
				<div class="inputBox">
					<input
						type="text"
						id="demo_input"
						class="wordInput"
						disabled="true"
						placeholder=""
						spellcheck="false"
						autocomplete="off"
						autocorrect="off"
						enterkeyhint="next"
						mozactionhint="next"
						autofocus="true"
					/>
				</div>
			</foreignObject>
		</g>
		<g v-show="show_input">
			<path
				d="M -20 180 l 20 -20 20 20 -40 0"
				fill="none"
				stroke="#00000066"
				stroke-width="2"
			/>
			<text
				x="0"
				y="200"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="24"
				fill="#00000088"
				>skriv det andra ordet</text
			>
			<foreignObject x="-200" y="50" width="400" height="100">
				<div class="inputBox">
					<input
						type="text"
						id="input"
						class="wordInput"
						maxlength="15"
						placeholder=""
						spellcheck="false"
						autocomplete="off"
						autocorrect="off"
						enterkeyhint="next"
						mozactionhint="next"
						autofocus="true"
					/>
				</div>
			</foreignObject>
		</g>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_episodic_modified_associative_learning/lang.js";
import persist from "@/persist.js";
//import gsap from "gsap";

var timer = 0;

export default {
	name: "test_episodic_mal",
	//genväg-alias:
	local: store.state.test_episodic_mal,
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {
			done: false,
			demo: false,
			list: [],
			test_order: [],
			phase: -1,

			word_a: "",
			word_b: "",

			show_input: false,
			show_demo_input: false,
			continue_button_visible: false,

			active_buttons: false,
			ins_a: false,
			ins_b: false,
			ins_c: false,
			ins_d: false,
			ins_e: false,
			ins_f: false,
			l1: false,
			l2: false,
			show_pause: false,
			timer_enabled: false,

			last_keypress: 0,

			results: {
				start_time: 0,
				total_time: 0,
				c: [],
				c2: [],
				d: [],
				rt: [],
				s: [],
				a: [],
				correct: 0,
				correct_easy: 0,
				correct_hard: 0,
				score: 0,
			},
		};
	},
	watch: {
		phase: function (/*new_item*/) {
			//console.log("phase -- " + this.phase);
			switch (this.phase) {
				case 0:
					this.clearText();
					this.ins_a = true;
					this.continue_button_visible = true;
					break;
				case 1:
					this.clearText();
					this.ins_b = true;
					this.continue_button_visible = true;
					break;
				case 2:
					this.clearText();
					setTimeout(() => {
						this.phase++;
					}, 2000);
					break;
				case 3:
					this.clearText();
					this.word_a = "koja";
					this.word_b = "slott";
					this.start_test_timer(3.6);
					setTimeout(() => {
						this.phase++;
					}, 3700);
					break;
				case 4:
					//this.clearText();
					this.word_a = "";
					this.word_b = "";
					setTimeout(() => {
						this.phase++;
					}, 3000);
					break;
				case 5:
					this.clearText();
					this.ins_c = true;
					this.continue_button_visible = true;
					break;
				case 6:
					this.clearText();
					this.ins_d = true;
					setTimeout(() => {
						this.phase++;
					}, 6000);
					break;
				case 7:
					this.demo = true;
					this.word_a = "koja";
					this.word_b = "";
					this.show_demo_input = true;
					this.start_test_timer(7);
					setTimeout(() => {
						document.getElementById("demo_input").value = "s";
						setTimeout(() => {
							document.getElementById("demo_input").value = "sl";
							setTimeout(() => {
								document.getElementById("demo_input").value =
									"slo";
								setTimeout(() => {
									document.getElementById(
										"demo_input"
									).value = "slot";
									setTimeout(() => {
										document.getElementById(
											"demo_input"
										).value = "slott";
									}, 200);
								}, 500);
							}, 700);
						}, 1000);
					}, 2000);
					setTimeout(() => {
						this.phase++;
					}, 12000);
					break;
				case 8:
					this.clearText();
					this.ins_e = true;
					this.word_a = "";
					this.word_b = "";
					this.show_demo_input = false;
					this.continue_button_visible = true;
					break;
				case 9:
					this.clearText();
					this.l1 = true;
					setTimeout(() => {
						this.phase++;
					}, 3000);
					break;
				case 10:
					this.clearText();
					this.present_list(0);
					break;
				case 11:
					this.clearText();
					setTimeout(() => {
						this.ins_f = true;
						setTimeout(() => {
							this.phase++;
						}, 6000);
					}, 2000);
					break;
				case 12:
					this.demo = false;
					this.clearText();
					this.test_list(0);
					break;
				case 13:
					this.clearText();
					this.show_pause = true;
					this.start_test_timer(20);
					setTimeout(() => {
						this.show_pause = false;
						this.phase++;
					}, 20200);
					break;
				case 14:
					this.clearText();
					this.l2 = true;
					setTimeout(() => {
						this.phase++;
					}, 3000);
					break;
				case 15:
					this.clearText();
					this.present_list(1);
					break;
				case 16:
					this.clearText();
					setTimeout(() => {
						this.ins_f = true;
						setTimeout(() => {
							this.phase++;
						}, 6000);
					}, 2000);
					break;
				case 17:
					this.demo = false;
					this.clearText();
					this.test_list(1);
					break;
				case 18:
					this.test_is_over();
					break;
			}
		},
	},

	methods: {
		clearText() {
			this.ins_a = false;
			this.ins_b = false;
			this.ins_c = false;
			this.ins_d = false;
			this.ins_e = false;
			this.ins_f = false;
			this.l1 = false;
			this.l2 = false;
			this.word_a = "";
			this.word_b = "";
			this.show_input = false;
			this.show_demo_input = false;
		},

		clicked_continue() {
			this.continue_button_visible = false;
			this.phase += 1;
		},

		addKeyboardListener() {
			document
				.getElementById("input")
				.addEventListener("keyup", this.enterKeyPushed.bind(this));
		},
		removeKeyboardListener() {
			document
				.getElementById("input")
				.removeEventListener("keyup", this.enterKeyPushed.bind(this));
		},
		enterKeyPushed: function (e) {
			//console.log(e.key);
			/*if (e.key == "Enter") {
				this.enterWord();
			} else if (e.key == "Tab") {
				document.getElementById("input").focus();
			} else {
				this.last_keypress = Date.now();
			}*/
			if (e.key != "Enter") {
				this.last_keypress = Date.now();
			}
		},

		test_is_over() {
			if (timer != 0) clearTimeout(timer);
			this.timer_enabled = false;
			this.active_buttons = false;
			this.clearText();
			this.done = true;

			const arc = document.getElementById("time_arc");
			arc.setAttribute("opacity", 0);

			//console.log("DONE!" );

			this.removeKeyboardListener();

			this.saveSessionResults();
			setTimeout(() => {
				store.dispatch("gotoModule", "home");
			}, 5000);
		},
		saveSessionResults() {
			this.results.total_time = Math.trunc(
				Date.now() - this.results.start_time
			);
			persist.log("results", this.results);
			persist.set_progress_data("episodic", 1);
		},
		start_test_timer(secs) {
			var start_time = Date.now();
			var end_time = start_time + secs * 1000;
			var interval = end_time - start_time;

			const arc = document.getElementById("time_arc");
			arc.setAttribute("d", describeArc(0, 0, 300, 360, 360));
			arc.setAttribute("opacity", 0.5);
			this.timer_enabled = true;

			timer = setInterval(() => {
				var now = Date.now();
				if (now >= end_time) {
					this.timer_enabled = false;
					if (timer != 0) {
						clearInterval(timer);
						timer = 0;
					}
				} else {
					var left = end_time - now;
					var prop = 1 - left / interval;

					const arc = document.getElementById("time_arc");
					arc.setAttribute(
						"d",
						describeArc(0, 0, 300, prop * 360, 360)
					);
				}
			}, 25);
		},
		present_list(n) {
			this.list = this.text.word_pairs[n];
			this.present_item(0);
		},
		present_item(n) {
			this.clearText();
			this.word_a = this.list[n].a;
			this.word_b = this.list[n].b;
			this.start_test_timer(2.9);
			setTimeout(() => {
				if (n + 1 < this.list.length) {
					this.present_item(n + 1);
				} else {
					this.phase++;
				}
			}, 3000);
		},
		test_list(n) {
			this.list = this.text.word_pairs[n];
			this.test_order = new Array(this.list.length);
			for (var i = 0; i < this.list.length; i++) {
				let pos = this.list[i].pos;
				this.test_order[pos] = i;
			}
			this.test_item(0);
		},
		test_item(n) {
			this.clearText();
			this.show_input = true;
			setTimeout(() => {
				this.word_a = this.list[this.test_order[n]].a;
				this.word_b = "";
				document.getElementById("input").value = "";
				document.getElementById("input").focus();
				this.start_test_timer(6.9);
				this.item_start_time = Date.now();
				this.last_keypress = this.item_start_time + 7000;

				setTimeout(() => {
					this.word_a = "";
					this.word_b = "";
					this.show_input = false;
					this.end_test_item(n);
					setTimeout(() => {
						if (n + 1 < this.list.length) {
							this.test_item(n + 1);
						} else {
							this.phase++;
						}
					}, 2000);
				}, 7000);
			}, 100);
		},
		end_test_item(n) {
			//let cue = this.list[this.test_order[n]].a.toLowerCase().trim();
			let facit = this.list[this.test_order[n]].b.toLowerCase().trim();
			let answer = document
				.getElementById("input")
				.value.toLowerCase()
				.trim();
			let rt = this.last_keypress - this.item_start_time;
			let c = answer == facit;
			let dist = this.levenshteinDistance(facit, answer);
			let grp = this.list[this.test_order[n]].grp;
			let c2 = dist < 4;
			var score = 0;
			if (c2) {
				if (grp == 0) {
					score = 1;
					this.results.correct_easy += 1;
				} else {
					score = 2;
					this.results.correct_hard += 1;
				}
				this.results.score += score;
				this.results.correct += 1;
			}
			/*console.log(
				cue +
					": " +
					facit +
					" == " +
					answer +
					"? " +
					(c ? "correct" : "incorrect") +
					", dist: " +
					dist +
					", rt: " +
					rt +
					", grp: " +
					grp +
					", score: " +
					score
			);*/
			/*this.results.items.push({
				c: c,
				c2: c2,
				d: dist,
				g: grp,
				rt: rt,
				s: score,
			});*/
			this.results.c.push(c ? 1 : 0);
			this.results.c2.push(c2 ? 1 : 0);
			this.results.d.push(dist);
			this.results.rt.push(rt);
			this.results.s.push(score);
			this.results.a.push(answer);
			/*
				num_correct_1a: 0,
				num_correct_1b: 0,
				num_correct_2a: 0,
				num_correct_2b: 0,
				num_correct_a: 0,
				num_correct_b: 0,
				total_score: 0,
			*/
		},
		levenshteinDistance(s1, s2) {
			var m = s1.length;
			var n = s2.length;
			var matrix = new Array();
			var line;
			var i;
			var j;
			for (i = 0; i <= m; ++i) {
				line = new Array();
				for (j = 0; j <= n; ++j) {
					if (i != 0) line.push(0);
					else line.push(j);
				}
				line[0] = i;
				matrix.push(line);
			}
			var cost;
			for (i = 1; i <= m; ++i)
				for (j = 1; j <= n; ++j) {
					if (s1.charAt(i - 1) == s2.charAt(j - 1)) cost = 0;
					else cost = 1;
					matrix[i][j] = Math.min(
						matrix[i - 1][j] + 1,
						matrix[i][j - 1] + 1,
						matrix[i - 1][j - 1] + cost
					);
				}
			return matrix[m][n];
		},
	},
	mounted() {
		this.results.start_time = Date.now();
		//console.log(this.results.start_time);
		this.phase = 0;
		this.addKeyboardListener();
	},
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y,
	].join(" ");

	return d;
}
</script>