export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "fi":
					return {
						ins1: "???",
						ins2: "Mikä näistä",
						ins3: "sopii tähän?",
						ins4: "Aivan. Otetaan seuraava!",

						look: "Katsotaan vielä kerran",

						fail_a: "Kaikki nämä perhoset ovat sinisiä",
						fail_b: "Nämä kaksi lamppua ovat keltaisia",
						fail_c: "Kaikki nämä ruudut ovat sinisiä<br>ja niissä on viiva poikittain tällä tavalla.",

						corr_a: "Tämä on myös sininen",
						corr_b1: "Tässä on vihreä lamppu",
						corr_b2: "Meidän täytyy valita vihreä lamppu, kuten tämä tässä.",
						corr_c: "Tämä on myös sininen ja<br>siinä on viiva poikittain.",

						point: "Siksi tämä sopii tähän.",
						next_failed: "Mennään seuraavaan tehtävään!",
						done: "Tehtävä suoritettu!",
						time_left: "Tid kvar",
					};
				case "sv":
				default:
					return {
						ins1: "Titta på det här mönstret",
						ins2: "Vilken pusselbit här",
						ins3: "passar in här?",
						ins4: "Javisst. Nu tar vi nästa!",
						ins5: "Vilken pusselbit passar här?",

						look: "Vi tittar en gång till",

						fail1: "Här går EN linje",
						fail2: "och här finns TRE rader med punkter",
						fail3: "Nu tar vi nästa!",

						corr: "precis som i den här pusselbiten!",
						point: "Därför passar den in här.",

						minutes_left: "Du har nu 40 minuter kvar",

						done: "Klart!",
						time_left: "Tid kvar",
						items_left: "Frågor kvar",
					};
			}
		}
	}
}


