export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						instructions: {
							t0: (number) => { return `Vänd på dessa kort och hitta alla <font color="#FFCC66">${number}</font> dolda par` },
							t0_button: 'Tryck här för att se en instruktion ➤',
							t2: 'Här ligger 6 kort',
							t3: 'Tryck på ett kort för att se dess symbol',
							t4: 'Tryck på ett annat kort',
							t5: 'Dessa två symboler är olika. Fortsätt leta!',
							t6: 'Välj ett nytt kort',
							t7: 'Den här symbolen har jag sett tidigare! Var var den?',
							t10: 'Välj ett nytt kort',
							t11: 'Och ett till...',
							t12: 'Jag har sett stjärnan tidigare!',
							t13: 'Här är den...',
							t14: 'Och den andra...',
							t17: 'Alla par är funna!'
						},
					}
			}
		}
	}
}
