export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
				return {
					pageTitle: "Dagens träningsuppgifter",
					menu_help: "Se till att det är tyst och lugnt omkring dig innan du börjar. Det är viktigt att du gör ditt bästa och att du arbetar så noggrant och snabbt som möjligt.<br>Lycka till!",
					currentSession: (num) => { return `Session ${num}` },
					spatialSpanLabel: "Rutan",
					spatialUpdatingLabel: "Bollen",
					digitsUpdatingLabel: "Siffran",
					episodic_memory_spatial_label: 'Memory',
					shifting_predictable_label: 'Skifta',
					wm_maintenance_label: 'Rutan',
					episodic_memory_relational_label: 'Tripplarna',
				};
			case "zh":
				return {
					pageTitle: "今天的训练项目",
					menu_help: "开始之前，请到一个舒适安静的地方进行训练。<br>操作时，尽量越准确快速越好。加油！",
					currentSession: (num) => { return `第${num}节` },
					spatialSpanLabel: "方块", // 空间广度
					spatialUpdatingLabel: "4弹珠", // 空间刷新
					digitsUpdatingLabel: "4数字", // 数字刷新
					episodic_memory_spatial_label: '卡片配对', // 情景空间记忆
					shifting_predictable_label: '移动', // 双任务协调
					wm_maintenance_label: '方块', // 工作记忆
					episodic_memory_relational_label: '三物链接', //(3-item-link). old: '连结', // 情节连贯
				};
			case "en":
			default:
				return {
					pageTitle: "Today's training tasks",
					menu_help: "Before you begin, make sure it's quiet around you. It's important that you try to do your best and work as carefully and quickly as possible.<br>Good luck!",
					currentSession: (num) => { return `Session ${num}` },
					spatialSpanLabel: "Tiles",
					spatialUpdatingLabel: "4 Marbles",
					digitsUpdatingLabel: "4 Numbers",
					episodic_memory_spatial_label: 'Pairs',
					shifting_predictable_label: 'Shift',
					wm_maintenance_label: 'Tiles',
					episodic_memory_relational_label: 'Triplets',
				};
			}
		}
	}
}
