export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						instruct0: "I uppgiften som kommer snart ska du få svara på enkla påståenden och samtidigt komma ihåg bokstäver.<br><br>Men vi börjar med att öva på att komma ihåg bokstäver.<br><br>",
						instruct1: "Bokstäver kommer att visas, en i taget.<br><br>Försök att komma ihåg alla bokstäver som visas.",
						instruct2: "Nu kommer du få se några påståenden. Vissa av dem är sanna och andra är falska. Svara genom att trycka på knapparna ”Sant” och ”Falskt”", // svara så snabbt som möjligt
						instruct3: "Okej, bra! Du kommer nu få öva på att både svara på påståenden och komma ihåg bokstäver på samma gång.<br><br>Du får bara en kort tid på dig att svara rätt på varje påstående.",
						instruct4: "Den här mätaren visar om du svarat rätt eller fel på påståendena.<br><br>Försök att svara rätt på så många påståenden som möjligt så att visaren ligger i det gröna fältet.",
						instruct5: "Okej, är du redo?<br><br>Försök att både svara rätt och komma ihåg alla bokstäver.",
						instruct6: "Nu är övningarna slut!<br><br>Nu kommer de riktiga uppgifterna. De är likadana som de du just övat på.<br><br>Försök svara rätt och komma ihåg alla bokstäver.",
						letter_response_instr: "Tryck på bokstäverna i samma ordning som de visades",
						current_results: (lnum, lcorr, num_attempts, qnum, qcorr) => {
							var str = "";
							if (lnum > 0) {
								if (lcorr == lnum) {
									if (lnum > 2) {
										str += `Du kom ihåg <font color="#88FF88">alla ${lcorr} bokstäver</font>`;
									}
									else {
										str += `Du kom ihåg <font color="#88FF88">alla bokstäver</font>`;
									}
								}
								else if (num_attempts == 0) {
									str += `<font color="#FF8888">Inte alla bokstäver</font> blev rätt.<br><br>Försök en gång till!`;
								}
								else {
									str += `<font color="#FF8888">Inte alla bokstäver</font> blev rätt`;
								}
								str += "<br><br>";
							}
							if (qnum > 0) {
								if (qcorr == 0) str += `<font color="#FF8888">Du svarade inte rätt på några påståenden</font>`;
								else if (qcorr == 1) str += `Du svarade rätt på <font color="#88FF88">1 påstående</font>`;
								else if (qcorr > 1) {
									if (qcorr == qnum && qnum > 2)
										str += `Du svarade rätt på <font color="#88FF88">alla ${qcorr} påståenden</font>`;
									else
										str += `Du svarade rätt på <font color="#88FF88">${qcorr} påståenden</font>`;
								}
							}
							return str;
						},
						questions: [
							// Övning + mäta tid
							"Bilar har inga hjul.", false,
							"En säng är ett djur.", false,
							"Cyklar har runda hjul", true,
							"När det regnar blir det torrt.", false,
							"Ett fönster kan gå sönder.", true,
							"Vilda djur är genomskinliga.", false,
							"Man kan dricka en telefon.", false,
							"En ambulans har en siren.", true,
							"En jägare använder gevär.", true,
							"Små barn är pensionärer.", false,
							"Ett köksbord kan ha fyra ben.", true,
							"Orkestern spelar på saft.", false,
							"Solen värmer upp jorden.", true,
							"En brevbärare delar ut post.", true,
							"En soffa är en möbel.", true,
							"En turist är en regnbåge.", false,
							//--- Övning (påståenden & bokstäver)
							"Is smälter till vatten.", true,
							"Man sover i en säng.", true,

							"Det är livsfarligt att skratta.", false,
							"Piano är ett musikinstrument.", true,

							"En fotboll är fyrkantig.", false,
							"Sköldpaddor har fyra hjul.", false,
							//--- 2
							"Ett äpple är en frukt.", true,
							"Smörgåsar kan man äta.", true,

							"Stockholm är en stad.", true,
							"En häst skriver böcker.", false,
							//--- 3
							"I skogen finns det träd.", true,
							"En skjorta är ett klädesplagg.", true,
							"Eld är en nyttig maträtt.", false,

							"Människor kan andas under vatten.", false,
							"En klocka visar tiden.", true,
							"Ett lejon har vingar.", false,
							//--- 4
							"På en spis tvättar man kläder.", false,
							"En kanot flyter på vatten.", true,
							"Man kan dansa till musik.", true,
							"En apelsin är en hundras.", false,

							"På vintern kan det snöa.", true,
							"Det är olagligt att prata.", false,
							"En restaurang serverar mat.", true,
							"En tårta är ett verktyg.", false,
							//--- 5
							"En cykel är ett rovdjur.", false,
							"Julafton är på sommaren", false,
							"Tåg går på räls.", true,
							"En armbåge är en kroppsdel.", true,
							"På bibliotek finns det böcker.", true,

							"Åskväder är vanligt på vintern.", false,
							"Man kan måla med en anka.", false,
							"En fisk bor i skogen.", false,
							"Man kan äta en persika.", true,
							"En vulkan är en prydnadssak.", false,
							//--- 6
							"På en biograf visas film.", true,
							"Porslin går lätt sönder.", true,
							"Måndag är veckans första dag.", true,
							"En inbrottstjuv är laglydig.", false,
							"Skridskor kan man dricka.", false,
							"En skorsten lever i öknen.", false,
							//---- ytterligare 20
							"Väskan kan stå på ett ben ", false,
							"Snö är ganska varmt ", false,
							"Man kan ringa med en telefonen ", true,
							"En bil kryper på knä", false,
							"Man kan rita en cirkel ", true,
							"Vatten kan brinna", false,
							//--- 7
							"Mat är bra om man är hungrig ", true,
							"Man kan spela datorspel på land", true,
							"Fiskar kan andas under vatten", true,
							"Lampor lyser när de har slocknat", false,
							"Element är varma", true,
							"Små barn är mindre än stora barn", true,
							"Foten är nedanför knät", true,

							"Kaniner kan flyga ", false,
							"Bananer är runda ", false,
							"En fågel kan flyga.", true,
							"Bilen krockade med solen ", false,
							"Barn är gamla", false,
							"Mammor har inga barn", false,
							"Man kan dricka coca cola", true,
						],
					};
			}
		}
	}
}


