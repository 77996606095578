<template>
	<g id="grid">
		<sq ref="sq0" id="sq0" :idx="0" :val="t0" transform="translate(-400, -400)" />
		<sq ref="sq1" id="sq1" :idx="1" :val="t1" transform="translate(-200, -400)" />
		<sq ref="sq2" id="sq2" :idx="2" :val="t2" transform="translate(0, -400)" />
		<sq ref="sq3" id="sq3" :idx="3" :val="t3" transform="translate(+200, -400)" />

		<sq ref="sq4" id="sq4" :idx="4" :val="t4" transform="translate(-400, -200)" />
		<sq ref="sq5" id="sq5" :idx="5" :val="t5" transform="translate(-200, -200)" />
		<sq ref="sq6" id="sq6" :idx="6" :val="t6" transform="translate(0, -200)" />
		<sq ref="sq7" id="sq7" :idx="7" :val="t7" transform="translate(+200, -200)" />

		<sq ref="sq8" id="sq8" :idx="8" :val="t8" transform="translate(-400, 0)" />
		<sq ref="sq9" id="sq9" :idx="9" :val="t9" transform="translate(-200, -0)" />
		<sq ref="sq10" id="sq10" :idx="10" :val="t10" transform="translate(0, 0)" />
		<sq ref="sq11" id="sq11" :idx="11" :val="t11" transform="translate(+200, 0)" />

		<sq ref="sq12" id="sq12" :idx="12" :val="t12" transform="translate(-400, +200)" />
		<sq ref="sq13" id="sq13" :idx="13" :val="t13" transform="translate(-200, +200)" />
		<sq ref="sq14" id="sq14" :idx="14" :val="t14" transform="translate(0, +200)" />
		<sq ref="sq15" id="sq15" :idx="15" :val="t15" transform="translate(+200, +200)" />
	</g>
</template>

<script>
import sq from "@/v/shared/span_tile.vue";
import Timer from "advanced-timer";

var sequenceTimer;

export default {
	name: "span_grid",
	components: {
		sq
	},
	data: () => { return {
		tiles: [],
		list: [],
		response: [],
		t0: -1, t1: -1, t2: -1, t3: -1, t4: -1, t5: -1, t6: -1, t7: -1, t8: -1, t9: -1, t10: -1, t11: -1, t12: -1, t13: -1, t14: -1, t15: -1,
		clickable: false,
		callback: null,
	}},
	mounted() {
	},
	methods: {
		set_tile: function(idx, value) {
			switch(idx) {
			case 0: this.t0 = value; break;
			case 1: this.t1 = value; break;
			case 2: this.t2 = value; break;
			case 3: this.t3 = value; break;
			case 4: this.t4 = value; break;
			case 5: this.t5 = value; break;
			case 6: this.t6 = value; break;
			case 7: this.t7 = value; break;
			case 8: this.t8 = value; break;
			case 9: this.t9 = value; break;
			case 10: this.t10 = value; break;
			case 11: this.t11 = value; break;
			case 12: this.t12 = value; break;
			case 13: this.t13 = value; break;
			case 14: this.t14 = value; break;
			case 15: this.t15 = value; break;
			}
		},
		tile: function(idx) {
			var value;
			switch(idx) {
			case 0: value = this.t0; break;
			case 1: value = this.t1; break;
			case 2: value = this.t2; break;
			case 3: value = this.t3; break;
			case 4: value = this.t4; break;
			case 5: value = this.t5; break;
			case 6: value = this.t6; break;
			case 7: value = this.t7; break;
			case 8: value = this.t8; break;
			case 9: value = this.t9; break;
			case 10: value = this.t10; break;
			case 11: value = this.t11; break;
			case 12: value = this.t12; break;
			case 13: value = this.t13; break;
			case 14: value = this.t14; break;
			case 15: value = this.t15; break;
			}
			return value;
		},
		tile_clicked: function(idx) {
			if (this.clickable == false)
				return;

			for (var n = 0; n < this.response.length; ++n) {
				if (this.response[n] == idx) {
					for (var del = n; del < this.response.length; ++del) {
						this.set_tile(this.response[del], 6);
					}
					this.response.length = n;
					return;
				}
			}
			this.response.push(idx);
			this.set_tile(idx, 2);
			if (this.response.length == this.list.length) {
				this.clickable = false;
				this.callback();
			}
		},
		set_grid(value) {
			for (var i = 0; i < 16; ++i) this.set_tile(i, value);
		},
		accept_clicks(callback) {
			this.set_grid(6);
			this.response = [];
			this.clickable = true;
			this.callback = callback;
		},
		presentList(list, callback) {
			this.tiles.length = 0;
			this.list = list;
			this.set_grid(0);
			sequenceTimer = new Timer(2000).repeat(list.length + 1).action(t => {
				if (t.currentCycle <= list.length) {
					const i = t.currentCycle - 1;
					this.set_tile(this.list[i], 1);
				} else {
					if (callback != undefined) {
						callback();
					}
				}
			});
			sequenceTimer.start();
		},
		evalResponse(callback) {
			var correct = [];
			var numCorrect = 0;
			for (var i = 0;  i < this.list.length; ++i) {
				if (this.list[i] == this.response[i]) {
					correct.push(1);
					numCorrect++;
				} else {
					correct.push(0);
				}
			}
			var perfect = numCorrect == this.list.length? 1 : 0;
			sequenceTimer = new Timer(250)
				.repeat(this.response.length + 1)
				.action(t => {
					const i = t.currentCycle - 1;
					if (i < this.response.length) {
						const sq = this.response[t.currentCycle - 1];
						if (this.list[i] == this.response[i]) {
							this.set_tile(sq, 4);
						} else {
							this.set_tile(sq, 5);
						}
					} else {
						callback();
					}
				});
			sequenceTimer.start();
			return {level: this.list.length, list: this.list, resp: this.response, corr: correct, numCorrect: numCorrect, perf: perfect};
		},
		show_interlude() {
			for (var i = 0; i < this.response.length; ++i) {
				this.set_tile(this.response[i], 3);
			}
			for (i = 0; i < 16; ++i) {
				if (this.tile(i) == 6) this.set_tile(i, 7);
			}
		}
	}
};
</script>
