<template>
	<g>
		<g id="episodic_memory_relational_icon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<rect
				x="-3"
				y="-30"
				width="6"
				height="90"
				stroke="none"
				fill="#FFF2E3"
			/>
			<path d="M 0 -60 C  30 -60,  70 -40,  75 0 Q  50 -20,  25 0" stroke="none" fill="#FFD6A5"/>
			<path d="M 0 -60 C -30 -60, -70 -40, -75 0 Q -50 -20, -25 0" stroke="none" fill="#FF8800"/>
			<path d="M -25 0 Q 0 -20, 25 0 L 0 -60" stroke="none" fill="#FFB55F"/>
			<path d="M -25 0 Q 0 -20, 25 0 Q 25 -30, 0 -60 Q -25 -30, -25 0" stroke="none" fill="#FFB55F"/>



			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.episodic_memory_relational_label}}</text>
		</g>
	</g>
</template>

<script>
export default {
	name: "episodic_memory_relational_icon",
	idleTween: null,
};
</script>
