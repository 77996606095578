<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="true"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox" v-html="this.text.menu_help" />
			</foreignObject>
		</transition>

		<transition name="fade">
			<g
				:opacity="this.episodic_memory_relational_opacity"
				transform="translate(-300, -140)"
				v-on:click="this.click_episodic_memory_relational"
			>
				<episodic_memory_relational_icon />
			</g>
		</transition>
		<transition name="fade">
			<g
				:opacity="this.wm_maintenance_opacity"
				transform="translate(0, -140)"
				v-on:click="this.click_wm_maintenance"
			>
				<wm_maintenance_icon />
			</g>
		</transition>
		<transition name="fade">
			<g
				:opacity="this.updating_digits_opacity"
				transform="translate(300, -140)"
				v-on:click="this.click_ipark_updating_digits"
			>
				<digitsUpdatingIcon />
			</g>
		</transition>

		<transition name="fade">
			<g
				:opacity="this.episodic_memory_spatial_opacity"
				transform="translate(-300, 160)"
				v-on:click="this.click_episodic_memory_spatial"
			>
				<episodic_memory_spatial_icon />
			</g>
		</transition>
		<transition name="fade">
			<g
				:opacity="this.updating_spatial_opacity"
				transform="translate(0, 160)"
				v-on:click="this.click_ipark_updating_spatial"
			>
				<spatialUpdatingIcon />
			</g>
		</transition>
		<transition name="fade">
			<g
				:opacity="this.shifting_predictable_opacity"
				transform="translate(300, 160)"
				v-on:click="this.click_shifting_predictable"
			>
				<shifting_predictable_icon />
			</g>
		</transition>

		<transition name="fade">
			<g transform="translate(670, -490)">
				<logoutButton />
			</g>
		</transition>

		<session_timeline :sessions="144" :current_session="this.session" />
		<text
			x="-400"
			y="483"
			class="txt"
			text-anchor="end"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="30"
			fill="#FFFFFF"
			>{{ this.text.currentSession(session + 1) }}</text
		>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/finger/menu/lang.js";

import episodic_memory_relational_icon from "@/v/taskMenu/icons/episodic_memory_relational_icon.vue";
import spatialUpdatingIcon from "@/v/taskMenu/icons/spatialUpdatingIcon.vue";
import digitsUpdatingIcon from "@/v/taskMenu/icons/digitsUpdatingIcon.vue";
import episodic_memory_spatial_icon from "@/v/taskMenu/icons/episodic_memory_spatial_icon.vue";
import shifting_predictable_icon from "@/v/taskMenu/icons/shifting_predictable_icon.vue";
import wm_maintenance_icon from "@/v/taskMenu/icons/wm_maintenance_icon.vue";

import logoutButton from "@/v/taskMenu/logoutButton.vue";
import session_timeline from "@/v/session_timeline.vue";
import persist from "@/persist.js";

export default {
	name: "menu_finger",
	components: {
		spatialUpdatingIcon,
		digitsUpdatingIcon,
		episodic_memory_relational_icon,
		episodic_memory_spatial_icon,
		shifting_predictable_icon,
		wm_maintenance_icon,
		session_timeline,
		logoutButton,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {
			show_updating_spatial: false,
			show_updating_digits: false,
			show_episodic_memory_spatial: false,
			show_shifting_predictable: false,
			show_wm_maintenance: false,

			episodic_memory_relational_opacity: 0,
			updating_spatial_opacity: 0,
			updating_digits_opacity: 0,

			episodic_memory_spatial_opacity: 0,
			shifting_predictable_opacity: 0,
			wm_maintenance_opacity: 0,

			session: 0,
		};
	},
	methods: {
		click_episodic_memory_relational() {
			if (this.show_episodic_memory_relational)
				store.dispatch(
					"gotoModule",
					"finger_episodic_memory_relational"
				);
		},
		click_ipark_updating_digits() {
			if (this.show_updating_digits)
				store.dispatch("gotoModule", "finger_updating_digits");
		},
		click_ipark_updating_spatial() {
			if (this.show_updating_spatial)
				store.dispatch("gotoModule", "finger_updating_spatial");
		},
		click_episodic_memory_spatial() {
			if (this.show_episodic_memory_spatial)
				store.dispatch("gotoModule", "finger_episodic_memory_spatial");
		},
		click_shifting_predictable() {
			if (this.show_shifting_predictable)
				store.dispatch("gotoModule", "finger_shifting_predictable");
		},
		click_wm_maintenance() {
			if (this.show_wm_maintenance)
				store.dispatch("gotoModule", "finger_wm_maintenance");
		},
	},
	mounted() {
		//console.log('LOGIN:');
		//console.log(this.$store.state.login_progress);
		//console.log('NOW:');
		//console.log(this.$store.state.progress);

		// BLOCK A
		const login_emr_num = this.$store.state.login_progress.emr_num || 0;
		const login_wm_maintenance_num =
			this.$store.state.login_progress.wm_maintenance_num || 0;
		const login_updating_digits_num =
			this.$store.state.login_progress.updating_digits_num || 0;

		const current_emr_num = this.$store.state.progress.emr_num || 0;
		const current_wm_maintenance_num =
			this.$store.state.progress.wm_maintenance_num || 0;
		const current_updating_digits_num =
			this.$store.state.progress.updating_digits_num || 0;

		// BLOCK B
		const login_episodic_memory_spatial_num =
			this.$store.state.login_progress.episodic_memory_spatial_num || 0;
		const login_updating_spatial_num =
			this.$store.state.login_progress.updating_spatial_num || 0;
		const login_shifting_num =
			this.$store.state.login_progress.shifting_num || 0;

		const current_episodic_memory_spatial_num =
			this.$store.state.progress.episodic_memory_spatial_num || 0;
		const current_updating_spatial_num =
			this.$store.state.progress.updating_spatial_num || 0;
		const current_shifting_num =
			this.$store.state.progress.shifting_num || 0;

		//---
		const session = Math.min(
			login_emr_num,
			login_updating_spatial_num,
			login_updating_digits_num,
			login_episodic_memory_spatial_num,
			login_shifting_num,
			login_wm_maintenance_num
		);

		const a_sessions = Math.min(
			login_emr_num,
			login_wm_maintenance_num,
			login_updating_digits_num
		);

		const b_sessions = Math.min(
			login_episodic_memory_spatial_num,
			login_updating_spatial_num,
			login_shifting_num
		);

		var a_b_session = "a";
		if (a_sessions <= b_sessions) {
			a_b_session = "a";
		} else {
			a_b_session = "b";
		}

		/*
		console.log("---LOGIN----------------------");
		console.log("A:");
		console.log("login_emr_num: " + login_emr_num);
		console.log("login_wm_maintenance_num: " + login_wm_maintenance_num);
		console.log("login_updating_digits_num: " + login_updating_digits_num);
		console.log("B:");
		console.log(
			"login_episodic_memory_spatial_num: " +
				login_episodic_memory_spatial_num
		);
		console.log(
			"login_updating_spatial_num: " + login_updating_spatial_num
		);
		console.log("login_shifting_num: " + login_shifting_num);
		console.log("------------------------------");
		console.log("a_session: " + a_sessions);
		console.log("b_session: " + b_sessions);
		console.log("  session: " + session);
		console.log("      A/B: " + a_b_session);
		console.log(" ");
		console.log("---CURRENT--------------------");
		console.log("A:");
		console.log("current_emr_num: " + current_emr_num);
		console.log(
			"current_wm_maintenance_num: " + current_wm_maintenance_num
		);
		console.log(
			"current_updating_digits_num: " + current_updating_digits_num
		);
		console.log("B:");
		console.log(
			"current_episodic_memory_spatial_num: " +
				current_episodic_memory_spatial_num
		);
		console.log(
			"current_updating_spatial_num: " + current_updating_spatial_num
		);
		console.log("current_shifting_num: " + current_shifting_num);
		console.log("------------------------------");
		*/

		// A:
		this.show_episodic_memory_relational = current_emr_num <= session;
		this.show_wm_maintenance = current_wm_maintenance_num <= session;
		this.show_updating_digits = current_updating_digits_num <= session;
		// B:
		this.show_episodic_memory_spatial =
			current_episodic_memory_spatial_num <= session;
		this.show_updating_spatial = current_updating_spatial_num <= session;
		this.show_shifting_predictable = current_shifting_num <= session;

		//console.log("group: " + this.$store.state.group);

		const display_session = a_sessions + b_sessions;
		this.session = display_session;

		//console.log("display_sessions: " + display_session);

		const met_finger = this.$store.state.group == "met";
		const demo_finger = this.$store.state.group == "demo";

		if (this.$store.state.motivation_done == false) {
			store.dispatch("gotoModule", "form_motivation");
		} else if (demo_finger) {
			this.show_episodic_memory_relational = true;
			this.show_wm_maintenance = true;
			this.show_updating_digits = true;
			this.show_episodic_memory_spatial = true;
			this.show_updating_spatial = true;
			this.show_shifting_predictable = true;
		} else if (met_finger) {
			// A-B sessions:
			if (a_b_session == "a") {
				this.show_episodic_memory_spatial = false;
				this.show_updating_spatial = false;
				this.show_shifting_predictable = false;
				if (
					this.$store.state.concentration_done == false &&
					!this.show_episodic_memory_relational &&
					!this.show_wm_maintenance &&
					!this.show_updating_digits
				) {
					store.dispatch("gotoModule", "form_concentration");
				}
			} else {
				this.show_episodic_memory_relational = false;
				this.show_wm_maintenance = false;
				this.show_updating_digits = false;
				if (
					this.$store.state.concentration_done == false &&
					!this.show_episodic_memory_spatial &&
					!this.show_updating_spatial &&
					!this.show_shifting_predictable
				) {
					store.dispatch("gotoModule", "form_concentration");
				}
			}
		} else {
			// Singer!

			this.show_episodic_memory_spatial =
				current_episodic_memory_spatial_num <= session;
			this.show_updating_spatial =
				current_updating_spatial_num <= session;
			this.show_shifting_predictable = current_shifting_num <= session;

			if (
				!(
					this.show_episodic_memory_relational ||
					this.show_updating_spatial ||
					this.show_updating_digits ||
					this.show_episodic_memory_spatial ||
					this.show_shifting_predictable ||
					this.show_wm_maintenance
				) &&
				this.$store.state.concentration_done == false
			) {
				store.dispatch("gotoModule", "form_concentration");
			}
		}

		if (this.$store.state.concentration_done) {
			persist.logout();
			store.dispatch("gotoModule", "login");
		}

		this.episodic_memory_relational_opacity = this
			.show_episodic_memory_relational
			? 1.0
			: 0.1;
		this.wm_maintenance_opacity = this.show_wm_maintenance ? 1.0 : 0.1;
		this.updating_digits_opacity = this.show_updating_digits ? 1.0 : 0.1;

		this.episodic_memory_spatial_opacity = this.show_episodic_memory_spatial
			? 1.0
			: 0.1;
		this.updating_spatial_opacity = this.show_updating_spatial ? 1.0 : 0.1;
		this.shifting_predictable_opacity = this.show_shifting_predictable
			? 1.0
			: 0.1;

		/*this.episodic_memory_spatial_opacity = this.show_episodic_memory_spatial
			? 1.0
			: 0.1;
		this.updating_spatial_opacity = this.show_updating_spatial ? 1.0 : 0.1;
		this.shifting_predictable_opacity = this.show_shifting_predictable
			? 1.0
			: 0.1;*/

		/*
		if (this.$store.state.motivation_done == false) {
			store.dispatch("gotoModule", "form_motivation");
		} else if (
			!(
				this.show_episodic_memory_relational ||
				this.show_updating_spatial ||
				this.show_updating_digits ||
				this.show_episodic_memory_spatial ||
				this.show_shifting_predictable ||
				this.show_wm_maintenance
			) &&
			this.$store.state.concentration_done == false
		) {
			store.dispatch("gotoModule", "form_concentration");
		} else if (this.$store.state.concentration_done) {
			persist.logout();
			store.dispatch("gotoModule", "login");
		}*/

		//store.dispatch("gotoModule", "finger_episodic_memory_spatial");
	},
};
</script>
