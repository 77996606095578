<template>
	<g>
		<play ref="play" />
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/reco/keep_track/lang.js";
import play from "@/v/reco/keep_track/play.vue";
import instructions from "@/v/reco/keep_track/instructions.vue";

export default {
	name: "reco_keep_track",
	//genväg-alias:
	local: store.state.reco_keep_track,
	components: {
		play,
		instructions
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	mounted() {
		store.commit('setTaskMode', 'ready');
		//store.commit("taskPhase", 0);
	}
};
</script>

<style lang="stylus">
.skyword {
	cursor: default;
	user-select: none;
	text-shadow: 0px 0px 8px #FFFFFF;
}
</style>
