<template>
	<g>
		<transition name="fade">
			<itemSet ref="itemSet" />
		</transition>
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		<training v-if="this.$store.state.taskContainer.taskPlaying" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/finger/updating_digits/lang.js";
import instructions from "@/v/finger/updating_digits/instructions.vue";
import training from "@/v/finger/updating_digits/training.vue";
import itemSet from "@/v/finger/updating_digits/itemSet.vue";

export default {
	name: "finger_updating_digits",
	//genväg-alias:
	local: store.state.finger_updating_digits,
	components: {
		instructions,
		training,
		itemSet,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	mounted() {
		//const params = this.calculateParameters(level);
		store.commit("finger_updating_digits_setInstructionsViewed", true);
		store.commit("finger_updating_digits_resetitemSet");
		store.commit("finger_updating_digits_showitemSet");
		
		store.commit("taskPhase", 0);
		store.commit("setTaskMode", "ready");
	}
};
</script>

<style lang="stylus"></style>
