<template>
	<g>
		<defs>
			<g id="brick" transform="scale(0.45)">
				<path
					d="M -120 -60 L 0 -135 120 -60 120 67 0 135 -120 67 -120 -60"
					fill="#FFA000C0"
					stroke="#00000008"
					stroke-width="10"
				/>
				<path d="M 0 0 L 0 -135 120 -60 120 67 0 0" fill="#FFFFFF15" />
				<path d="M 0 0 L 120 67 0 135-120 67 0 0" fill="#00000004" />

				<text
					opacity="1.0"
					x="0"
					y="15"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="150"
					fill="#FFFFFF"
				>A</text>
			</g>
		</defs>

		<g>
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<use xlink:href="#brick" x="0" y="0" />

			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.lettersUpdatingLabel}}</text>
		</g>
	</g>
</template>

<script>

export default {
	name: "lettersUpdatingIcon",
	idleTween: null,
};
</script>
