<template>
	<g :id="'c'+idx" class="button" v-on:click="clicked">
		<transition name="fade">
			<g v-if="visible">
				<rect rx="10" fill="#00000011" x="-75" y="-68" width="140" height="140" />
				<g v-if="is_up">
					<rect  rx="10" fill="#DDDDDDFF" x="-70" y="-70" width="140" height="140" stroke="#33333333" stroke-width="3px" />
					<circle fill="#E0E0E0FF" stroke="none" cx="0" cy="0" r="60" />
					<path v-if="sign==0" fill="#000000AA" stroke="#00000066" stroke-width="5" d="M -30 -30 L 30 30 -30 30 30 -30 Z" />
					<path v-else-if="sign==1" fill="#000000AA" stroke="#00000066" stroke-width="5" d="M 35 0 L -28 21 11 -33 11 33 -28 -21 Z" />
					<path v-else-if="sign==2" fill="#000000AA" stroke="#00000066" stroke-width="5" d="M 0 -30 Q 50 25 0 30, -50 25 0 -30 Z" />
					<path v-else-if="sign==3" fill="#000000AA" stroke="#00000066" stroke-width="5" d="M 25 -30 Q -23 -30 -25 0, -23 30 25 30, -15 0 25 -30 Z" />
					<path v-else-if="sign==4" fill="none" stroke="#000000AA" stroke-width="5" d="M -30 0 L 30 0 M 0 -30 L 0 30" />
					<path v-else-if="sign==5" fill="none" stroke="#000000AA" stroke-width="5" d="M -30 -15 L 15 30 M -15 -30 L 30 15 M -30 15 L 13 -30 M -15 30 L 30 -15" />
					<path v-else-if="sign==6" fill="none" stroke="#000000AA" stroke-width="5" d="M -30 -8 Q -15 -20 0 -10, 15 0 30 -15 M -30 15 Q -15 0 0 10, 15 20 30 8" />
					<g v-else-if="sign==7">
						<circle fill="#000000AA" stroke="#00000066" stroke-width="5" cx="0" cy="0" r="35" />
						<circle fill="#DDDDDDFF" stroke="#00000066" stroke-width="5" cx="0" cy="0" r="20" />
					</g>
					<g v-else-if="sign==8">
						<rect rx="5" fill="#000000AA" stroke="#00000066" stroke-width="5" x="-35" y="-35" width="70" height="70" />
						<rect rx="3" fill="#DDDDDDFF" stroke="#00000066" stroke-width="5" x="-26" y="-26" width="22" height="22" />
						<rect rx="3" fill="#DDDDDDFF" stroke="#00000066" stroke-width="5" x="5"   y="5"   width="22" height="22" />
						<rect rx="3" fill="#DDDDDDFF" stroke="#00000066" stroke-width="5" x="-26" y="5"   width="22" height="22" />
						<rect rx="3" fill="#DDDDDDFF" stroke="#00000066" stroke-width="5" x="5"   y="-26" width="22" height="22" />
					</g>
					<path v-else-if="sign==9" fill="#000000AA" stroke="#00000066" stroke-width="5" d="M 35 0 Q 0 0 28 21, 0 0 11 33, 0 0 -11 33, 0 0 -28 21, 0 0 -35 0, 0 0 -28 -21, 0 0 -11 -33, 0 0 11 -33, 0 0 28 -21, 0 0 35 0 Z" />
					<path v-else-if="sign==10" fill="none" stroke="#000000AA" stroke-width="5" d="M 0 0 L 1 1 1 2 -1 3 -3 2 -5 0 -5 -4 -2 -7 2 -8 7 -5 10 0 9 6 4 11 -4 12 -11 8 -15 0 -13 -9 -5 -16 6 -17 15 -11 20 0 17 12 7 21 -7 22 -19 14 -25 0 -21 -15 -8 -26 9 -27 23 -17 30 0 25 18 10 30 -10 31 -27 20 -35 0" />
					<g v-else-if="sign==11">
						<circle fill="none" stroke="#000000AA" stroke-width="5" cx="13" cy="22" r="11" /><circle fill="none" stroke="#000000AA" stroke-width="5" cx="-12" cy="22" r="11" /><circle fill="none" stroke="#000000AA" stroke-width="5" cx="-25" cy="0" r="11" /><circle fill="none" stroke="#000000AA" stroke-width="5" cx="-13" cy="-22" r="11" /><circle fill="none" stroke="#000000AA" stroke-width="5" cx="12" cy="-22" r="11" /><circle fill="none" stroke="#000000AA" stroke-width="5" cx="25" cy="0" r="11" />
					</g>
				</g>
				<rect v-else rx="10" fill="#CCCCCCFF" x="-70" y="-70" width="140" height="140" stroke="#33333333" stroke-width="3px" />
			</g>
		</transition>
	</g>
</template>

<script>

/*
		<text
			y="-50"
			x="-50"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="20"
			fill="#FF0000"
		>{{sign}}</text>
*/

import gsap from "gsap";

export default {
	name: "card",
	props: {
		idx: {
			type: Number,
			default: 0
		}
	},
	data: () => { return {
		visible: false,
		sign: 0,
		is_up: false,
		found: false,
		num_mistakes: 0,
		num_turns: 0,
	}},
	vars: {
		xpos: 0,
		ypos: 0,
	},
	methods: {
		clicked: function(/*event*/) {
			if (this.found == false && this.is_up == false) {
				this.$parent.card_turned(this);
			}
		},
		show(vis = true) {
			if (vis) {
				this.visible = vis;
				//gsap.fromTo("#c" + this.idx, {opacity: 0}, {opacity: 1, duration: 1});
				gsap.to("#c" + this.idx, {opacity: 1, duration: 1});
			} else {
				gsap.to("#c" + this.idx, {opacity: 1, duration: 1, onComplete:()=>{this.vis = false;}});
			}
		},
		mark_as_found() {
			if (this.found == false) {
				gsap.to("#c" + this.idx, {delay: 2, opacity: 0.05, duration: 1});
			}
			this.found = true;
		},
		turn(up, delay = 0) {
			//console.log('turn ' + this.idx + ': ' + up + ', delay ' + delay);
			if (this.visible == false) return;
			//if (this.is_up == up) return;

			const sel = "#c" + this.idx;
			var tl = gsap.timeline();
			tl.fromTo(sel,
				{scaleX: 1, scaleY: 1}, 
				{scaleX: 0, scaleY: 1.1, x: this.vars.xpos+60, y: this.vars.ypos-6, delay: delay, duration: 0.1, onComplete:()=>{
					this.is_up = up;
				}});
			tl.to(sel, {
				x: this.vars.xpos,
				y: this.vars.ypos,
				scaleX: 1.0,
				scaleY: 1.0,
				delay: 0.1,
				duration: 0.1
			});
		},
		move_to(x, y, delay = 0) {
			if (this.visible == false) return;

			this.vars.xpos = x;
			this.vars.ypos = y;
			gsap.to("#c" + this.idx, {
				x: x,
				y: y,
				delay: delay,
				duration: 0.5});
		},
		changeState: function(newState) {
			if (this.visible == false) return;
			const sel = "#c" + this.idx;

			var tl;
			switch (newState) {
			case 1: // blink
				tl = gsap.timeline();
				tl.to(sel, { fill: "#CC8822", duration: 0.2 });
				tl.to(sel, { fill: "#99BBCC", delay: 0.6, duration: 0.2 });
				//tl.to(sel, { fill: "#CCCCCC", delay: 0.6, duration: 0.2 });
				break;
			case 2: // selected
				tl = gsap.timeline();
				tl.to(sel, { fill: "#55808E", duration: 0.2 });
				tl.to(sel, { fill: "#35606E", duration: 0.2 });
				break;
			case 3: // selected + inactive
				//gsap.to(sel, { fill: "#AAAAAA", duration: 1 });
				gsap.to(sel, { fill: "#35606E", duration: 1 });
				break;
			case 4: // correct
				tl = gsap.timeline();
				tl.to(sel, { fill: "#FFFFFF", duration: 0.1 });
				tl.to(sel, { fill: "#55AA55", delay: 0.1, duration: 2 });
				break;
			case 5: // incorrect
				tl = gsap.timeline();
				tl.to(sel, { fill: "#000000", duration: 0.1 });
				tl.to(sel, { fill: "#884444", delay: 0.1, duration: 0.5 });
				//gsap.to(sel, { fill: "#884444", duration: 2.2 });
				break;
			case 6: // clickable + non-selected
				gsap.to(sel, { fill: "#CCCCCC", duration: 0.1 });
				break;
			default:
				gsap.to(sel, { fill: "#99BBCC", duration: 0.2 });
			}
		}
	},
	mounted() {
		this.vars = {xpos: 0, ypos: 0};
		this.changeState(0, -1);
	}
};
</script>
