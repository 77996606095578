<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="this.$store.state.taskContainer.taskPhase == 1 || this.$store.state.taskContainer.taskPhase == 2"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.task(this.$store.state.spatialSpan.level)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="this.$store.state.taskContainer.taskPhase == 3 || this.$store.state.taskContainer.taskPhase == 4"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.respond(this.$store.state.spatialSpan.level)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<g v-if="this.$store.state.taskContainer.taskPhase > 6 || (this.$store.state.taskContainer.taskPhase <= 1 && this.$store.state.spatialSpan.round > 0)">
				<foreignObject x="-600" y="-495" width="1200" height="99">
					<div class="txtbox">
						<span
							v-html="$parent.text.results.numCorrect(this.$store.state.spatialSpan.numCorrect, this.$store.state.spatialSpan.lastLevel)"
						/>
					</div>
				</foreignObject>
				<timeline v-bind:stages="9" v-bind:pos="this.$store.state.spatialSpan.round" />
			</g>
		</transition>
	</g>
</template>

<script>
import stat from "@/stat.js";
import { store } from "@/store.js";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";

export default {
	name: "training",
	components: {
		timeline
	},
	watch: {
		phase: function(/*newPhase*/) {
			this.update();
		}
	},
	methods: {
		update() {
			switch (this.$store.state.taskContainer.taskPhase) {
			case 0:
			case 1: // Din uppgift...
				store.commit("spatialSpan_resetGrid");
				var delay;
				if (store.state.spatialSpan.level == 1) delay = 4000;
				else if (store.state.spatialSpan.level == 2) delay = 3000;
				else if (store.state.spatialSpan.level == 3) delay = 2000;
				else delay = 500;
				setTimeout(
					() =>
						this.$parent.$parent.$refs.countDown.play(() =>
							store.commit("taskPhase", 2)
						),
					delay
				);
				break;
			case 2: // Din uppgift...
				//sel.classList.remove("cursorHide");
				//sel.classList.add("cursorPointer");

				store.commit("spatialSpan_showGrid");
				//this.$parent.$refs.grid.presentList([7, 13, 2], () =>
				this.$parent.$refs.grid.presentList(
					this.createRandomList(store.state.spatialSpan.level),
					() => store.commit("taskPhase", 3)
				);
				break;
			case 3: // interlude
				setTimeout(
					() => store.commit("taskPhase", 4),
					100
				);
				break;
			case 4: // respond
				//sel.classList.add("cursorPointer");
				//sel.classList.remove("cursorHide");
				store.commit("spatialSpan_showGrid");
				store.commit("spatialSpan_acceptClicks", true);
				break;
			case 5:
				setTimeout(
					() => store.commit("spatialSpan_showInterludeGrid"),
					1000
				);
				setTimeout(
					() => store.commit("taskPhase", 6),
					2000
				);
				break;
			case 6: // show round results
				var roundResults = this.$parent.$refs.grid.evalResponse();
				this.sessionData.rounds.push(roundResults);
				break;
			case 7: // show round results, 2
				if (store.state.spatialSpan.round < 9) {
					setTimeout(
						() => store.commit("taskPhase", 0),
						2000
					);
				} else {
					setTimeout(
						() => store.commit("taskPhase", 8),
						4000
					);
				}
				break;
			case 8: // done!
				this.saveSessionResults();
				store.commit("setTaskMode", "sessionScore");
				break;
			}
		},
		createRandomList(length) {
			var i = Math.min(length, 16);
			var list = [];
			while (i > 0) {
				var sq = Math.trunc(Math.random() * 16);
				if (sq < 16) {
					if (list.indexOf(sq) < 0) {
						list.push(sq);
						--i;
					}
				}
			}
			return list;
		},
		saveSessionResults() {
			this.sessionData.sumCorrect = stat.sum(this.$store.state.spatialSpan.numCorrectPerRound);
			this.sessionData.timeEnd = new Date().toLocaleString();

			persist.log('results', {
				sum_correct: this.sessionData.sumCorrect,
				rounds: this.sessionData.rounds,
				level: this.$store.state.spatialSpan.level
			});

			persist.set_progress_data('spatial_span_level', this.$store.state.spatialSpan.level);

			persist.addToHistory('score', this.sessionData.sumCorrect).then(history => {
				store.commit("setSessionScores", history);
				store.commit('setTaskMode', 'sessionScore');
			});
		}
	},
	sessionData: {},
	computed: {
		phase: function() {
			return this.$store.state.taskContainer.taskPhase;
		}
	},
	mounted() {
		this.sessionData = {
			timeStart: new Date().toLocaleString(),
			level: this.$store.state.spatialSpan.level,
			rounds: []
		};
		this.update();
	}
};
</script>
