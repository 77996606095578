<template>
	<g>
		<g id="shifting_unpredictable_icon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<rect
				stroke="none"
				stroke-width="5"
				fill="#9EB8C5"
				x="-42" y="-42"
				width="84" height="84"
				rx="5"
			/>
			<circle
				cx="0" cy="0" r="38"
				stroke="#F3A00055"
				stroke-width="5"
				fill="#F3A000"
			/>
			<text
				opacity="1.0"
				x="1"
				y="4"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="47"
				fill="#FFFFFF"
			>A</text>

			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.shifting_unpredictable_label}}</text>
		</g>
	</g>
</template>

<script>
export default {
	name: "shifting_unpredictable_icon",
	idleTween: null,
};
</script>
