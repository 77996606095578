<template>
	<g>
		<grid ref="grid" />
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		<play v-if="this.$store.state.taskContainer.taskPlaying" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_span_spatial_linear/lang.js";
import instructions from "@/v/test_span_spatial_linear/instructions.vue";
import play from "@/v/test_span_spatial_linear/play.vue";
import grid from "@/v/shared/span_grid.vue";


export default {
	name: "test_span_spatial_linear",
	components: {
		instructions,
		play,
		grid,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	mounted() {
		//store.commit('setTaskMode', 'ready');
		//store.commit('setTaskMode', 'auto');
		store.commit('setTaskMode', 'instructions');
		store.commit("taskPhase", 0);
		setTimeout(() => {
			store.commit('taskPhase', 1);
		}, 10000);

		this.$refs.grid.set_grid(0);
	}
};
</script>
