<template>
	<g :id="'item' + idx" class="button" v-on:click="clicked">
		<clipPath :id="'hexclip' + idx">
			<path d="M -60 -30 L 0 -67 60 -30 60 34 0 67 -60 34 Z" />
		</clipPath>

		<path
			:id="'item' + idx + 'bg'"
			d="M -60 -30 L 0 -67 60 -30 60 34 0 67 -60 34 Z"
			fill="#99BBCC"
			stroke="#00000008"
			stroke-width="10"
		/>
		<path d="M 0 0 L 0 -67 60 -30 60 34 Z" fill="#FFFFFF15" />
		<path d="M 0 0 L 60 34 0 67-60 34 Z" fill="#00000004" />

		<circle r="90" cx="60" cy="-30" fill="#FFFFFF04" :clip-path="'url(#hexclip'+idx+')'" />
		<circle r="90" cx="-60" cy="-30" fill="#88888804" :clip-path="'url(#hexclip'+idx+')'" />
		<circle r="90" cx="0" cy="67" fill="#00000004" :clip-path="'url(#hexclip'+idx+')'" />

		<circle r="90" cx="0" cy="-67" fill="#FFFFAA08" :clip-path="'url(#hexclip'+idx+')'" />
		<circle r="90" cx="60" cy="30" fill="#FFAAFF08" :clip-path="'url(#hexclip'+idx+')'" />
		<circle r="90" cx="-60" cy="30" fill="#AAFFAA08" :clip-path="'url(#hexclip'+idx+')'" />

		<text
			:id="'item' + idx + 'value'"
			opacity="1.0"
			x="0"
			y="10"
			class="txt"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="80"
			fill="#FFFFFF"
		>{{label}}</text>

	</g>
</template>

<script>
import gsap from "gsap";

//{{this.txt}}

export default {
	name: "item",
	props: {
		symbol: {
			type: String,
			default: '?'
		},
		idx: {
			type: String,
			default: '_'
		}
	},
	data: () => { return {
		label: '-',
		xpos: 0,
	}},
	methods: {
		clicked: function(/*event*/) {
			if (this.idx.charAt(0) == 'b') {
				this.$parent.clickedButton(this);
			}
			else if (this.idx.charAt(0) == 'a') {
				this.$parent.clickedAnswerItem(this);
			}
			/*
			var len = this.$parent.currentAnswer.length;
			console.log(len);
			console.log(this.$parent.currentList);
			if (len >= this.$parent.currentList.length) {
				return;
			}
			var item = this.$parent.a[len];
			//item.moveTo(-200, -200);
			const it = "#item" + item.idx;
			//console.log(it);
			//console.log(item);
			//var xp =  this.$parent.calcX(this.idx)
			//console.log(this.idx)
			gsap.set(it, { x: this.xpos, y: 300 });
			this.$parent.addAnswerItem(this.symbol.toString());
			*/
		},
		changeState: function(newState) {
			const sel = "#item" + this.idx + "bg";
			const item = "#item" + this.idx;


			var tl;
			switch (newState) {
			case 1: // blink
				tl = gsap.timeline();
				tl.to(sel, { fill: "#CC8822", duration: 0.1 });
				tl.to(sel, { fill: "#99BBCC", delay: 0.6, duration: 0.2 });
				break;
			case 2: // selected
				tl = gsap.timeline();
				tl.to(sel, { fill: "#55808E", duration: 0.2 });
				tl.to(sel, { fill: "#35606E", duration: 0.2 });
				break;
			case 3: // selected + inactive
				gsap.to(sel, { fill: "#35606E", duration: 1 });
				break;
			case 'correct': // correct
				tl = gsap.timeline();
				tl.to(sel, { fill: "#FFFFFF", duration: 0.1 });
				tl.to(sel, { fill: "#55AA55", delay: 0.1, duration: 2 });
				break;
			case 'incorrect': // incorrect
				tl = gsap.timeline();
				tl.to(sel, { fill: "#000000", duration: 0.1 });
				tl.to(sel, { fill: "#884444", delay: 0.1, duration: 0.5 });
				break;
			case 6: // clickable + non-selected
				gsap.to(sel, { fill: "#CCCCCC", duration: 0.1 });
				break;
			case 'white':
				gsap.to(sel, { fill: "#FFFFFF88", duration: 0.1 });
				break;
			case 'black':
				gsap.to(sel, { fill: "#000000CC", duration: 2 });
				break;
			case 'orange':
				gsap.to(sel, { fill: "#FFA000CC", duration: 2 });
				gsap.to(item, { opacity: 1, duration: 1 });
				break;
			case 'blue':
				gsap.to(sel, { fill: "#0055FFCC", duration: 0.1 });
				break;
			case 'blink':
				tl = gsap.timeline();
				//tl.call(() => {this.label = '-'; console.log('-');}, null, 0);
				tl.to(sel, { fill: "#FFA000", delay: 0, duration: 0.0 });
				tl.call(() => {this.label = ''}, null, 1);
				tl.to(sel, { fill: "#99BBCC", delay: 0, duration: 0.5 });
				break;
			case 'blank':
				this.label = '';
				gsap.to(sel, { fill: "#99BBCC", duration: 1 });
				gsap.to(item, { opacity: 1, duration: 1 });
				break;
			case 'hidden':
				//gsap.to(item, { opacity: 0, duration: 0.1 });
				gsap.set(item, { opacity: 0 });
				this.label = '';
				break;
			case 'visible':
				//gsap.to(item, { opacity: 1, duration: 0.1 });
				gsap.set(item, { opacity: 1 });
				break;
			default:
				gsap.to(sel, { fill: "#99BBCC", duration: 0.2 });
			}
		},
		moveTo: function(x, y) {
			const item = "#item" + this.idx;
			var tl = gsap.timeline();
			tl.to(item, { duration: 0.5, x: x, y: y });
			this.xpos = x;
		},
		setPos: function(x, y) {
			const item = "#item" + this.idx;
			gsap.set(item, { x: x, y: y });
			this.xpos = x;
		}
	},
	/*computed: {
		itemSet() {
			return store.state.reco_updating_letters.itemSet;
		}
	},*/
	watch: {
		itemSet: function(newitemSet, previtemSet) {
			if (newitemSet[this.idx] != previtemSet[this.idx]) {
				this.changeState(newitemSet[this.idx], previtemSet[this.idx]);
			}
		}
	},
	mounted() {
		//this.changeState(0, -1);
		//this.moveTo(-300, 100);
		this.label = this.symbol;
	}
};
</script>
