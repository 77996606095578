<template>
	<transition name="quickfade">
		<foreignObject v-if="this.$store.state.errorView.visible" v-on:click="$store.commit('hideError')" x="-790" y="420" width="1580" height="99">
			<div class="error">
				<span v-html="this.$store.state.errorView.msg" /> 🔥
			</div>
		</foreignObject>
	</transition>
</template>

<script>
export default {
	name: "errorView"
}

//		setTimeout(() => {throw "XYZ failed";}, 6000);
//		throw "ABC failed";

</script>

<style lang="stylus">
.error {
	cursor: default;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	background-color: #440000BB;
	border-radius: 25px;
	border: 2px solid black;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 30px;
	font-family: sans-serif;
	color: #CC0000;
	letter-spacing: 0.05em;
	text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000, -1px -1px 12px #000;
}
</style>
