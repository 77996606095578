import Vue from 'vue'
import { store } from '@/store.js'
import lang from "@/lang.js";
import App from './App.vue'

Vue.config.productionTip = true;

const language = (window.navigator.userLanguage || window.navigator.language || 'en').substring(0, 2);

if (location.protocol == 'http:') {
	if (location.hostname != 'localhost') {
		//location.replace(`https:${location.href.substring(location.protocol.length)}`);
		location.replace('https://psymet.online');
	}
	//console.log(location);
}

//console.log('main.js lang: ' + language);

var shared = {
	browser_lang: language,
	text: lang.getLocalisedText(language)
};

shared.install = function () {
	Object.defineProperty(Vue.prototype, '$shared', {
		get() { return shared }
	})
}


Vue.use(shared);

const root = new Vue({
	el: '#app',
	store,
	render: h => h(App)
});


Vue.config.errorHandler = (err, vm) => {
	/* eslint-disable no-console */
	//console.log(`err: [${err}] [${vm.$options._componentTag}]`);
	root.$store.commit("showError", `${err} - ${vm.$options._componentTag}`);
	/* eslint-enable no-console */

	// TODO: log to server.

	// return false to stop the propagation of errors further to parent or global error handler:
	return false;
}

window.onerror = function (message, source, lineno, colno, error) {
	/* eslint-disable no-console */
	//console.log(`err: ${error}.`);
	/* eslint-enable no-console */
	root.$store.commit("showError", `${error}`);

	// TODO: log to server.
	return true;
};

