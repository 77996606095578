export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
			default:
				return {
					question: "Hur stark är din upplevelse av mental trötthet just nu?",
					a1: "1. Ingen alls",
					a2: "2. Svag",
					a3: "3. Måttlig",
					a4: "4. Stark",
					a5: "5. Mycket stark",
				}
			}
		}
	}
}
