<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="stage == 0" x="-600" y="-495" width="1200" height="160">
				<div class="txtbox">
					<div class="link" v-on:click="begin()">
						<span v-html="$parent.text.instructions.t0_button" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 1" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t1" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 2" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t2" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 3" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t3" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 4" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t4" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 5" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t5" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 6" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t6" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 7" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t7" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 8" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t8" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 9" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t9" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 10" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t10" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 11" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t11" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="stage == 12" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t12" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<rect
				v-if="hint_upper"
				stroke="#FFFFFF"
				stroke-width="10"
				fill="none"
				x="-310" y="-310"
				width="620" height="320"
				rx="15"
			/>
		</transition>
		<transition name="fade">
			<rect
				v-if="hint_lower"
				stroke="#FFFFFF"
				stroke-width="10"
				fill="none"
				x="-310" y="-10"
				width="620" height="320"
				rx="15"
			/>
		</transition>
		<g v-if="show_keyboard">
			<text
				opacity="1.0"
				x="-550"
				y="-30"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="65"
				fill="#000000CC"
			>F</text>
			<text
				v-if="show_keyboard"
				opacity="1.0"
				x="550"
				y="-30"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="65"
				fill="#000000CC"
			>J</text>
		</g>
		<hand v-show="handVisible" ref="hand" />
		<hand2 v-show="handVisible" ref="hand2" />
	</g>
</template>

<script>
//import { store } from "@/store.js";
import hand from "@/v/hand.vue";
import hand2 from "@/v/hand2.vue";
import gsap from "gsap";

export default {
	name: "instructions",
	components: { hand, hand2 },
	data: function() {
		return {
			stage: 0,
			handVisible: false,
			hand: null,
			show_keyboard: false,
			hint_upper: false,
			hint_lower: false,
		};
	},
	methods: {
		begin() {
			this.input_disabled = true; // förbjud input under instruktionen
			this.$store.commit('setTaskMode', 'instructions');	
			this.$store.commit('taskPhase', 1);
			this.handVisible = false;
			this.stage = -1;
			this.hint_upper = false;
			this.hint_lower = false;

			this.$parent.items = [];
			this.$parent.items.push({ block: -2, quad: 0, stim: 1 });
			this.$parent.items.push({ block: -2, quad: 1, stim: 2 });
			this.$parent.items.push({ block: -2, quad: 2, stim: 3 });
			this.$parent.items.push({ block: -2, quad: 3, stim: 4 });

			// --- Odd ---
			this.$parent.items.push({ block: 0, quad: 0, stim: 1, task: "oe" });
			this.$parent.items.push({ block: 0, quad: 1, stim: 7, task: "oe" });
			this.$parent.items.push({ block: 0, quad: 0, stim: 9, task: "oe" });
			this.$parent.items.push({ block: 0, quad: 1, stim: 3, task: "oe" });

			// --- Even ---
			this.$parent.items.push({ block: 1, quad: 0, stim: 8, task: "oe" });
			this.$parent.items.push({ block: 1, quad: 1, stim: 4, task: "oe" });
			this.$parent.items.push({ block: 1, quad: 0, stim: 6, task: "oe" });
			this.$parent.items.push({ block: 1, quad: 1, stim: 2, task: "oe" });

			// --- Low ---
			this.$parent.items.push({ block: 0, quad: 2, stim: 1, task: "lh" });
			this.$parent.items.push({ block: 0, quad: 3, stim: 4, task: "lh" });
			this.$parent.items.push({ block: 0, quad: 2, stim: 2, task: "lh" });
			this.$parent.items.push({ block: 0, quad: 3, stim: 3, task: "lh" });

			// --- High ---
			this.$parent.items.push({ block: 0, quad: 2, stim: 8, task: "lh" });
			this.$parent.items.push({ block: 0, quad: 3, stim: 9, task: "lh" });
			this.$parent.items.push({ block: 0, quad: 2, stim: 7, task: "lh" });
			this.$parent.items.push({ block: 0, quad: 3, stim: 6, task: "lh" });

			// --- All ---
			this.$parent.items.push({ block: 0, quad: 0, stim: 1, task: "oe" });
			this.$parent.items.push({ block: 0, quad: 1, stim: 8, task: "oe" });
			this.$parent.items.push({ block: 0, quad: 2, stim: 6, task: "lh" });
			this.$parent.items.push({ block: 0, quad: 3, stim: 3, task: "lh" });

			this.$parent.items.push({ block: 0, quad: 0, stim: 6, task: "oe" });
			this.$parent.items.push({ block: 0, quad: 1, stim: 2, task: "oe" });
			this.$parent.items.push({ block: 0, quad: 2, stim: 2, task: "lh" });
			this.$parent.items.push({ block: 0, quad: 3, stim: 4, task: "lh" });

			// -------

			this.$parent.trial = -1;
			this.$parent.item_visible = false;
			this.$parent.item_response_blocked = true;
			this.$parent.block_feedback = false;
			this.$parent.show_buttons = false;

			var t8 = gsap.timeline();
			t8.to(this, { delay: 2, onStart: () => { this.stage = 1;  }, duration: 4 });
			// Visa de fyra kvadranterna
			t8.to(this, { onStart: () => { this.$parent.trial = 0; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$parent.trial = 1; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$parent.trial = 2; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$parent.trial = 3; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });
			//----------

			t8.to(this, { onStart: () => { this.stage = 2; this.$parent.show_buttons = true; }, duration: 2 });
			t8.to(this, {
				onStart: () => {
					this.handVisible = true;
					this.$refs.hand.show(() => {
						this.hand = gsap.timeline();
						this.hand.to("#hand", {
							duration: 1,
							x: 550,
							y: 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
							}
						});
					});

					this.$refs.hand2.show(() => {
						this.hand2 = gsap.timeline();
						this.hand2.to("#hand2", {
							duration: 1,
							x: -550,
							y: 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand2.click();
							}
						});
					});
				},
				duration: 12,
			});
			// Visa keyboard
			t8.to(this, { onStart: () => { this.stage = -1; this.show_keyboard = false; }, duration: 1 });
			t8.to(this, { onStart: () => { this.stage = 3;  this.show_keyboard = true; },  duration: 8, });
			t8.to(this, { onStart: () => { this.stage = -1; this.show_keyboard = false; }, duration: 2, });

			t8.to(this, { onStart: () => { this.stage = 4;  }, duration: 6 });
			t8.to(this, { onStart: () => { this.stage = -1; }, duration: 1 });


			// Övre
			t8.to(this, { onStart: () => { this.stage = 5; this.hint_upper = true; }, duration: 12 });
			t8.to(this, { onStart: () => { this.stage = -1; }, duration: 1 });

			t8.to(this, { onStart: () => { this.stage = 6; this.hint_upper = false; }, duration: 4 });
			// Animera udda
			t8.to(this, { onStart: () => { this.$parent.trial = 4; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 5; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 6; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 7; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });


			t8.to(this, { onStart: () => { this.stage = 7;  }, duration: 4 });
			// Animera jämna
			t8.to(this, { onStart: () => { this.$parent.trial = 8; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 9; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 10; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 11; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });


			// Nedre
			t8.to(this, { onStart: () => { this.stage = 8; this.hint_lower = true; }, duration: 12 });
			t8.to(this, { onStart: () => { this.stage = -1; this.hint_lower = false; }, duration: 1 });

			t8.to(this, { onStart: () => { this.stage = 9;  }, duration: 4 });
			// Animera låga
			t8.to(this, { onStart: () => { this.$parent.trial = 12; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 13; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 14; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 15; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });


			t8.to(this, { onStart: () => { this.stage = 10;  }, duration: 4 });
			// Animera höga
			t8.to(this, { onStart: () => { this.$parent.trial = 16; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 17; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 18; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 19; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });

			
			t8.to(this, { onStart: () => { this.stage = 11;  }, duration: 8 });
			// Animera alla
			t8.to(this, { onStart: () => { this.$parent.trial = 20; this.$parent.item_visible = true; }, duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 21; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 22; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 23; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });

			t8.to(this, { onStart: () => { this.$parent.trial = 24; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 25; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 26; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.trial = 27; },  duration: 2 });
			t8.to(this, { onStart: () => { this.$refs.hand2.click(); }, duration: 1 });
			t8.to(this, { onStart: () => { this.$parent.item_visible = false; this.$parent.trial = -1; this.stage = -1; }, duration: 2 });

			
			t8.to(this, { onStart: () => { this.stage = 12; this.$refs.hand.hide(); this.$refs.hand2.hide(); }, duration: 7 });
			t8.to(this, { onStart: () => { this.stage = -1; this.handVisible = false; }, duration: 2 });

			t8.to(this, {
				onStart: () => {
					this.stage = -1;
					this.$parent.setup();
				},
				duration: 1,
			});
		},
	},
	mounted() {
		this.stage = 0;
	}
};

</script>
