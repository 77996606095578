<template>
	<g>
		<g id="episodic_memory_spatial_icon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-75"
				y="-75"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-22.5"
				y="-75"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="30"
				y="-75"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-75"
				y="-22.5"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-22.5"
				y="-22.5"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="30"
				y="-22.5"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-75"
				y="30"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-22.5"
				y="30"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="30"
				y="30"
				width="45"
				height="45"
			/>
			<path fill="#ff9922dd" stroke="#CC660088" stroke-width="2" d="M -10 -10 L 10 10 -10 10 10 -10 Z" />
			<path fill="#ff9922dd" stroke="#CC660088" stroke-width="2" d="M -52.5 -10 Q -36.5 8 -52.5 10, -68.5 8 -52.5 -10 Z" />

			<circle fill="#ff9922dd" stroke="#CC660088" stroke-width="2" cx="52.5" cy="0" r="11" />
			<circle fill="#DDDDDDFF" stroke="#CC660088" stroke-width="2" cx="52.2" cy="0" r="6" />



			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.episodic_memory_spatial_label}}</text>
		</g>
	</g>
</template>

<script>
export default {
	name: "episodic_memory_spatial_icon",
	idleTween: null,
};
</script>
