export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "zh":
					return {
						instructions: {
							t0: (number) => { return `将卡片翻过来，寻找<font color="#FFCC66">${number}</font>对相同的卡片` },
							t0_button: '看示范点击这里',
							t2: '这里有6张卡片',
							t3: '点击卡片来翻看图案',
							t4: '再翻看另一张',
							t5: '这两张的图案不相同，请继续寻找',
							t6: '翻看新一张',
							t7: '这个图案在哪张卡片上出现过呢？',
							t10: '翻看另一张',
							t11: '再翻看另一张',
							t12: '这个星形出现过！',
							t13: '是这张…',
							t14: '和这张',
							t17: '配对完成！'
						},
					}
				case "sv":
				case "en":
				default:
					return {
						instructions: {
							t0: (number) => { return `Turn these cards and find the <font color="#FFCC66">${number}</font> hidden pairs` },
							t0_button: 'Press here to see an instruction ➤',
							t2: 'Here are 6 cards',
							t3: 'Tap on a card to reveal its symbol',
							t4: 'Tap on another card',
							t5: 'These two symbols do not match. Keep searching!',
							t6: 'Pick a new card',
							t7: 'I have seen this symbol before! Where was it?',
							t10: 'Pick a new card',
							t11: 'And another one',
							t12: 'I have seen the star before!',
							t13: 'Here it is...',
							t14: 'And the other one...',
							t17: 'Found all the pairs!'
						},
					}
			}
		}
	}
}
