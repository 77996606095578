<template>
	<g>
		<item idx="a0" ref="a0" />
		<item idx="a1" ref="a1" />
		<item idx="a2" ref="a2" />
		<item idx="a3" ref="a3" />
		<item idx="a4" ref="a4" />
		<item idx="a5" ref="a5" />
		<item idx="a6" ref="a6" />
		<item idx="a7" ref="a7" />
		<item idx="a8" ref="a8" />
		<item idx="a9" ref="a9" />
		<item idx="aa" ref="aa" />
		<item idx="ab" ref="ab" />

		<item idx="b0" ref="b0" symbol="0" />
		<item idx="b1" ref="b1" symbol="1" />
		<item idx="b2" ref="b2" symbol="2" />
		<item idx="b3" ref="b3" symbol="3" />
		<item idx="b4" ref="b4" symbol="4" />
		<item idx="b5" ref="b5" symbol="5" />
		<item idx="b6" ref="b6" symbol="6" />
		<item idx="b7" ref="b7" symbol="7" />
		<item idx="b8" ref="b8" symbol="8" />
		<item idx="b9" ref="b9" symbol="9" />

		<item idx="q0" ref="q0" />
		<item idx="q1" ref="q1" />
		<item idx="q2" ref="q2" />
		<item idx="q3" ref="q3" />
		<item idx="q4" ref="q4" />
		<item idx="q5" ref="q5" />
		<item idx="q6" ref="q6" />
		<item idx="q7" ref="q7" />
		<item idx="q8" ref="q8" />
		<item idx="q9" ref="q9" />
		<item idx="qa" ref="qa" />
		<item idx="qb" ref="qb" />

		<transition name="quickfade">
			<g
				v-if="phase == 'list_presented' && currentAnswer.length > 0"
				v-on:click="clickedErase()"
				class="clickable"
			>
				<rect
					x="680"
					y="250"
					width="110"
					height="100"
					stroke="#0000000a"
					stroke-width="10"
					fill="#FFA000CC"
					rx="15"
				/>
				<text
					x="735"
					y="305"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="64"
					fill="#FFFFFFCC"
					>⌫</text
				>
			</g>
		</transition>

		<transition name="fade">
			<foreignObject
				v-if="phase == -1"
				x="-400"
				y="-495"
				width="800"
				height="599"
			>
				<div class="txtbox">
					<span v-html="text.instructions" />
				</div>
			</foreignObject>
		</transition>

		<transition name="fade">
			<foreignObject
				v-if="this.done"
				x="-400"
				y="-360"
				width="800"
				height="199"
			>
				<div class="txtbox">
					<span v-html="text.done" />
				</div>
			</foreignObject>
		</transition>

		<transition name="fade">
			<foreignObject
				v-if="phase == 'list_presented'"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="text.respond" />
				</div>
			</foreignObject>
		</transition>

		<transition name="fade">
			<foreignObject
				v-if="phase == 'show_correct'"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span
						v-html="
							text.num_correct(
								this.currentNumCorrect,
								this.currentList.length
							)
						"
					/>
				</div>
			</foreignObject>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_span_digits/lang.js";
import item from "@/v/test_span_digits/item.vue";
import Timer from "advanced-timer";
import persist from "@/persist.js";

var sequenceTimer;

export default {
	name: "test_span_digits",
	//genväg-alias:
	local: store.state.test_span_digits,
	components: {
		item,
		//instructions,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	watch: {
		//phase: function(/*newPhase*/) {
		//	this.update();
		//},
	},
	data: () => {
		return {
			currentAnswer: [],
			currentList: [],
			currentNumCorrect: 0,
			level: 2,
			topLevel: 0,
			topLevel_b: 0,
			topLevel_c: 0,
			numRetries: 0,
			phase: -1,
			done: false,
			roundStartTime: 0,
			startTime: 0,
			totalTime: 0,
		};
	},
	methods: {
		hideList: function () {
			for (var i = 0; i < this.q.length; i++) {
				this.q[i].changeState("hidden");
				this.q[i].setPos(0, 700);
			}
		},
		presentList: function (list) {
			this.currentList = list;
			//var n = list.length;
			/*for (var i = 0; i < n; i++) {
				this.q[i].label = '';
				this.q[i].changeState('blank');
				this.q[i].setPos(0, -180);
				this.q[i].moveTo(this.calcX(i, n), -180);
			}
			for (i = n; i < this.q.length; i++) {
				this.q[i].changeState('hidden');
				this.q[i].setPos(0, 700);
			}
			
			sequenceTimer = new Timer(2000).repeat(list.length + 1).action(t => {
				const i = t.currentCycle - 1;
				if (t.currentCycle <= list.length) {
					this.q[i].label = list[i];
					this.q[i].changeState('blink');
				} else {
					for (var c = 0; c < n; c++) {
						this.q[c].label = '?';
						this.showButtons();
						this.phase = 'list_presented';
					}
				}
			});
			sequenceTimer.start();
			*/

			for (var i = 0; i < this.q.length; i++) {
				this.q[i].changeState("hidden");
				this.q[i].setPos(0, 700);
			}
			this.q[0].changeState("visible");
			this.q[0].changeState("blank");
			this.q[0].setPos(0, -180);

			sequenceTimer = new Timer(2000)
				.repeat(list.length + 1)
				.action((t) => {
					const i = t.currentCycle - 1;
					if (t.currentCycle <= list.length) {
						this.q[0].label = list[i];
						this.q[0].changeState("visible");
						this.q[0].changeState("blink");
					} else {
						/*for (var c = 0; c < n; c++) {
						this.q[c].label = '?';
						this.q[c].changeState('visible');

						this.q[c].changeState('blank');
						this.q[c].setPos(0, -180);
						this.q[c].moveTo(this.calcX(c, n), -180);
					}*/
						this.q[0].changeState("visible");
						this.q[0].changeState("blank");
						this.q[0].label = "?";
						this.q[0].setPos(0, -180);

						this.showButtons();
						this.phase = "list_presented";
					}
				});
			sequenceTimer.start();
		},
		hideAnswer: function () {
			var n = this.a.length;
			for (var i = 0; i < n; i++) {
				this.a[i].moveTo(0, -30);
				this.a[i].changeState("hidden");
			}
		},
		showAnswer: function (ans) {
			var n = ans.length;
			for (var i = 0; i < n; i++) {
				this.a[i].label = ans[i];
				this.a[i].changeState("visible");
				this.a[i].changeState("white");
				this.a[i].moveTo(this.calcX(i, n), -30);
			}
			for (i = n; i < this.a.length; i++) {
				this.a[i].setPos(0, 700);
				this.a[i].changeState("hidden");
			}
		},
		hideButtons: function () {
			var n = this.b.length;
			for (var i = 0; i < n; i++) {
				this.b[i].moveTo(0, 300);
				this.b[i].changeState("hidden");
			}
		},
		showButtons: function () {
			this.roundStartTime = Date.now();
			this.currentRts = new Array(this.level);
			this.currentItemsCorrect = new Array(this.level);
			var n = this.b.length;
			for (var i = 0; i < n; i++) {
				this.b[i].setPos(0, 300);
				this.b[i].moveTo(this.calcX(i, n), 300);
				this.b[i].changeState("orange");
				this.b[i].label = i.toString();
			}
		},
		addAnswerItem: function (char) {
			this.currentAnswer.push(char);
			this.showAnswer(this.currentAnswer);
		},
		calcX: function (num, max) {
			return -(max - 1) * 130 * 0.5 + num * 130;
		},
		clickedButton: function (button) {
			if (this.phase != "list_presented") return;

			var n = this.currentAnswer.length;
			if (n >= this.currentList.length) {
				return;
			}

			var elapsedTime = (Date.now() - this.roundStartTime) / 1000;
			this.currentRts[n] = elapsedTime;

			this.a[n].setPos(button.xpos, 300);
			this.addAnswerItem(button.label);
			if (n + 1 >= this.currentList.length) {
				this.sequenceAnswered();
			}
		},
		clickedAnswerItem: function (it) {
			if (this.phase != "list_presented") return;
			var n = this.a.indexOf(it);
			for (var c = n; c < this.currentAnswer.length; c++) {
				this.a[c].changeState("hidden");
			}
			this.currentAnswer = this.currentAnswer.slice(0, n);
			this.showAnswer(this.currentAnswer);
		},
		clickedErase: function () {
			if (this.currentAnswer.length > 0) {
				this.currentAnswer = this.currentAnswer.slice(
					0,
					this.currentAnswer.length - 1
				);
				this.showAnswer(this.currentAnswer);
			}
		},
		sequenceAnswered: function () {
			this.phase = "";
			var n;
			/*for (n = 0; n < this.a.length; n++) {
			}*/
			for (n = 0; n < this.b.length; n++) {
				this.b[n].changeState("hidden");
			}
			this.revealTruth();
			//console.log(this.currentRts);
			//console.log(this.currentItemsCorrect);
		},
		revealTruth: function () {
			var numCorrect = 0;
			for (var n = 0; n < this.currentList.length; n++) {
				this.q[n].setPos(0, -180);
				this.q[n].changeState("visible");
				this.q[n].changeState("blank");
				this.q[n].label = this.currentList[n];
				this.q[n].moveTo(this.calcX(n, this.currentList.length), -180);

				if (this.currentAnswer[n] == this.currentList[n]) {
					numCorrect++;
					this.currentItemsCorrect[n] = 1;
				} else {
					this.currentItemsCorrect[n] = 0;
				}
			}
			this.currentNumCorrect = numCorrect;
			sequenceTimer = new Timer(500)
				.repeat(this.currentAnswer.length + 1)
				.action((t) => {
					const i = t.currentCycle - 1;
					if (t.currentCycle <= this.currentAnswer.length) {
						if (this.currentAnswer[i] == this.currentList[i]) {
							this.a[i].changeState("correct");
						} else {
							this.a[i].changeState("incorrect");
						}
					} else {
						this.phase = "show_correct";
						setTimeout(() => {
							this.roundComplete();
						}, 2000);
					}
				});
			sequenceTimer.start();
		},
		roundComplete: function () {
			var level_b = this.level - 1 + this.currentNumCorrect / this.level;

			var err = Math.min(
				this.levenshteinDistance(this.currentAnswer, this.currentList),
				this.level
			);
			var ok = this.level - err;
			var partial_correct = ok / this.level;
			var level_c = this.level - 1 + partial_correct;

			this.topLevel_b = Math.max(this.topLevel_b, level_b);
			this.topLevel_c = Math.max(this.topLevel_c, level_c);

			// https://www.sciencedirect.com/science/article/pii/S0165178120331553 ??
			this.rounds.push({
				level: this.level,
				level_b: level_b,
				level_c: level_c,
				corr: this.currentNumCorrect,
				rt: Date.now() - this.roundStartTime,
				attempt: this.numRetries + 1,
				rts: this.currentRts,
				corrs: this.currentItemsCorrect,
			});

			// Maxnivå
			if (this.level <= this.lists.length + 1) {
				if (this.currentNumCorrect == this.level) {
					this.topLevel = this.level;
					if (this.level < this.lists.length + 1) {
						this.level += 1;
						this.numRetries = 0;
						this.nextRoundReady();
					}
					return;
				} else if (this.numRetries < 1) {
					this.numRetries += 1;
					this.nextRoundReady();
					return;
				}
			}
			this.taskComplete();
		},
		levenshteinDistance(s1, s2) {
			var m = s1.length;
			var n = s2.length;
			var matrix = new Array();
			var line;
			var i;
			var j;
			for (i = 0; i <= m; ++i) {
				line = new Array();
				for (j = 0; j <= n; ++j) {
					if (i != 0) line.push(0);
					else line.push(j);
				}
				line[0] = i;
				matrix.push(line);
			}
			var cost;
			for (i = 1; i <= m; ++i)
				for (j = 1; j <= n; ++j) {
					if (s1[i - 1] == s2[j - 1]) cost = 0;
					else cost = 1;
					matrix[i][j] = Math.min(
						matrix[i - 1][j] + 1,
						matrix[i][j - 1] + 1,
						matrix[i - 1][j - 1] + cost
					);
				}
			return matrix[m][n];
		},
		playClicked: function () {
			this.newRound();
		},
		newRound: function () {
			if (this.rounds.length == 0) {
				this.startTime = Date.now();
			}
			this.currentAnswer = [];
			this.currentList = [];
			this.hideAnswer();
			this.hideList();
			this.hideButtons();
			this.phase = "";

			var list = this.lists[this.level - 2][this.numRetries];

			this.$parent.$refs.countDown.play(
				() => {
					this.presentList(list);
				},
				1,
				1
			);
		},
		nextRoundReady: function () {
			this.$parent.setPlayClickCallback(this.playClicked);
			store.commit("taskPhase", 0);
			if (this.rounds.length == 0) {
				store.commit("setTaskMode", "ready_no_exit");
			} else {
				store.commit("setTaskMode", "ready_continue");
			}
		},
		taskComplete: function () {
			this.totalTime = Date.now() - this.startTime;

			persist.log("results", {
				start_time: this.startTime,
				total_time: Math.trunc(this.totalTime),
				rounds: this.rounds,
				level: this.topLevel,
				level_b: this.topLevel_b,
				level_c: this.topLevel_c,
			});

			setTimeout(() => {
				this.done = true;
				persist.set_progress_data("digits", 1);
			}, 2000);

			setTimeout(() => {
				this.done = true;
				//store.dispatch('gotoModule', 'test_wisc4_mat');
				store.dispatch("gotoModule", "home");
				//if (this.$store.state.pwd != "majakka99") {
				//	window.location.replace("https://survey.umu.se/matte1");
				//}
			}, 5000);
		},
	},
	a: [], // answer items
	b: [], // button items
	q: [], // question items
	lists: [],
	rounds: [],
	mounted() {
		this.lists = [
			// level 2
			[
				["1", "2"],
				["3", "4"],
			],
			// level 3
			[
				["9", "9", "1"],
				["1", "1", "2"],
			],
			// level 4
			[
				["0", "3", "3", "4"],
				["7", "6", "1", "8"],
			],
			// level 5
			[
				["9", "7", "8", "4", "8"],
				["7", "8", "3", "5", "1"],
			],
			// level 6
			[
				["1", "7", "1", "3", "7", "1"],
				["3", "4", "8", "8", "5", "5"],
			],
			// level 7
			[
				["6", "1", "2", "9", "6", "6", "4"],
				["3", "5", "4", "6", "7", "2", "0"],
			],
			// level 8
			[
				["1", "4", "5", "1", "5", "4", "8", "1"],
				["7", "4", "8", "9", "0", "5", "4", "3"],
			],
			// level 9
			[
				["4", "5", "8", "2", "7", "3", "5", "4", "0"],
				["0", "1", "0", "6", "0", "6", "8", "1", "3"],
			],
			// level 10
			[
				["6", "3", "7", "5", "7", "8", "2", "2", "0", "1"],
				["3", "4", "7", "4", "6", "2", "1", "1", "0", "7"],
			],
			// level 11
			[
				["4", "6", "2", "7", "8", "0", "6", "7", "6", "6", "3"],
				["5", "7", "3", "7", "2", "4", "1", "5", "4", "5", "1"],
			],
			// level 12
			[
				["6", "3", "0", "3", "6", "4", "9", "1", "9", "5", "6", "2"],
				["9", "0", "5", "3", "4", "1", "1", "2", "7", "2", "7", "4"],
			],
		];

		this.a = [
			this.$refs.a0,
			this.$refs.a1,
			this.$refs.a2,
			this.$refs.a3,
			this.$refs.a4,
			this.$refs.a5,
			this.$refs.a6,
			this.$refs.a7,
			this.$refs.a8,
			this.$refs.a9,
			this.$refs.aa,
			this.$refs.ab,
		];

		this.b = [
			this.$refs.b0,
			this.$refs.b1,
			this.$refs.b2,
			this.$refs.b3,
			this.$refs.b4,
			this.$refs.b5,
			this.$refs.b6,
			this.$refs.b7,
			this.$refs.b8,
			this.$refs.b9,
		];

		this.q = [
			this.$refs.q0,
			this.$refs.q1,
			this.$refs.q2,
			this.$refs.q3,
			this.$refs.q4,
			this.$refs.q5,
			this.$refs.q6,
			this.$refs.q7,
			this.$refs.q8,
			this.$refs.q9,
			this.$refs.qa,
			this.$refs.qb,
		];

		this.rounds = [];
		this.level = 2;
		this.hideAnswer();
		this.hideList();
		this.hideButtons();
		this.nextRoundReady();
	},
};
</script>

<style lang="stylus"></style>
