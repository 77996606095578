<template>
	<g>
		<defs>
			<g id="sq" transform="scale(0.25)">
				<rect rx="10" fill="#00000044" x="5" y="5" width="180" height="180" />
				<rect rx="10" fill="#F8A23C" x="0" y="0" width="180" height="180" />
				<rect rx="5" fill="#FFFFFF2a" x="5" y="5" width="15" height="165" />
				<rect rx="5" fill="#FFFFFF2a" x="5" y="5" width="150" height="15" />
				<rect rx="5" fill="#0000000a" x="160" y="10" width="15" height="165" />
				<rect rx="5" fill="#0000000a" x="25" y="160" width="150" height="15" />
			</g>
		</defs>

		<g id="spatialSpanIcon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>
			<use xlink:href="#sq" x="-83" y="-83" />
			<use xlink:href="#sq" x="-23" y="-83" />
			<use xlink:href="#sq" x="37" y="-83" />
			<use xlink:href="#sq" x="-83" y="-23" />
			<use xlink:href="#sq" x="-23" y="-23" />
			<use xlink:href="#sq" x="37" y="-23" />
			<use xlink:href="#sq" x="-83" y="37" />
			<use xlink:href="#sq" x="-23" y="37" />
			<use xlink:href="#sq" x="37" y="37" />
			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.spatialSpanLabel}}</text>
		</g>
	</g>
</template>

<script>
export default {
	name: "spatialSpanIcon",
	idleTween: null,
};
</script>
