<template>
	<g>
		<defs>
			<g id="colbrick" transform="scale(0.45)">
				<path
					d="M -120 -60 L 0 -135 120 -60 120 67 0 135 -120 67 Z"
					fill="#FFA000C0"
					stroke="#00000008"
					stroke-width="10"
				/>
				<path d="M 0 0 L 0 -135 120 -60 120 67 Z" fill="#EEEEEEFF" />
				<path d="M 0 0 L 120 67 0 135-120 67 Z" fill="#555555FF" />
			</g>
		</defs>

		<g id="coloursUpdatingIcon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<use xlink:href="#colbrick" x="0" y="0" />

			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.coloursUpdatingLabel}}</text>
		</g>
	</g>
</template>

<script>

export default {
	name: "coloursUpdatingIcon",
	idleTween: null,
};
</script>
