<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="this.$store.state.taskContainer.taskPhase == 1 || this.$store.state.taskContainer.taskPhase == 2"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.task(this.level)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="this.$store.state.taskContainer.taskPhase == 3 || this.$store.state.taskContainer.taskPhase == 4"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.respond(this.level)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<g v-if="this.$store.state.taskContainer.taskPhase > 6 || (this.$store.state.taskContainer.taskPhase <= 1 && this.$store.state.spatialSpan.round > 0)">
				<foreignObject x="-600" y="-495" width="1200" height="99">
					<div class="txtbox">
						<span
							v-html="$parent.text.results.numCorrect(this.round_num_correct, this.last_level)"
						/>
					</div>
				</foreignObject>
				<timeline v-bind:stages="round" v-bind:pos="round" />
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";

export default {
	name: "play",
	components: {
		timeline
	},
	watch: {
		phase: function(/*newPhase*/) {
			this.update();
		}
	},
	methods: {
		update() {
			switch (this.$store.state.taskContainer.taskPhase) {
			case 0:
				this.sessionData.start_time = Date.now();
				store.dispatch('nextTaskPhase');
				break;
			case 1: // Din uppgift...
				setTimeout(
					() =>
						this.$parent.$parent.$refs.countDown.play(() => {
							store.dispatch('nextTaskPhase');
						}),
					this.round == 0? 3000 : 1100
				);
				break;
			case 2: // Din uppgift...
				//sel.classList.remove("cursorHide");
				//sel.classList.add("cursorPointer");

				this.$parent.$refs.grid.presentList(
					this.createRandomList(this.level),
					() => store.dispatch('nextTaskPhase')
				);
				break;
			case 3: // interlude
				store.dispatch('nextTaskPhase', 250);
				break;
			case 4: // respond
				//sel.classList.add("cursorPointer");
				//sel.classList.remove("cursorHide");
				this.round_answer_start_time = Date.now();
				this.$parent.$refs.grid.accept_clicks(() => store.dispatch('nextTaskPhase'));
				break;
			case 5:
				setTimeout(() => this.$parent.$refs.grid.show_interlude(),	250);
				store.dispatch('nextTaskPhase', 2000);
				break;
			case 6: // show round results
				var roundResults = this.$parent.$refs.grid.evalResponse(() => {store.dispatch('nextTaskPhase');});
				roundResults.time = Date.now() - this.round_answer_start_time;
				this.sessionData.rounds.push(roundResults);
				this.round_num_correct = roundResults.numCorrect;
				this.last_level = this.level;
				break;
			case 7: // show round results, 2
				if (this.round % 3 == 2) {
					const c1 = this.sessionData.rounds[this.round - 2].numCorrect == this.level? 1 : 0;
					const c2 = this.sessionData.rounds[this.round - 1].numCorrect == this.level? 1 : 0;
					const c3 = this.sessionData.rounds[this.round - 0].numCorrect == this.level? 1 : 0;
					const sum = c1 + c2 + c3;
					if (sum < 2 || this.level >= 16) {
						store.dispatch('gotoTaskPhase', {next: 9, ms: 4000});
						return;
					}
					this.level++;
				}
				store.dispatch('nextTaskPhase', 4000);
				break;
			case 8: // round done!
				this.round += 1;
				setTimeout(() => this.$parent.$refs.grid.set_grid(7), 3000);
				store.dispatch('gotoTaskPhase', {next: 1, ms: 3000});
				break;
			case 9: // test done!
				this.$parent.$refs.grid.set_grid(8)
				this.saveSessionResults();
				store.commit('setTaskMode', 'clickToExit');
				break;
			}
		},
		createRandomList(length) {
			var i = Math.min(length, 16);
			var list = [];
			while (i > 0) {
				var sq = Math.trunc(Math.random() * 16);
				if (sq < 16) {
					if (list.indexOf(sq) < 0) {
						list.push(sq);
						--i;
					}
				}
			}
			return list;
		},
		saveSessionResults() {
			var sum_correct = 0;
			var rounds_correct = 0;
			var top_level = 0;
			var times_perfect_this_level = 0;
			var current_level = 0;
			for (var r = 0; r < this.sessionData.rounds.length; ++r) {
				sum_correct += this.sessionData.rounds[r].numCorrect;
				rounds_correct += this.sessionData.rounds[r].perf;
				if (this.sessionData.rounds[r].level > current_level) {
					current_level = this.sessionData.rounds[r].level;
					times_perfect_this_level = 0;
				}
				if (this.sessionData.rounds[r].perf == 1) times_perfect_this_level++;
				if (times_perfect_this_level >= 2) top_level = current_level;
			}
			const total_time = Date.now() - this.sessionData.start_time;

			persist.log('results', {
				sum_correct: sum_correct,
				rounds_correct: rounds_correct,
				level: top_level,
				rounds: this.sessionData.rounds,
				start_time: this.sessionData.start_time,
				total_time: Math.trunc(total_time),
			});
		}
	},
	sessionData: {},
	data: () => { return {
		level: 2,
		last_level: -1,
		round: 0,
		round_num_correct: 0,
		round_answer_start_time: 0,
	}},
	computed: {
		phase: function() {
			return this.$store.state.taskContainer.taskPhase;
		}
	},
	mounted() {
		this.level = 2;
		this.$parent.$refs.grid.set_grid(0);
		this.sessionData = {
			start_time: Date.now(),
			total_time: 0,
			rounds: []
		};
		this.update();
	}
};
</script>
