<template>
	<g class="button" v-on:click="clicked">
		<circle
			:id="'sq' + idx + 'bg'"
			r="90"
			fill="#000000"
			cx="90"
			cy="90"
			stroke="#00000010"
			stroke-width="5"
		/>
		<defs>
			<clipPath :id="'clip' + idx">
				<circle r="90" cx="90" cy="90" />
			</clipPath>
		</defs>
		<circle r="90" cx="120" cy="90" fill="#FFFFFF0F" :clip-path="'url(#clip'+idx+')'" />
		<ellipse rx="100" ry="80" fill="#FFFFFF0F" cx="90" cy="45" :clip-path="'url(#clip'+idx+')'" />
		<ellipse rx="84" ry="60" fill="#FFFFFF0F" cx="90" cy="20" :clip-path="'url(#clip'+idx+')'" />
		<ellipse rx="60" ry="30" fill="#FFFFFF0F" cx="90" cy="20" :clip-path="'url(#clip'+idx+')'" />
		<ellipse rx="25" ry="10" fill="#FFFFFF1F" cx="90" cy="10" :clip-path="'url(#clip'+idx+')'" />

		<g :id="'sq' + idx + 'star'">
			<path d="M 99 74 C 138 75 138 75 109 96 C 119 130 119 130 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74 C 90 40 90 40 102 74" stroke="#00000022" stroke-width="8" fill="#FFCC66"/>
			<path d="M 78 74 C 90 40 90 40 102 74 C 138 75 138 75 109 96" stroke="none" fill="#FFFFAA2C"/>
			<path d="M 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74" stroke="none" fill="#0000000C"/>
		</g>
	</g>
</template>

<script>
import { store } from "@/store.js";
import gsap from "gsap";

export default {
	name: "sq",
	props: {
		idx: {
			type: Number,
			default: 0
		}
	},
	methods: {
		clicked: function(/*event*/) {
			store.dispatch("spatialSpan_clickSquare", this.idx);
		},
		changeState: function(newState) {
			const sel = "#sq" + this.idx + "bg";
			const star = "#sq" + this.idx + "star";

			var tl;
			switch (newState) {
			case 0:
				//tl = gsap.timeline();
				//tl.to(sel, { fill: "#000000", duration: 0.7 });
				//tl.to(sel, { fill: "#550055", duration: 0.7 });
				gsap.to(sel, { fill: "#99BBCC", duration: 0.5 });
				gsap.to(star, { opacity: "0", duration: 0.5 });
				//gsap.set(sel, { fill: "#99BBCC"});
				//gsap.set(star, { opacity: "0"});
				break;
			case 1: // blink
				tl = gsap.timeline();
				tl.to(sel, { fill: "#CC8822", duration: 0.1 });
				tl.to(sel, { fill: "#99BBCC", delay: 1.0, duration: 0.2 });
				gsap.set(star, { opacity: "0"});
				break;
			case 2: // selected
				tl = gsap.timeline();
				tl.to(sel, { fill: "#55808E", duration: 0.2 });
				tl.to(sel, { fill: "#35606E", duration: 0.2 });
				gsap.set(star, { opacity: "0"});
				break;
			case 3: // selected + inactive
				//gsap.to(sel, { fill: "#AAAAAA", duration: 1 });
				gsap.to(sel, { fill: "#35606E", duration: 1 });
				gsap.set(star, { opacity: "0"});
				break;
			case 4: // correct
				tl = gsap.timeline();
				tl.to(sel, { fill: "#FFFFFF", duration: 0.1 });
				//tl.to(star, { opacity: "1", duration: 0.1 });
				//tl.to(sel, { fill: "#448844", delay: 0.1, duration: 2 });
				tl.to(sel, { fill: "#55AA55", delay: 0.1, duration: 2 });
				//tl.to(sel, { fill: "#55AA55", duration: 2 });
				gsap.to(star, { opacity: "1", delay: 0.2, duration: 1.5 });
				break;
			case 5: // incorrect
				tl = gsap.timeline();
				tl.to(sel, { fill: "#000000", duration: 0.1 });
				tl.to(sel, { fill: "#884444", delay: 0.1, duration: 0.5 });
				gsap.set(star, { opacity: "0"});
				//gsap.to(sel, { fill: "#884444", duration: 2.2 });
				break;
			case 6: // clickable + non-selected
				gsap.to(sel, { fill: "#CCCCCC", duration: 0.1 });
				gsap.set(star, { opacity: "0"});
				break;
			default:
				//gsap.to(sel, { fill: "#99BBCC", delay: 0.2, duration: 0.1 });
				//gsap.to(star, { opacity: "0", delay: 0.2, duration: 0.1 });
				gsap.set(sel, { fill: "#99BBCC"});
				gsap.set(star, { opacity: "0"});
				break;
			}
		}
	},
	computed: {
		grid() {
			return store.state.ipark_updating_spatial.grid;
		}
	},
	watch: {
		grid: function(newGrid, prevGrid) {
			if (newGrid[this.idx] != prevGrid[this.idx]) {
				this.changeState(newGrid[this.idx], prevGrid[this.idx]);
			}
		}
	},
	mounted() {
		this.changeState(0, -1);
	}
};
</script>
