export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "fi":
					return {
						instructions: 'Näytölle ilmestyy numeroita yksi kerrallaan.<br><br>Sinun tehtäväsi on painaa mieleen numerot ja muistaa ne kaikki samassa järjestyksessä kuin ne näytettiin.',
						done: "Kaikki tehtävät suoritettu.<br>Voit nyt sulkea selaimen",
						// Paina mieleesi kaikki numerot, jotka näytetään
						respond: "Paina numeroita samassa järjestyksessä",
						num_correct: (num, tot) => {
							return `Sait <b><font color="#AAFFAA">${num}</font></b> / ${tot} oikein`;
						},
					};
				case "sv":
				default:
					return {
						taskName: "Siffror",
						instructions: 'Siffror kommer att visas, en i taget.<br><br>Din uppgift är att minnas och återge alla siffror i samma ordning som de visades.',
						done: "Klart!",
						respond: "Tryck på siffrorna i samma ordning",
						num_correct: (num, tot) => {
							return `Du hade <b><font color="#AAFFAA">${num}</font></b> av ${tot} rätt`;
						},
					};
			}
		}
	}
}
