<template>
	<g>
		<g v-if="this.$store.state.study == 'ipark'">
			<menu_itest v-if="this.$store.state.condition == 'itest'" />
			<menu_ipark v-else />
		</g>
		<menu_finger v-else-if="this.$store.state.study == 'finger'" />
		<menu_mind_ad v-else-if="this.$store.state.study == 'mind_ad'" />
		<menu_reco v-else-if="this.$store.state.study == 'reco'" />
		<menu_anx v-else-if="this.$store.state.study == 'anx'" />
		<menu_nfl v-else-if="this.$store.state.study == 'nfl'" />
		<menu_nfl2 v-else-if="this.$store.state.study == 'nfl2'" />
		<home_choke v-else-if="this.$store.state.study == 'choke'" />
		<taskMenu v-else />
	</g>
</template>

<script>
// menu:
import taskMenu from "@/v/taskMenu/_.vue";
import menu_itest from "@/v/taskMenu/menu_itest.vue";
import menu_ipark from "@/v/taskMenu/menu_ipark.vue";
import menu_finger from "@/v/finger/menu/menu_finger.vue";
import menu_mind_ad from "@/v/mind_ad/menu/menu_mind_ad.vue";
import menu_reco from "@/v/reco/menu/menu_reco.vue";
import menu_anx from "@/v/anx/menu/menu_anx.vue";
import menu_nfl from "@/v/nfl/menu/menu_nfl.vue";
import menu_nfl2 from "@/v/nfl2/menu/menu_nfl2.vue";
import home_choke from "@/v/choke/home/_.vue";
import persist from "@/persist.js";
import lang from "@/lang.js";

export default {
	name: "home",
	components: {
		taskMenu,
		menu_itest,
		menu_ipark,
		menu_finger,
		menu_mind_ad,
		menu_reco,
		menu_anx,
		menu_nfl,
		menu_nfl2,
		home_choke,
	},
	mounted() {
		const language = persist.get_locally_saved("lang");
		if (language != null && language != "") {
			//console.log('lang = ' + language);
			this.$shared.text = lang.getLocalisedText(language);
			//console.log(this.$shared.text);
		}
	},
};
</script>
