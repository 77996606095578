var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[_c('transition',{attrs:{"name":"fade"}},[(
				this.$store.state.taskContainer.taskPhase == 1 ||
				this.$store.state.taskContainer.taskPhase == 2
			)?_c('foreignObject',{attrs:{"x":"-790","y":"-495","width":"1580","height":"99"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$parent.text.forward.task(4))}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(this.$store.state.taskContainer.taskPhase == 4)?_c('foreignObject',{attrs:{"x":"-790","y":"-495","width":"1580","height":"99"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$parent.text.forward.respond(4))}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(this.$store.state.taskContainer.taskPhase == 7)?_c('g',[_c('foreignObject',{attrs:{"x":"-600","y":"-495","width":"1200","height":"99"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(
							_vm.$parent.text.results.numCorrect(
								this.$store.state.finger_updating_spatial
									.numCorrect,
								4
							)
						)}})])]),_c('timeline',{attrs:{"stages":5,"pos":this.round}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }