<template>
	<g>
		<transition name="quickfade">
			<g v-if="this.current_letter != ''">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-100"
					y="-115"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="0"
					y="-10"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="80"
					fill="#000000"
					>{{ this.current_letter }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.show_results">
				<foreignObject x="-600" y="-495" width="1200" height="599">
					<div class="txtbox">
						<div class="readbox">
							<span
								v-html="
									text.current_results(
										this.current_sequence.letters.length,
										this.current_letters_correct,
										this.num_attempts_at_this_level,
										this.current_sequence.questions.length,
										this.current_questions_correct
									)
								"
							/>
						</div>
					</div>
				</foreignObject>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.show_letter_response">
				<foreignObject x="-600" y="-495" width="1200" height="599">
					<div class="txtbox">
						<div class="readbox">
							<span v-html="text.letter_response_instr" />
						</div>
					</div>
				</foreignObject>

				<rect
					x="-320"
					y="-315"
					width="640"
					height="100"
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					rx="15"
				/>
				<text
					x="0"
					y="-260"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="64"
					fill="#000000"
					>{{ this.current_letter_response }}</text
				>
				<g v-on:click="clicked_letter('F')" class="clickable">
					<rect
						x="-360"
						y="-180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-285"
						y="-100"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>F</text
					>
				</g>
				<g v-on:click="clicked_letter('H')" class="clickable">
					<rect
						x="-170"
						y="-180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-95"
						y="-100"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>H</text
					>
				</g>
				<g v-on:click="clicked_letter('J')" class="clickable">
					<rect
						x="20"
						y="-180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="95"
						y="-100"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>J</text
					>
				</g>
				<g v-on:click="clicked_letter('K')" class="clickable">
					<rect
						x="210"
						y="-180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="285"
						y="-100"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>K</text
					>
				</g>

				<g v-on:click="clicked_letter('L')" class="clickable">
					<rect
						x="-360"
						y="0"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-285"
						y="80"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>L</text
					>
				</g>
				<g v-on:click="clicked_letter('M')" class="clickable">
					<rect
						x="-170"
						y="0"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-95"
						y="80"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>M</text
					>
				</g>
				<g v-on:click="clicked_letter('P')" class="clickable">
					<rect
						x="20"
						y="0"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="95"
						y="80"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>P</text
					>
				</g>
				<g v-on:click="clicked_letter('Q')" class="clickable">
					<rect
						x="210"
						y="0"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="285"
						y="80"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>Q</text
					>
				</g>

				<g v-on:click="clicked_letter('R')" class="clickable">
					<rect
						x="-360"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-285"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>R</text
					>
				</g>
				<g v-on:click="clicked_letter('S')" class="clickable">
					<rect
						x="-170"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-95"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>S</text
					>
				</g>
				<g v-on:click="clicked_letter('T')" class="clickable">
					<rect
						x="20"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="95"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>T</text
					>
				</g>
				<g v-on:click="clicked_letter('Y')" class="clickable">
					<rect
						x="210"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="285"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>Y</text
					>
				</g>
				<g v-on:click="clicked_erase()" class="clickable">
					<rect
						x="400"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#88888844"
						rx="15"
					/>
					<text
						x="470"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#00000066"
						>⌫</text
					>
				</g>
			</g>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.current_question.length > 0"
				x="-700"
				y="-100"
				width="1400"
				height="200"
			>
				<div class="txtbox">
					<div class="bigbox">
						{{ this.current_question }}
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 0"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct0" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 1"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct1" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 2"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct2" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 3"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct3" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 4"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct4" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 5"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct5" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 6"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct6" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<g
			v-if="continue_button_visible"
			v-on:click="clicked_continue()"
			class="clickable"
		>
			<rect
				stroke="#00000020"
				stroke-width="10"
				fill="#FFFFFFCC"
				x="-150"
				y="375"
				width="300"
				height="100"
				rx="15"
			/>
			<text
				x="0"
				y="425"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="30"
				fill="#000000"
				>Gå vidare →</text
			>
		</g>
		<g v-if="show_truth_buttons">
			<g v-on:click="clicked_true()" class="clickable">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#CCFFCCCC"
					x="-375"
					y="250"
					width="350"
					height="100"
					rx="15"
				/>
				<text
					x="-200"
					y="300"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000"
					>Sant</text
				>
			</g>
			<g v-on:click="clicked_false()" class="clickable">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFCCCCCC"
					x="25"
					y="250"
					width="350"
					height="100"
					rx="15"
				/>
				<text
					x="200"
					y="300"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000"
					>Falskt</text
				>
			</g>
		</g>
		<gauge ref="gauge" />
		<transition name="fade">
			<g v-if="this.practice">
				<circle
					cx="740"
					cy="440"
					r="60"
					fill="#CC333333"
					stroke="none"
				/>
				<text
					x="740"
					y="440"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="24"
					fill="#FFFFFF"
					>Övning</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.show_timeup">
				<circle cx="0" cy="0" r="140" fill="#FF8800" stroke="none" />
				<text
					x="0"
					y="0"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="36"
					fill="#FFFFFF"
					>För långsam!</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.stage == 100">
				<circle cx="0" cy="0" r="140" fill="#88FF88" stroke="none" />
				<text
					x="0"
					y="0"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="36"
					fill="#008800"
					>Klart!</text
				>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_operation_span_verbal/lang.js";
import gauge from "@/v/gauge.vue";
import stat from "@/stat.js";
import persist from "@/persist.js";

//import gsap from "gsap";
/*
		<transition name="quickfade">
			<gauge v-if="show_gauge" ref="gauge" />
		</transition>
*/
export default {
	name: "test_operation_span_verbal",
	//genväg-alias:
	local: store.state.test_operation_span_verbal,
	components: {
		gauge,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {
			practice: false,
			continue_button_visible: true,
			show_truth_buttons: false,
			show_letter_response: false,
			show_gauge: false,
			show_timeup: false,
			current_letter_response: "",
			current_letters_correct: 0,
			current_questions_correct: 0,
			accumulated_questions_correct: 0,
			accumulated_questions_presented: 0,
			item_question_start_time: 0,
			item_letters_start_time: 0,
			show_results: false,
			stage: -1,
			current_letter: "",
			current_question: "",
			current_question_answer: false,
			current_sequence: [],
			current_sequence_index: -1,
			global_question_index: -1,
			show_instruct: -1,
			trials_section: "instructions",
			round: 0,
			current_questions_rt: [],
			time_limit_1sd: 0,
			time_limit_2sd: 0,
			time_limit_2_5sd: 0,
			test_q_num_1sd: 0,
			test_q_num_2sd: 0,
			test_q_num_2_5sd: 0,
			test_q_num_timeup: 0,
			test_q_num_presented: 0,
			question_timer: 0,
			timer_stage: -1,
			test_letter_set: [],
			results: {
				start_time: 0,
				total_time: 0,
				practice_question_c_prop: 0,
				practice_question_rt_mean: 0,
				practice_question_rt_sd: 0,

				question_c_prop: 0,
				question_rt_1sd_prop: 0,
				question_rt_2sd_prop: 0,
				question_rt_2_5sd_prop: 0,
				question_rt_timeup_prop: 0,

				level: 0,
				level_b: 0,
				level_c: 0,
				practice_retries: 0,
			},
		};
	},
	watch: {
		stage: function (/*new_stage*/) {
			//console.log("--- new stage: " + this.stage);
			switch (this.stage) {
				case 0:
					this.practice = false;
					this.show_instruct = 0;
					this.show_continue_button();
					break;
				case 1:
					this.results.start_time = Date.now();
					this.show_instruct = 1;
					this.show_continue_button();
					break;
				case 2:
					this.continue_button_visible = false;
					setTimeout(() => {
						this.stage = this.stage + 1;
					}, 100);
					break;
				case 3:
					this.practice = true;
					this.trials_section = "p0";
					this.num_attempts_at_this_level = 0;
					this.present_seq({ letters: "FR", questions: [] });
					break;
				case 4:
					this.trials_section = "p0";
					this.num_attempts_at_this_level = 0;
					this.present_seq({ letters: "TKM", questions: [] });
					break;
				case 5:
					this.practice = false;
					this.show_instruct = 2;
					this.show_continue_button();
					break;
				//------------------------------- Timing ----------------------
				case 6:
					this.practice = true;
					this.accumulated_questions_presented = 0;
					this.accumulated_questions_correct = 0;
					this.trials_section = "measure_timing";
					this.present_seq({
						letters: "",
						questions: [
							0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
							15,
						],
					});
					break;
				case 7:
					this.accumulated_questions_presented = 0;
					this.accumulated_questions_correct = 0;
					this.practice = false;
					this.trials_section = "";
					this.show_instruct = 3;
					this.show_continue_button();
					break;
				case 8:
					this.trials_section = "";
					this.show_instruct = 4;
					//this.show_gauge = true;
					this.$refs.gauge.show_demo();
					//this.show_continue_button();
					break;
				case 9:
					this.accumulated_questions_presented = 0;
					this.accumulated_questions_correct = 0;
					this.trials_section = "";
					this.show_instruct = 5;
					this.show_continue_button();
					break;
				//------------------------------- Combined practice------------
				case 10:
					this.continue_button_visible = false;
					setTimeout(() => {
						this.stage = this.stage + 1;
					}, 100);
					break;
				case 11:
					this.practice = true;
					this.num_attempts_at_this_level = 0;
					this.trials_section = "p1";
					this.present_seq({
						letters: "MT",
						questions: [16, 17],
					});
					break;
				case 12:
					this.num_attempts_at_this_level = 0;
					this.trials_section = "p2";
					this.present_seq({
						letters: "HP",
						questions: [18, 19],
					});
					break;
				case 13:
					this.num_attempts_at_this_level = 0;
					this.trials_section = "p3";
					this.present_seq({
						letters: "FL",
						questions: [20, 21],
					});
					break;
				//------------------------------- Real test -------------------
				case 14:
					this.practice = false;
					this.trials_section = "";
					this.show_instruct = 6;
					this.show_continue_button();
					break;
				//------------------------------- 2 ---------------------------
				case 15:
					this.test_q_num_timeup = 0;
					this.test_q_num_presented = 0;
					this.question_c_prop = 0;
					this.test_q_num_1sd = 0;
					this.test_q_num_2sd = 0;
					this.test_q_num_2_5sd = 0;
					this.accumulated_questions_presented = 0;
					this.accumulated_questions_correct = 0;
					this.practice = false;
					this.num_attempts_at_this_level = 0;
					this.trials_section = "real_test";
					this.level = 0;
					this.present_seq({
						letters: this.test_letter_set[0][0], //"JQ",
						//letters: "JQ",
						questions: [22, 23],
					});
					break;
				case 16:
					this.present_seq({
						letters: this.test_letter_set[0][1], //"MS",
						questions: [24, 25],
					});
					break;
				//------------------------------- 3 ---------------------------
				case 17:
					this.num_attempts_at_this_level = 0;
					this.present_seq({
						letters: this.test_letter_set[1][0], //"QLF",
						questions: [26, 27, 28],
					});
					break;
				case 18:
					this.present_seq({
						letters: this.test_letter_set[1][1], //"TFM",
						questions: [29, 30, 31],
					});
					break;
				//------------------------------- 4 ---------------------------
				case 19:
					this.num_attempts_at_this_level = 0;
					this.present_seq({
						letters: this.test_letter_set[2][0], //"KMYL",
						questions: [32, 33, 34, 35],
					});
					break;
				case 20:
					this.present_seq({
						letters: this.test_letter_set[2][1], //"HPTL",
						questions: [36, 37, 38, 39],
					});
					break;
				//------------------------------- 5 ---------------------------
				case 21:
					this.num_attempts_at_this_level = 0;
					this.present_seq({
						letters: this.test_letter_set[3][0], //"SLJTK",
						questions: [40, 41, 42, 43, 44],
					});
					break;
				case 22:
					this.present_seq({
						letters: this.test_letter_set[3][1], //"YQJKF",
						questions: [45, 46, 47, 48, 49],
					});
					break;
				//------------------------------- 6 ---------------------------
				case 23:
					this.num_attempts_at_this_level = 0;
					this.present_seq({
						letters: this.test_letter_set[4][0], //"YPHJKR",
						questions: [50, 51, 52, 53, 54, 55],
					});
					break;
				case 24:
					this.present_seq({
						letters: this.test_letter_set[4][1], //"RJMSHL",
						questions: [56, 57, 58, 59, 60, 61],
					});
					break;
				//------------------------------- 7 ---------------------------
				case 25:
					this.num_attempts_at_this_level = 0;
					this.present_seq({
						letters: this.test_letter_set[5][0], //"TRYSFHP",
						questions: [62, 63, 64, 65, 66, 67, 68],
					});
					break;
				case 26:
					this.present_seq({
						letters: this.test_letter_set[5][1], //"FQSTKPR",
						questions: [69, 70, 71, 72, 73, 74, 75],
					});
					break;
				//------------------------------- testet klart------------------
				case 27:
					setTimeout(() => {
						this.stage = this.stage + 1;
					}, 4000);
					break;
				case 28:
					this.stage = 100;
					break;
				case 100:
					//console.log("accumulated_questions_presented: " + this.accumulated_questions_presented);
					//console.log("test_q_num_presented: ", this.test_q_num_presented);
					this.results.question_c_prop =
						this.accumulated_questions_correct /
						this.accumulated_questions_presented;
					this.results.question_rt_1sd_prop =
						this.test_q_num_1sd / this.test_q_num_presented;
					this.results.question_rt_2sd_prop =
						this.test_q_num_2sd / this.test_q_num_presented;
					this.results.question_rt_2_5sd_prop =
						this.test_q_num_2_5sd / this.test_q_num_presented;
					this.results.question_rt_timeup_prop =
						this.test_q_num_timeup / this.test_q_num_presented;
					//console.log("--" );
					//console.log("question_c_prop:         " + this.results.question_c_prop);
					//console.log("question_rt_1sd_prop:    " + this.results.question_rt_1sd_prop);
					//console.log("question_rt_2sd_prop:    " + this.results.question_rt_2sd_prop);
					//console.log("question_rt_2_5sd_prop:  " + this.results.question_rt_2_5sd_prop);
					//console.log("question_rt_timeup_prop: " + this.results.question_rt_timeup_prop);
					//console.log("--" );
					//console.log("level:  " + this.results.level);
					//console.log("level_b: " + this.results.level_b);
					//console.log("level_c: " + this.results.level_c);
					//console.log("practice_retries: " + this.results.practice_retries);
					//console.log("--" );
					//console.log("practice_question_c_prop:  " + this.results.practice_question_c_prop);
					//console.log("practice_question_rt_mean: " + this.results.practice_question_rt_mean);
					//console.log("practice_question_rt_sd:   " + this.results.practice_question_rt_sd);
					//console.log("--" );
					//console.log("DONE!" );
					this.saveSessionResults();
					setTimeout(() => {
						//store.dispatch('gotoModule', 'test_wisc4_mat');
						store.dispatch("gotoModule", "home");
					}, 5000);
					break;
			}
		},
	},

	methods: {
		/*init_result_arrays() {
			const total_num_items = this.text.questions.length;
			this.results.q_rt = [];
			this.results.q_r = [];
			this.results.q_c = [];
			this.results.q_to = [];
			this.results.l_rt = [];
			this.results.l_c = [];

			for (var i = 0; i < total_num_items; i++) {
				this.results.q_rt.push(0);
			}
		},*/
		saveSessionResults() {
			this.results.total_time = Math.trunc(
				Date.now() - this.results.start_time
			);
			persist.log("results", this.results);
			persist.set_progress_data("verbal", 1);
		},
		clicked_continue() {
			if (this.show_letter_response) {
				this.calc_current_results();
			} else if (this.show_results) {
				this.goto_next_stage();
			} else {
				this.goto_next_stage();
			}
		},
		clicked_true() {
			//console.log("clicked_true");
			this.log_question_response(true);
		},
		clicked_false() {
			//console.log("clicked_false");
			this.log_question_response(false);
		},
		question_timeup() {
			if (this.timer_stage != this.stage) {
				//console.log("time up -- collision");
				return;
			}
			//console.log("time up");
			this.test_q_num_timeup++;
			this.show_timeup = true;
			this.show_truth_buttons = false;
			this.continue_button_visible = false;
			this.current_letter = "";
			this.current_question = "";
			setTimeout(() => {
				this.show_timeup = false;
				this.question_ended();
			}, 1000);
		},
		log_question_response(response_truth) {
			if (this.show_timeup) {
				//console.log("already time up!");
				return;
			}

			this.show_timeup = false;
			var rt = Date.now() - this.item_question_start_time;
			if (this.question_timer != 0) {
				clearTimeout(this.question_timer);
				this.question_timer = 0;
			}
			//console.log("rt = " + rt);
			if (this.trials_section == "real_test") {
				if (rt < this.time_limit_2_5sd) {
					this.test_q_num_2_5sd++;
					//console.log("2.5");
					if (rt < this.time_limit_2sd) {
						this.test_q_num_2sd++;
						//console.log("2");
						if (rt < this.time_limit_1sd) {
							this.test_q_num_1sd++;
							//console.log("1");
						}
					}
				}
			}
			if (this.current_question_answer == response_truth) {
				this.current_questions_correct++;
			}
			this.current_questions_rt.push(rt);
			this.question_ended();
		},
		question_ended() {
			//console.log("---");
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";
			this.present_current_letter();
		},
		clicked_letter(letter) {
			var pos = this.current_letter_response.indexOf(letter);
			if (pos >= 0) {
				this.current_letter_response =
					this.current_letter_response.substring(0, pos);
			} else {
				this.current_letter_response =
					this.current_letter_response + letter;
			}
			//if (this.current_letter_response.length >= this.current_sequence.letters.length
			if (
				this.current_letter_response.length >= 1 &&
				this.continue_button_visible == false
			) {
				this.show_continue_button();
			}
		},
		clicked_erase() {
			var newlen = this.current_letter_response.length - 1;
			if (newlen >= 0) {
				this.current_letter_response =
					this.current_letter_response.substring(0, newlen);
			}
			if (newlen <= 0) {
				this.continue_button_visible = false;
			}
		},
		show_continue_button() {
			this.continue_button_visible = true;
			//setTimeout(() => {
			//	this.continue_button_visible = true;
			//}, 250);
		},
		goto_next_stage() {
			var stage_delay = 300;
			if (this.show_gauge) {
				this.$refs.gauge.hide();
				stage_delay += 2100;
			}
			this.show_letter_response = false;
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";
			this.current_letter_response = "";
			this.show_results = false;

			var next = this.stage;
			if (
				this.trials_section == "p0" &&
				this.current_letters_correct <
					this.current_sequence.letters.length
			) {
				this.results.practice_retries++;
				next = 2;
			} else if (
				this.trials_section == "p3" &&
				this.current_letters_correct < 2
			) {
				// Fortsätt öva
				this.results.practice_retries++;
				next = 10;
			} else if (this.trials_section == "real_test") {
				if (this.num_attempts_at_this_level == 0) {
					//if (this.current_letters_correct < this.current_sequence.letters.length) {
					if (
						this.results.level <
						this.current_sequence.letters.length
					) {
						// FEL: ett försök till...
						this.num_attempts_at_this_level++;
						next = this.stage + 1;
					} else {
						// ALLA RÄTT:
						if (this.current_sequence.letters.length == 7) {
							// Alla nivåer avklarade
							next = 100;
						} else {
							// skippa till nästa nivå
							next = this.stage + 2;
						}
					}
				} else {
					//if (this.current_letters_correct < this.current_sequence.letters.length) {
					if (
						this.results.level <
						this.current_sequence.letters.length
					) {
						// FEL: avbryt testet, pga 2 fel i rad
						this.num_attempts_at_this_level++;
						next = 100;
					} else {
						// klarade andra försöket!
						next = this.stage + 1;
					}
				}
			} else {
				next = this.stage + 1;
			}

			//this.stage = -1;
			setTimeout(() => {
				this.stage = next;
			}, stage_delay);
		},
		sequence_complete() {
			if (this.current_sequence.letters.length > 0) {
				this.show_letter_response_buttons();
			} else {
				this.calc_current_results();
			}
		},
		calc_current_results() {
			var i, len;
			// räkna rätta bokstäver:
			len = Math.min(
				this.current_sequence.letters.length,
				this.current_letter_response.length
			);
			this.current_letters_correct = 0;
			for (i = 0; i < len; i++) {
				if (
					this.current_sequence.letters[i] ==
					this.current_letter_response[i]
				)
					this.current_letters_correct++;
			}

			var cs = "";
			var rs = "";
			for (i = 0; i < this.current_sequence.letters.length; i++)
				cs += this.current_sequence.letters[i];
			for (i = 0; i < this.current_letter_response.length; i++)
				rs += this.current_letter_response[i];

			this.accumulated_questions_presented +=
				this.current_sequence.questions.length;
			this.accumulated_questions_correct +=
				this.current_questions_correct;

			if (this.trials_section == "measure_timing") {
				this.results.practice_question_c_prop =
					this.current_questions_correct /
					this.current_sequence.questions.length;
				var rt_mean = stat.mean(this.current_questions_rt);
				var rt_sd = stat.standardDev(this.current_questions_rt);
				this.results.practice_question_rt_mean = rt_mean;
				this.results.practice_question_rt_sd = rt_sd;
				this.time_limit_1sd = rt_mean + rt_sd;
				this.time_limit_2sd = rt_mean + 2 * rt_sd;
				this.time_limit_2_5sd = rt_mean + 2.5 * rt_sd;
				//console.log("PRAC MEAN:  ", this.results.practice_question_rt_mean);
				//console.log("PRAC SD:    ", this.results.practice_question_rt_sd);
				//console.log("TIME LIMIT: ", this.time_limit_2_5sd);
				//console.log("TIME +1 SD: ", this.time_limit_1sd);
				//console.log("TIME +2 SD: ", this.time_limit_2sd);
			} else if (this.trials_section == "real_test") {
				// Alla bokstäver rätt:
				//if (this.current_letters_correct == this.current_sequence.letters.length) {
				if (cs == rs) {
					this.results.level = this.current_sequence.letters.length;
					this.results.level_b = this.results.level;
					//console.log("--- LEVEL " + this.results.level + " ACCOMPLISHED ---");
				} else {
					// Fractal:
					var base = this.current_sequence.letters.length - 1;
					var fract =
						base +
						this.current_letters_correct /
							this.current_sequence.letters.length;
					this.results.level_b = Math.max(
						this.results.level_b,
						fract
					);

					// Levenshtein:
					//console.log(cs + " :: " + rs + "  --  " + this.levenshteinDistance(cs, rs));

					var err = Math.min(
						this.levenshteinDistance(cs, rs),
						this.current_sequence.letters.length
					);
					var ok = this.current_sequence.letters.length - err;
					var partial_correct =
						ok / this.current_sequence.letters.length;
					var leven = base + partial_correct;
					this.results.level_c = Math.max(
						this.results.level_c,
						leven
					);
					//console.log("level_b: " + this.results.level_b);
					//console.log("level_c: " + this.results.level_c);
				}
			}
			//console.log("RT MEAN: ", stat.mean(this.current_questions_rt));

			this.show_letter_response = false;
			this.show_results = true;

			this.continue_button_visible = false;
			var showg =
				this.trials_section == "p1" ||
				this.trials_section == "p2" ||
				this.trials_section == "p3" ||
				this.trials_section == "real_test";
			if (this.current_sequence.questions.length > 0 && showg) {
				var prop_questions_correct =
					this.accumulated_questions_correct /
					this.accumulated_questions_presented;
				this.$refs.gauge.show(() => {
					this.$refs.gauge.moveTo(prop_questions_correct, () => {
						this.show_continue_button();
					});
				});
			} else {
				this.show_continue_button();
			}
		},
		show_letter(letter) {
			this.current_letter = letter;
			setTimeout(() => {
				this.current_letter = "";
			}, 1000);
		},
		present_seq(seq) {
			this.show_instruct = -1;
			this.current_letter = "";
			this.current_question = "";
			this.current_sequence_index = -1;
			this.current_sequence = seq;
			this.current_questions_correct = 0;
			this.current_letter_response = "";
			this.current_questions_rt = [];

			this.continue_button_visible = false;
			this.show_truth_buttons = false;

			//console.log("--------------------------");
			setTimeout(() => {
				this.$parent.$refs.countDown.play(() => {
					this.present_next_item();
				});
			}, 250);
		},
		present_next_item() {
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";

			var current_length = Math.max(
				this.current_sequence.letters.length,
				this.current_sequence.questions.length
			);
			this.current_sequence_index++;
			if (this.current_sequence_index < current_length) {
				setTimeout(() => {
					this.present_current_question();
				}, 500);
			} else {
				this.sequence_complete();
			}
		},
		present_current_question() {
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.show_timeup = false;
			this.current_letter = "";
			this.current_question = "";

			if (
				this.current_sequence_index >=
				this.current_sequence.questions.length
			) {
				this.present_current_letter();
				return;
			}

			// 2x eftersom varannan position i denna array är sanningsvärdet för frågan...
			var q_idx =
				2 *
				this.current_sequence.questions[this.current_sequence_index];

			this.current_question = this.text.questions[q_idx];
			this.current_question_answer = this.text.questions[q_idx + 1];
			//console.log("    " + this.current_question + " == " + this.text.questions[q_idx+1]);
			this.show_truth_buttons = true;
			this.item_question_start_time = Date.now();

			var timed_limited =
				this.trials_section == "p1" ||
				this.trials_section == "p2" ||
				this.trials_section == "p3" ||
				this.trials_section == "real_test";

			if (this.trials_section == "real_test") {
				this.test_q_num_presented++;
			}

			if (timed_limited) {
				this.timer_stage = this.stage;
				this.question_timer = setTimeout(() => {
					this.question_timeup();
				}, this.time_limit_2_5sd);
			}

			/*setTimeout(() => {
				this.$parent.$refs.countDown.play(() => {
					this.current_question = "";
					this.present_current_letter();
				});
			}, 1000);*/
		},
		present_current_letter() {
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";

			if (
				this.current_sequence_index >=
				this.current_sequence.letters.length
			) {
				this.present_next_item();
				return;
			}

			this.current_letter =
				this.current_sequence.letters[this.current_sequence_index];
			//console.log("    " + this.current_letter);
			setTimeout(() => {
				this.current_letter = "";
				setTimeout(() => {
					this.present_next_item();
				}, 250);
			}, 1000);
		},
		show_letter_response_buttons() {
			this.show_letter_response = true;
			//this.show_continue_button();
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";
			this.item_letters_start_time = Date.now();
		},
		levenshteinDistance(s1, s2) {
			var m = s1.length;
			var n = s2.length;
			var matrix = new Array();
			var line;
			var i;
			var j;
			for (i = 0; i <= m; ++i) {
				line = new Array();
				for (j = 0; j <= n; ++j) {
					if (i != 0) line.push(0);
					else line.push(j);
				}
				line[0] = i;
				matrix.push(line);
			}
			var cost;
			for (i = 1; i <= m; ++i)
				for (j = 1; j <= n; ++j) {
					if (s1.charAt(i - 1) == s2.charAt(j - 1)) cost = 0;
					else cost = 1;
					matrix[i][j] = Math.min(
						matrix[i - 1][j] + 1,
						matrix[i][j - 1] + 1,
						matrix[i - 1][j - 1] + cost
					);
				}
			return matrix[m][n];
		},
	},
	mounted() {
		this.stage = 0;
		//this.stage = 15;
		//this.time_limit_2_5sd = 3000;
		//console.log("state.test_set: " + this.$store.state.test_set);

		const condition = this.$store.state.progress.condition || 1;
		if (condition == 1) store.commit("set_test_set", "B");
		else store.commit("set_test_set", "A");

		//FHJK LMPQ RSTY
		if (this.$store.state.test_set == "A") {
			this.test_letter_set = [
				["JQ", "MS"],
				["QLF", "TFM"],
				["KMYL", "HPTL"],
				["SLJTK", "YQJKF"],
				["YPHJKR", "RJMSHL"],
				["TRYSFHP", "FQSTKPR"],
			];
		} else {
			// set "B"
			this.test_letter_set = [
				["TF", "LK"],
				["RQY", "JST"],
				["TYJP", "HLMP"],
				["RKYST", "PLYMK"],
				["KLQRYP", "RJFHKL"],
				["RYHJSTL", "SYTLKFH"],
			];
		}
	},
};
</script>