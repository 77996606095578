<template>
	<g id="grid">
		<sq ref="sq0" id="sq0" :idx="0" transform="translate(-400, -400)" />
		<sq ref="sq1" id="sq1" :idx="1" transform="translate(-200, -400)" />
		<sq ref="sq2" id="sq2" :idx="2" transform="translate(0, -400)" />
		<sq ref="sq3" id="sq3" :idx="3" transform="translate(+200, -400)" />

		<sq ref="sq4" id="sq4" :idx="4" transform="translate(-400, -200)" />
		<sq ref="sq5" id="sq5" :idx="5" transform="translate(-200, -200)" />
		<sq ref="sq6" id="sq6" :idx="6" transform="translate(0, -200)" />
		<sq ref="sq7" id="sq7" :idx="7" transform="translate(+200, -200)" />

		<sq ref="sq8" id="sq8" :idx="8" transform="translate(-400, 0)" />
		<sq ref="sq9" id="sq9" :idx="9" transform="translate(-200, -0)" />
		<sq ref="sq10" id="sq10" :idx="10" transform="translate(0, 0)" />
		<sq ref="sq11" id="sq11" :idx="11" transform="translate(+200, 0)" />

		<sq ref="sq12" id="sq12" :idx="12" transform="translate(-400, +200)" />
		<sq ref="sq13" id="sq13" :idx="13" transform="translate(-200, +200)" />
		<sq ref="sq14" id="sq14" :idx="14" transform="translate(0, +200)" />
		<sq ref="sq15" id="sq15" :idx="15" transform="translate(+200, +200)" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import sq from "@/v/reco/wm_maintenance/square.vue";
//import gsap from "gsap";
import Timer from "advanced-timer";

var sequenceTimer;

export default {
	name: "grid",
	components: {
		sq
	},
	methods: {
		presentList(list, callback) {
			store.commit("spatialSpan_resetGrid");
			store.commit("spatialSpan_setList", list);
			sequenceTimer = new Timer(2000).repeat(list.length + 1).action(t => {
				if (t.currentCycle <= list.length) {
					const i = t.currentCycle - 1;
					store.commit("spatialSpan_presentItem", {
						index: i,
						item: list[i]
					});
				} else {
					if (callback != undefined) {
						callback();
					}
				}
			});
			sequenceTimer.start();
		},
		evalResponse() {
			const response = store.state.spatialSpan.response;
			const list = store.state.spatialSpan.list;
			var correct = [];
			var numCorrect = 0;
			for (var i = 0; i<list.length; ++i) {
				if (list[i] == response[i]) {
					correct.push(1);
					numCorrect++;
				} else {
					correct.push(0);
				}
			}
			sequenceTimer = new Timer(250)
				.repeat(response.length + 1)
				.action(t => {
					const i = t.currentCycle - 1;
					if (i < response.length) {
						const sq =
							store.state.spatialSpan.response[t.currentCycle - 1];
						if (list[i] == response[i]) {
							store.commit("spatialSpan_setGridItem", {
								item: sq,
								value: 4
							});
						} else {
							store.commit("spatialSpan_setGridItem", {
								item: sq,
								value: 5
							});
						}
					} else {
						store.commit("spatialSpan_setNumCorrect", numCorrect);
						var lastLevel = store.state.spatialSpan.lastLevel;
						var nextLevel = lastLevel;
						const round = store.state.spatialSpan.round;
						if (round % 3 == 2) {
							const prevCor = store.state.spatialSpan.numCorrectPerRound;
							const c1 = prevCor[round - 2] == list.length ? 1 : 0;
							const c2 = prevCor[round - 1] == list.length ? 1 : 0;
							const c3 = prevCor[round] == list.length ? 1 : 0;
							const sum = c1 + c2 + c3;
							if (sum >= 2) {
								nextLevel++;
							} else {
								nextLevel--;
							}
						}
						nextLevel = Math.min(16, nextLevel);
						nextLevel = Math.max(1, nextLevel);
						store.commit("spatialSpan_setLevel", nextLevel);
						store.commit("spatialSpan_setRound", round + 1);
						store.dispatch("nextTaskPhase");
					}
				});
			sequenceTimer.start();
			return {list: store.state.spatialSpan.list, response: response, correct: correct, numCorrect: numCorrect};
		}
	}
};
</script>
