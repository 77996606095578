<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="
					this.$store.state.taskContainer.taskPhase == 1 ||
					this.$store.state.taskContainer.taskPhase == 2
				"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.task(4)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="this.$store.state.taskContainer.taskPhase == 4"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.respond(4)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<g v-if="this.$store.state.taskContainer.taskPhase == 7">
				<foreignObject x="-600" y="-495" width="1200" height="99">
					<div class="txtbox">
						<span
							v-html="
								$parent.text.results.numCorrect(
									this.$store.state.finger_updating_spatial
										.numCorrect,
									4
								)
							"
						/>
					</div>
				</foreignObject>
				<timeline v-bind:stages="5" v-bind:pos="this.round" />
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";

export default {
	name: "training",
	components: {
		timeline,
	},
	watch: {
		phase: function (/*newPhase*/) {
			this.update();
		},
	},
	data: () => {
		return {
			start_time: 0,
			round: 0,
			rounds: [],
			items_correct: 0,
			lists_correct: 0,
			num_to_recall: 0,
			level: 0,
			lengths: [],
			//placebo_mode: false,
			round_answer_start_time: 0,
			demo: false,
		};
	},
	methods: {
		update() {
			switch (this.$store.state.taskContainer.taskPhase) {
				case 0:
				case 1: // Din uppgift...
					store.commit("finger_updating_spatial_resetGrid");
					//store.dispatch('nextTaskPhase', 2000);
					this.$parent.$parent.$refs.countDown.play(() =>
						store.commit("taskPhase", 2)
					);
					break;
				case 2: // Din uppgift...
					//sel.classList.remove("cursorHide");
					//sel.classList.add("cursorPointer");

					store.commit("finger_updating_spatial_showGrid");

					this.$parent.$refs.grid.presentList(
						this.lists[this.round].items,
						() => store.commit("taskPhase", 3)
					);
					break;
				case 3: // interlude
					store.dispatch("nextTaskPhase", 1000);
					break;
				case 4: // respond
					//sel.classList.add("cursorPointer");
					//sel.classList.remove("cursorHide");
					store.commit("finger_updating_spatial_showGrid");
					store.commit("finger_updating_spatial_acceptClicks", true);
					this.round_answer_start_time = Date.now();
					break;
				case 5:
					setTimeout(
						() =>
							store.commit(
								"finger_updating_spatial_showInterludeGrid"
							),
						1000
					);
					store.dispatch("nextTaskPhase", 2000);
					break;
				case 6: // show round results
					var result = this.$parent.$refs.grid.evalResponse();
					result.time = Date.now() - this.round_answer_start_time;
					this.rounds.push(result);
					this.items_correct += result.numC;
					if (result.numC == this.num_to_recall) this.lists_correct++;
					this.round++;
					store.dispatch("nextTaskPhase", 3000);
					break;
				case 7: // show round results, 2
					if (this.round < this.lists.length) {
						store.commit("showContinueButton");
					} else {
						setTimeout(() => store.commit("taskPhase", 100), 1000);
					}
					break;
				case 8:
					store.commit("finger_updating_spatial_resetGrid");
					setTimeout(
						() => store.commit("setTaskMode", "playing"),
						3000
					);
					break;
				case 100: // done!
					if (this.demo) {
						store.commit("setTaskMode", "clickToExit");
					} else {
						this.saveSessionResults();
					}
					break;
			}
		},
		createLists() {
			var lists = [];
			lists.push(this.createRandomList(this.lengths[0]));
			lists.push(this.createRandomList(this.lengths[1]));
			lists.push(this.createRandomList(this.lengths[2]));
			lists.push(this.createRandomList(this.lengths[3]));
			lists.push(this.createRandomList(this.lengths[4]));

			var m = lists.length,
				t,
				i;

			// shuffle lists:
			while (m) {
				i = Math.floor(Math.random() * m--);

				t = lists[m];
				lists[m] = lists[i];
				lists[i] = t;
			}
			this.lists = lists;

			return lists;
		},
		createRandomList(len) {
			var i = Math.min(len, 16);
			var list = [];
			while (i > 0) {
				var sq = Math.trunc(Math.random() * 16);
				if (sq < 16) {
					if (list.indexOf(sq) < 0) {
						list.push(sq);
						--i;
					}
				}
			}
			return { items: list };
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			const done_num =
				this.$store.state.progress.updating_spatial_num || 0;
			persist.set_progress_data("updating_spatial_num", done_num + 1);

			persist.log("results", {
				n: done_num,
				level: this.level,
				rounds: this.rounds,
				items_correct: this.items_correct,
				lists_correct: this.lists_correct,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
			});

			//if (!this.placebo_mode) {
			if (
				(this.lists_correct >= 4 || this.items_correct >= 16) &&
				this.level < 2
			) {
				persist.set_progress_data(
					"updating_spatial_level",
					this.level + 1
				);
			} else if (
				(this.lists_correct <= 1 || this.items_correct <= 4) &&
				this.level > 0
			) {
				persist.set_progress_data(
					"updating_spatial_level",
					this.level - 1
				);
			}
			//}

			persist
				.addToHistory("score", this.items_correct)
				.then((history) => {
					store.commit("setSessionScores", history);
					store.commit("setTaskMode", "sessionScore");
				});
		},
		set_list_lengths() {
			/*if (this.placebo_mode) {
				this.lengths = [4,4,4,4,4,4,4,4,4,4];
			}
			else {*/
			if (this.level == 0) {
				this.lengths = [4, 5, 6, 6, 7];
			} else if (this.level == 1) {
				this.lengths = [6, 7, 8, 9, 10];
			} else {
				const done =
					this.$store.state.progress.updating_spatial_num || 0;
				this.lengths =
					done % 2 == 0 ? [5, 7, 9, 11, 13] : [6, 8, 10, 12, 14];
				// jämna sessionsnummer / udda sessionsnumer
			}
			//}
		},
	},
	computed: {
		phase: function () {
			return this.$store.state.taskContainer.taskPhase;
		},
	},
	mounted() {
		this.num_to_recall = 4;

		this.demo = this.$store.state.group == "demo";

		if (this.demo) {
			this.level = 0;
		} else {
			this.level = this.$store.state.progress.updating_spatial_level || 0;
		}

		//this.placebo_mode = this.$store.state.condition == 'ctrl';
		this.set_list_lengths();
		this.createLists();

		store.commit("finger_updating_spatial_init", {
			level: this.level,
			num_to_recall: this.num_to_recall,
		});
		store.commit("setTaskMode", "playing");
		this.round = 0;
		this.start_time = Date.now();
		this.update();
	},
};
</script>
