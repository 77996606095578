export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						taskName: "Håll kollen",
						instructions: {
							a: 'Du kommer att få se ett antal ord. Några av dessa passar i lådorna nedan. ' +
								'Du skall komma ihåg det ord som senast passade i varje låda.',
							b: 'Tryck här för att se en instruktion ➤',
							c: "I det här exemplet finns två lådor.",
							d: (word, category) => { return `Ordet <font color="#FFCC66">${word}</font> passar i lådan <font color="#FFCC66">${category}</font>.` },
							e: (word) => { return `Ordet <font color="#FFCC66">${word}</font> passar inte i någon av lådorna.` },
							f: "Skriv in det sista ordet för varje låda",
						},
						play: {
							respond: (num) => {
								if (num == 1) return 'Skriv in det sista ordet';
								else return 'Skriv in det sista ordet för varje låda';
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt boll';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						categories: [
							{
								categoryName: 'Djur',
								words: ["hund", "elefant", "räv", "katt", "gris", "älg", "råtta", "giraff", "igelkott", "åsna", "häst", "björn", "kanin", "apa", "kamel"]
							},
							{
								categoryName: 'Växter',
								words: ["gran", "tall", "björk", "maskros", "blåsippa", "ek", "rönn", "klöver", "ormbunke", "syren", "brännässla", "ljung", "näckros", "viol", "alm"]
							},
							{
								categoryName: 'Kläder',
								words: ["byxor", "kjol", "strumpor", "skjorta", "luva", "overall", "jacka", "kavaj", "halsduk", "keps", "slips", "kappa", "tröja", "hatt", "handskar"]
							},
							{
								categoryName: 'Släktingar',
								words: ["kusin", "faster", "farmor", "morbror", "son", "dotter", "mamma", "halvbror", "mormor", "moster", "bror", "syster", "morfar", "farfar", "pappa"]
							},
							{
								categoryName: 'Sport',
								words: ["fotboll", "hockey", "tennis", "handboll", "basket", "simning", "badminton", "brottning", "golf", "slalom", "rally", "bandy", "bordtennis", "boxning", "fäktning"]
							},
							{
								categoryName: 'Yrken',
								words: ["lärare", "snickare", "tandläkare", "målare", "polis", "optiker", "läkare", "murare", "sotare", "bagare", "pilot", "frisör", "kock", "mekaniker", "skomakare"]
							},
							{
								categoryName: 'Möbler',
								words: ["bord", "stol", "pall", "bänk", "soffa", "fåtölj", "säng", "gungstol", "garderob", "byrå", "skåp", "solstol", "skrivbord", "bokhylla", "hurts"]
							},
							{
								categoryName: 'Fordon',
								words: ["bil", "buss", "cykel", "moped", "motorcykel", "ambulans", "skåpbil", "tåg", "taxi", "lastbil", "fyrhjuling", "bandvagn", "traktor", "skoter"]
							},
							{
								categoryName: 'Instrument',
								words: ["gitarr", "violin", "trumpet", "saxofon", "flöjt", "orgel", "piano", "munspel", "trombon", "trumma", "klarinett", "harpa", "cello", "dragspel", "banjo"]
							},
							{
								categoryName: 'Verktyg',
								words: ["hammare", "såg", "borr", "hyvel", "fil", "kofot", "lödkolv", "plåtsax", "tång", "kniv", "mejsel", "syl", "stämjärn", "skiftnyckel", "häftpistol"]
							},
							{
								categoryName: 'Byggnader',
								words: ["villa", "stuga", "höghus", "sporthall", "igloo", "stall", "lada", "slott", "växthus", "fabrik", "lekstuga", "skjul", "sågverk", "garage", "hydda"]
							},
						]
					};
			}
		}
	}
}
