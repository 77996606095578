<template>
	<g>
		<transition name="quickfade">
			<g v-if="this.current_letter != ''">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-100"
					y="-115"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="0"
					y="-10"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="80"
					fill="#000000"
					>{{ this.current_letter }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.show_results">
				<foreignObject x="-600" y="-495" width="1200" height="599">
					<div class="txtbox">
						<div class="readbox">
							<span
								v-html="
									text.current_results(
										this.current_sequence.letters.length,
										this.current_letters_correct,
										this.current_sequence.questions.length,
										this.current_questions_correct
									)
								"
							/>
						</div>
					</div>
				</foreignObject>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.show_letter_response">
				<foreignObject x="-600" y="-495" width="1200" height="599">
					<div class="txtbox">
						<div class="readbox">
							<span v-html="text.letter_response_instr" />
						</div>
					</div>
				</foreignObject>

				<rect
					x="-320"
					y="-315"
					width="640"
					height="100"
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					rx="15"
				/>
				<text
					x="0"
					y="-260"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="64"
					fill="#000000"
					>{{ this.current_letter_response }}</text
				>
				<g v-on:click="clicked_letter('F')" class="clickable">
					<rect
						x="-360"
						y="-180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-285"
						y="-100"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>F</text
					>
				</g>
				<g v-on:click="clicked_letter('H')" class="clickable">
					<rect
						x="-170"
						y="-180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-95"
						y="-100"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>H</text
					>
				</g>
				<g v-on:click="clicked_letter('J')" class="clickable">
					<rect
						x="20"
						y="-180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="95"
						y="-100"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>J</text
					>
				</g>
				<g v-on:click="clicked_letter('K')" class="clickable">
					<rect
						x="210"
						y="-180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="285"
						y="-100"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>K</text
					>
				</g>

				<g v-on:click="clicked_letter('L')" class="clickable">
					<rect
						x="-360"
						y="0"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-285"
						y="80"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>L</text
					>
				</g>
				<g v-on:click="clicked_letter('M')" class="clickable">
					<rect
						x="-170"
						y="0"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-95"
						y="80"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>M</text
					>
				</g>
				<g v-on:click="clicked_letter('P')" class="clickable">
					<rect
						x="20"
						y="0"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="95"
						y="80"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>P</text
					>
				</g>
				<g v-on:click="clicked_letter('Q')" class="clickable">
					<rect
						x="210"
						y="0"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="285"
						y="80"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>Q</text
					>
				</g>

				<g v-on:click="clicked_letter('R')" class="clickable">
					<rect
						x="-360"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-285"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>R</text
					>
				</g>
				<g v-on:click="clicked_letter('S')" class="clickable">
					<rect
						x="-170"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="-95"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>S</text
					>
				</g>
				<g v-on:click="clicked_letter('T')" class="clickable">
					<rect
						x="20"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="95"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>T</text
					>
				</g>
				<g v-on:click="clicked_letter('Y')" class="clickable">
					<rect
						x="210"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#FFFFFF88"
						rx="15"
					/>
					<text
						x="285"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#000000"
						>Y</text
					>
				</g>
				<g v-on:click="clicked_erase()" class="clickable">
					<rect
						x="400"
						y="180"
						width="150"
						height="150"
						stroke="#00000020"
						stroke-width="10"
						fill="#88888844"
						rx="15"
					/>
					<text
						x="470"
						y="260"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="64"
						fill="#00000066"
						>⌫</text
					>
				</g>
			</g>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.current_question.length > 0"
				x="-700"
				y="-100"
				width="1400"
				height="200"
			>
				<div class="txtbox">
					<div class="bigbox">
						{{ this.current_question }}
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_current_question_eq.length > 0"
				x="-700"
				y="-100"
				width="1400"
				height="200"
			>
				<div class="txtbox">
					<div class="bigbox">
						{{ this.show_current_question_eq }}
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 0"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct0" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 1"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct1" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 2"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct2" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 3"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct3" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 4"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct4" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 5"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct5" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 6"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct6" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="quickfade">
			<foreignObject
				v-if="this.show_instruct == 7"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox">
					<div class="readbox">
						<span v-html="text.instruct7" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<g
			v-if="continue_button_visible"
			v-on:click="clicked_continue()"
			class="clickable"
		>
			<rect
				stroke="#00000020"
				stroke-width="10"
				fill="#FFFFFFCC"
				x="-150"
				y="375"
				width="300"
				height="100"
				rx="15"
			/>
			<text
				x="0"
				y="425"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="30"
				fill="#000000"
				>{{ this.text.continue_ }}</text
			>
		</g>
		<g v-if="show_truth_buttons">
			<g v-on:click="clicked_true()" class="clickable">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#CCFFCCCC"
					x="-375"
					y="250"
					width="350"
					height="100"
					rx="15"
				/>
				<text
					x="-200"
					y="300"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000"
					>{{ this.text.true_ }}</text
				>
			</g>
			<g v-on:click="clicked_false()" class="clickable">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFCCCCCC"
					x="25"
					y="250"
					width="350"
					height="100"
					rx="15"
				/>
				<text
					x="200"
					y="300"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000"
					>{{ this.text.false_ }}</text
				>
			</g>
		</g>
		<gauge ref="gauge" />
		<transition name="fade">
			<g v-if="this.practice">
				<circle
					cx="740"
					cy="440"
					r="60"
					fill="#CC333333"
					stroke="none"
				/>
				<text
					x="740"
					y="440"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="24"
					fill="#FFFFFF"
					>{{ this.text.practice }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.show_timeup">
				<circle cx="0" cy="0" r="160" fill="#FF8800" stroke="none" />
				<text
					x="0"
					y="0"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="36"
					fill="#FFFFFF"
					>{{ this.text.too_slow }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.stage == 100">
				<circle cx="0" cy="0" r="160" fill="#88FF88" stroke="none" />
				<text
					x="0"
					y="0"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="36"
					fill="#008800"
					>{{ this.text.done }}</text
				>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_operation_span_math_adult/lang.js";
import gauge from "@/v/gauge.vue";
import stat from "@/stat.js";
import persist from "@/persist.js";

//import gsap from "gsap";
/*
		<transition name="quickfade">
			<gauge v-if="show_gauge" ref="gauge" />
		</transition>
*/
export default {
	name: "test_operation_span_math_adult",
	//genväg-alias:
	local: store.state.test_operation_span_math_adult,
	components: {
		gauge,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {
			practice: false,
			continue_button_visible: true,
			show_truth_buttons: false,
			show_letter_response: false,
			show_gauge: false,
			show_timeup: false,
			current_letter_response: "",
			current_letters_correct: 0,
			current_questions_correct: 0,
			accumulated_questions_correct: 0,
			accumulated_questions_presented: 0,
			item_question_start_time: 0,
			item_letters_start_time: 0,
			show_results: false,
			stage: -1,
			current_letter: "",
			current_question: "",
			current_question_eq: "",
			show_current_question_eq: "",
			current_question_answer: false,
			current_sequence: [],
			current_sequence_index: -1,
			global_question_index: -1,
			show_instruct: -1,
			trials_section: "instructions",
			round: 0,
			current_questions_rt: [],
			time_limit_2_5sd: 0,
			test_q_num_1sd: 0,
			test_q_num_2sd: 0,
			test_q_num_2_5sd: 0,
			test_q_num_timeup: 0,
			test_q_num_presented: 0,
			question_timer: 0,
			timer_stage: -1,
			test_letter_set: [],
			results: {
				start_time: 0,
				total_time: 0,
				practice_math_c: 0,
				practice_math_rt_mean: 0,
				practice_math_rt_sd: 0,

				letters_c: 0,
				letters_c2: 0,
				math_c: 0,

				mc: [], // math items, correct?
				mrt: [], // math items, rt
				mr: [], // math items, response
				lc: [], // letter items, sum correct
				lc2: [], // letter items, correct2 = string length - levenshtein distance
				lr: [], // letter items, response
			},
		};
	},
	watch: {
		stage: function (/*new_stage*/) {
			//console.log("--- new stage: " + this.stage);
			switch (this.stage) {
				case 0:
					this.practice = false;
					this.show_instruct = 0;
					this.show_continue_button();
					break;
				case 1:
					this.results.start_time = Date.now();
					this.show_instruct = 1;
					this.show_continue_button();
					break;
				case 2:
					this.continue_button_visible = false;
					setTimeout(() => {
						this.stage = this.stage + 1;
					}, 100);
					break;
				case 3:
					this.practice = true;
					this.trials_section = "p0";
					this.present_seq({ letters: "FR", questions: [] });
					break;
				case 4:
					this.trials_section = "p0";
					this.present_seq({ letters: "TKM", questions: [] });
					break;
				case 5:
					this.practice = false;
					this.show_instruct = 2;
					this.show_continue_button();
					break;
				case 6:
					this.practice = false;
					this.show_instruct = 3;
					//this.show_continue_button();
					this.show_truth_buttons = true;
					break;
				//------------------------------- Timing ----------------------
				case 7:
					this.practice = true;
					this.accumulated_questions_presented = 0;
					this.accumulated_questions_correct = 0;
					this.trials_section = "measure_timing";
					this.present_seq({
						letters: "",
						questions: this.text.questions.measure,
					});
					break;
				case 8:
					this.accumulated_questions_presented = 0;
					this.accumulated_questions_correct = 0;
					this.practice = false;
					this.trials_section = "";
					this.show_instruct = 4;
					this.show_continue_button();
					break;
				case 9:
					this.trials_section = "";
					this.show_instruct = 5;
					//this.show_gauge = true;
					this.$refs.gauge.show_demo();
					//this.show_continue_button();
					break;
				case 10:
					this.accumulated_questions_presented = 0;
					this.accumulated_questions_correct = 0;
					this.trials_section = "";
					this.show_instruct = 6;
					this.show_continue_button();
					break;
				//------------------------------- Combined practice------------
				case 11:
					this.continue_button_visible = false;
					setTimeout(() => {
						this.stage = this.stage + 1;
					}, 100);
					break;
				case 12:
					this.practice = true;
					this.trials_section = "p1";
					this.present_seq({
						letters: "MT",
						questions: this.text.questions.practice[0],
					});
					break;
				case 13:
					this.trials_section = "p2";
					this.present_seq({
						letters: "HP",
						questions: this.text.questions.practice[1],
					});
					break;
				case 14:
					this.trials_section = "p3";
					this.present_seq({
						letters: "FL",
						questions: this.text.questions.practice[2],
					});
					break;
				//------------------------------- Real test -------------------
				case 15:
					this.practice = false;
					this.trials_section = "";
					this.show_instruct = 7;
					this.show_continue_button();
					break;
				//------------------------------- 3 ---------------------------
				case 16:
					this.test_q_num_timeup = 0;
					this.test_q_num_presented = 0;
					this.question_c_prop = 0;
					this.test_q_num_1sd = 0;
					this.test_q_num_2sd = 0;
					this.test_q_num_2_5sd = 0;
					this.accumulated_questions_presented = 0;
					this.accumulated_questions_correct = 0;
					this.practice = false;
					this.trials_section = "real_test";
					this.level = 0;
					this.present_seq({
						letters: this.text.questions.test_letters[0],
						questions: this.text.questions.test[0],
					});
					break;
				case 17:
					this.present_seq({
						letters: this.text.questions.test_letters[1],
						questions: this.text.questions.test[1],
					});
					break;
				//------------------------------- 4 ---------------------------
				case 18:
					this.present_seq({
						letters: this.text.questions.test_letters[2],
						questions: this.text.questions.test[2],
					});
					break;
				case 19:
					this.present_seq({
						letters: this.text.questions.test_letters[3],
						questions: this.text.questions.test[3],
					});
					break;
				//------------------------------- 5 ---------------------------
				case 20:
					this.present_seq({
						letters: this.text.questions.test_letters[4],
						questions: this.text.questions.test[4],
					});
					break;
				case 21:
					this.present_seq({
						letters: this.text.questions.test_letters[5],
						questions: this.text.questions.test[5],
					});
					break;
				//------------------------------- 6 ---------------------------
				case 22:
					this.present_seq({
						letters: this.text.questions.test_letters[6],
						questions: this.text.questions.test[6],
					});
					break;
				case 23:
					this.present_seq({
						letters: this.text.questions.test_letters[7],
						questions: this.text.questions.test[7],
					});
					break;
				//------------------------------- 7 ---------------------------
				case 24:
					this.present_seq({
						letters: this.text.questions.test_letters[8],
						questions: this.text.questions.test[8],
					});
					break;
				case 25:
					this.present_seq({
						letters: this.text.questions.test_letters[9],
						questions: this.text.questions.test[9],
					});
					break;
				//------------------------------- 8 ---------------------------
				case 26:
					this.present_seq({
						letters: this.text.questions.test_letters[10],
						questions: this.text.questions.test[10],
					});
					break;
				case 27:
					this.present_seq({
						letters: this.text.questions.test_letters[11],
						questions: this.text.questions.test[11],
					});
					break;
				//------------------------------- 9 ---------------------------
				case 28:
					this.present_seq({
						letters: this.text.questions.test_letters[12],
						questions: this.text.questions.test[12],
					});
					break;
				case 29:
					this.present_seq({
						letters: this.text.questions.test_letters[13],
						questions: this.text.questions.test[13],
					});
					break;
				//------------------------------- testet klart------------------
				case 30:
					setTimeout(() => {
						this.stage = 100;
					}, 4000);
					break;
				case 100:
					this.results.num_timeup = this.test_q_num_timeup;
					//console.log("DONE!" );
					this.saveSessionResults();
					setTimeout(() => {
						//store.dispatch('gotoModule', 'home');
						//store.dispatch("gotoModule", "test_span_digits");
						store.dispatch("gotoModule", "home");
					}, 5000);
					break;
			}
		},
	},

	methods: {
		saveSessionResults() {
			this.results.total_time = Math.trunc(
				Date.now() - this.results.start_time
			);
			persist.log("results", this.results);
			persist.set_progress_data("ospan", 1);
		},
		clicked_continue() {
			if (this.current_question.length > 0) {
				this.get_current_question_response();
			} else if (this.show_letter_response) {
				this.calc_current_results();
			} else if (this.show_results) {
				this.goto_next_stage();
			} else {
				this.goto_next_stage();
			}
		},
		clicked_true() {
			//console.log("clicked_true");
			this.log_question_response(true);
		},
		clicked_false() {
			//console.log("clicked_false");
			this.log_question_response(false);
		},
		question_timeup() {
			if (this.timer_stage != this.stage) {
				//console.log("time up -- collision");
				return;
			}
			if (this.trials_section == "real_test") {
				this.results.mc.push(0);
				this.results.mr.push("-");
				this.results.mrt.push(this.time_limit_2_5sd);
			}
			//console.log("time up");
			this.test_q_num_timeup++;
			this.show_timeup = true;
			this.show_truth_buttons = false;
			this.continue_button_visible = false;
			this.current_letter = "";
			this.current_question = "";
			this.current_question_eq = "";
			setTimeout(() => {
				this.show_timeup = false;
				this.question_ended();
			}, 1000);
		},
		log_question_response(response_truth) {
			if (this.stage == 6) {
				// Specialfall tidigt i instruktionen...
				this.goto_next_stage();
				return;
			}

			if (this.show_timeup) {
				//console.log("already time up!");
				// Om en timeup har inträffat så loggas redan math rt och responser på annat ställe
				return;
			}

			this.show_timeup = false;
			var rt = Date.now() - this.item_question_start_time;
			if (this.question_timer != 0) {
				clearTimeout(this.question_timer);
				this.question_timer = 0;
			}
			//console.log("rt = " + rt);
			if (this.trials_section == "real_test") {
				if (this.current_question_answer == response_truth) {
					this.results.math_c++;
					this.results.mc.push(1);
				} else {
					this.results.mc.push(0);
				}
				this.results.mr.push(response_truth ? "t" : "f");
				this.results.mrt.push(rt);
			}
			if (this.current_question_answer == response_truth) {
				this.current_questions_correct++;
			}
			this.current_questions_rt.push(rt);
			this.question_ended();
		},
		question_ended() {
			//console.log("---");
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";
			this.present_current_letter();
		},
		clicked_letter(letter) {
			var pos = this.current_letter_response.indexOf(letter);
			if (pos >= 0) {
				this.current_letter_response =
					this.current_letter_response.substring(0, pos);
			} else {
				this.current_letter_response =
					this.current_letter_response + letter;
			}
			//if (this.current_letter_response.length >= this.current_sequence.letters.length
			if (
				this.current_letter_response.length >= 1 &&
				this.continue_button_visible == false
			) {
				this.show_continue_button();
			}
		},
		clicked_erase() {
			var newlen = this.current_letter_response.length - 1;
			if (newlen >= 0) {
				this.current_letter_response =
					this.current_letter_response.substring(0, newlen);
			}
			if (newlen <= 0) {
				this.continue_button_visible = false;
			}
		},
		show_continue_button() {
			this.continue_button_visible = true;
			//setTimeout(() => {
			//	this.continue_button_visible = true;
			//}, 250);
		},
		goto_next_stage() {
			var stage_delay = 300;
			if (this.show_gauge) {
				this.$refs.gauge.hide();
				stage_delay += 2100;
			}
			this.show_letter_response = false;
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";
			this.current_letter_response = "";
			this.show_results = false;

			setTimeout(() => {
				this.stage = this.stage + 1;
			}, stage_delay);
		},
		sequence_complete() {
			if (this.current_sequence.letters.length > 0) {
				this.show_letter_response_buttons();
			} else {
				this.calc_current_results();
			}
		},
		calc_current_results() {
			var i, len;
			// räkna rätta bokstäver:
			len = Math.min(
				this.current_sequence.letters.length,
				this.current_letter_response.length
			);
			this.current_letters_correct = 0;
			for (i = 0; i < len; i++) {
				if (
					this.current_sequence.letters[i] ==
					this.current_letter_response[i]
				)
					this.current_letters_correct++;
			}

			var cs = "";
			var rs = "";
			for (i = 0; i < this.current_sequence.letters.length; i++)
				cs += this.current_sequence.letters[i];
			for (i = 0; i < this.current_letter_response.length; i++)
				rs += this.current_letter_response[i];

			this.accumulated_questions_presented +=
				this.current_sequence.questions.length;
			this.accumulated_questions_correct +=
				this.current_questions_correct;

			if (this.trials_section == "measure_timing") {
				this.results.practice_math_c = this.current_questions_correct;
				//this.results.practice_question_c_prop =
				//	this.current_questions_correct /
				//	this.current_sequence.questions.length;
				var rt_mean = stat.mean(this.current_questions_rt);
				var rt_sd = stat.standardDev(this.current_questions_rt);
				this.results.practice_math_rt_mean = Math.trunc(rt_mean);
				this.results.practice_math_rt_sd = Math.trunc(rt_sd);
				this.time_limit_2_5sd = Math.trunc(rt_mean + 2.5 * rt_sd);
			} else if (this.trials_section == "real_test") {
				// Letters:
				this.results.letters_c += this.current_letters_correct;

				let lc2 = Math.max(
					0,
					cs.length - this.levenshteinDistance(cs, rs)
				);
				this.results.letters_c2 += lc2;

				this.results.lc.push(this.current_letters_correct);
				this.results.lc2.push(lc2);
				this.results.lr.push(rs);
			}
			//console.log("RT MEAN: ", stat.mean(this.current_questions_rt));

			this.show_letter_response = false;
			this.show_results = true;

			this.continue_button_visible = false;
			var showg =
				this.trials_section == "p1" ||
				this.trials_section == "p2" ||
				this.trials_section == "p3" ||
				this.trials_section == "real_test";
			if (this.current_sequence.questions.length > 0 && showg) {
				var prop_questions_correct =
					this.accumulated_questions_correct /
					this.accumulated_questions_presented;
				this.$refs.gauge.show(() => {
					this.$refs.gauge.moveTo(prop_questions_correct, () => {
						this.show_continue_button();
					});
				});
			} else {
				this.show_continue_button();
			}
		},
		show_letter(letter) {
			this.current_letter = letter;
			setTimeout(() => {
				this.current_letter = "";
			}, 1000);
		},
		present_seq(seq) {
			this.show_instruct = -1;
			this.current_letter = "";
			this.current_question = "";
			this.current_sequence_index = -1;
			this.current_sequence = seq;
			this.current_questions_correct = 0;
			this.current_letter_response = "";
			this.current_questions_rt = [];

			this.continue_button_visible = false;
			this.show_truth_buttons = false;

			setTimeout(() => {
				this.$parent.$refs.countDown.play(() => {
					this.present_next_item();
				});
			}, 250);
		},
		present_next_item() {
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";
			this.show_current_question_eq = "";

			var current_length = Math.max(
				this.current_sequence.letters.length,
				this.current_sequence.questions.length
			);
			this.current_sequence_index++;
			if (this.current_sequence_index < current_length) {
				setTimeout(() => {
					this.present_current_question();
				}, 500);
			} else {
				this.sequence_complete();
			}
		},
		present_current_question() {
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.show_timeup = false;
			this.current_letter = "";
			this.current_question = "";
			this.show_current_question_eq = "";

			const idx = this.current_sequence_index;
			//console.log("---- Question " + idx);

			if (
				this.current_sequence_index >=
				this.current_sequence.questions.length
			) {
				this.present_current_letter();
				return;
			}
			const qparts = this.current_sequence.questions[idx];

			this.current_question = qparts[0];
			this.current_question_eq = qparts[1];
			this.current_question_answer = qparts[2];

			//console.log("Q:  " + this.current_question);
			//console.log("EQ: " + this.current_question_eq);
			//console.log("A:  " + this.current_question_answer);
			//console.log(" ");
			//console.log("    " + this.current_question + " == " + this.text.questions[q_idx+1]);
			//this.show_truth_buttons = true;

			this.show_continue_button();

			this.item_question_start_time = Date.now();

			var timed_limited =
				this.trials_section == "p1" ||
				this.trials_section == "p2" ||
				this.trials_section == "p3" ||
				this.trials_section == "real_test";

			if (this.trials_section == "real_test") {
				this.test_q_num_presented++;
			}

			if (timed_limited) {
				this.timer_stage = this.stage;
				this.question_timer = setTimeout(() => {
					this.question_timeup();
				}, this.time_limit_2_5sd);
			}

			/*setTimeout(() => {
				this.$parent.$refs.countDown.play(() => {
					this.current_question = "";
					this.present_current_letter();
				});
			}, 1000);*/
		},
		get_current_question_response() {
			this.continue_button_visible = false;
			this.current_letter = "";
			this.current_question = "";
			this.show_truth_buttons = true;
			this.show_current_question_eq = this.current_question_eq;
		},
		present_current_letter() {
			this.continue_button_visible = false;
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";
			this.show_current_question_eq = "";

			if (
				this.current_sequence_index >=
				this.current_sequence.letters.length
			) {
				this.present_next_item();
				return;
			}

			this.current_letter =
				this.current_sequence.letters[this.current_sequence_index];
			//console.log("    " + this.current_letter);
			setTimeout(() => {
				this.current_letter = "";
				setTimeout(() => {
					this.present_next_item();
				}, 250);
			}, 1000);
		},
		show_letter_response_buttons() {
			this.show_letter_response = true;
			//this.show_continue_button();
			this.show_truth_buttons = false;
			this.current_letter = "";
			this.current_question = "";
			this.show_current_question_eq = "";
			this.item_letters_start_time = Date.now();
		},
		levenshteinDistance(s1, s2) {
			var m = s1.length;
			var n = s2.length;
			var matrix = new Array();
			var line;
			var i;
			var j;
			for (i = 0; i <= m; ++i) {
				line = new Array();
				for (j = 0; j <= n; ++j) {
					if (i != 0) line.push(0);
					else line.push(j);
				}
				line[0] = i;
				matrix.push(line);
			}
			var cost;
			for (i = 1; i <= m; ++i)
				for (j = 1; j <= n; ++j) {
					if (s1.charAt(i - 1) == s2.charAt(j - 1)) cost = 0;
					else cost = 1;
					matrix[i][j] = Math.min(
						matrix[i - 1][j] + 1,
						matrix[i][j - 1] + 1,
						matrix[i - 1][j - 1] + cost
					);
				}
			return matrix[m][n];
		},
	},
	mounted() {
		this.stage = 0;
		//this.stage = 15;
		//console.log("state.test_set: " + this.$store.state.test_set);

		//this.test_letter_set = text.questions.test_letters;
		/*this.test_letter_set = [
			["JQ", "MS"],
			["QLF", "TFM"],
			["KMYL", "HPTL"],
			["SLJTK", "YQJKF"],
			["YPHJKR", "RJMSHL"],
			["TRYSFHP", "FQSTKPR"],
		];*/

		//FHJK LMPQ RSTY
		/*if (this.$store.state.test_set == "A") {
		} else {
			// set "B"
			this.test_letter_set = [
				["TF", "LK"],
				["RQY", "JST"],
				["TYJP", "HLMP"],
				["RKYST", "PLYMK"],
				["KLQRYP", "RJFHKL"],
				["RYHJSTL", "SYTLKFH"],
			];
		}*/
	},
};
</script>