<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="true" x="-600" y="-495" width="1200" height="599">
				<div class="txtbox">
					Välkommen till dagens träningspass!
					<div class="readbox">
						Innan du startar dagens pass,
						se till att det är lugnt runt dig.
						Det är viktigt att du försöker göra
						ditt bästa och att du arbetar
						så noggrant och snabbt som möjligt.
						Lycka till!
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<g :opacity="this.updating_letters_opacity" transform="translate(-450, 0)" v-on:click="this.click_ipark_updating_letters">
				<lettersUpdatingIcon/>
			</g>
		</transition>
		<transition name="fade">
			<g :opacity="this.updating_spatial_opacity" transform="translate(-150, 0)"  v-on:click="this.click_ipark_updating_spatial">
				<spatialUpdatingIcon />
			</g>
		</transition>
		<transition name="fade">
			<g :opacity="this.updating_colours_opacity" transform="translate(150, 0)" v-on:click="this.click_ipark_updating_colours">
				<coloursUpdatingIcon />
			</g>
		</transition>
		<transition name="fade">
			<g :opacity="this.keep_track_opacity" transform="translate(450, 0)" v-on:click="this.click_ipark_keep_track">
				<keepTrackIcon/>
			</g>
		</transition>
		<transition name="fade">
			<g transform="translate(670, -490)">
				<logoutButton />
			</g>
		</transition>

		<timeline :stages="30" :pos="this.session" />
		<text
			x="-710"
			y="483"
			class="txt"
			text-anchor="start"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="30"
			fill="#FFFFFF"
		>Session: {{session}}</text>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/taskMenu/lang.js";
import coloursUpdatingIcon from "@/v/taskMenu/icons/coloursUpdatingIcon.vue";
import spatialUpdatingIcon from "@/v/taskMenu/icons/spatialUpdatingIcon.vue";
import lettersUpdatingIcon from "@/v/taskMenu/icons/lettersUpdatingIcon.vue";
import keepTrackIcon from "@/v/taskMenu/icons/keepTrackIcon.vue";
import logoutButton from "@/v/taskMenu/logoutButton.vue";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";

export default {
	name: "menu_ipark",
	components: {
		coloursUpdatingIcon,
		spatialUpdatingIcon,
		lettersUpdatingIcon,
		keepTrackIcon,
		timeline,
		logoutButton
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	data: () => { return {
		show_keep_track: false,
		show_updating_spatial: false,
		show_updating_colours: false,
		show_updating_letters: false,
		keep_track_opacity: 0,
		updating_spatial_opacity: 0,
		updating_colours_opacity: 0,
		updating_letters_opacity: 0,
		session: 0,
	}},
	methods: {
		click_ipark_keep_track() {
			if (this.show_keep_track) store.dispatch("gotoModule", "ipark_keep_track")},
		click_ipark_updating_letters() {
			if (this.show_updating_letters) store.dispatch("gotoModule", "ipark_updating_letters")},
		click_ipark_updating_colours() {
			if (this.show_updating_colours) store.dispatch("gotoModule", "ipark_updating_colours")},
		click_ipark_updating_spatial() {
			if (this.show_updating_spatial) store.dispatch("gotoModule", "ipark_updating_spatial")},
	},
	mounted() {
		const login_keep_track_num       = this.$store.state.login_progress.keep_track_num || 0;
		const login_updating_spatial_num = this.$store.state.login_progress.updating_spatial_num || 0;
		const login_updating_colours_num = this.$store.state.login_progress.updating_colours_num || 0;
		const login_updating_letters_num = this.$store.state.login_progress.updating_letters_num || 0;
		//---
		const current_keep_track_num       = this.$store.state.progress.keep_track_num || 0;
		const current_updating_spatial_num = this.$store.state.progress.updating_spatial_num || 0;
		const current_updating_colours_num = this.$store.state.progress.updating_colours_num || 0;
		const current_updating_letters_num = this.$store.state.progress.updating_letters_num || 0;
		//---
		//console.log('keep_track:       ' + login_keep_track_num       + ' -> ' + current_keep_track_num);
		//console.log('updating_spatial: ' + login_updating_spatial_num + ' -> ' + current_updating_spatial_num);
		//console.log('updating_colours: ' + login_updating_colours_num + ' -> ' + current_updating_colours_num);
		//console.log('updating_letters: ' + login_updating_letters_num + ' -> ' + current_updating_letters_num);
		//---
		const session = Math.min(
			login_keep_track_num,
			login_updating_spatial_num,
			login_updating_colours_num,
			login_updating_letters_num,
		);
		this.show_keep_track = current_keep_track_num <= session;
		this.show_updating_spatial = current_updating_spatial_num <= session;
		this.show_updating_colours = current_updating_colours_num <= session;
		this.show_updating_letters = current_updating_letters_num <= session;

		this.keep_track_opacity       = current_keep_track_num       <= session? 1.0 : 0.1;
		this.updating_spatial_opacity = current_updating_spatial_num <= session? 1.0 : 0.1;
		this.updating_colours_opacity = current_updating_colours_num <= session? 1.0 : 0.1;
		this.updating_letters_opacity = current_updating_letters_num <= session? 1.0 : 0.1;

		this.session = session + 1;

		this.$store.commit('setLanguage', 'sv');

		if (this.$store.state.motivation_done == false) {
			store.dispatch("gotoModule", 'form_motivation');
		}
		else if (
			!(this.show_keep_track     || this.show_updating_spatial ||
			this.show_updating_colours || this.show_updating_letters)
			&& this.$store.state.concentration_done == false
		){
			store.dispatch("gotoModule", 'form_concentration');
		}
		else if (this.$store.state.concentration_done) {
			persist.logout();
			store.dispatch('gotoModule', 'login');
		}

	}

};
</script>
