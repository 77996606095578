export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "fi":
					return {
						instructions: 'Näytölle ilmestyy kirjaimia, yksi kerrallaan.<br><br>Sinun tehtäväsi on painaa mieleen kirjaimet ja muistaa ne kaikki samassa järjestyksessä kuin ne näytettiin.',
						done: "Tehtävä suoritettu!",
						// Paina mieleesi kaikki kirjaimet, jotka näytetään
						respond: "Paina kirjaimia samassa järjestyksessä",
						num_correct: (num, tot) => {
							return `Sait <b><font color="#AAFFAA">${num}</font></b> / ${tot} oikein`;
						},
					};
				case "sv":
				default:
					return {
						taskName: "Bokstäver",
						instructions: 'Bokstäver kommer att visas, en i taget.<br><br>Din uppgift är att minnas och återge alla bokstäver i samma ordning som de visades.',
						done: "Klart!",
						respond: "Tryck på bokstäverna i samma ordning",
						num_correct: (num, tot) => {
							return `Du hade <b><font color="#AAFFAA">${num}</font></b> av ${tot} rätt`;
						},
					};
			}
		}
	}
}
