<template>
	<transition name="fade">
		<g v-if="$parent.show_gauge">
			<rect
				x="-130"
				y="-130"
				width="100"
				height="100"
				fill="none"
				stroke="none"
			/>
			<path
				id="bg"
				opacity="1"
				fill="#FFFFFF"
				stroke="#00000077"
				stroke-width="3"
			/>
			<path
				id="red"
				opacity="1"
				fill="none"
				stroke="#FE0315"
				stroke-width="40"
			/>
			<path
				id="orange"
				opacity="1"
				fill="none"
				stroke="#FEA513"
				stroke-width="40"
			/>
			<path
				id="yellow"
				opacity="1"
				fill="none"
				stroke="#FFD706"
				stroke-width="40"
			/>
			<path
				id="green"
				opacity="1"
				fill="none"
				stroke="#9BCD31"
				stroke-width="40"
			/>
			<path
				d="M -130 0 L -130 30 130 30 130 0 M 0 0"
				fill="#FFFFFF"
				stroke="#00000077"
				stroke-width="3"
			/>
			<text
				x="124"
				y="20"
				font-size="1.5em"
				fill="#9BCD31"
				text-anchor="end"
				>{{ $parent.text.correct }}</text
			>
			<text
				x="-124"
				y="20"
				font-size="1.5em"
				fill="#FE0315"
				text-anchor="start"
				>{{ $parent.text.incorrect }}</text
			>

			<path
				id="hnd"
				:transform="'rotate(' + rot + ')'"
				d="M -8 0 L 0 -100 8 0"
				stroke="none"
				fill="#000000"
			/>
			<circle cx="0" cy="0" r="8" stroke="none" fill="#000000" />
			<circle cx="0" cy="0" r="4" stroke="none" fill="#FFFFFF" />
		</g>
	</transition>
</template>

<script>
import gsap from "gsap";

export default {
	name: "gauge",
	data: function () {
		return {
			rot: 0,
			value: 0.5,
		};
	},
	methods: {
		init() {
			const arc = document.getElementById("arc");
			arc.setAttribute("d", describeArc(0, 0, 100, 0, 360));
			arc.setAttribute("opacity", 1);

			const bg = document.getElementById("bg");
			const red = document.getElementById("red");
			const orange = document.getElementById("orange");
			const yellow = document.getElementById("yellow");
			const green = document.getElementById("green");
			bg.setAttribute("d", describeArc(0, 0, 130, -90, 90));
			red.setAttribute("d", describeArc(0, 0, 100, -90, -45));
			orange.setAttribute("d", describeArc(0, 0, 100, -45, 0));
			yellow.setAttribute("d", describeArc(0, 0, 100, 0, 45));
			green.setAttribute("d", describeArc(0, 0, 100, 45, 90));
		},
		show(andThen = null) {
			this.$parent.show_gauge = true;
			setTimeout(() => {
				this.init();
				if (andThen != null) {
					setTimeout(() => {
						andThen();
					}, 200);
				}
			}, 100);
		},
		hide() {
			this.$parent.show_gauge = false;
		},
		show_demo() {
			this.show();
			setTimeout(() => {
				this.setTo(0.5);
				this.moveTo(0.85, () => {
					this.moveTo(0.25, () => {
						this.moveTo(0.95, () => {
							this.$parent.show_continue_button();
						});
					});
				});
			}, 700);
		},
		setTo(newVal) {
			var prop =
				newVal < 0.55
					? (5 / 11) * newVal
					: 0.25 + (5 / 3) * (newVal - 0.55);
			this.rot = 180 * prop - 90;
			this.value = newVal;
		},
		moveTo(newVal, andThen = null) {
			var prop =
				newVal < 0.55
					? (5 / 11) * newVal
					: 0.25 + (5 / 3) * (newVal - 0.55);
			var start_rot = this.rot;
			var end_rot = 180 * prop - 90;
			var dummy = {};
			var t1 = gsap.timeline();
			t1.to(dummy, {
				duration: 2,
				ease: "none",
				onUpdate: () => {
					this.rot =
						start_rot + t1.progress() * (end_rot - start_rot);
				},
				onComplete: () => {
					if (andThen != null) andThen();
				},
			});
		},
	},
	mounted() {
		this.setTo(0.5);
	},
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y,
	].join(" ");

	return d;
}
</script>
