<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="phase == 0" x="-600" y="-495" width="1200" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.a(4)" />
					<div class="link" v-on:click="$store.commit('taskPhase', 1)">
						<span v-html="$parent.text.instructions.b" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 6" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.d2" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 8" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.d3" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 11" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.forward.respond(4)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 13" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.e" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 0 && this.$store.state.mind_ad_updating_digits.instructionsViewed"
				x="-600"
				y="450"
				width="1200"
				height="100"
			>
				<div class="hint">
					<span v-html="$parent.text.instructions.f" />
				</div>
			</foreignObject>
		</transition>
		<hand v-show="handVisible" ref="hand" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import hand from "@/v/hand.vue";
import gsap from "gsap";

export default {
	name: "instructions",
	components: { hand },
	data: function() {
		return {
			handVisible: false
		};
	},
	watch: {
		phase: function(/*newPhase*/) {
			this.update();
		}
	},
	methods: {
		update() {
			const placebo = this.$store.state.condition == 'ctrl';
			switch (this.$store.state.taskContainer.taskPhase) {
			case 0: // Din uppgift...
				//sel.classList.remove("cursorHide");
				//sel.classList.add("cursorPointer");
				store.commit("setTaskMode", "ready");
				store.commit("mind_ad_updating_digits_resetitemSet");
				store.commit("mind_ad_updating_digits_showitemSet");
				//store.dispatch('nextTaskPhase', 3000);
				break;
			case 1:
				store.commit("mind_ad_updating_digits_resetitemSet");
				store.commit("mind_ad_updating_digits_showitemSet");

				store.commit('setTaskMode', 'instructions');
				//store.commit("taskPhase", 10);
				store.dispatch('nextTaskPhase', 1000);

				//sel.classList.remove("cursorPointer");
				//sel.classList.add("cursorHide");

				//store.commit("taskPhase", 10)
				break;
			case 2: // c: "Nu ska jag visa!",
				//store.dispatch('nextTaskPhase', 2000);
				store.commit("taskPhase", 6);
				break;
			/*case 3:
				store.dispatch('nextTaskPhase', 1000);
				break;
			case 4: // d1: "I det här exemplet..."
				store.dispatch('nextTaskPhase', 5000);
				break;
			case 5: //---
				store.dispatch('nextTaskPhase', 1000);
				break;*/
			case 6: // d2: "Snart kommer 3 bollar att blinka",
				store.dispatch('nextTaskPhase', 6000);
				break;
			case 7: //---
				store.dispatch('nextTaskPhase', 1000);
				break;
			case 8: // d3: "Men jag ska bara återge de två sista",
				store.dispatch('nextTaskPhase', 4000);
				break;
			case 9:
				this.$parent.$parent.$refs.countDown.play(() =>
					store.commit("taskPhase", 10)
				);
				break;
			case 10:
				store.commit("mind_ad_updating_digits_resetitemSet");
				if (placebo) {
					this.$parent.$refs.itemSet.presentList(
						["2", "1", "3", "4"],
						() => store.commit("taskPhase", 11)
					);
				} else {
					this.$parent.$refs.itemSet.presentList(
						["4", "2", "1", "3", "4"],
						() => store.commit("taskPhase", 11)
					);
				}
				break;
			case 11:
				store.commit("mind_ad_updating_digits_resetitemSet");
				this.handVisible = true;
				this.$refs.hand.show(() => {
					var hand = gsap.timeline();
					hand.to("#hand", {
						delay: 0.5,
						duration: 2,
						x: -130,
						y: 350,
						ease: "power3.inOut",
						onComplete: () => {
							this.$refs.hand.click();
							store.dispatch("mind_ad_updating_digits_clickItem", {
								itemIndex: 6,
								roboClick: true
							});
						}
					});
					hand.to("#hand", {
						delay: 0.5,
						duration: 2,
						x: -390,
						y: 350,
						ease: "power3.inOut",
						onComplete: () => {
							this.$refs.hand.click();
							store.dispatch("mind_ad_updating_digits_clickItem", {
								itemIndex: 5,
								roboClick: true
							});
						}
					});
					hand.to("#hand", {
						delay: 1,
						duration: 1.5,
						x: 130,
						y: 350,
						ease: "power3.inOut",
						onComplete: () => {
							this.$refs.hand.click();
							store.dispatch("mind_ad_updating_digits_clickItem", {
								itemIndex: 7,
								roboClick: true
							});
						}
					});
					hand.to("#hand", {
						delay: 1,
						duration: 1.5,
						x: 390,
						y: 350,
						ease: "power3.inOut",
						onComplete: () => {
							this.$refs.hand.click();
							store.dispatch("mind_ad_updating_digits_clickItem", {
								itemIndex: 8,
								roboClick: true
							});
							//store.commit("taskPhase", 12);
							store.dispatch('nextTaskPhase', 1000);
						}
					});
				});
				break;
			case 12:
				this.handVisible = false;
				this.$parent.$refs.itemSet.evalResponse(true);
				store.dispatch('nextTaskPhase', 2000);
				break;
			case 13:
				store.dispatch('nextTaskPhase', 8000);
				break;
			case 14:
				store.commit("mind_ad_updating_digits_setInstructionsViewed");
				//store.commit("taskPhase", 0);
				store.commit("setTaskMode", "ready");
				//store.dispatch('nextTaskPhase', 6000);
				break;
			}
		}
	},
	computed: {
		phase: function() {
			return this.$store.state.taskContainer.taskPhase;
		}
	},
	mounted() {
		store.commit("setTaskMode", "ready");
		this.update();
	}
};

</script>
