export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
					return {
						taskName: "Rutan",
						instructions: {
							a: "Här visas 16 rutor. Några av dem kommer att <u>blinka</u>. Din uppgift är att minnas vilka rutor som blinkade och i vilken ordning de blinkade.",
							b: "Tryck här för att se en instruktion ➤",
							c: "Nu ska jag visa!",
							d: "Kom ihåg rutorna som blinkar",
							e: "3 rätt!",
							f: "Tryck på den stora pilen för att börja!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Kom ihåg vilken ruta som blinkar';
								else if (num == 2) return 'Kom ihåg båda rutorna som blinkar';
								else return `Kom ihåg i vilken ordning alla ${num} rutor blinkar`;
							},
							respond: (num) => {
								if (num == 1) return 'Tryck på rutan som blinkade';
								else if (num == 2) return 'Tryck på båda rutorna som blinkade, i samma ordning';
								else return `Tryck på alla ${num} rutor i samma ordning`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt ruta';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Omgång ${num} av ${tot}`;
							}
						}
					};
				case "zh":
					return {
						instructions: {
							a: "在这16个方块中，有一些将会<u>亮起</u>。尽量记住哪些方块亮起和它们亮起的次序。",
							b: "看示范点击这里！➤",
							c: " ",
							d: "记住亮起的方块",
							e: "3个正确！",
							f: "点击箭头开始",
						},
						forward: {
							task: (num) => {
								if (num == 1) return '记住亮起的方块';
								else if (num == 2) return '记住亮起的两个方块';
								else return `记住这${num}个方块亮起的次序`;
							},
							respond: (num) => {
								if (num == 1) return '点击正确的方块';
								else if (num == 2) return '按照同样的次序点击正确的两个方块';
								else return `按照同样的次序点击正确的${num}个方块`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return '那不是正确的方块';
									else return '正确！';
								} else {
									if (num == 0) return `这次没有正确的方块`;
									else if (num == tot) return `完全正确！`;
									else return `总共 ${tot}个中的${num}个正确`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `总共${tot}个回合之${num}`;
							}
						}
					};
				case "fi":
					return {
						instructions: {
							a: "Tässä on 16 laattaa. Joihinkin niistä <u>syttyy valo</u>. Tehtäväsi on muistaa mihin syttyi valo, ja missä järjestyksessä se tapahtui.",
							b: "Paina tästä ja katso esimerkkiä! ➤",
							c: " ",
							d: "Muista laatat",
							e: "3 oikein!",
							f: "Paina isoa nuolta aloittaaksesi!",
						},
						forward: {
							task: (num) => {
								var nnn = '';
								switch (num) {
									case 1: nnn = ':een'; break;
									case 2: nnn = ':een'; break;
									case 3: nnn = ':een'; break;
									case 4: nnn = ':ään'; break;
									case 5: nnn = ':een'; break;
									case 6: nnn = ':een'; break;
									case 7: nnn = ':ään'; break;
									case 8: nnn = ':aan'; break;
									case 9: nnn = ':ään'; break;
									default: nnn = ''; break;
								}
								if (num == 1) return 'Muista laatta, johon syttyy valo';
								else if (num == 2) return 'Muista molemmat laatat, joihin syttyy valo';
								else return `Muista järjestys, jossa ${num}${nnn} laattaan syttyy valo`;
							},
							respond: (num) => {
								var nnn = '';
								switch (num) {
									case 1: nnn = ':tä'; break;
									case 2: nnn = ':ta'; break;
									case 3: nnn = ':a'; break;
									case 4: nnn = ':ää'; break;
									case 5: nnn = ':tä'; break;
									case 6: nnn = ':ta'; break;
									case 7: nnn = ':ää'; break;
									case 8: nnn = ':aa'; break;
									case 9: nnn = ':ää'; break;
									default: nnn = ''; break;
								}
								if (num == 1) return 'Napauta laattaa';
								else if (num == 2) return 'Napauta molempia laattoja samassa järjestyksessä';
								else return `Napauta kaikkia ${num}${nnn} laattaa samassa järjestyksessä`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Se ei ollut oikea laatta';
									else return 'Oikein!';
								} else {
									if (num == 0) return `Ei oikeita laattoja tällä kertaa`;
									else if (num == tot) return `Täydellistä!`;
									else return `${num} / ${tot} oikein`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Kierros ${num} / ${tot}`;
							}
						}
					};
				case "en":
				default:
					return {
						instructions: {
							a: "Here are 16 tiles. Some of them will <u>light up</u>. Your task is to remember which ones that lit up and in what order they did so.",
							b: "Press here to see an instruction ➤",
							c: " ",
							d: "Remember the tiles",
							e: "3 correct!",
							f: "Press the big arrow to begin!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Remember the tile that lights up';
								else if (num == 2) return 'Remember both tiles that light up';
								else return `Remember the order in which the ${num} tiles light up`;
							},
							respond: (num) => {
								if (num == 1) return 'Tap the tile';
								else if (num == 2) return 'Tap both tiles, in the same order';
								else return `Tap all ${num} tiles in the same order`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'That was not the correct tile';
									else return 'Correct!';
								} else {
									if (num == 0) return `No correct tiles this time`;
									else if (num == tot) return `Perfect!`;
									else return `${num} of ${tot} correct`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Round ${num} of ${tot}`;
							}
						}
					};
			}
		}
	}
}
