<template>
	<g>
		<g id="shifting_predictable_icon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<rect
				stroke="#00000020"
				stroke-width="5"
				fill="none"
				x="-80" y="-80"
				width="160" height="160"
				rx="5"
			/>
			<line
				stroke="#00000020"
				stroke-width="4"
				stroke-dasharray="5, 4"
				x1="-75" y1="0" x2="75" y2="0"
			/>
			<line
				stroke="#00000020"
				stroke-width="4"
				stroke-dasharray="5, 4"
				x1="0" y1="-75" x2="0" y2="75"
			/>

			<rect
				stroke="#FFFFFF10"
				stroke-width="5"
				fill="#F3A000"
				x="-80" y="-80"
				width="80" height="80"
				rx="5"
			/>
			<text
				opacity="1.0"
				x="-40"
				y="-35"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="47"
				fill="#FFFFFF"
			>1</text>

			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.shifting_predictable_label}}</text>
		</g>
	</g>
</template>

<script>
export default {
	name: "shifting_predictable_icon",
	idleTween: null,
};
</script>
