export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
					return {
						see_colours: "Kan du skilja alla dessa färger åt?",
						yes: "Ja, inga problem",
						no: "Nej, inte så bra",
						instructions: {
							t0_button: 'Tryck här för att se en instruktion ➤',
							t1: 'Här nedanför ser du två ord och en färg',
							t2: 'Det första ordet är en plats (Skog)<br>och det andra ordet är ett föremål (Bok)',
							a_place: 'Skog',
							an_object: 'Bok',
							t3: 'Din uppgift är att komma ihåg vilket föremål och vilken färg som hör till platsen',
							t4: 'Du kan till exempel föreställa dig en stor gul bok mitt i skogen',
							t5: 'I det efterföljande testet kommer platsen att visas. Din uppgift är då att ange det föremål och den färg som hör till platsen'
						},
						training: {
							colours_correct: (num) => {
								return `Rätta färger: ${num}`;
							},
							objects_correct: (num) => {
								return `Rätta föremål: ${num}`;
							},
							pick_colour_and_object: 'Ange rätt färg och föremål',
						},
						places: [
							"Akvedukt",
							"Äldreboende",
							"Allrum",
							"Ambassad",
							"Äng",
							"Arena",
							"Arkiv",
							"Atrium",
							"Aula",
							"Aveny",
							"Bäck",
							"Backe",
							"Bageri",
							"Balkong",
							"Bänk",
							"Barnhem",
							"Barnkammare",
							"Barrikad",
							"Barstol",
							"Bastu",
							"Bensinmack",
							"Berg ",
							"Bibliotek",
							"Biltvätt",
							"Bio",
							"Bowlinghall",
							"Brandstation",
							"Bro",
							"Bukt",
							"Busshållplats",
							"Butik",
							"By",
							"Cykelbana",
							"Dal",
							"Datacenter",
							"Datorlabb",
							"Djungel",
							"Djuraffär",
							"Djurpark",
							"Dörr",
							"Entré",
							"Fabrik",
							"Fält",
							"Familjerum",
							"Fängelse",
							"Färjeterminal",
							"Flod",
							"Flygplats",
							"Fönster",
							"Fontän",
							"Församlingshus",
							"Förråd",
							"Fyr",
							"Gång",
							"Garage",
							"Garderob",
							"Gästrum",
							"Gatlykta",
							"Gräsmatta",
							"Grotta",
							"Gym",
							"Hall",
							"Halvö",
							"Hav",
							"Hemmabio",
							"Hemmakontor",
							"Hobbyrum",
							"Hög",
							"Holme",
							"Hörsal",
							"Hotell",
							"Hylla",
							"Igloo",
							"Järnhandel",
							"Kafé",
							"Kafeteria",
							"Källare",
							"Kanal",
							"Kapprum",
							"Kasino",
							"Klädbutik",
							"Klassrum ",
							"Klippa",
							"Kloster",
							"Koja",
							"Kök",
							"Konferensrum",
							"Konserthall",
							"Kontor",
							"Kontrollrum",
							"Köpcentrum",
							"Korridor",
							"Kraftverk",
							"Kulle",
							"Kyrka",
							"Laboratorium",
							"Låda",
							"Ladugård",
							"Lekrum",
							"Foajé",
							"Loft",
							"Mataffär",
							"Matbord",
							"Matkällare",
							"Matsal",
							"Militärbas",
							"Monument",
							"Museum",
							"Nattduksbord",
							"Nöjesfält ",
							"Observatorium",
							"Öken",
							"Oljedepå",
							"Omklädningsrum",
							"Pagod",
							"Palats",
							"Pariserhjul",
							"Park ",
							"Parkering",
							"Parlament ",
							"Pergola",
							"Personalrum",
							"Pir",
							"Polisstation",
							"Port",
							"Postkontor",
							"Postrum",
							"Pumphus",
							"Radhus",
							"Radiotorn",
							"Raffinaderi",
							"Rättssal",
							"Resort",
							"Restaurang",
							"Salong",
							"Säng",
							"Savann",
							"Simbassäng",
							"Sjö",
							"Sjöbod",
							"Sjukhus",
							"Skafferi ",
							"Skåp",
							"Skeppsvarv",
							"Skjul",
							"Skog",
							"Skola",
							"Skorsten",
							"Skrivbord ",
							"Skrivbordsstol",
							"Slott",
							"Soffa",
							"Soffbord",
							"Sovrum",
							"Spårvagn",
							"Sporthall",
							"Stad",
							"Stadshus",
							"Staffli",
							"Staket",
							"Stall",
							"Sten",
							"Stol",
							"Strand",
							"Studenthem",
							"Studio",
							"Stuga",
							"Svit",
							"Tåg",
							"Tågräls",
							"Tågstation",
							"Tak",
							"Tält",
							"Teater",
							"Tempel",
							"Terrass",
							"Toalett",
							"Torn",
							"Trädgårdsdamm",
							"Trappa",
							"Träsk",
							"Tronsal",
							"Trottoar",
							"Tunnel",
							"Tvättstuga",
							"Universitet",
							"Uteplats",
							"Utomhuskök ",
							"Utrustningsrum",
							"Väg",
							"Vägg",
							"Vandrarhem",
							"Väntrum",
							"Vardagsrum",
							"Vårdcentral",
							"Vattentorn",
							"Växthus",
							"Veranda",
							"Villa",
							"Vind",
							"Vindsrum",
							"Vinkällare",
							"Verkstad",
						],
						objects: [
							"Ägg",
							"Äggklocka",
							"Äggkopp",
							"Ål",
							"Älg",
							"Ambulans",
							"Anka",
							"Ansiktsmask",
							"Antilop",
							"Armband",
							"Åsna",
							"Babian",
							"Baddräkt",
							"Badrock",
							"Badsalt",
							"Balettskor",
							"Ballong",
							"Bältdjur",
							"Skärp",
							"Bandage",
							"Banjo",
							"Batteri",
							"Bäver",
							"Bestick",
							"Bikini",
							"Bil",
							"Bilnyckel",
							"Björn",
							"Bläckfisk",
							"Blöja",
							"Blomma",
							"Blomvas",
							"Blus",
							"Bok",
							"Boll",
							"Bomullspinnar",
							"Borr",
							"Brädspel",
							"Brandalarm",
							"Brandbil",
							"Brödkniv",
							"Brödkorg",
							"Burk",
							"Buss",
							"Byxor",
							"Cello",
							"Chinchilla",
							"Cykel",
							"Dagbok",
							"Dammtrasa",
							"Dammvippa",
							"Datormus",
							"Dator",
							"Deg",
							"Delfin",
							"Deodorant",
							"Diskhandduk ",
							"Docka",
							"Dockhus",
							"Domino",
							"Dörrmatta",
							"Duk",
							"Duva",
							"Ekorre",
							"Elefant",
							"Fläkt",
							"Elgitarr",
							"Eyeliner",
							"Fackla",
							"Fågel",
							"Falk",
							"Får",
							"Färja",
							"Fasan",
							"Finskor",
							"Fiol",
							"Fisk",
							"Fiskskål",
							"Fjäril",
							"Fladdermus",
							"Fläkt",
							"Flamingo",
							"Flaska",
							"Flasköppnare",
							"Flöjt",
							"Fluga",
							"Flygplan",
							"Förkläde",
							"Sladd",
							"Förstoringsglas",
							"Fotografi",
							"Frukost",
							"Frukt",
							"Gaffel",
							"Galje",
							"Gardin",
							"Garn",
							"Gås",
							"Gepard",
							"Get",
							"Geting",
							"Gibbon",
							"Giraff",
							"Gitarr",
							"Glas",
							"Glasögon",
							"Glass",
							"Golfbil",
							"Gorilla",
							"Gräshoppa",
							"Grill",
							"Gris",
							"Groda",
							"Gympaskor",
							"Häger",
							"Haj",
							"Halsband",
							"Halsduk",
							"Hammare",
							"Hamster",
							"Handduk",
							"Handkräm",
							"Handskar",
							"Handväska",
							"Hänglås",
							"Hårband",
							"Hårborste",
							"Hare",
							"Hårklämma",
							"Harpa",
							"Hårspray",
							"Häst",
							"Hatt",
							"Helikopter",
							"Hink",
							"Hjälm",
							"Hörlurar",
							"Hostmedicin ",
							"Humla",
							"Hund",
							"Hundhalsband",
							"Hyena",
							"Igelkott",
							"Insekt",
							"Isbjörn",
							"Jacka",
							"Jaguar",
							"Jeans",
							"Kackerlacka",
							"Kaffe",
							"Kaffefilter",
							"Kaffekanna",
							"Kaka",
							"Kaktus",
							"Kalender",
							"Kamel",
							"Kameleont",
							"Kamera",
							"Kam",
							"Känguru",
							"Kanin",
							"Kanna",
							"Kanot",
							"Kappa",
							"Karaff",
							"Kartong",
							"Katt",
							"Keps",
							"Kikare",
							"Kjol",
							"Klänning",
							"Klarinett",
							"Klocka",
							"Knapp",
							"Ko",
							"Koala",
							"Kobra",
							"Kofta",
							"Kompass",
							"Kompost",
							"Kontaktlins",
							"Kopp",
							"Kork",
							"Korkskruv",
							"Korsett",
							"Kortspel",
							"Kostym",
							"Krabba",
							"Kreditkort",
							"Kristallkrona",
							"Kritor",
							"Krokodil",
							"Krukväxt",
							"Kuvert",
							"Kvast",
							"Kyckling",
							"Lakan",
							"Läppglans",
							"Läppstift",
							"Larv",
							"Lastbil",
							"Lax",
							"Lejon",
							"Leksaksrobot",
							"Lim",
							"Linne",
							"Ljus",
							"Locktång",
							"Lokomotiv",
							"Luftskepp",
							"Madrass",
							"Målarbok",
							"Manet",
							"Marsvin",
							"Mascara",
							"Mask",
							"Måttband",
							"Medicin",
							"Middag",
							"Miniräknare",
							"Moped",
							"Mopp",
							"Morgonrock",
							"Motorbåt",
							"Motorcykel",
							"Mugg",
							"Mullvad",
							"Munspel",
							"Mus",
							"Myra",
							"Näbbdjur",
							"Nagellack",
							"Näktergal",
							"Näsduk",
							"Nattlinne",
							"Noshörning",
							"Nyckel",
							"Nyckelknippa",
							"Nyckelpiga",
							"Ödla",
							"Ögondroppar",
							"Orangutang",
							"Ordbok",
							"Örhängen",
							"Orm",
							"Örn",
							"Öronpropp",
							"Ost",
							"Ostron",
							"Påfågel",
							"Paket",
							"Panflöjt",
							"Papegoja",
							"Papper",
							"Paraply",
							"Parasoll",
							"Parfym",
							"Pass",
							"Pelikan",
							"Pengar",
							"Penna",
							"Piano",
							"Pikétröja",
							"Pil",
							"Piller",
							"Pincett",
							"Pingvin",
							"Plånbok",
							"Polisbil",
							"Portfölj",
							"Puma",
							"Racerbil",
							"Rådjur",
							"Räka",
							"Raket",
							"Rakhyvel",
							"Råtta",
							"Räv",
							"Regnjacka",
							"Rengöringsmedel",
							"Resårband",
							"Ring",
							"Ringklocka ",
							"Rivjärn",
							"Roddbåt",
							"Säckpipa",
							"Såg",
							"Sågfisk",
							"Säl",
							"Sardin",
							"Sax",
							"Saxofon",
							"Schampo",
							"Schimpans",
							"Sedel",
							"Segelbåt",
							"Sengångare",
							"Servett",
							"Köplista",
							"Sjöhäst",
							"Sjölejon",
							"Sjöstjärna",
							"Skål",
							"Skalbagge",
							"Skallerorm",
							"Skärbräda",
							"Skata",
							"Skateboard",
							"Sked",
							"Skjorta",
							"Sköldpadda",
							"Skor",
							"Skorpion",
							"Shorts",
							"Skoter",
							"Skrivbok",
							"Skunk",
							"Slips",
							"Snigel",
							"Sockar",
							"Solglasögon",
							"Solkräm",
							"Soptunna",
							"Sparv",
							"Spegel",
							"Spindel",
							"Sportskor",
							"Spruta",
							"Stege",
							"Stövlar",
							"Stråhatt",
							"Strumpbyxor",
							"Struts",
							"Strykjärn",
							"Sudd",
							"Surfbräda",
							"Svan",
							"Svävare",
							"Synål",
							"Synt",
							"Syrsa",
							"T-shirt",
							"Täcke",
							"Tallrik",
							"Tamburin",
							"Tandborste",
							"Tandkräm",
							"Tändsticka",
							"Tandtråd",
							"Tangentbord",
							"Tärningar",
							"Telefon",
							"Tidning",
							"Tiger",
							"Tonfisk",
							"Torkställning",
							"Traktor",
							"Trana",
							"Tratt",
							"Tröja",
							"Trollslända",
							"Trombon",
							"Trummor",
							"Trumpet",
							"Trumpinnar",
							"Tuggummi",
							"Tusenfoting",
							"Tvål",
							"Tvättkorg",
							"Tvättlapp",
							"U-båt",
							"Uggla",
							"Undertröja",
							"Väckarklocka",
							"Valross",
							"Vantar",
							"Varg",
							"Väst",
							"Växtkruka",
							"Vessla",
							"Vildhund",
							"Vildsvin",
							"Virknål",
							"Vykort",
							"Xylofon",
							"Zebra",
						]
					};

				case "zh":
					return {
						see_colours: "您可以分辨得出所有颜色吗？",
						yes: "可以",
						no: "不太可以",
						instructions: {
							t0_button: '点击这里看示范',
							t1: '下面您将看到两个词语连同一个颜色',
							t2: '第一个词语是一个地方<br>而第二个词语是一样物件',
							a_place: '森林',
							an_object: '书',
							t3: '您的任务是记住哪样物件和颜色与那个地方同时出现',
							t4: '比如，想象在森林中间​​有一个明亮的黄色大书本',
							t5: '之后，屏幕将显示出地方的提示词语，您将要回想与这个地方同时出现的物件和相对的颜色',
						},
						training: {
							colours_correct: (num) => {
								return `正确颜色： ${num}`;
							},
							objects_correct: (num) => {
								return `正确物件： ${num}`;
							},
							pick_colour_and_object: '请选择正确的颜色和物件',
						},
						places: [
						/* "Bed" */					"床",
						/* "Bench" */				"凳子",
						/* "Chair" */				"椅子",
						/* "Sofa" */				"沙发",
						/* "Cupboard" */			"柜子",
						/* "Deckchair" */			"躺椅",
						/* "Desk" */				"书桌",
						/* "Drawing board" */		"画板",
						/* "Easel" */				"画架",
						/* "Filing cabinet" */		"文件柜",
						/* "Locker" */				"储物柜",
						/* "Nightstand" */			"床头柜",
						/* "Shelf" */				"架子",
						/* "Dining table" */		"餐桌",
						/* "Wardrobe" */			"衣柜",
						/* "Coffee table" */		"茶几",
						/* "Bar stool" */			"酒吧凳",
						/* "Drawer" */				"抽屉",
						/* "Aircraft cabin" */		"机舱",
						/* "Airport lounge" */		"机场候机厅",
						/* "Aisle" */				"走道",
						/* "Assembly hall" */		"大会堂",
						/* "Atrium" */				"中庭",
						/* "Attic" */				"阁楼",
						/* "Auditorium" */			"礼堂",
						/* "Ballroom" */			"舞厅",
						/* "Basement" */			"地下室",
						/* "Bedroom" */				"睡房",
						/* "Billiard room" */		"台球室",
						/* "Cafeteria" */			"食堂",
						/* "Cell" */				"牢房",
						/* "Church porch" */		"教堂中庭",
						/* "Classroom" */			"课室",
						/* "Cloakroom" */			"衣帽间",
						/* "Computer lab" */		"计算机实验室",
						/* "Conference room" */		"会议室",
						/* "Control room" */		"控制室",
						/* "Corridor" */			"走廊",
						/* "Courtroom" */			"法庭",
						/* "Dining room" */			"饭厅",
						/* "Dressing room" */		"更衣室",
						/* "Dungeon" */				"地牢",
						/* "Greenhouse" */			"温室",
						/* "Hall" */				"大厅",
						/* "Home cinema" */			"家庭影院",
						/* "Honeymoon suite" */		"蜜月套房",
						/* "Infirmary" */			"病房",
						/* "Kitchen" */				"厨房",
						/* "Laundry room" */		"洗衣房",
						/* "Lavatory" */			"卫生间",
						/* "Living room" */			"客厅",
						/* "Lobby" */				"大堂",
						/* "Loft" */				"阁楼",
						/* "Nursery" */				"托儿所",
						/* "Observatory" */			"天文台",
						/* "Office" */				"办公室",
						/* "Outdoor kitchen" */		"户外厨房",
						/* "Pantry" */				"食品储存室",
						/* "Patio" */				"露台",
						/* "Porch" */				"阳台",
						/* "Post room" */			"邮局",
						/* "Print room" */			"打印室",
						/* "Sauna" */				"桑拿",
						/* "Showroom" */			"陈列室",
						/* "Staffroom" */			"教职员室",
						/* "Student lounge" */		"学生休息室",
						/* "Studio" */				"工作室",
						/* "Study" */				"学习室",
						/* "Suite" */				"套房",
						/* "Wine cellar" */			"酒库",
						/* "Church" */				"教堂",
						/* "Museum" */				"博物馆",
						/* "Library" */				"图书馆",
						/* "Hospital" */			"医院",
						/* "Health Centre" */		"联络所",
						/* "School" */				"学校",
						/* "Theatre" */				"剧场",
						/* "Sports hall" */			"体育场",
						/* "Train Station" */		"地铁站",
						/* "Restaurant" */			"餐厅",
						/* "Cinema" */				"戏院",
						/* "Café" */				"咖啡厅",
						/* "Gas station" */			"加油站",
						/* "Airport" */				"机场",
						/* "Food Store" */			"便利店",
						/* "Clothing Store" */		"服装店",
						/* "Concert Hall" */		"音乐厅",
						/* "Bakery" */				"烘培店",
						/* "Park" */				"公园",
						/* "Police station" */		"警察局",
						/* "Fire Station" */		"消防局",
						/* "Villa" */				"别墅",
						/* "Palace" */				"皇宫",
						/* "Cottage" */				"茅庐",
						/* "Townhouse" */			"公寓住宅",
						/* "Rent House" */			"出租屋",
						/* "Igloo" */				"冰屋",
						/* "Tent" */				"帐篷",
						/* "Cave" */				"山洞",
						/* "Cabin" */				"木屋",
						/* "Hotel" */				"酒店",
						/* "Hostel" */				"宿舍",
						/* "Castle" */				"堡垒",
						/* "Monastary" */			"寺庙",
						/* "Resort" */				"度假村",
						/* "Casino" */				"赌场",
						/* "Aqueduct" */			"水道",
						/* "Barricade" */			"路障",
						/* "Bridge" */				"桥",
						/* "Bus stop" */			"巴士站",
						/* "Canal" */				"水沟",
						/* "Chimney" */				"烟囱",
						/* "Tower" */				"塔",
						/* "Dam" */					"水坝",
						/* "Dock" */				"码头",
						/* "Ferris wheel" */		"摩天轮",
						/* "Gate" */				"大门",
						/* "Handrails" */			"扶手",
						/* "Monument" */			"纪念碑",
						/* "Railway" */				"铁路",
						/* "Road" */				"马路",
						/* "Roller coaster" */		"过山车",
						/* "Streetlamp" */			"街灯",
						/* "Swimming pool" */		"游泳池",
						/* "Tram" */				"电车",
						/* "Train" */				"火车",
						/* "Tunnel" */				"隧道",
						/* "Lighthouse" */			"灯塔",
						/* "Factory" */				"工厂",
						/* "Power station" */		"发电厂",
						/* "Refinery" */			"炼油厂",
						/* "Warehouse" */			"仓库",
						/* "Bungalow" */			"洋房",
						/* "Retirement home" */		"疗养院",
						/* "Dormitory" */			"宿舍",
						/* "Prison" */				"监狱",
						/* "Car wash" */			"洗车站",
						/* "Amusement park" */		"游乐园",
						/* "Bowling alley" */		"保龄球场",
						/* "Arena" */				"竞技场",
						/* "Data centre" */			"数据中心",
						/* "Laboratory" */			"实验室",
						/* "Call center" */			"电话服务中心",
						/* "University" */			"大学",
						/* "Orphanage" */			"孤儿院",
						/* "Shrine" */				"神社",
						/* "Temple" */				"佛堂",
						/* "City hall" */			"市政府",
						/* "Embassy" */				"大使馆",
						/* "Parliament house" */	"国会大厦",
						/* "Post office" */			"邮政局",
						/* "Shipyard" */			"船厂",
						/* "Ferry terminal" */		"客轮码头",
						/* "Garage" */				"车库",
						/* "Car park" */			"停车场",
						/* "Gym" */					"健身房",
						/* "Military base" */		"军事基地",
						/* "Fountain" */			"瀑布",
						/* "Garden pond" */			"花园池塘",
						/* "Cave" */				"洞穴",
						/* "Avenue" */				"大街",
						/* "Lawn" */				"草坪",
						/* "Fence" */				"篱笆",
						/* "Pavement" */			"走道",
						/* "Tree house" */			"树屋",
						/* "Cycle lane" */			"脚车道",
						/* "Gift shop" */			"礼品店",
						/* "Supermarket" */			"超级市场",
						/* "Shopping centre" */		"购物商场",
						/* "Boutique" */			"精品店",
						/* "Bazaar" */				"市集",
						/* "Hardware shop" */		"五金店",
						/* "Pet shop" */			"宠物店",
						/* "Zoo" */					"动物园",
						/* "City" */				"城市",
						/* "Village" */				"乡村",
						/* "Hill" */				"山坡",
						/* "Beach" */				"沙滩",
						/* "Valley" */				"山谷",
						/* "Lake" */				"湖泊",
						/* "Meadow" */				"草地",
						/* "Field" */				"田野",
						/* "River" */				"河",
						/* "Mountain" */			"山",
						/* "Forest" */				"森林",
						/* "Desert" */				"沙漠",
						/* "Jungle" */				"丛林",
						/* "Bay" */					"海湾",
						/* "Peninsula" */			"半岛",
						/* "Sea" */					"大海",
						/* "Savanna" */				"稀树草原",
						/* "Islet" */				"小岛",
						/* "Slope" */				"坡",
						/* "Swamp" */				"沼泽",
						/* "Rock" */				"石头",
						/* "Cliff" */				"悬崖",
						/* "Wall" */				"墙",
						/* "Floor" */				"地板",
						/* "Ceiling" */				"天花板",
						/* "Roof" */				"屋顶",
						/* "Window" */				"窗口",
						/* "Door" */				"门",
						/* "Balcony" */				"阳台",
						/* "Staircase" */			"楼梯",
						/* "Home Office" */			"学习室",
						/* "Guest Room" */			"客房",
						/* "Storage Room" */		"储藏室",
						/* "Playroom" */			"游戏室"
						],
						objects: [
						/* "Ferry" */				"客船",
						/* "Car" */					"车子",
						/* "Bus" */					"巴士",
						/* "Motorbike" */			"摩托车",
						/* "Bicycle" */				"脚踏车",
						/* "Truck" */				"货车",
						/* "Motorboat" */			"游艇",
						/* "Sailboat" */			"帆船",
						/* "Golf cart" */			"高尔夫车",
						/* "Rickshaw" */			"三轮车",
						/* "Race car" */			"跑车",
						/* "Rocket" */				"火箭",
						/* "Balloon" */				"气球",
						/* "Helicopter" */			"直升机",
						/* "Airplane" */			"飞机",
						/* "Scooter" */				"滑板车",
						/* "Skateboard" */			"滑板",
						/* "Tractor" */				"拖拉机",
						/* "Canoe" */				"独木舟",
						/* "Submarine" */			"潜艇",
						/* "Surfboard" */			"从浪板",
						/* "Ambulance" */			"救护车",
						/* "Fire truck" */			"消防车",
						/* "Police car" */			"警车",
						/* "Rowing boat" */			"划船",
						/* "Ant" */					"蚂蚁",
						/* "Antelope" */			"羚羊",
						/* "Bison" */				"野牛",
						/* "Spider" */				"蜘蛛",
						/* "Bear" */				"熊",
						/* "Baboon" */				"狒狒",
						/* "Beetle" */				"甲虫",
						/* "Beaver" */				"海狸",
						/* "Bird" */				"鸟",
						/* "Butterfly" */			"蝴蝶",
						/* "Bat" */					"蝙蝠",
						/* "Camel" */				"骆驼",
						/* "Caterpillar" */			"毛虫",
						/* "Centipede" */			"蜈蚣",
						/* "Chameleon" */			"变色龙",
						/* "Cheetah" */				"猎豹",
						/* "Chicken" */				"鸡",
						/* "Chimpanzee" */			"黑猩猩",
						/* "Cockroach" */			"蟑螂",
						/* "Crab" */				"螃蟹",
						/* "Chinchilla" */			"龙猫",
						/* "Clownfish" */			"小丑鱼",
						/* "Cow" */					"牛",
						/* "Crane" */				"鹤",
						/* "Donkey" */				"驴",
						/* "Dog" */					"狗",
						/* "Deer" */				"鹿",
						/* "Dolphin" */				"海豚",
						/* "Duck" */				"鸭子",
						/* "Eagle" */				"老鹰",
						/* "Eel" */					"鳗鱼",
						/* "Elephant" */			"大象",
						/* "Emu" */					"鸸",
						/* "Flamingo" */			"火烈鸟",
						/* "Fly" */					"苍蝇",
						/* "Fox" */					"狐狸",
						/* "Ferret" */				"雪貂",
						/* "Frog" */				"青蛙",
						/* "Giraffe" */				"长颈鹿",
						/* "Goat" */				"山羊",
						/* "Gecko" */				"壁虎",
						/* "Gibbon" */				"长臂猿",
						/* "Goose" */				"鹅",
						/* "Grasshopper" */			"草蜢",
						/* "Gorilla" */				"大猩猩",
						/* "Guinea pig" */			"豚鼠",
						/* "Hamster" */				"仓鼠",
						/* "Horse" */				"马",
						/* "Hare" */				"野兔",
						/* "Hyena" */				"鬣狗",
						/* "Insect" */				"昆虫",
						/* "Fish" */				"鱼",
						/* "Jaguar" */				"捷豹",
						/* "Jellyfish" */			"水母",
						/* "Kangaroo" */			"袋鼠",
						/* "Cobra" */				"眼镜蛇",
						/* "Koala" */				"树熊",
						/* "Ladybug" */				"瓢虫",
						/* "Lion" */				"狮子",
						/* "Llama" */				"羊驼",
						/* "Leopard" */				"豹",
						/* "Lizard" */				"蜥蜴",
						/* "Lobster" */				"龙虾",
						/* "Civet" */				"灵猫",
						/* "Monkey" */				"猴子",
						/* "Mouse" */				"老鼠",
						/* "Orangutan" */			"人猿",
						/* "Octopus" */				"八爪鱼",
						/* "Ostrich" */				"鸵鸟",
						/* "Oyster" */				"生蚝",
						/* "Peacock" */				"孔雀",
						/* "Penguin" */				"企鹅",
						/* "Parrot" */				"鹦鹉",
						/* "Pig" */					"猪",
						/* "Puma */					"彪马",
						/* "Pigeon" */				"鸽子",
						/* "Piranha" */				"食人鱼",
						/* "Platypus" */			"鸭嘴兽",
						/* "Rabbit" */				"兔子",
						/* "Rat" */					"老鼠",
						/* "Rhinoceros" */			"犀牛",
						/* "Rattlesnake" */			"响尾蛇",
						/* "Salmon" */				"三文鱼",
						/* "Sea Lion" */			"海狮",
						/* "Seahorse" */			"海马",
						/* "Shark" */				"鲨鱼",
						/* "Shrimp" */				"虾",
						/* "Snail" */				"蜗牛",
						/* "Snake" */				"蛇",
						/* "Squirrel" */			"松鼠",
						/* "Starfish" */			"海星",
						/* "Stingray" */			"魔鬼鱼",
						/* "Swan" */				"天鹅",
						/* "Sardine" */				"沙丁鱼",
						/* "Scorpion" */			"蝎子",
						/* "Seal" */				"海豹",
						/* "Sloth" */				"树懒",
						/* "Sparrow" */				"麻雀",
						/* "Sheep" */				"绵羊",
						/* "Tiger" */				"老虎",
						/* "Tortoise" */			"乌龟",
						/* "Turtle" */				"海龟",
						/* "Tuna" */				"金枪鱼",
						/* "Walrus" */				"海象",
						/* "Wasp" */				"黄蜂",
						/* "Wolf" */				"狼",
						/* "Wild Boar" */			"野猪",
						/* "Zebra" */				"斑马",
						/* "Polar bear" */			"北极熊",
						/* "Owl" */					"猫头鹰",
						/* "Swallow" */				"燕子",
						/* "Bumblebee" */			"熊蜂",
						/* "Cricket" */				"蟋蟀",
						/* "Cat" */					"猫",
						/* "Crocodile" */			"鳄鱼",
						/* "Dragonfly" */			"蜻蜓",
						/* "Worm" */				"虫",
						/* "Hedgehog" */			"刺猬",
						/* "Nightingale" */			"夜莺",
						/* "Pangolin" */			"穿山甲",
						/* "Pheasant" */			"野鸡",
						/* "Gong" */				"锣",
						/* "Tambourine" */			"手鼓",
						/* "Xylophone" */			"木琴",
						/* "Bagpipe" */				"风笛",
						/* "Clarinet" */			"单簧管",
						/* "Flute" */				"长笛",
						/* "Harmonica" */			"口琴",
						/* "Saxophone" */			"萨克管",
						/* "Trombone" */			"长号",
						/* "Trumpet" */				"喇叭",
						/* "Cello" */				"大提琴",
						/* "Guitar" */				"吉他",
						/* "Harp" */				"竖琴",
						/* "Piano" */				"钢琴",
						/* "Violin" */				"小提琴 ",
						/* "Electric guitar" */		"电子吉他",
						/* "Skirt" */				"短裙",
						/* "Bikini" */				"比基尼",
						/* "Jumper" */				"连身衣",
						/* "Sneakers" */			"球鞋",
						/* "Hoodie" */				"连帽衫",
						/* "Vest" */				"背心",
						/* "High heels" */			"高跟鞋",
						/* "Flip flops" */			"拖鞋",
						/* "Handbag" */				"小提袋",
						/* "Tank top" */			"T恤",
						/* "Singlet" */				"背心",
						/* "Boots" */				"靴子",
						/* "Shorts" */				"短裤",
						/* "Umbrella" */			"雨伞",
						/* "Dress" */				"裙子",
						/* "Socks" */				"袜子",
						/* "Swimsuit" */			"泳衣",
						/* "Trench coat" */			"风衣",
						/* "Winter coat" */			"冬衣",
						/* "Straw hat" */			"草帽",
						/* "Cap" */					"帽子",
						/* "Scarf" */				"丝巾",
						/* "Glasses" */				"眼镜",
						/* "Sunglasses" */			"墨镜",
						/* "Apron" */				"围裙",
						/* "Tie" */					"领带",
						/* "Watch" */				"手表",
						/* "Necklace" */			"项链",
						/* "Bow tie" */				"领结",
						/* "Helmet" */				"头盔",
						/* "Trousers" */			"长裤",
						/* "Gloves" */				"手套",
						/* "Purse" */				"皮包",
						/* "Sweater" */				"毛衣",
						/* "Shirt" */				"衬衫",
						/* "Jeans" */				"牛仔裤",
						/* "Suit" */				"套装",
						/* "Shoes" */				"鞋子",
						/* "Cardigan" */			"羊毛衣",
						/* "Jacket" */				"夹克",
						/* "Sport shoes" */			"运动鞋",
						/* "Polo shirt" */			"马球衬衫",
						/* "Cargo pants" */			"工装裤",
						/* "Belt" */				"皮带",
						/* "Bathrobe" */			"浴衣",
						/* "Sports jacket" */		"运动外套",
						/* "T-shirt" */				"T恤衫",
						/* "Pullover" */			"套衫",
						/* "Wallet" */				"皮夹",
						/* "Ring" */				"戒指",
						/* "Earrings" */			"耳环",
						/* "Perfume" */				"香水",
						/* "Bracelet" */			"手镯",
						/* "Stockings" */			"丝袜",
						/* "Mirror" */				"镜子",
						/* "Nail polish" */			"指甲油",
						/* "Lip gloss" */			"唇彩",
						/* "Eyeliner" */			"眼线",
						/* "Lipstick" */			"口红",
						/* "Mascara" */				"睫毛膏",
						/* "Hand lotion" */			"护手霜",
						/* "Razor" */				"剃须刀",
						/* "Tissues" */				"纸巾",
						/* "Toothbrush" */			"牙刷",
						/* "Sunscreen" */			"防晒油",
						/* "Deodorant" */			"除臭剂",
						/* "Toothpaste" */			"牙膏",
						/* "Cotton bud" */			"棉花棒",
						/* "Tweezers" */			"镊子",
						/* "Scissors" */			"剪刀",
						/* "Mouthwash" */			"漱口液",
						/* "Dental floss" */		"牙线",
						/* "Comb" */				"梳子",
						/* "Hair spray" */			"发胶",
						/* "Hair clip" */			"发夹",
						/* "Hair band" */			"发带",
						/* "Diary" */				"日记",
						/* "Bottle" */				"瓶子",
						/* "Chewing gum" */			"口香糖",
						/* "Photo" */				"照片",
						/* "Postcard" */			"明信片",
						/* "Dictionary" */			"字典",
						/* "Book" */				"书",
						/* "Credit card" */			"信用卡",
						/* "Money" */				"钱",
						/* "Key" */					"钥匙",
						/* "Phone" */				"电话",
						/* "Button" */				"纽扣",
						/* "Pen" */					"钢笔",
						/* "Pencil" */				"铅笔",
						/* "Banknote" */			"钞票",
						/* "Passport" */			"护照",
						/* "Notebook" */			"笔记本",
						/* "Medicine" */			"药",
						/* "Battery" */				"电池",
						/* "Newspaper" */			"报纸",
						/* "Alarm clock" */			"闹钟",
						/* "Ball" */				"球",
						/* "Ballet shoes" */		"芭蕾舞鞋",
						/* "Bandage" */				"胶布",
						/* "Barbecue grill" */		"烧烤架",
						/* "Bath salt" */			"浴盐",
						/* "Bath towel" */			"浴巾",
						/* "Bed sheet" */			"床单",
						/* "Glass" */				"玻璃",
						/* "Bin" */					"垃圾桶",
						/* "Binoculars" */			"望远镜",
						/* "Blanket" */				"被子",
						/* "Blouse" */				"女衬衫",
						/* "Board game" */			"棋盘游戏",
						/* "Bottle opener" */		"开瓶器",
						/* "Bowl" */				"碗",
						/* "Spoon" */				"汤匙",
						/* "Fork" */				"叉子",
						/* "Bread basket" */		"面包篮",
						/* "Bread knife" */			"面包刀",
						/* "Briefcase" */			"公事包",
						/* "Broom" */				"扫把",
						/* "Bucket" */				"水桶",
						/* "Cactus" */				"仙人掌",
						/* "Flower" */				"花",
						/* "Fruit" */				"水果",
						/* "Cake" */				"蛋糕",
						/* "Calculator" */			"计算机",
						/* "Calendar" */			"日历",
						/* "Camera" */				"相机",
						/* "Candle" */				"蜡烛",
						/* "Canvas" */				"帆布",
						/* "Car key" */				"车钥匙",
						/* "Card game" */			"纸牌游戏",
						/* "Cardboard" */			"纸板",
						/* "Coffee" */				"咖啡",
						/* "Dinner" */				"晚餐",
						/* "Breakfast" */			"早餐",
						/* "Chandelier" */			"水晶吊灯",
						/* "Cheese grater" */		"乳酪刨丝器",
						/* "Cheese" */				"乳酪",
						/* "Chopsticks" */			"筷子",
						/* "Saw" */					"锯子",
						/* "Cleaning product" */	"清洁剂",
						/* "Clipboard" */			"写字板",
						/* "Clock" */				"时钟",
						/* "Clothes hanger" */		"衣架",
						/* "Clothes iron" */		"烫斗",
						/* "Coffee filter" */		"咖啡过滤器",
						/* "Coffee pot" */			"咖啡壶",
						/* "Coloring book" */		"填色本",
						/* "Combination lock" */	"密码锁",
						/* "Compass" */				"指南针",
						/* "Computer" */			"电脑",
						/* "Computer mouse" */		"滑鼠",
						/* "Contact lens" */		"隐形眼镜",
						/* "Cork" */				"木塞",
						/* "Corkscrew" */			"开瓶器",
						/* "Cough medicine" */		"咳嗽药水",
						/* "Crayons" */				"蜡笔",
						/* "Crochet hook" */		"钩针",
						/* "Cup" */					"杯子",
						/* "Curling iron" */		"卷发棒",
						/* "Curtains" */			"窗帘",
						/* "Cutlery" */				"餐具",
						/* "Cutting board" */		"砧板",
						/* "Dart" */				"飞镖",
						/* "Diaper" */				"纸尿片",
						/* "Dice" */				"骰子",
						/* "Dish towel" */			"抹布",
						/* "Diving mask" */			"潜水面罩",
						/* "Dog collar" */			"狗项圈",
						/* "Doll" */				"娃娃",
						/* "Dollhouse" */			"娃娃屋",
						/* "Domino*/				"骨牌",
						/* "Doorbell" */			"门铃",
						/* "Doormat" */				"门垫",
						/* "Doorstop" */			"门挡",
						/* "Dough" */				"面团",
						/* "Dress shoes" */			"礼服鞋",
						/* "Dressing gown" */		"礼服",
						/* "Drill" */				"钻头",
						/* "Drum set" */			"鼓",
						/* "Drum sticks" */			"鼓棒",
						/* "Drying rack" */			"晾衣架",
						/* "Dust cloth" */			"擦布",
						/* "Duster" */				"除尘器",
						/* "Earbud" */				"耳塞",
						/* "Egg box" */				"蛋盒",
						/* "Eggs" */				"鸡蛋",
						/* "Egg cup" */				"蛋杯",
						/* "Egg timer" */			"煮蛋计时器",
						/* "Elastic band" */		"松紧带",
						/* "Electric fan" */		"电扇",
						/* "Envelope" */			"信封",
						/* "Eraser" */				"橡皮擦",
						/* "Eye drops" */			"眼药水",
						/* "Extension cord" */		"延长线",
						/* "Face mask" */			"口罩",
						/* "Fan" */					"风扇",
						/* "Fire alarm" */			"火警",
						/* "Fish bowl" */			"鱼缸",
						/* "Torch" */				"手电筒",
						/* "Mop" */					"拖把",
						/* "Flower vase" */			"花瓶",
						/* "Folding chair" */		"折叠椅",
						/* "Funnel" */				"漏斗",
						/* "Glue stick" */			"胶棒",
						/* "Hammer" */				"锤子",
						/* "Hand bag" */			"手提包",
						/* "Handkerchief" */		"手帕",
						/* "Headphones" */			"手机",
						/* "Houseplant" */			"室内植物",
						/* "Ice cream" */			"冰淇淋",
						/* "Jar" */					"罐子",
						/* "Jug" */					"水瓶",
						/* "Key chain" */			"钥匙扣",
						/* "Keyboard" */			"键盘",
						/* "Ladder" */				"梯子",
						/* "Laundry bin" */			"洗衣桶",
						/* "Magnifying glass" */	"放大镜",
						/* "Matchstick" */			"火柴",
						/* "Mattress" */			"床褥",
						/* "Mug" */					"茶杯",
						/* "Napkin" */				"餐巾",
						/* "Night gown" */			"睡衣",
						/* "Padlock" */				"锁头",
						/* "Paper bag" */			"纸袋",
						/* "Paper" */				"纸张",
						/* "Pill" */				"药丸",
						/* "Plant pot" */			"花盆",
						/* "Plate" */				"碟子",
						/* "Raincoat" */			"雨衣",
						/* "Sewing needle" */		"缝纫针",
						/* "Shampoo" */				"洗发液",
						/* "Shopping list" */		"购物清单",
						/* "Soap bar" */			"肥皂",
						/* "Syringe" */				"注射器",
						/* "Tape measure" */		"卷尺",
						/* "Toy robot" */			"机器人",
						/* "Yarn" */				"针织纱"
						]
					};

				case "en":
				default:
					return {
						see_colours: "Can you distinguish all these colours?",
						yes: "Yes, I can",
						no: "No, not very well",
						instructions: {
							t0_button: 'Press here to see an instruction ➤',
							t1: 'Below you see two words and a colour',
							t2: 'The first word is a place (forest)<br>and the second word is an object (book)',
							a_place: 'Forest',
							an_object: 'Book',
							t3: 'Your task is to remember which object and colour are associated with the place',
							t4: 'For example, imagine a big bright yellow book in the middle of the forest',
							t5: 'At test the place will be given as a cue, and your task is to recall the object and the colour associated with the place',
						},
						training: {
							colours_correct: (num) => {
								return `Colours correct: ${num}`;
							},
							objects_correct: (num) => {
								return `Objects correct: ${num}`;
							},
							pick_colour_and_object: 'Pick the correct colour and object',
						},
						places: [
							"Bed",
							"Bench",
							"Chair",
							"Sofa",
							"Cupboard",
							"Deckchair",
							"Desk",
							//"Drawing board",
							//"Easel",
							"Filing cabinet",
							"Locker",
							"Nightstand",
							"Shelf",
							"Dining table",
							"Wardrobe",
							"Coffee table",
							"Bar stool",
							"Drawer",
							//"Aircraft cabin",
							"Airport lounge",
							"Aisle",
							//"Assembly hall",
							"Atrium",
							"Attic",
							"Auditorium",
							"Ballroom",
							"Basement",
							//"Battery room",
							"Bedroom",
							"Billiard room",
							//"Breezeway",
							"Cafeteria",
							"Cell",
							"Church porch",
							"Classroom",
							//"Cloakroom",
							"Closet",
							//"Common room",
							"Computer lab",
							"Conference room",
							"Control room",
							"Corridor",
							"Courtroom",
							"Crypt",
							"Den",
							//"Dining room",
							//"Drawing room",
							"Dressing room",
							"Dungeon",
							"Electrical room",
							"Entryway",
							"Equipment room",
							"Family room",
							"Food cellar",
							//"Furnace room",
							//"Garret",
							//"Granary",
							"Greenhouse",
							//"Green room",
							"Hall",
							"Home cinema",
							"Honeymoon suite",
							//"Infirmary",
							"Kitchen",
							//"Laundry room",
							//"Lavatory",
							"Living room",
							"Lobby",
							"Loft",
							//"Nursery",
							"Observatory",
							"Office",
							"Outdoor kitchen",
							"Pantry",
							"Patio",
							//"Parlour",
							"Porch",
							"Post room",
							"Print room",
							"Sauna",
							"Server room",
							"Showroom",
							"Staffroom",
							"Student lounge",
							"Studio",
							"Study",
							"Suite",
							//"Throne room",
							"Waiting hall",
							"Wine cellar",
							"Workshop",
							"Church",
							"Museum",
							"Library",
							"Hospital",
							"Health Centre",
							"School",
							"Theatre",
							"Sports hall",
							"Train Station",
							"Restaurant",
							"Cinema",
							"Café",
							"Gas station",
							"Airport",
							"Food Store",
							"Clothing Store",
							"Concert Hall",
							"Bakery",
							"Park",
							"Police station",
							"Fire Station",
							"Villa",
							"Palace",
							"Cottage",
							"Townhouse",
							//"Rent House",
							"Igloo",
							"Tent",
							"Cave",
							"Cabin",
							"Hotel",
							"Hostel",
							"Castle",
							//"Monastary",
							"Resort",
							"Casino",
							//"Aqueduct",
							"Barricade",
							"Boat lift",
							"Bridge",
							"Bus stop",
							"Canal",
							"Carport",
							"Chimney",
							"Radio tower",
							"Tower",
							"Dam",
							"Dock",
							"Ferris wheel",
							"Gate",
							//"Handrails",
							"Marina",
							"Monument",
							"Oil depot",
							"Pier",
							"Railway",
							"Shelter",
							"Road",
							"Roller coaster",
							"Silo",
							"Streetlamp",
							"Swimming pool",
							//"Tram",
							"Train",
							"Tunnel",
							"Water tower",
							"Lighthouse",
							"Factory",
							"Power station",
							"Refinery",
							"Warehouse",
							"Bungalow",
							"Retirement home",
							"Dormitory",
							"Prison",
							"Car wash",
							"Amusement park",
							"Bowling alley",
							"Arena",
							"Data centre",
							"Laboratory",
							"Call center",
							//"Pump house",
							"Barn",
							"Stable",
							"Archive",
							"University",
							"Orphanage",
							"Community hall",
							"Shrine",
							"Temple",
							"Pagoda",
							"City hall",
							"Consulate",
							"Embassy",
							//"Parliament house",
							"Post office",
							"Boathouse",
							"Shipyard",
							//"Space port",
							"Ferry terminal",
							"Garage",
							"Car park",
							"Park",
							"Gym",
							"Military base",
							//"Pergola",
							"Shed",
							"Fountain",
							//"Garden pond",
							"Cave",
							"Hedge",
							"Avenue",
							"Lawn",
							"Fence",
							"Pavement",
							"Tree house",
							"Cycle lane",
							"Gift shop",
							"Supermarket",
							"Shopping centre",
							"Boutique",
							//"Bazaar",
							"Hardware shop",
							"Pet shop",
							"Zoo",
							"City",
							"Village",
							"Hill",
							"Beach",
							"Valley",
							"Lake",
							"Meadow",
							"Field",
							"River",
							"Mountain",
							"Forest",
							"Desert",
							"Jungle",
							"Bay",
							"Gulf",
							"Brook",
							"Peninsula",
							"Sea",
							"Savanna",
							"Islet",
							"Slope",
							"Swamp",
							"Rock",
							"Cliff",
							"Wall",
							"Floor",
							"Ceiling",
							"Roof",
							"Window",
							"Door",
							"Terrace",
							"Balcony",
							"Staircase",
							"Home Office",
							"Guest Room",
							"Storage Room",
							"Dining Room",
							"Nursery",
							"Playroom",
							"Laundry room",
							"Hobby Room",
							"Mound",
						],
						objects: [
							"Ferry",
							"Car",
							"Bus",
							"Motorbike",
							"Moped",
							"Bicycle",
							"Truck",
							"Motorboat",
							"Sailboat",
							"Golf cart",
							//"Hovercraft",
							//"Jet ski",
							"Locomotive",
							//"Rickshaw",
							"Race car",
							"Rocket",
							"Airship",
							"Balloon",
							"Helicopter",
							"Airplane",
							"Scooter",
							"Skateboard",
							"Tractor",
							"Canoe",
							"Submarine",
							"Surfboard",
							"Ambulance",
							"Fire truck",
							"Police car",
							"Rowing boat",
							"Ant",
							"Antelope",
							"Armadillo",
							"Bison",
							"Spider",
							"Bear",
							"Baboon",
							"Beetle",
							"Beaver",
							"Bird",
							"Butterfly",
							"Bat",
							"Camel",
							"Caterpillar",
							"Centipede",
							"Chameleon",
							"Cheetah",
							"Chicken",
							"Chimpanzee",
							"Cockroach",
							"Crab",
							//"Chinchilla",
							"Clownfish",
							"Cow",
							"Crane",
							"Donkey",
							"Dog",
							//"Dingo",
							"Deer",
							"Dolphin",
							"Duck",
							"Eagle",
							"Eel",
							"Elephant",
							"Elk",
							//"Emu",
							"Falcon",
							"Flamingo",
							"Fly",
							"Fox",
							//"Ferret",
							"Frog",
							"Giraffe",
							"Goat",
							//"Gecko",
							//"Gibbon",
							"Goose",
							"Grasshopper",
							"Gorilla",
							"Guinea pig",
							"Hamster",
							"Horse",
							"Hare",
							//"Heron",
							"Hyena",
							//"Insect",
							//"Fish",
							"Jaguar",
							"Jackal",
							"Jellyfish",
							"Kangaroo",
							//"Kiwi",
							//"Kookaburra",
							"Cobra",
							"Koala",
							"Ladybug",
							"Lion",
							//"Lamprey",
							"Llama",
							"Leopard",
							"Lizard",
							"Lobster",
							//"Civet",
							"Monkey",
							"Mouse",
							"Magpie",
							"Mole",
							"Orangutan",
							"Octopus",
							"Ostrich",
							"Oyster",
							"Peacock",
							"Penguin",
							"Parrot",
							"Pelican",
							"Pig",
							"Puma",
							"Pigeon",
							"Piranha",
							"Platypus",
							"Rabbit",
							"Rat",
							"Rhinoceros",
							"Rattlesnake",
							"Robin",
							"Salmon",
							"Sea Lion",
							"Seahorse",
							"Shark",
							"Shrimp",
							"Snail",
							"Snake",
							"Squirrel",
							"Starfish",
							"Stingray",
							"Swan",
							"Sawfish",
							"Sardine",
							"Scorpion",
							"Seal",
							"Skunk",
							"Sloth",
							"Sparrow",
							"Sheep",
							"Tapir",
							"Tiger",
							"Tortoise",
							"Turtle",
							"Tuna",
							"Walrus",
							"Wasp",
							"Wolf",
							"Wild Boar",
							"Zebra",
							"Polar bear",
							"Owl",
							"Swallow",
							"Bumblebee",
							"Cricket",
							"Cat",
							"Crocodile",
							"Dragonfly",
							"Worm",
							"Hedgehog",
							"Mule",
							"Nightingale",
							"Pangolin",
							"Pheasant",
							"Salamander",
							"Weasel",
							"Cymbal",
							"Gong",
							//"Maraca",
							"Tambourine",
							//"Xylophone",
							"Bagpipe",
							"Clarinet",
							"Flute",
							"Harmonica",
							"Pan flute",
							"Saxophone",
							"Trombone",
							"Trumpet",
							"Banjo",
							"Cello",
							"Guitar",
							"Harp",
							"Piano",
							"Violin",
							"Electric guitar",
							//"Synthesizer",
							"Skirt",
							"Bikini",
							//"Jumper",
							"Sneakers",
							"Hoodie",
							"Vest",
							"High heels",
							"Flip flops",
							"Tank top",
							"Singlet",
							"Boots",
							"Shorts",
							//"Umbrella",
							"Dress",
							"Hawaiian shirt",
							"Mittens",
							"Socks",
							"Swimsuit",
							"Trench coat",
							"Winter coat",
							"Straw hat",
							"Cap",
							"Scarf",
							"Glasses",
							"Sunglasses",
							"Hat",
							"Apron",
							"Tie",
							"Watch",
							"Necklace",
							"Bow tie",
							"Helmet",
							"Trousers",
							"Umbrella",
							"Gloves",
							"Purse",
							"Sweater",
							"Shirt",
							"Jeans",
							"Suit",
							"Dress shirt",
							"Shoes",
							"Cardigan",
							"Jacket",
							//"Sport shoes",
							"Waistcoat",
							"Polo shirt",
							"Jumper",
							//"Cargo pants",
							"Belt",
							"Bathrobe",
							"Sports jacket",
							"T-shirt",
							"Pullover",
							"Wallet",
							"Gym shoes",
							"Ring",
							"Earrings",
							"Perfume",
							"Bracelet",
							"Stockings",
							"Mirror",
							"Nail polish",
							"Lip gloss",
							"Eyeliner",
							"Lipstick",
							"Mascara",
							"Hand lotion",
							"Razor",
							"Tissues",
							"Toothbrush",
							"Sunscreen",
							"Deodorant",
							"Toothpaste",
							//"Cotton bud",
							"Tweezers",
							"Scissors",
							"Mouthwash",
							"Comb",
							"Hair spray",
							"Hair clip",
							"Hairbrush",
							"Hair band",
							"Diary",
							"Bottle",
							"Chewing gum",
							"Packet",
							"Photo",
							"Postcard",
							"Dictionary",
							"Book",
							"Credit card",
							"Money",
							"Key",
							"Phone",
							"Button",
							"Pen",
							"Pencil",
							//"Banknote",
							"Passport",
							"Notebook",
							"Medicine",
							"Battery",
							"Newspaper",
							"Alarm clock",
							"Ball",
							"Ballet shoes",
							"Bandage",
							"Barbecue grill",
							"Bath salt",
							"Bath towel",
							"Bed sheet",
							"Glass",
							"Bin",
							"Binoculars",
							"Blanket",
							"Blouse",
							"Board game",
							"Bottle opener",
							"Bowl",
							"Spoon",
							"Fork",
							"Bread basket",
							"Bread knife",
							"Briefcase",
							"Broom",
							"Bucket",
							"Cactus",
							"Flower",
							"Fruit",
							"Cake",
							"Calculator",
							"Calendar",
							"Camera",
							"Candle",
							"Canvas",
							"Car key",
							"Carafe",
							"Card game",
							"Cardboard",
							"Coffee",
							"Dinner",
							"Breakfast",
							"Chandelier",
							"Cheese grater",
							"Cheese",
							"Chopsticks",
							"Saw",
							"Cleaning product",
							"Clipboard",
							"Clock",
							"Clothes hanger",
							"Clothes iron",
							"Coffee filter",
							"Coffee pot",
							"Coloring book",
							"Combination lock",
							"Compass",
							"Compost bin",
							"Computer",
							"Computer mouse",
							"Contact lens",
							"Cork",
							"Corkscrew",
							"Corset",
							"Cough medicine",
							"Crayons",
							//"Cream jug",
							"Crêpe pan",
							//"Crochet hook",
							"Cup",
							"Curling iron",
							"Curtains",
							"Cutlery",
							"Cutting board",
							"Dart",
							"Dental floss",
							"Diaper",
							"Dice",
							"Dish towel",
							"Diving mask",
							"Dog collar",
							"Doll",
							"Dollhouse",
							"Domino",
							"Doorbell",
							"Doormat",
							"Doorstop",
							"Dough",
							"Dress shoes",
							"Dressing gown",
							"Drill",
							"Drum set",
							"Drum sticks",
							"Drying rack",
							"Dust cloth",
							"Duster",
							"Duvet",
							//"Earbud",
							"Earplug",
							"Egg box",
							"Eggs",
							"Egg cup",
							"Egg timer",
							"Elastic band",
							"Electric fan",
							"Envelope",
							"Eraser",
							"Eye drops",
							"Extension cord",
							"Face mask",
							"Fan",
							"Fire alarm",
							"Fish bowl",
							"Torch",
							"Floor wiper",
							"Flower vase",
							"Folding chair",
							"Funnel",
							"Glue stick",
							"Hammer",
							"Handbag",
							"Handkerchief",
							"Headphones",
							"Houseplant",
							"Ice cream",
							"Jar",
							"Jug",
							"Key chain",
							"Keyboard",
							"Ladder",
							"Laundy bin",
							"Magnifying glass",
							"Matchstick",
							"Mattress",
							"Mug",
							"Napkin",
							"Night gown",
							"Padlock",
							"Paper bag",
							"Paper",
							"Parasol",
							"Pill",
							"Plant pot",
							"Plate",
							"Raincoat",
							"Sewing needle",
							"Schampoo",
							"Shopping list",
							"Soap bar",
							"Syringe",
							"Tape measure",
							"Toy robot",
							"Washcloth",
							"Yarn"
						]
					};

				case "fi":
					return {
						see_colours: "Pystytkö erottamaan kaikki nämä värit?",
						yes: "Kyllä pystyn",
						no: "En pysty kovin hyvin",
						instructions: {
							t0_button: 'Klikkaa tästä ohjeeseen',
							t1: 'Alla näet kaksi sanaa ja värin',
							t2: 'Ensimmäinen sana on paikka (Metsä)<br>ja toinen sana on asia (Kirja)',
							a_place: 'Metsä',
							an_object: 'Kirja',
							t3: 'Tehtäväsi on muistaa mikä asia ja väri liittyvät paikkaan',
							t4: 'Kuvittele esimerkiksi iso kirkkaan keltainen kirja keskellä metsää',
							t5: 'Harjoituksessa paikka annetaan vihjeenä, ja sinun tehtäväsi on palauttaa mieleen paikkaan liittyvä esine ja väri'
						},
						training: {
							colours_correct: (num) => {
								return `Oikeita värejä: ${num}`;
							},
							objects_correct: (num) => {
								return `Oikeita esineitä: ${num}`;
							},
							pick_colour_and_object: 'Valitse oikea väri ja esine',
						},
						places: [
							"Sänky",
							"Penkki",
							"Tuoli",
							"Sohva",
							"Kaappi",
							"Rantatuoli",
							"Kirjoituspöytä",
							"Arkistokaappi",
							"Pukukaappi",
							"Yöpöytä",
							"Hylly",
							"Ruokapöytä",
							"Vaatekaappi",
							"Sohvapöytä",
							"Baarijakkara",
							"Laatikosto",
							"Lentokenttäaula",
							"Ullakko",
							"Auditorio",
							"Juhlasali",
							"Kellari",
							"Makuuhuone",
							"Biljardihuone",
							"Kahvila",
							"Vankiselli",
							"Kirkon portaat",
							"Luokkahuone",
							"Komero",
							"Tietokoneluokka",
							"Konferenssihuone",
							"Valvontahuone",
							"Käytävä",
							"Oikeussali",
							"Krypta",
							"Luola",
							"Pukeutumishuone",
							"Tyrmä",
							"Eteinen",
							"Välinevarasto",
							"Ruokakellari",
							"Kasvihuone",
							"Sali",
							"Kotiteatteri",
							"Hääsviitti",
							"Keittiö",
							"Olohuone",
							"Aula",
							"Parvi",
							"Observatorio",
							"Toimisto",
							"Kesäkeittiö",
							"Ruokakomero",
							"Patio",
							"Kuisti",
							"Postihuone",
							"Tulostushuone",
							"Sauna",
							"Serverihuone",
							"Näyttelytila",
							"Henkilökunnan tila",
							"Opiskelijatila",
							"Studio",
							"Työhuone",
							"Sviitti",
							"Odotustila",
							"Viinikellari",
							"Työpaja",
							"Kirkko",
							"Museo",
							"Kirjasto",
							"Sairaala",
							"Terveyskeskus",
							"Koulu",
							"Teatteri",
							"Urheiluhalli",
							"Juna-asema",
							"Ravintola",
							"Elokuvateatteri",
							"Café",
							"Huoltoasema",
							"Lentokenttä",
							"Ruokakauppa",
							"Vaatekauppa",
							"Konserttisali",
							"Leipomo",
							"Puisto",
							"Poliisiasema",
							"Paloasema",
							"Huvila",
							"Palatsi",
							"Mökki",
							"Townhouse",
							"Iglu",
							"Teltta",
							"Hytti",
							"Hotelli",
							"Hostelli",
							"Linna",
							"Kasino",
							"Barrikadi",
							"Silta",
							"Bussipysäkki",
							"Autokatos",
							"Savupiippu",
							"Radiotorni",
							"Torni",
							"Pato",
							"Satama",
							"Maailmanpyörä",
							"Portti",
							"Monumentti",
							"Laituri",
							"Rautatie",
							"Suojapaikka",
							"Tie",
							"Vuoristorata",
							"Siilo",
							"Uima-allas",
							"Juna",
							"Tunneli",
							"Vesitorni",
							"Majakka",
							"Tehdas",
							"Voimalaitos",
							"Puhdistamo",
							"Varasto",
							"Palvelutalo",
							"Makuusali",
							"Vankila",
							"Autopesula",
							"Huvipuisto",
							"Keilarata",
							"Areena",
							"Palvelinkeskus",
							"Laboratorio",
							"Puhelinpalvelukeskus",
							"Navetta",
							"Talli",
							"Arkisto",
							"Yliopisto",
							"Orpokoti",
							"Yhteisötila",
							"Alttari",
							"Temppeli",
							"Kaupungintalo",
							"Konsulaatti",
							"Suurlähetystö",
							"Postitoimisto",
							"Venevaja",
							"Telakka",
							"Lauttaterminaali",
							"Autotalli",
							"Autoparkki",
							"Parkkipaikka",
							"Kuntosali",
							"Sotilastukikohta",
							"Katos",
							"Lähde",
							"Pensasaita",
							"Puistokatu",
							"Nurmi",
							"Aita",
							"Jalkakäytävä",
							"Puumaja",
							"Pyöräkaista",
							"Lahjapuoti",
							"Supermarketti",
							"Ostoskeskus",
							"Putiikki",
							"Rautakauppa",
							"Eläinkauppa",
							"Eläintarha",
							"Kaupunki",
							"Kylä",
							"Mäki",
							"Ranta",
							"Laakso",
							"Järvi",
							"Niitty",
							"Pelto",
							"Joki",
							"Vuori",
							"Metsä",
							"Aavikko",
							"Viidakko",
							"Lahti",
							"Puro",
							"Niemimaa",
							"Meri",
							"Savanni",
							"Luoto",
							"Rinne",
							"Suo",
							"Kallio",
							"Jyrkänne",
							"Seinä",
							"Lattia",
							"Sisäkatto",
							"Katto",
							"Ikkuna",
							"Ovi",
							"Terassi",
							"Parveke",
							"Portaikko",
							"Kotitoimisto",
							"Vierashuone",
							"Säilytystila",
							"Garage",
							"Ruokailuhuone",
							"Lastenhoitohuone",
							"Leikkihuone",
							"Pyykkihuone",
							"Harrastustila",
							"Kukkula",
							"Lato",
							"Aitta",
							"Saari",
							"Erämaa",
							"Työmaa",
							"Tunturi",
							"Kenttä",
							"Jalkapallokenttä",
							"Jäähalli",
							"Pururata",
							"Polku",
							"Lampi",
						],
						objects: [
							"Lautta",
							"Auto",
							"Bussi",
							"Moottorupyörä",
							"Mopo",
							"Polkupyörä",
							"Rekka",
							"Moottorivene",
							"Purjevene",
							"Golfauto",
							"Veturi",
							"Kilpa-auto",
							"Raketti",
							"Ilmalaiva",
							"Kuumailmapallo",
							"Helikopteri",
							"Lentokone",
							"Skootteri",
							"Skeittilauta",
							"Traktori",
							"Kanootti",
							"Sukellusvene",
							"Surffilauta",
							"Ambulanssi",
							"Paloauto",
							"Poliisiauto",
							"Soutuvene",
							"Muurahainen",
							"Antilooppi",
							"Vyötiäinen",
							"Biisoni",
							"Hämähäkki",
							"Karhu",
							"Paviaani",
							"Koppakuoriainen",
							"Majava",
							"Lintu",
							"Perhonen",
							"Lepakko",
							"Kameli",
							"Toukka",
							"Tuhatjalkainen",
							"Kameleontti",
							"Gepardi",
							"Kana",
							"Simpanssi",
							"Torakka",
							"Rapu",
							"Lehmä",
							"Kurki",
							"Aasi",
							"Koira",
							"Peura",
							"Delfiini",
							"Ankka",
							"Kotka",
							"Ankerias",
							"Elefantti",
							"Hirvi",
							"Haukka",
							"Flamingo",
							"Kärpänen",
							"Kettu",
							"Sammakko",
							"Kirahvi",
							"Vuohi",
							"Hanhi",
							"Heinäsirkka",
							"Gorilla",
							"Marsu",
							"Hamsteri",
							"Hevonen",
							"Jänis",
							"Hyeena",
							"Jaguaari",
							"Sakaali",
							"Meduusa",
							"Kenguru",
							"Kobra",
							"Koala",
							"Leppäkerttu",
							"Leijona",
							"Laama",
							"Leopardi",
							"Lisko",
							"Hummeri",
							"Apina",
							"Hiiri",
							"Harakka",
							"Myyrä",
							"Oranki",
							"Mustekala",
							"Strutsi",
							"Osteri",
							"Riikinkukko",
							"Pingviini",
							"Papukaija",
							"Pelikaani",
							"Sika",
							"Puuma",
							"Pulu",
							"Piraija",
							"Vesinokkaeläin",
							"Rusakko",
							"Rotta",
							"Sarvikuono",
							"Kalkkarokäärme",
							"Punarinta",
							"Lohi",
							"Merileijona",
							"Merihevonen",
							"Hai",
							"Katkarapu",
							"Etana",
							"Käärme",
							"Orava",
							"Meritähti",
							"Joutsen",
							"Sardiini",
							"Skorpioni",
							"Hylje",
							"Haisunäätä",
							"Laiskiainen",
							"Varpunen",
							"Lammas",
							"Tiikeri",
							"Maakilpikonna",
							"Kilpikonna",
							"Tonnikala",
							"Mursu",
							"Ampiainen",
							"Susi",
							"Villisika",
							"Seepra",
							"Jääkarhu",
							"Pöllö",
							"Pääskynen",
							"Kimalainen",
							"Sirkka",
							"Kissa",
							"Krokotiili",
							"Sudenkorento",
							"Mato",
							"Siili",
							"Muuli",
							"Satakieli",
							"Fasaani",
							"Salamanteri",
							"Näätä",
							"Gongi",
							"Tamburiini",
							"Säkkipilli",
							"Klarinetti",
							"Huilu",
							"Harmonikka",
							"Panhuilu",
							"Saksofoni",
							"Pasuuna",
							"Trumpetti",
							"Banjo",
							"Sello",
							"Kitara",
							"Harppu",
							"Piano",
							"Viulu",
							"Sähkökitara",
							"Hame",
							"Bikinit",
							"Lenkkitossut",
							"Huppari",
							"Liivi",
							"Korkokengät",
							"Varvassandaalit",
							"Käsilaukku",
							"Toppi",
							"Aluspaita",
							"Saappaat",
							"Shortsit",
							"Mekko",
							"Havaijipaita",
							"Lapaset",
							"Sukat",
							"Uimapuku",
							"Trenssi takki",
							"Talvitakki",
							"Olkihattu",
							"Lippalakki",
							"Huivi",
							"Lasit",
							"Aurinkolasit",
							"Hattu",
							"Esiliina",
							"Kravatti",
							"Rannekello",
							"Kaulakoru",
							"Solmio",
							"Kypärä",
							"Saapikkaat",
							"Housut",
							"Sateenvarjo",
							"Hanskat",
							"Laukku",
							"Pusero",
							"Paita",
							"Farkut",
							"Puku",
							"Kauluspaita",
							"Kengät",
							"Villatakki",
							"Takki",
							"Poolopaita",
							"Villapaita",
							"Vyö",
							"Kylpytakki",
							"Bleiseri",
							"T-paita", "Lompakko",
							"Voimistelutossut",
							"Sormus",
							"Korvakorut",
							"Hajuvesi",
							"Rannekoru",
							"Polvisukat",
							"Peili",
							"Kynsilakka",
							"Huulikiilto",
							"Rajauskynä",
							"Huulipuna",
							"Ripsiväri",
							"Käsivoide",
							"Partahöylä",
							"Talouspaperi",
							"Hammasharja",
							"Aurinkovoide",
							"Deodorantti",
							"Hammastahna",
							"Pinsetit",
							"Sakset",
							"Suuvesi",
							"Hammaslanka",
							"Kampa",
							"Hiuslakka",
							"Hiuspinni",
							"Hiusharja",
							"Hiuspanta",
							"Päiväkirja",
							"Pullo",
							"Purukumi",
							"Paketti",
							"Valokuva",
							"Postikortti",
							"Sanakirja",
							"Kirja",
							"Luottokortti",
							"Raha",
							"Avain",
							"Puhelin",
							"Nappi",
							"Kynä",
							"Lyijykynä",
							"Passi",
							"Muistikirja",
							"Lääke",
							"Paristo",
							"Sanomalehti",
							"Herätyskello",
							"Pallo",
							"Balettitossut",
							"Laastari",
							"Grilli",
							"Kylpysuola",
							"Kylpypyyhe",
							"Lakana",
							"Lasi",
							"Roskakori",
							"Kiikarit",
							"Peitto", "Lautapeli",
							"Pullonavaaja",
							"Kulho",
							"Lusikka",
							"Haarukka",
							"Leipäkori",
							"Leipäveitsi",
							"Salkku",
							"Luuta",
							"Ämpäri",
							"Kaktus",
							"Kukka",
							"Hedelmä",
							"Kakku",
							"Laskin",
							"Kalenteri",
							"Kamera",
							"Kynttilä",
							"Kangas",
							"Auton avaimet",
							"Karahvi",
							"Korttipeli",
							"Pahvi",
							"Kahvi",
							"Päivällinen",
							"Aamupala",
							"Kattokruunu",
							"Juustoraastin",
							"Juusto",
							"Syömäpuikot",
							"Saha",
							"Puhdistusaine",
							"Kello",
							"Henkari",
							"Silitysrauta",
							"Suodatinpussi",
							"Kahvipannu",
							"Värityskirja",
							"Yhdistelmälukko",
							"Kompassi",
							"Kompostiastia",
							"Tietokone",
							"Tietokoneen hiiri",
							"Piilolinssit",
							"Korkki",
							"Korkkiruuvi",
							"Korsetti",
							"Yskänlääke",
							"Väriliitu",
							"Lettupannu",
							"Kuppi",
							"Kiharrin",
							"Verhot",
							"Aterimet",
							"Leikkuulauta",
							"Tikka",
							"Vaippa",
							"Noppa",
							"Astiapyyhe",
							"Sukellusmaski",
							"Kaulapanta",
							"Nukke",
							"Nukketalo",
							"Dominopa",
							"Ovikello",
							"Ovimatto",
							"Ovikiila",
							"Taikina",
							"Puvunkengät",
							"Aamutakki",
							"Pora",
							"Rummut",
							"Rumpukapulat",
							"Kuivausteline",
							"Pölyliina",
							"Pölyhuisku",
							"Täkki",
							"Korvatulppa",
							"Munakenno",
							"Muna",
							"Munakuppi",
							"Munakello",
							"Kuminauha",
							"Tuuletin",
							"Kirjekuori",
							"Pyyhekumi",
							"Silmätipat",
							"Jatkojohto",
							"Kasvonaamio",
							"Puhallin",
							"Palohälytin",
							"Akvaario",
							"Soihtu",
							"Lattialasta",
							"Kukkamaljakko",
							"Taittotuoli",
							"Suppilo",
							"Liimapuikko",
							"Vasara", "Nenäliina",
							"Kuulokkeet",
							"Huonekasvi",
							"Jäätelö",
							"Lasipurkki",
							"Kannu",
							"Avainketju",
							"Näppäimistö",
							"Tikapuut",
							"Pyykkikori",
							"Suurennuslasi",
							"Tulitikku",
							"Patja",
							"Muki",
							"Servetti",
							"Yöpuku",
							"Munalukko",
							"Paperipussi",
							"Paperi",
							"Auringonvarjo",
							"Pilleri",
							"Kukkaruukku",
							"Lautanen",
							"Sadetakki",
							"Ompeluneula",
							"Shampoo",
							"Ostoslista",
							"Palasaippua",
							"Ruisku",
							"Mittanauha",
							"Lelurobotti",
							"Pesuliina",
							"Lanka",
							"Pipo",
							"Lämpömittari",
							"Kumisaappaat",
						]
					};

			}
		}
	}
}
