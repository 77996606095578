<template>
	<path
		v-show="playing"
		id="arc"
		opacity="0"
		fill="none"
		stroke="#FFFFFF"
		stroke-width="100"
	/>
</template>

<script>
import gsap from "gsap";

export default {
	name: "count-down",
	data: function () {
		return {
			playing: false,
		};
	},
	methods: {
		play(whenDone, delay = 0.1, duration = 1.0) {
			this.playing = true;
			const arc = document.getElementById("arc");

			arc.setAttribute("d", describeArc(0, 0, 100, 0, 360));

			var dummy = { prop: 0, opacity: 0 };

			var t1 = gsap.timeline();
			t1.to(dummy, {
				duration: 0.5 * duration,
				opacity: 0.8,
				//delay: 0.25,
				delay: delay,
				ease: "none",
				onUpdate: () => {
					arc.setAttribute("opacity", dummy.opacity);
				},
			});
			t1.to(dummy, {
				//duration: 3,
				duration: 0.5 * duration,
				prop: 1,
				//delay: 3,
				delay: 0.1,
				ease: "none",
				onUpdate: () => {
					arc.setAttribute(
						"d",
						describeArc(0, 0, 100, dummy.prop * 360, 360)
					);
					arc.setAttribute(
						"opacity",
						Math.min(0.8, 1.4 - dummy.prop)
					);
				},
				onComplete: () => {
					this.playing = false;
					arc.setAttribute("opacity", 0);
					whenDone();
				},
			});
		},
	},
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y,
	].join(" ");

	return d;
}
</script>
