export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
					return {
						instructions: {
							a: (tot) => {
								if (tot == 1) return `Siffror kommer att visas, en i taget. Din uppgift är att minnas och återge den <font color="#FFCC66">sista</font> siffran.`;
								else return `Siffror kommer att visas, en i taget. Din uppgift är att minnas och återge de <font color="#FFCC66">${tot} sista</font> siffrorna.`;
							},
							b: "Tryck här för att se en instruktion ➤",
							c: "Nu ska jag visa!",
							d1: 'I det här exemplet ska <font color="#FFCC66">4 siffror</font> återges',
							d2: "Snart kommer ett antal siffror visas",
							d3: `Uppgiften är att återge de <font color="#FFCC66">4 sista</font>`,
							e: "4 rätt!",
							f: "Tryck på den stora pilen för att börja!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Kom ihåg den <font color="#FFCC66">sista</font> siffran';
								else return `Kom ihåg de <font color="#FFCC66">${num} sista</font> siffrorna`;
							},
							respond: (num) => {
								if (num == 1) return 'Vilken siffra var det som visades sist?';
								else return `Återge de <font color="#FFCC66">${num} sista</font> siffrorna, i samma ordning`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt siffra';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Omgång ${num} av ${tot}`;
							}
						}
					};
				case "zh":
					return {
						instructions: {
							a: (tot) => {
								if (tot == 1) return `一些数字将一个接一个出现。请记住以及回想 <font color="#FFCC66">最后一个</font> 数字。`;
								else return `一些数字将一个接一个出现。请记住以及回想 <font color="#FFCC66">最后${tot}个</font> 数字。`;
							},
							b: "点击这里看示范！ ➤",
							c: " ",
							d1: " ",
							d2: "一些数字将会出现",
							d3: `请记住 <font color="#FFCC66">最后4个</font> 数字`,
							e: "4个正确！",
							f: "点击大箭头准备开始！",
						},
						forward: {
							task: (num) => {
								if (num == 1) return '请记住 <font color="#FFCC66">最后一个</font> 数字';
								else return `请记住 <font color="#FFCC66">最后 ${num}个 </font> 数字`;
							},
							respond: (num) => {
								if (num == 1) return '最后一个数字是什么？';
								else return `请记住 <font color="#FFCC66">最后 ${num} 个</font> 数字`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return '数字不正确';
									else return '正确！';
								} else {
									if (num == 0) return `这次都不正确`;
									else if (num == tot) return `完美！`;
									else return `${num}/${tot}个正确`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return ` ${num}/${tot}个回合`;
							}
						}
					};
				case "en":
				default:
					return {
						instructions: {
							a: (tot) => {
								if (tot == 1) return `Digits will appear one at a time. Your task is to remember and recall the <font color="#FFCC66">last</font> digit.`;
								else return `Digits will appear one at a time. Your task is to remember and recall the <font color="#FFCC66">${tot} last</font> digits.`;
							},
							b: "Press here to see an instruction ➤",
							c: " ",
							d1: " ",
							d2: "Soon some digits will be presented",
							d3: `Your task is to remember the <font color="#FFCC66">4 last</font> digits`,
							e: "4 correct!",
							f: "Press the big arrow to begin!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Remember the <font color="#FFCC66">last</font> digit';
								else return `Remember the <font color="#FFCC66">${num} last</font> digits`;
							},
							respond: (num) => {
								if (num == 1) return 'Which was the last digit?';
								else return `Recall the <font color="#FFCC66">${num} last</font> digits`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'That was the wrong digit';
									else return 'Correct!';
								} else {
									if (num == 0) return `No correct this time.`;
									else if (num == tot) return `Perfect!`;
									else return `${num} of ${tot} correct`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Round ${num} of ${tot}`;
							}
						}
					};
			}
		}
	}
}
