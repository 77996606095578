export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
					return {
						question: "Hur bra kunde du koncentrera dig under passet?",
						a1: "1. Inte alls",
						a2: "2.",
						a3: "3.",
						a4: "4.",
						a5: "5. Mycket bra",
					}
				case "fi":
					return {
						question: "Kuinka hyvin pystyt keskittymään tämän päiväiseen harjoitteluun?",
						a1: "1. Erittäin huonosti",
						a2: "2.",
						a3: "3.",
						a4: "4.",
						a5: "5. Erittäin hyvin",
					}
				case "zh":
					return {
						question: "您对今天的认知训练的专注力好吗？",
						a1: "1. 不好",
						a2: "2.",
						a3: "3.",
						a4: "4.",
						a5: "5. 非常好",
					}
				case "en":
				default:
					return {
						question: "How well could you concentrate on today's training?",
						a1: "1. Not at all",
						a2: "2.",
						a3: "3.",
						a4: "4.",
						a5: "5. Very well",
					}
			}
		}
	}
}
