<template>
	<g>
		<transition name="fade">
			<itemSet ref="itemSet" />
		</transition>
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		<training v-if="this.$store.state.taskContainer.taskPlaying" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/ipark/updating_letters/lang.js";
import instructions from "@/v/ipark/updating_letters/instructions.vue";
import training from "@/v/ipark/updating_letters/training.vue";
import itemSet from "@/v/ipark/updating_letters/itemSet.vue";

export default {
	name: "ipark_updating_letters",
	//genväg-alias:
	local: store.state.ipark_updating_letters,
	components: {
		instructions,
		training,
		itemSet,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	mounted() {
		/*
		var level = this.$store.state.progress.updating_letters_level || 0;
		const params = this.calculateParameters(level);
		store.commit("ipark_updating_letters_initLevel", level);
		store.commit("ipark_updating_letters_setInstructionsViewed", true);
		store.commit("ipark_updating_letters_resetitemSet");
		store.commit("ipark_updating_letters_showitemSet");
		*/
		store.commit("taskPhase", 0);
		store.commit("setTaskMode", "ready");
	}
};
</script>

<style lang="stylus"></style>
