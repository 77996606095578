<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="this.phase == 1 || this.phase == 2"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.task(4)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="this.phase == 3 || this.phase == 4"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.respond(4)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<g
				v-if="
					this.phase > 6 ||
					(this.phase == 0 &&
						this.$store.state.test_updating_digits.round > 0)
				"
			>
				<foreignObject x="-600" y="-495" width="1200" height="99">
					<div class="txtbox">
						<span
							v-html="
								$parent.text.results.numCorrect(
									this.$store.state.test_updating_digits
										.numCorrect,
									4
								)
							"
						/>
					</div>
				</foreignObject>
				<timeline
					v-bind:stages="10"
					v-bind:pos="this.$store.state.test_updating_digits.round"
				/>
			</g>
		</transition>
	</g>
</template>

<script>
//import stat from "@/stat.js";
import { store } from "@/store.js";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";

export default {
	name: "training",
	components: {
		timeline,
	},
	watch: {
		phase: function (/*newPhase*/) {
			this.update();
		},
	},
	methods: {
		update() {
			switch (this.$store.state.taskContainer.taskPhase) {
				case 0:
				case 1: // Din uppgift...
					store.commit("test_updating_digits_reset_item_set");
					setTimeout(
						() =>
							this.$parent.$parent.$refs.countDown.play(() =>
								store.commit("taskPhase", 2)
							),
						500
					);
					break;
				case 2: // Din uppgift...
					//sel.classList.remove("cursorHide");
					//sel.classList.add("cursorPointer");
					store.commit("test_updating_digits_show_item_set");
					this.$parent.$refs.itemSet.presentList(
						this.sessionData.rounds[
							store.state.test_updating_digits.round
						].items,
						/*store.state.test_updating_digits.lists[
						store.state.test_updating_digits.round
					],*/
						() => store.commit("taskPhase", 3)
					);
					break;
				case 3: // interlude
					/*setTimeout(
					() => store.commit("taskPhase", 4),
					100
				);*/
					store.commit("taskPhase", 4);
					break;
				case 4: // respond
					//sel.classList.add("cursorPointer");
					//sel.classList.remove("cursorHide");
					store.commit("test_updating_digits_acceptClicks", true);
					this.round_answer_start_time = Date.now();
					break;
				case 5:
					store.commit(
						"test_updating_digits_showResponseItems",
						false
					);
					setTimeout(() => store.commit("taskPhase", 6), 2000);
					break;
				case 6: // show round results
					var roundResults =
						this.$parent.$refs.itemSet.evalResponse();
					var r = store.state.test_updating_digits.round - 1;
					this.sessionData.rounds[r].resp = roundResults.response;
					this.sessionData.rounds[r].corr = roundResults.correct;
					this.sessionData.rounds[r].numCorr =
						roundResults.numCorrect;
					this.sessionData.rounds[r].time =
						Date.now() - this.round_answer_start_time;
					if (roundResults.numCorrect == 4)
						this.sessionData.lists_correct++;
					this.sessionData.sum_correct += roundResults.numCorrect;
					break;
				case 7: // show round results, 2
					if (
						store.state.test_updating_digits.round <
						this.sessionData.rounds.length
					) {
						store.commit("showContinueButton");
					} else {
						setTimeout(() => store.commit("taskPhase", 9), 7000);
					}
					break;
				case 8:
					store.commit("setTaskMode", "playing");
					break;
				case 9: // done!
					this.saveSessionResults();
					break;
			}
		},
		createLists() {
			const numToRecall = 4;

			const types = store.state.test_updating_digits.itemTypes;

			var lists = [];
			//for (var n = 0; n < 16; ++n)
			//	lists.push(this.createRandomList(types, n, true));

			lists.push(this.createRandomList(types, 7, true));
			lists.push(this.createRandomList(types, 11, true));
			lists.push(this.createRandomList(types, 10, true));
			lists.push(this.createRandomList(types, 5, true));
			lists.push(this.createRandomList(types, 12, true));
			lists.push(this.createRandomList(types, 8, true));
			lists.push(this.createRandomList(types, 9, true));
			lists.push(this.createRandomList(types, 13, true));
			lists.push(this.createRandomList(types, 6, true));
			lists.push(this.createRandomList(types, 14, true));

			// shuffle
			/*var m = lists.length,
				t,
				i;
			while (m) {
				i = Math.floor(Math.random() * m--);
				t = lists[m];
				lists[m] = lists[i];
				lists[i] = t;
			}*/

			this.sessionData = {
				time_start: Date.now(),
				numToRecall: numToRecall,
				rounds: [],
				lists_correct: 0,
				sum_correct: 0,
			};

			for (var round = 0; round < lists.length; ++round) {
				this.sessionData.rounds.push({
					items: lists[round],
					length: lists[round].length,
					resp: [],
					corr: [],
					numCorr: 0,
				});
			}
			return { numToRecall, lists };
		},
		/**
		 * Retrieve a specified quantity of elements from an array, at random.
		 *
		 * @param {Array} set of values to select from.
		 * @param {Number} quantity of elements to retrieve.
		 * @param {Boolean} allow the same number to be returned twice.
		 * @return {Array} random elements.
		 */
		createRandomList(arr, quant, allowDuplicates) {
			if (arr.length === 0) {
				throw new Error("Empty array");
			} else if (quant > arr.length && !allowDuplicates) {
				throw new Error("Quantity requested exceeds size of array");
			}

			if (allowDuplicates === true) {
				var result = [],
					i;
				var n0,
					n1 = -1;
				for (i = 0; i < quant; i++) {
					do {
						n0 = arr[Math.floor(Math.random() * arr.length)];
					} while (n0 == n1);
					result[i] = n0;
					n1 = n0;
				}
				return result;
			} else {
				return this.shuffle(arr).slice(0, quant);
			}
		},
		saveSessionResults() {
			const total_time = Date.now() - this.sessionData.time_start;

			persist.log("results", {
				sum_correct: this.sessionData.sum_correct,
				num_to_recall: 4,
				rounds: this.sessionData.rounds,
				time_start: this.sessionData.time_start,
				total_time: Math.trunc(total_time),
				lists_correct: this.sessionData.lists_correct,
			});

			store.commit("setTaskMode", "clickToExit");
		},
	},
	sessionData: {},
	round_answer_start_time: 0,
	//lists_correct: 0,
	computed: {
		phase: function () {
			return this.$store.state.taskContainer.taskPhase;
		},
	},
	mounted() {
		store.commit("test_updating_digits_init_session", this.createLists());
		this.update();
	},
};
</script>
