export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "fi":
					return {
						ins1: "Katso näitä kuvia",
						ins2: "Mikä näistä",
						ins3: "sopii tähän?",
						ins4: "Aivan. Otetaan seuraava!",

						look: "Katsotaan vielä kerran",

						fail_a: "Kaikki nämä perhoset ovat sinisiä",
						fail_b: "Nämä kaksi lamppua ovat keltaisia",
						fail_c: "Kaikki nämä ruudut ovat sinisiä<br>ja niissä on viiva poikittain tällä tavalla.",

						corr_a: "Tämä on myös sininen",
						corr_b1: "Tässä on vihreä lamppu",
						corr_b2: "Meidän täytyy valita vihreä lamppu, kuten tämä tässä.",
						corr_c: "Tämä on myös sininen ja<br>siinä on viiva poikittain.",

						point: "Siksi tämä sopii tähän.",
						next_failed: "Mennään seuraavaan tehtävään!",
						done: "Tehtävä suoritettu!",
					};
				case "sv":
				default:
					return {
						ins1: "Titta på de här bilderna",
						ins2: "Vilken av de här",
						ins3: "passar in här?",
						ins4: "Javisst. Nu tar vi nästa!",

						look: "Vi tittar en gång till",

						fail_a: "Alla de här fjärilarna är blå",
						fail_b: "De här två lamporna är gula",
						fail_c: "Alla de här rutorna är blå och<br>har ett streck tvärs över på det här sättet.",

						corr_a: "Den här är också blå",
						corr_b1: "Här är en grön lampa",
						corr_b2: "Vi ska ha en sådan grön lampa som den här.",
						corr_c: "Den här är också blå och<br>har samma streck tvärs över.",

						point: "Därför passar den in här.",
						next_failed: "Nu tar vi nästa!",
						done: "Klart!",
					};
			}
		}
	}
}


