<template>
	<g>
		<card ref="c0" :idx="0" />
		<card ref="c1" :idx="1" />
		<card ref="c2" :idx="2" />
		<card ref="c3" :idx="3" />

		<card ref="c4" :idx="4" />
		<card ref="c5" :idx="5" />
		<card ref="c6" :idx="6" />
		<card ref="c7" :idx="7" />

		<card ref="c8" :idx="8" />
		<card ref="c9" :idx="9" />
		<card ref="c10" :idx="10" />
		<card ref="c11" :idx="11" />

		<card ref="c12" :idx="12" />
		<card ref="c13" :idx="13" />
		<card ref="c14" :idx="14" />
		<card ref="c15" :idx="15" />

		<card ref="c16" :idx="16" />
		<card ref="c17" :idx="17" />
		<card ref="c18" :idx="18" />
		<card ref="c19" :idx="19" />

		<card ref="c20" :idx="20" />
		<card ref="c21" :idx="21" />
		<card ref="c22" :idx="22" />
		<card ref="c23" :idx="23" />

		<transition name="fade">
			<text
				v-if="this.$store.state.taskContainer.taskMode == 'roundScore'"
				y="-60"
				x="0"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="90"
				fill="#FFFFFFEE"
			>{{'Points: ' + Math.floor(this.round_score)}}</text>
		</transition>
		<transition name="fade">
			<text
				v-if="this.$store.state.taskContainer.taskMode == 'roundScore'"
				y="60"
				x="0"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="90"
				fill="#FFFFFFEE"
			>{{'Time: ' + Math.floor(this.round_time) + ' s'}}</text>
		</transition>
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/mind_ad/episodic_memory_spatial/lang.js";
import card from "@/v/mind_ad/episodic_memory_spatial/card.vue";
import instructions from "@/v/mind_ad/episodic_memory_spatial/instructions.vue";
import gsap from "gsap";
import persist from "@/persist.js";

/*
		<g v-if="this.$store.state.taskContainer.taskMode == 'playing'">
			<text
				y="-300"
				x="600"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="30"
				fill="#FFFFFF"
			>{{'turns: ' + this.num_turns}}</text>
			<text
				y="-250"
				x="600"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="30"
				fill="#FFFFFF"
			>{{'mistakes: ' + this.num_mistakes}}</text>
		</g>
*/


export default {
	name: "mind_ad_episodic_memory_spatial",
	components: {
		card,
		instructions,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
		balloon_width() {
			return 618 * this.balloon_size;
		},
	},
	data: () => { return {
		start_time: 0,
		round_start_time: 0,
		rounds: [],
		num_turns: 0,
		num_cards: 24,
		num_cards_found: 0,
		card_a: null,
		card_b: null,
		accept_clicks: false,
		num_mistakes: 0,
		round_score: 0,
		session_score: 0,
		show_score: 0,
		rounds_done: 0,
		round_time: 0,
	}},
	methods: {
		x_location(idx) {
			const xp = [2,3,2,3,1,1,4,4,2,3,2,3,0,0,5,5,0,1,4,5,0,1,4,5];
			return (xp[idx]-2.5) * 170;
		},
		y_location(idx) {
			const yp = [1,1,2,2,1,2,1,2,0,0,3,3,1,2,1,2,3,3,3,3,0,0,0,0];
			return (yp[idx]-1.5) * 170;
		},
		init_cards(num) {
			this.num_turns = 0;
			this.num_mistakes = 0;
			this.round_score = 0;
			this.num_cards = num;
			var i;
			for (i = 0; i < 24; ++i) {
				if (i < this.num_cards) {
					this.cards[i].show();
					this.cards[i].sign = Math.trunc(i * 0.5);
					this.cards[i].move_to(this.x_location(i), this.y_location(i));
					this.cards[i].num_turns = 0;
					this.cards[i].num_mistakes = 0;
				} else {
					this.cards[i].visible = false;
				}
			}
			var m = this.num_cards, t, i2;

			while (m) {
				i2 = Math.floor(Math.random() * m--);
				//console.log('shuffle ' + m + ' to ' + i2);

				t = this.cards[m].sign;
				this.cards[m].sign = this.cards[i2].sign;
				this.cards[i2].sign = t;
			}
		},
		shuffle_cards() {
			
			var delay = 0.1;
			
			for (var c = 0; c < this.num_cards; ++c) {
				delay += 0.05;
				if (this.cards[c].is_up && this.cards[c].visible) {
					this.cards[c].show();
					this.cards[c].turn(false, delay);
				}
				//if (this.cards[c].is_up) this.cards[c].turn(false, 0.2 + 0.1*c);
			}
			delay += 0.2;
			//delay += this.num_cards * 0.25;
			//delay += 5;

			const spread = 200 + this.num_cards * 10;

			var i;

			var sel; sel = "#c" + this.idx;
			var tl; tl = gsap.timeline();
			for (i = 0; i < this.num_cards; ++i) {
				delay += 0.05;
				sel = "#c" + i;
				tl = gsap.timeline();
				tl.to(sel, {
					x: Math.random() * spread - 0.5 * spread,
					y: Math.random() * spread - 0.5 * spread,
					duration: 0.5,
					delay: delay,
				});
				tl.to(sel, {
					x: Math.random() * spread - 0.5 * spread,
					y: Math.random() * spread - 0.5 * spread,
					duration: 0.5
				});
				tl.to(sel, {
					x: this.x_location(i),
					y: this.y_location(i),
					duration: 0.5
				});
			}
			delay += 3 * 0.5;
			return delay;
		},
		shuffle(array) {
			var m = array.length,
				t, i;

			while (m) {
				i = Math.floor(Math.random() * m--);

				t = array[m];
				array[m] = array[i];
				array[i] = t;
			}

			return array;
		},
		card_turned(card) {
			if (this.accept_clicks == false) return;
			if (card == this.card_a || card == this.card_b) return;

			if (this.num_turns == 0) {
				this.round_start_time = Date.now();
				if (this.rounds_done == 0)
					this.start_time = this.round_start_time;
				store.commit("setTaskMode", "playing");
			}

			if (card.sign == -1) {
				// ännu obestämd symbol!
			}
			
			this.num_turns += 1;
			card.num_turns += 1;

			if (this.card_a == null && this.card_b == null) {
				this.card_a = card;
				this.card_a.turn(true);
				//console.log('a: ' + this.card_a.idx);
			}
			else if (this.card_a != null && this.card_b == null) {
				this.card_b = card;
				this.card_b.turn(true);
				//console.log('a: ' + this.card_a.idx);
				//console.log('b: ' + this.card_b.idx);
				if (this.card_a.sign == this.card_b.sign) {
					//console.log('match!');
					this.card_a.mark_as_found();
					this.card_b.mark_as_found();
					// Gör nya klick omedelbart tillgängliga:
					this.card_a = null;
					this.card_b = null;
					this.num_cards_found += 2;
					if (this.num_cards_found >= this.num_cards) {
						this.accept_clicks = false;
						setTimeout(() => {
							this.round_complete();
						}, 3000);
					}

					// TODO: signalera Bra-Gjort med stjärna e.d. om
					// card_b senast vändes för länge sedan -- det betyder
					// att man då kom ihåg kortet från lång tid tillbaka.
				}
				else {
					// Räkna a och b separat eftersom det är ett misstag att glömma
					// vilken som helst av dem:
					/*if (this.card_a.num_turns > 1) {
						this.num_mistakes += 1;
					}
					if (this.card_b.num_turns > 1) {
						this.num_mistakes += 1;
					}*/

					//console.log('--------- A idx: ' + this.cards.indexOf(this.card_a));
					//var str = '';
					if (this.card_a.num_turns > 1 && this.card_b.num_turns > 1 && this.card_a.sign != this.card_b.sign) {
						// A och B är kända sedan tidigare -- båda glömda!
						this.num_mistakes += 2;
						this.card_a.num_mistakes += 1;
						this.card_b.num_mistakes += 1;
					}
					else if (this.card_b.num_turns > 1 && this.card_a.sign != this.card_b.sign)  {
						// A är nytt men passar inte med tidigare känt B -- B har glömts!
						this.num_mistakes += 1;
						this.card_b.num_mistakes += 1;
					}
					else {
						// Kanske glömt motsvarighet till A och/eller B som INTE vändes denna gång! Kan ge 1 eller 2 fel.
						for (var vv = 0; vv < this.num_cards; ++vv) {
							//str = 'idx: ' + vv + ' -- sign: ' + this.cards[vv].sign + ' -- turns: ' + this.cards[vv].num_turns + ' --  ';
							if (this.cards[vv].found)
								continue;
								//str += 'already found';
							else if (this.cards[vv] == this.card_a)
								continue;
								//str += 'A ';
							else if (this.cards[vv] == this.card_b)
								continue;
								//str += 'B ';
							else if (this.cards[vv].num_turns == 0)
								continue;
								//str += 'never turned';
							//else if (this.cards[vv].sign != this.card_a.sign)
							//	str += 'wrong sign -- ';
							else if (this.cards[vv].sign == this.card_a.sign){
								//str += 'MISTAKE, same sign as A';
								//console.log('idx: ' + vv + ' -- MISTAKE, same sign as A');
								this.num_mistakes += 1;
								this.cards[vv].num_mistakes += 1;
							}
							else if (this.cards[vv].sign == this.card_b.sign && this.card_b.num_turns > 1){
								//str += 'MISTAKE, same sign as B';
								//console.log('idx: ' + vv + ' -- MISTAKE, same sign as B');
								this.num_mistakes += 1;
								this.cards[vv].num_mistakes += 1;
							}
							//else str += '?';
							//console.log(str);
						}
					}
				}
			}
			else if (this.card_a != null && this.card_b != null) {
				this.card_a.turn(false, 0);
				this.card_b.turn(false, 0);
				this.card_a = card;
				this.card_a.turn(true);
				this.card_b = null;
			}
		},
		round_complete() {
			store.commit("setTaskMode", "roundScore");
			this.round_score = Math.max(0, this.num_cards - this.num_mistakes);
			this.session_score += this.round_score;

			this.round_time = (Date.now() - this.round_start_time)/1000;

			var result = {
				turns: this.num_turns,
				mistakes: this.num_mistakes,
				time: Date.now() - this.round_start_time,
				score: this.round_score,
				cards: this.num_cards,
			};
			this.rounds.push(result);

			this.rounds_done += 1;
			this.accept_clicks = false;
			if (this.num_mistakes < 2 && this.num_cards < 24) {
				this.num_cards += 2;
			}
			else if (this.num_mistakes > 4 && this.num_cards > 6) {
				this.num_cards -= 2;
			}
			if (this.num_cards < 6)
				this.num_cards = 6;
			else if (this.num_cards > 24)
				this.num_cards = 24;
			gsap.to(this, 1, {show_score: this.session_score, delay: 1});

			setTimeout(() => {
				if (this.rounds_done < 2) {
					this.between_rounds();
				} else {
					this.saveSessionResults();
				}
			}, 6000);
		},
		between_rounds() {
			const delay = this.shuffle_cards();
			setTimeout(() => {
				store.commit('setTaskMode', 'playing');
				this.new_round();
			}, 1000*delay);
		},
		new_round() {
			this.init_cards(this.num_cards);
			for (var i = 0; i < this.num_cards; ++i) {
				this.cards[i].show(true);
				this.cards[i].found = false;
				this.cards[i].is_up = false;
			}
			this.card_a = null;
			this.card_b = null;
			this.num_cards_found = 0;
			this.accept_clicks = true;
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			const done_num = this.$store.state.progress.episodic_memory_spatial_num || 0;
			persist.set_progress_data('episodic_memory_spatial_num', done_num + 1);
			persist.set_progress_data('episodic_memory_spatial_num_cards', this.num_cards);

			persist.log('results', {
				score: this.session_score,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				rounds: this.rounds,
			});

			persist.addToHistory('score', this.session_score).then(history => {
				store.commit("setSessionScores", history);
				store.commit('setTaskMode', 'sessionScore');
			});
		},
	},
	cards: [],
	mounted() {
		store.commit('setTaskMode', 'readyImmediateInstruct');
		store.commit("taskPhase", 0);
		this.cards = [
			this.$refs.c0,  this.$refs.c1,  this.$refs.c2,  this.$refs.c3,
			this.$refs.c4,  this.$refs.c5,  this.$refs.c6,  this.$refs.c7, 
			this.$refs.c8,  this.$refs.c9,  this.$refs.c10, this.$refs.c11, 
			this.$refs.c12, this.$refs.c13, this.$refs.c14, this.$refs.c15, 
			this.$refs.c16, this.$refs.c17, this.$refs.c18, this.$refs.c19, 
			this.$refs.c20, this.$refs.c21, this.$refs.c22, this.$refs.c23 ];

		this.num_cards = this.$store.state.progress.episodic_memory_spatial_num_cards || 6;
		//this.init_cards(this.num_cards);
		setTimeout(() => {
			this.new_round();
		}, 1500);
	}
};
</script>

<style lang="stylus">
	.mini-balloon-enter-active, .mini-balloon-leave-active {
		transition: all 0.75s;
	}
	.mini-balloon-enter, .mini-balloon-leave-to {
		opacity: 0;
		transform: translate(350px, 10px);
	}
</style>
