<template>
	<g>
		<grid ref="grid" />
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		<play v-if="this.$store.state.taskContainer.taskPlaying" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_span_spatial/lang.js";
import instructions from "@/v/test_span_spatial/instructions.vue";
import play from "@/v/test_span_spatial/play.vue";
import grid from "@/v/shared/span_grid.vue";


export default {
	name: "test_span_spatial",
	components: {
		instructions,
		play,
		grid,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	mounted() {
		store.commit('setTaskMode', 'ready');
		store.commit("taskPhase", 0);
		this.$refs.grid.set_grid(0);
	}
};
</script>
