export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
			default:
				return {
					menu_help: "Testuppgifter",
					span_complex_a_label: 'A',
					span_complex_b_label: 'B',
					span_complex_math_a_label: 'C',
					span_complex_math_b_label: 'D',
					spatialSpanLabel: "Spatial Span",
					lettersUpdatingLabel: "Letter Span",
				};
			}
		}
	}
}
