<template>
	<g>
		<box
			v-for="(value, index) in this.numCategories"
			:key="index"
			:y="-60"
			:x="boxPos(index)"
			:name="categoryNames[index]"
			:boxIndex="index"
		/>
		<currentWord :x="0" :y="-300" />
		<transition name="fade">
			<foreignObject
				v-if="this.$store.state.taskContainer.taskMode == 'playing' && this.$store.state.taskContainer.taskPhase == 3"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.play.respond(this.categoryNames.length)" />
				</div>
			</foreignObject>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import Timer from "advanced-timer";
import gsap from "gsap";
import stat from "@/stat.js";
import box from "@/v/reco/keep_track/box.vue";
import currentWord from "@/v/reco/keep_track/currentWord.vue";
import persist from "@/persist.js";

var sequenceTimer;

export default {
	name: 'play',
	components: {
		box,
		currentWord,
	},
	data: () => { return {
		num_categories: 0,
		num_targets: 0,
		num_distractors: 0,
		//-----
		start_time: 0,
		level: 0,
		round: 0,
		total_num_correct: 0,
		round_num_correct: [],
		round_box_correct: [],
		round_box_distance: [],
		round_ans_words: [],
		round_cor_words: [],
		round_time: [],
		round_cats: [],
		round_list: [],
		num_rounds_correct: 0,
		//-----
		round_answer_start_time: 0,
		currentWord: '',
		categoryNames: ['a', 'b', 'c', 'd', 'e'],
		currentList: [],
		// Box-states: 'new', 'visible', 'hidden', 'matching', 'secret', 'cheating', 'asking', 'answered', 'reveal', 'correct', 'incorrect', 'inactive'
		boxes: [{}], // { category: '', lastWord: '', typedWord: '', state: 'new'}
		box1: { category: '', lastWord: '', typedWord: '', state: 'new'},
		//box2: { category: '', lastWord: '', typedWord: '', state: 'new'},
		box3: { category: '', lastWord: '', typedWord: '', state: 'new'},
		box4: { category: '', lastWord: '', typedWord: '', state: 'new'},
		box5: { category: '', lastWord: '', typedWord: '', state: 'new'},
	}},
	computed: {
		phase: function() {
			return this.$store.state.taskContainer.taskPhase;
		},
		numCategories() {
			return this.categoryNames.length;
		}
	},
	watch: {
		phase: function(/*newPhase*/) {
			this.update();
		}
	},
	methods: {
		createList(numCats, numTargets, numDistractors) {
			this.num_categories = numCats;
			this.num_targets = numTargets;
			this.num_distractors = numDistractors;

			/* eslint-disable no-console */
			//console.log('CREATE LIST: cats=' + numCats + ', targets=' + numTargets + ', dists=' + numDistractors);

			if (numTargets < numCats) {
				// Detta är inte okej, eftersom det behöver finnas minst 1 ord per kategori.
				return;
			}

			// Välj numCats kategorier:
			const numDistCats = numCats > 3? 2: 1; // Två distraktorkategorier om fler än 3 boxar.
			const totalNumCats = numCats + numDistCats; // +1 för en kategori med distraktorer.
			const randomCategories = stat.random(this.$parent.text.categories, totalNumCats, false);
			const distractorCatIndex = randomCategories.length - 1;
			const distractorCategory = randomCategories[distractorCatIndex];
			const distractorCatIndex2 = randomCategories.length - 2;
			const distractorCategory2 = randomCategories[distractorCatIndex2];

			var list = [];
			var catNames = [];
			for (var c = 0; c < numCats; ++c) {
				// Spara namnet på kategorin:
				var cat = randomCategories[c];
				catNames.push(cat.categoryName);

				// Välj, till att börja med, ETT ord per kategori:
				var w = cat.words[Math.floor(Math.random() * cat.words.length)];
				var listItem = {
					category: c,
					word: w
				};
				list.push(listItem);
			}

			// Lägg till distraktorer:
			var distractors, d, distractorItem;
			if (numDistCats == 1) {
				distractors = stat.random(distractorCategory.words, numDistractors, numDistractors > distractorCategory.words.length);

				for (d = 0; d < distractors.length; ++d) {
					distractorItem = {
						category: distractorCatIndex,
						word: distractors[d]
					};
					list.push(distractorItem);
				}
			} else {
				// Hämta distraktorer från två kategorier
				var d1 = stat.random(distractorCategory.words, numDistractors-2, numDistractors-2 > distractorCategory.words.length);
				var d2 = stat.random(distractorCategory2.words, 2, false);

				for (d = 0; d < d1.length; ++d) {
					distractorItem = {
						category: distractorCatIndex,
						word: d1[d]
					};
					list.push(distractorItem);
				}
				for (d = 0; d < d2.length; ++d) {
					distractorItem = {
						category: distractorCatIndex2,
						word: d2[d]
					};
					list.push(distractorItem);
				}
			}



			// Lägg till fler items i listan:
			const itemsToAdd = numTargets - numCats;
			for (var e = 0; e < itemsToAdd; ++e) {
				var item, word, targetCat, catIndex;
				var attempts = 0;
				do {
					++attempts;
					catIndex = Math.floor(Math.random() * numCats);
					targetCat = randomCategories[catIndex];
					word = targetCat.words[Math.floor(Math.random() * targetCat.words.length)];
					item = {category: catIndex, word: word};
					var found = false;
					for (var ii = 0;  ii < list.length; ++ ii) {
						if (list[ii].word == word) {
							found = true;
							break;
						}
					}
				} while (found && attempts < 300);
				list.push(item);
			}

			/*var type, i;
			for (i = 0; i < list.length; ++i) {
				if (list[i].category < numCats)
					type = '    target ';
				else
					type = 'distractor ';
				console.log(type + list[i].category + '  ' + list[i].word);
			}*/

			//console.log('--- SHUFFLE ---');

			list = stat.shuffle(list);

			/*for (i = 0; i < list.length; ++i) {
				if (list[i].category < numCats)
					type = '    target ';
				else
					type = 'distractor ';
				console.log(type + list[i].category + '  ' + list[i].word);
			}*/

			//console.log(list);
			//console.log('---');
			this.init_list(catNames, list);

			//this.cats_visible = true;

			/* eslint-enable no-console */
		},
		init_list(catNames, list) {
			const num = catNames.length;
			var boxes = [];
			for (var index = 0; index < num; index++) {
				boxes.push({
					category: catNames[index],
					lastWord: '', typedWord: '', state: 'visible'
				});
			}
			this.categoryNames = catNames.slice(0);
			this.currentList = list.slice(0);
			this.boxes = boxes;
		},
		/*present_item_original(item) {
			var boxes = this.boxes.slice(0);
			var currentBox = item.category;
			var currentWord = item.word;
			for (var boxIndex = 0; boxIndex < boxes.length; boxIndex++) {
				if (boxIndex == currentBox) {
					boxes[boxIndex].state = 'matching';
					boxes[boxIndex].lastWord = currentWord;
				}
				else
					boxes[boxIndex].state = 'visible';
			}
			this.boxes = boxes;
			this.currentWord = currentWord;
		},*/

		// Visar ej vilken låda ordet tillhör:
		present_item_no_help(item) {
			var currentBox = item.category;
			var currentWord = item.word;
			this.currentWord = currentWord;
			for (var boxIndex = 0; boxIndex < this.boxes.length; boxIndex++) {
				if (boxIndex == currentBox) {
					//this.boxes[boxIndex].state = 'matching';
					this.boxes[boxIndex].state = 'visible';
					this.boxes[boxIndex].lastWord = currentWord;
				}
				else {
					this.boxes[boxIndex].state = 'visible';
				}
			}
			gsap.fromTo(
				"#currentWord",
				{
					x: 0,
					y: 0,
					opacity: 0,
				},
				{
					opacity: 1,
					x: 0,
					y: 0,
					duration: 0.1,
				}
			);
		},
		present_item(item) {
			var currentBox = item.category;
			var currentWord = item.word;
			this.currentWord = currentWord;
			for (var boxIndex = 0; boxIndex < this.boxes.length; boxIndex++) {
				if (boxIndex == currentBox) {
					this.boxes[boxIndex].state = 'matching';
					this.boxes[boxIndex].lastWord = currentWord;
				}
				else {
					this.boxes[boxIndex].state = 'visible';
				}
			}
			gsap.fromTo(
				"#currentWord",
				{
					x: 0,
					y: 0,
					opacity: 0,
				},
				{
					opacity: 1,
					x: 0,
					y: 0,
					duration: 0.1,
				}
			);
		},
		animate_word(item) {
			var boxNum = item.category;
			if (boxNum < this.numCategories) {
				gsap.fromTo(
					"#currentWord",
					{
						x: 0,
						y: 0,
						opacity: 1,
					},
					{
						opacity: 0,
						x: this.boxPos(boxNum),
						y: 280,
						duration: 1,
						ease: "power3.inOut",
					}
				);
			} else {
				gsap.fromTo(
					"#currentWord",
					{
						x: 0,
						y: 0,
						opacity: 1,
					},
					{
						opacity: 0,
						x: 0,
						y: -30,
						duration: 1,
						ease: "power3.inOut",
					}
				);
			}
		},
		set_cats_state(newState) {
			var boxes = this.boxes.slice(0);
			for (var index = 0; index < boxes.length; index++) {
				boxes[index].state = newState;
			}
			this.boxes = boxes;
		},
		update() {
			if (this.$store.state.taskContainer.taskMode != 'playing') return;
			var phase = this.$store.state.taskContainer.taskPhase;
			//console.log('phase: ' + phase);
			switch(phase) {
			case 0:
				this.set_cats_state('visible');
				this.currentWord = '';
				break;
			case 1:
				this.set_cats_state('visible');
				this.$parent.$parent.$refs.countDown.play(() =>
					store.dispatch('nextTaskPhase'));
				break;
			case 2:
				this.currentWord = '';
				// Presentera listan
				var list = this.currentList;
				sequenceTimer = new Timer(4000).repeat(list.length + 1).action(t => {
					if (t.currentCycle <= list.length) {
						const i = t.currentCycle - 1;
						this.present_item_no_help(list[i]);
						gsap.fromTo(
							"#currentWord",
							{
								x: 0,
								y: 0,
								opacity: 1,
							},
							{
								delay: 1.75,
								opacity: 0,
								x: 0,
								y: -5,
								duration: 0.25,
								ease: "power3.inOut",
							}
						);

						/*
						// animera ordet:
						var boxNum = list[i].category;
						if (boxNum < this.numCategories) {
							gsap.fromTo(
								"#currentWord",
								{
									x: 0,
									y: 0,
									opacity: 1,
								},
								{
									delay: 2,
									opacity: 0,
									x: this.boxPos(boxNum),
									y: 280,
									duration: 1,
									ease: "power3.inOut",
								}
							);
						} else {
							gsap.fromTo(
								"#currentWord",
								{
									x: 0,
									y: 0,
									opacity: 1,
								},
								{
									delay: 2,
									opacity: 0,
									x: 0,
									y: -30,
									duration: 1,
									ease: "power3.inOut",
								}
							);
						}*/
					} else {
						this.set_cats_state('visible');
						store.dispatch('nextTaskPhase', 2000);
					}
				});
				sequenceTimer.start();
				break;
			case 3:
				this.round_answer_start_time = Date.now();
				this.set_cats_state('asking');
				setTimeout(() => {
					document.getElementById('input0').focus();
					this.addKeyboardListeners();
				}, 200);
				store.commit('showContinueButton');
				break;
			case 4:
				// Rätta svaren
				this.removeKeyboardListeners();
				var words = [];
				var wordInputs = document.getElementsByClassName("wordInput");
				for (const key in wordInputs) {
					if (key < this.numCategories) {
						words.push(wordInputs[key].value);
					}
				}
				this.process_input(words);
				store.dispatch('nextTaskPhase', 4000);
				break;
			case 5:
				this.set_cats_state('hidden');
				setTimeout(() => store.commit('taskPhase', 100), 1000);
				break;
			case 100:
				// Listan är klar! Ny lista eller avsluta?
				this.round += 1;
				if (this.round < 3) {
					this.nextList();
					//store.commit('setTaskMode', 'ready');
					store.commit('showContinueButton');
					store.commit('taskPhase', 0);
					break;
				}
				else {
					// Klart!
					store.commit('taskPhase', 999);
				}
				break;
			case 999: // done!
				this.saveSessionResults();
				store.commit("setTaskMode", "sessionScore");
				break;
			}
		},
		process_input(words) {
			var boxes = this.boxes.slice(0);
			var ans;
			var cor;
			var nc = 0;
			var boxCorr = [];
			var boxDist = [];
			var cor_words = [];
			var ans_words = [];
			const rtime = Date.now() - this.round_answer_start_time;
			this.round_time.push(rtime);
			for (var boxIndex = 0; boxIndex < boxes.length; boxIndex++) {
				var word = words[boxIndex];
				if (word == null) word = '';
				this.boxes[boxIndex].typedWord = word;
				ans = this.boxes[boxIndex].typedWord.toLowerCase().trim();
				cor = this.boxes[boxIndex].lastWord.toLowerCase().trim();
				ans_words.push(ans);
				cor_words.push(cor);
				//if (ans == cor) {
				const distance = this.levenshteinDistance(ans, cor);
				if (distance < 3) {
					boxes[boxIndex].state = 'correct';
					nc++;
					boxCorr.push(1);
				} else {
					boxes[boxIndex].state = 'incorrect';
					boxCorr.push(0);
				}
				boxDist.push(distance);
			}
			this.boxes = boxes;
			this.currentWord = '';

			if (nc == this.numCategories) {
				this.num_rounds_correct++;
			}
			this.total_num_correct += nc;
			this.round_num_correct.push(nc);
			this.round_box_correct.push(boxCorr);
			this.round_box_distance.push(boxDist);
			this.round_cor_words.push(cor_words);
			this.round_ans_words.push(ans_words);
			this.round_cats.push(this.categoryNames.slice());
			this.round_list.push(this.currentList.slice());
		},
		levenshteinDistance(s1, s2) {
			var m = s1.length;
			var n = s2.length;
			var matrix = new Array();
			var line;
			var i;
			var j;
			for (i = 0; i <= m; ++i) {
				line = new Array();
				for (j = 0; j <= n; ++j) {
					if (i != 0)
						line.push(0);
					else
						line.push(j);
				}
				line[0] = i;
				matrix.push(line);
			}
			var cost;
			for (i = 1; i <= m; ++i)
				for (j = 1; j <= n; ++j) {
					if (s1.charAt(i - 1) == s2.charAt(j - 1))
						cost = 0;
					else
						cost = 1;
					matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
				}
			return matrix[m][n];
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			const done_num = this.$store.state.progress.keep_track_num || 0;
			persist.set_progress_data('keep_track_num', done_num + 1);

			if (this.level == 0) {
				if (this.total_num_correct >= 5) {
					persist.set_progress_data('keep_track_level', 1);
				}
			} 
			else if (this.level == 1) {
				if (this.total_num_correct >= 7) {
					persist.set_progress_data('keep_track_level', 2);
				}
				else if (this.total_num_correct <= 3) {
					persist.set_progress_data('keep_track_level', 0);
				}
			}
			else if (this.level == 2) {
				if (this.total_num_correct <= 4) {
					persist.set_progress_data('keep_track_level', 1);
				}
			}


			persist.log('results', {
				level: this.level,
				correct: this.total_num_correct,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				round_time: this.round_time,
				round_num_correct: this.round_num_correct,
				round_box_correct: this.round_box_correct,
				round_box_distance: this.round_box_distance,
				round_ans_words: this.round_ans_words,
				round_cor_words: this.round_cor_words,
				round_cats: this.round_cats,
				round_list: this.round_list,
				num_rounds_correct: this.num_rounds_correct,
				num_categories: this.num_categories,
				num_targets: this.num_targets,
				num_distractors: this.num_distractors,
			});

			persist.addToHistory('score', this.total_num_correct).then(history => {
				store.commit("setSessionScores", history);
				store.commit('setTaskMode', 'sessionScore');
			});
		},
		addKeyboardListeners() {
			const wordInputs = document.getElementsByClassName("wordInput");
			for (const key in wordInputs) {
				if (key < this.numCategories) {
					wordInputs[key].addEventListener("keyup", this.enterKeyPushed.bind(this));
				}
			}
		},
		removeKeyboardListeners() {
			const wordInputs = document.getElementsByClassName("wordInput");
			for (const key in wordInputs) {
				if (key < this.numCategories) {
					wordInputs[key].removeEventListener("keyup", this.enterKeyPushed.bind(this));
				}
			}
		},
		enterKeyPushed: function(e) {
			if (e.key === 'Enter') {
				this.enterWord();
			}
		},
		enterWord: function() {
			var wordInputs = document.getElementsByClassName("wordInput");
			for (const key in wordInputs) {
				if (key < this.numCategories) {
					var val = wordInputs[key].value;
					if (val == null || val == '') {
						wordInputs[key].focus();
						return;
					}
				}
			}
			store.dispatch('nextTaskPhase');
		},
		nextList() {
			var numCats; // Antal kategorier (exklusive distraktorer)
			var numTargets; // Listans totala längd är numTargets + numDistractors
			var numDistractors;

			//const demo_mode = false;
			const placebo_mode = this.$store.state.condition == 'ctrl';

			/*if (demo_mode) {
				this.level = 0;
				numCats = 3;
				numTargets = 4;
				numDistractors = 1;
			}
			else*/
			if (placebo_mode) {
				this.level = 0;
				numCats = 4;
				numTargets = 4;
				numDistractors = 4;				
			}
			else {
				if (this.level < 0)
					this.level = 0;
				else if (this.level > 2)
					this.level = 2;

				if (this.level == 0 ) {
					numCats = 3;
					numTargets = 9;
					numDistractors = 3;
				} else if (this.level == 1 ) {
					numCats = 4;
					numTargets = 12;
					numDistractors = 4;
				} else {
					numCats = 5;
					numTargets = 15;
					numDistractors = 5;
				}
			}

		
			//console.log('PLACEBO? ' + placebo_mode);
			//console.log('LEVEL: ' + this.level);
			//console.log('NEXT LIST: ' + numCats + ' ' + numTargets + '' + numDistractors);
			this.createList(numCats, numTargets, numDistractors);
		},
		boxPos(index) {
			return -800+800/this.numCategories+1600/this.numCategories*index;
		}
	},
	mounted() {
		this.set_cats_state('visible');
		this.start_time = Date.now();
		this.level = this.$store.state.progress.keep_track_level || 0;
		this.nextList();
		this.update();
		
	}
}
</script>
