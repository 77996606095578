<template>
	<g>
		<g v-if="test_active">		
			<timeline v-if="block >= 0" v-bind:stages="32" v-bind:pos="pos_in_block + 1" />

			<transition name="fade">
				<g v-if="block<6 && !stim_visible">
					<rect
						stroke="#0000000C"
						stroke-width="7"
						stroke-dasharray="6, 7"
						fill="none"
						x="-140" y="-140"
						width="280" height="280"
						rx="15"
					/>
					<circle
						stroke="#0000000C"
						stroke-width="7"
						stroke-dasharray="6, 7"
						fill="none"
						cx="0" cy="0"
						r="140"
					/>
				</g>
			</transition>

			<g v-if="item_visible">
				<text
					v-if="vowel_consonant"
					opacity="1.0"
					x="0"
					y="380"
					class="txt"
					text-anchor="middle"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="35"
					fill="#FFFFFFCC"
				>{{this.text.vowel_or_consonant}}</text>
				<text
					v-if="early_late"
					opacity="1.0"
					x="0"
					y="380"
					class="txt"
					text-anchor="middle"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="35"
					fill="#FFFFFFCC"
				>{{this.text.early_or_late}}</text>

				<circle
					v-if="task == 'vc'"
					stroke="#FFFFFF10"
					stroke-width="10"
					fill="#F4A03B"
					cx="0" cy="0"
					r="150"
				/>
				<rect
					v-if="task == 'el'"
					stroke="#FFFFFF10"
					stroke-width="10"
					fill="#3BA0F4"
					x="-150" y="-150"
					width="300" height="300"
					rx="15"
				/>

				<text
					v-if="stimulus != '' && stim_visible"
					opacity="1.0"
					x="0"
					y="20"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="150"
					fill="#FFFFFF"
				>{{stimulus}}</text>

				<transition name="quickfade">
					<g v-if="show_buttons && !block_feedback" v-on:click="left_clicked">
						<path d="M -700 0 L -650 -100 -450 -100 -400 0 -450 100 -650 100 Z" fill="#00000020" />

						<text
							v-if="vowel_consonant"
							opacity="1.0"
							x="-550"
							y="0"
							class="txt"
							font-size="32px"
							font-family="sans-serif"
							fill="#FFFFFF"
							text-anchor="middle"
							dominant-baseline="middle"
						>{{this.text.vowel}}</text>
						<text
							v-if="early_late"
							opacity="1.0"
							x="-550"
							y="0"
							class="txt"
							font-size="32px"
							font-family="sans-serif"
							fill="#FFFFFF"
							text-anchor="middle"
							dominant-baseline="middle"
						>{{this.text.early}}</text>
					</g>
				</transition>

				<transition name="quickfade">
					<g v-if="show_buttons && !block_feedback" v-on:click="right_clicked">
						<path d="M 700 0 L 650 -100 450 -100 400 0 450 100 650 100 Z" fill="#00000020"  />

						<text
							v-if="vowel_consonant"
							opacity="1.0"
							x="550"
							y="0"
							class="txt"
							font-size="32px"
							font-family="sans-serif"
							fill="#FFFFFF"
							text-anchor="middle"
							dominant-baseline="middle"
						>{{this.text.consonant}}</text>
						<text
							v-if="early_late"
							opacity="1.0"
							x="550"
							y="0"
							class="txt"
							font-size="32px"
							font-family="sans-serif"
							fill="#FFFFFF"
							text-anchor="middle"
							dominant-baseline="middle"
						>{{this.text.late}}</text>
					</g>
				</transition>
			</g>
		</g>
		<transition name="fade">
			<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		</transition>
		<transition name="quickfade">
			<g v-if="block_feedback">
				<path id="clock" fill="none" stroke="#FFFFFFCC" stroke-width="10" />
				<circle cx="-550" cy="0" r="150" fill="none" stroke="#00000020" stroke-width="10" />
				<circle cx="-550" cy="0" r="130" fill="none" stroke="#00000020" stroke-width="20" stroke-dasharray="10, 9" />
				
				<text
					opacity="1.0"
					x="-550"
					y="0"
					font-family="sans-serif"
					font-size="80"
					fill="#000000"
					text-anchor="middle"
					dominant-baseline="middle"
				>{{this.block_feedback_seconds}}</text>
				<text
					opacity="1.0"
					x="-550"
					y="40"
					font-family="sans-serif"
					font-size="25"
					fill="#000000"
					text-anchor="middle"
					dominant-baseline="middle"
				>{{this.text.seconds}}</text>

				<circle cx="550" cy="0" r="150" fill="none" stroke="#00000020" stroke-width="10" />
				<path id="corr" fill="none" stroke="#FFFFFFCC" stroke-width="10" />
				<text
					opacity="1.0"
					x="550"
					y="0"
					font-family="sans-serif"
					font-size="80"
					fill="#000000"
					text-anchor="middle"
					dominant-baseline="middle"
				>{{this.block_feedback_correct}} / 32</text>
				<text
					opacity="1.0"
					x="550"
					y="40"
					font-family="sans-serif"
					font-size="25"
					fill="#000000"
					text-anchor="middle"
					dominant-baseline="middle"
				>{{this.text.correct}}</text>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import stat from "@/stat.js";
import lang from "@/v/reco/shifting_unpredictable/lang.js";
import instructions from "@/v/reco/shifting_unpredictable/instructions.vue";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";


export default {
	name: "reco_shifting_unpredictable",
	//genväg-alias:
	local: store.state.reco_shifting_unpredictable,
	components: {
		timeline,
		instructions,
	},
	//props: ['stim_param'],
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
		item() {
			return this.items[this.trial] || {
				block: -1,
				task: '',
				n: -2,
				stim: '',
				shift: 0,
				comp: 0,
				cresp: '',
			};
		},
		block() {
			return this.item.block;
		},
		task() {
			return this.item.task;
		},
		item_n() {
			return this.item.n;
		},
		stimulus() {
			return this.item.stim;
		},
		is_shift() {
			return this.item.shift == 1;
		},
		is_comp() {
			return this.item.comp;
		},
		corr_resp() {
			return this.item.cresp;
		},
		desc() {
			return this.item.desc || '---';
		},
		vowel_consonant() {
			return this.task == "vc";
		},
		early_late() {
			return this.task == "el";
		},
		pos_in_block() {
			return this.item_n % 32;
		},
		test_active() {
			return this.trial < this.items.length;
		}
	},
	watch: {
		item: function() {
			/*
			this.item_response_blocked = true;
			this.stim_visible = true;

			if (this.block > 0 && (this.item_n == -1 || (this.item_n >= 32 && this.item_n % 32 == 0))) {
				this.item_visible = false;
				setTimeout(() => {
					this.item_error = false;
					this.block_feedback = true;	
					this.update_feedback_arcs();
				}, 100);

				this.$parent.$refs.countDown.play(() => {
					this.item_visible = true;
					this.item_response_blocked = false;
					this.item_start_time = Date.now();
					this.block_feedback = false;
				}, 7, 3);
			}
			else {
				this.item_start_time = Date.now();
				this.item_visible = true;
				setTimeout(() => {
					this.item_error = false;
					this.item_response_blocked = false;
				}, 100);
			}
			*/

			this.block_feedback = false;
			this.item_start_time = Date.now();
			if (this.item_n == 0) {
				this.block_start_time = Date.now();
			}
			this.stim_visible = true;
			this.item_visible = true;
			this.item_response_blocked = true;
			setTimeout(() => {
				this.item_response_blocked = false;
			}, 100);
		},
	},
	data: () => { return {
		stim_visible: true,
		show_buttons: true,
		input_disabled: false,
		items: [],
		trial: 0,
		item_visible: false,
		item_error: false,
		item_response_blocked: false,
		item_start_time: 0,
		start_time: 0,
		block_start_time: 0,
		block_feedback: false,
		block_feedback_seconds: 123,
		block_feedback_correct: 0,
		session_score: 0,
		balanced_list: [
			'A','E','U','Y','A','E','U','Y',
			'B','D','X','Z','B','D','X','Z',
			'A','E','U','Y','A','E','U','Y',
			'B','D','X','Z','B','D','X','Z',
		],
	}},
	methods: {
		create_lists() {
			// Initiera tom lista
			this.items = [];
			var i;
			var rnd;
			var block_items;

			//
			//----------------------------------------------------------------------------
			//
			// 32 st vowel/consonant, lika många av varje:

			rnd = this.random_letter();
			this.items.push({
				block: 0,
				task: 'vc',
				n: -1,
				stim: rnd,
				shift: 0,
				comp: this.is_compatible(rnd),
				cresp: this.correct_response_for(rnd, 'vc'),
			});
			block_items = [];
			for (i = 0; i < 32; ++i) {
				block_items.push({
					block: 0,
					task: 'vc',
					n: i,
					stim: this.balanced_list[i],
					shift: 0,
					comp: this.is_compatible(this.balanced_list[i]),
					cresp: this.correct_response_for(this.balanced_list[i], 'vc'),
				});
			}
			stat.shuffle(block_items);
			this.items = this.items.concat(block_items);
			
			//
			//----------------------------------------------------------------------------
			//
			// 32 st början/slutet, lika många av varje:
			rnd = this.random_letter();
			this.items.push({
				block: 1,
				task: 'el',
				n: -1,
				stim: rnd,
				shift: 0,
				comp: this.is_compatible(rnd),
				cresp: this.correct_response_for(rnd, 'vc'),
			});
			block_items = [];
			for (i = 0; i < 32; ++i) {
				block_items.push({
					block: 1,
					task: 'el',
					n: i,
					stim: this.balanced_list[i],
					shift: 0,
					comp: this.is_compatible(this.balanced_list[i]),
					cresp: this.correct_response_for(this.balanced_list[i], 'el'),
				});
			}
			stat.shuffle(block_items);
			this.items = this.items.concat(block_items);

			//
			//----------------------------------------------------------------------------
			//
			// 1 trial + 4 block om 32 trials, vowel/consonant/början/slutet
			//

			
			

			rnd = this.random_letter();
			this.items.push({
				block: 2,
				task: 'el',
				n: -1,
				stim: rnd,
				shift: 0,
				comp: this.is_compatible(rnd),
				cresp: this.correct_response_for(rnd, 'el'),
			});

			var task;
			for (var block = 2; block < 6; ++block) {
				block_items = [];
				for (i = 0; i < 32; ++i) {
					task = i < 16? 'vc' : 'el';
					block_items.push({
						block: block,
						task: task,
						n: i,
						stim: this.balanced_list[i],
						shift: 0,
						comp: this.is_compatible(this.balanced_list[i]),
						cresp: this.correct_response_for(this.balanced_list[i], task),
					});
				}
				stat.shuffle(block_items);
				this.items = this.items.concat(block_items);
			}

			// Fixa shift
			for (i = 1; i < this.items.length; ++i) {
				this.items[i].shift = (this.items[i].task == this.items[i-1].task) ? 0 : 1;
			}
			// Fixa n
			var prevblock = -10;
			var nn = -1;
			for (i = 0; i < this.items.length; ++i) {
				if (this.items[i].block != prevblock) {
					prevblock = this.items[i].block;
					nn = (this.items[i].block <= 2)? -1 : 0;
				}
				this.items[i].n = nn++;
			}


			prevblock = -10;
			for (var xx = 0; xx < this.items.length; ++xx) {
				if (this.items[xx].block != prevblock) {
					prevblock = this.items[xx].block;
					/*console.log('--------------- block: ' + this.items[block] + '---------------');
					console.log('i'
						+ "\tblk"
						+ "\ttsk"
						+ "\tn"
						+ "\tLet"
						+ "\tShift"
						+ "\tComp"
						+ "\tResp"
					);*/
				}
				/*console.log(xx
					+ "\t" + this.items[xx].block
					+ "\t" + this.items[xx].task
					+ "\t" + this.items[xx].n
					+ "\t" + this.items[xx].stim
					+ "\t" + this.items[xx].shift
					+ "\t" + this.items[xx].comp
					+ "\t" + this.items[xx].cresp
				);*/
			}
		},
		is_compatible(L) {
			return (L == 'A' || L == 'E' || L == 'X' || L == 'Z')? 1 : 0;
		},
		correct_response_for(L, task) {
			if (task == 'vc')
				return L == 'A' || L == 'E' || L == 'U' || L == 'Y' ? 'V' : 'C';
			else
				return L == 'A' || L == 'B' || L == 'D' || L == 'E' ? 'E' : 'L';
		},
		random_letter() {
			return ['A','B','D','E','U','X','Y','Z'][Math.trunc(Math.random()*8)];
		},
		basic_stats(a) {
			var i;
			var result = {
				//num: a.length,
			};
			var rts = [];
			var num_correct = 0;

			// Behandla enbart items som är korrekt besvarade och har rt > 200 ms:
			for (i = 0; i < a.length; ++i) {
				if (a[i].corr) {
					num_correct++;
					if (a[i].rt > 200) {
						rts.push(a[i].rt);
					}
				}
			}
			result.num_c = num_correct;

			// Måste ha ett antal rätt (4?) för att räkna användbart medel och standardavvikelse
			if (rts.length > 3) {
				var rt_mean  = stat.mean(rts);
				var rt_sd = stat.standardDev(rts);
				var rts_ok = [];
				var ok = [];
				for (i = 0; i < a.length; ++i) {
					// Ignorera items som har fel svar eller har rt >= 200 ms eller har rt > 3 sd över medel.
					if (a[i].corr && a[i].rt > 200 && a[i].rt <= rt_mean + 3 * rt_sd) {
						ok.push(a[i]);
						rts_ok.push(a[i].rt);
					}
				}
				// fråga Anna om vilka data som ska användas:
				// ska vi filtrera items med rt < 100? och mer än 3 sd?
				// ska vi beräkna nytt medel och sd efter filtrering?
				result.rt_mean = parseFloat(stat.mean(rts_ok).toFixed(2));
				result.rt_sd = parseFloat(stat.standardDev(rts_ok).toFixed(2));
				result.num_ok = rts_ok.length;
				result.ok = ok;
			}
			else {
				result.rt_mean = -999999;
				result.rt_sd = -99999;
				result.num_ok = 0;
				result.ok = [];
			}
			
			return result;
		},
		split_at_shift(a) {
			var r = {
				shift: [],
				non_shift: [],
			};
			a.forEach(e => {
				if (e.shift == 1)
					r.shift.push(e);
				else
					r.non_shift.push(e);
			});
			return r;
		},
		split_at_comp(a) {
			var r = {
				comp: [],
				non_comp: [],
			};
			a.forEach(e => {
				if (e.comp == 1)
					r.comp.push(e);
				else
					r.non_comp.push(e);
			});
			return r;
		},
		end_test() {
			this.show_buttons = false;
			// feedback för sista blocket
			this.block_feedback = true;

			setTimeout(() => {
				this.block_feedback = false;
				store.commit('setTaskMode', 'clickToExit');
				this.saveSessionResults();
			}, 10000);
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			// Ta bort onödig information:
			this.items.forEach(e => {
				delete e.desc;
			});

			// Block 0: vowel/consonant
			//console.log('---- vc ----');
			const vc = this.basic_stats(this.items.slice(1, 33));
			//console.log(vc);

			// Block 1: early/late
			//console.log('---- el ----');
			const el = this.basic_stats(this.items.slice(34, 66));
			//console.log(el);

			// Block 2+: vowel/consonant/början/slutet
			//console.log('---- vc/el ----');
			const mix = this.basic_stats(this.items.slice(67));
			//console.log(mix);

			const {shift, non_shift} = this.split_at_shift(mix.ok);
			const shift_stats = this.basic_stats(shift);
			const non_shift_stats = this.basic_stats(non_shift);
			//console.log('SHIFT:');
			//console.log(shift_stats);
			//console.log('NON-SHIFT:');
			//console.log(non_shift_stats);


			const {comp: shift_comp, non_comp: shift_non_comp} = this.split_at_comp(shift);
			const shift_comp_stats = this.basic_stats(shift_comp);
			const shift_non_comp_stats = this.basic_stats(shift_non_comp);
			//console.log('SHIFT & COMPATIBLE:');
			//console.log(shift_comp_stats);
			//console.log('SHIFT & NON-COMPATIBLE:');
			//console.log(shift_non_comp_stats);


			/*const {comp: non_shift_comp, non_comp: non_shift_non_comp} = this.split_at_comp(non_shift);
			console.log('NON-SHIFT & COMPATIBLE:');
			console.log(this.basic_stats(non_shift_comp));
			console.log('NON-SHIFT & NON-COMPATIBLE:');
			console.log(this.basic_stats(non_shift_non_comp));*/


			// ta bort detaljer... de finns ju kvar i this.items[]
			delete vc.ok;
			delete el.ok;
			delete mix.ok;
			delete shift_stats.ok;
			delete non_shift_stats.ok;
			delete shift_comp_stats.ok;
			delete shift_non_comp_stats.ok;


			const speed = 1000 / Math.min(5000, Math.max(200, mix.rt_mean));
			const percent_correct = mix.num_ok / this.items.length;
			const score = Math.trunc(100 * speed * percent_correct);

			this.session_score = score;


			const done_num = this.$store.state.progress.shifting_up_num || 0;
			persist.set_progress_data('shifting_up_num', done_num + 1);

			persist.log('results', {
				score: this.session_score,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				vc: vc,
				el: el,
				mix: mix,
				shift: shift_stats,
				non_shift: non_shift_stats,
				shift_comp: shift_comp_stats,
				shift_non_comp: shift_non_comp_stats,
				x: this.items,
			});

			persist.addToHistory('score', this.session_score).then(history => {
				store.commit("setSessionScores", history);
				store.commit('setTaskMode', 'sessionScore');
			});
		},
		left_clicked() {
			const response = this.task == "vc"? 'V' : 'E';
			this.log_response(response);
		},
		right_clicked() {
			const response = this.task == "vc"? 'C' : 'L';
			this.log_response(response);
		},
		log_response(response) {
			if (this.input_disabled)
				return;
			if (this.trial >= this.items.length || this.item_response_blocked)
				return;

			if (this.trial == 1) {
				this.start_time = Date.now();
				store.commit("setTaskMode", "playing");
			}


			// initiera feedback-data:
			if (this.pos_in_block == 0) {
				this.block_feedback_correct = 0;
			}


			this.item_response_blocked = true;
			this.stim_visible = false;

			const response_time = Date.now() - this.item_start_time;
			const error = this.corr_resp != response;

			//console.log(this.trial + ': ' + response + (error?' ERR ':' OK  ') + response_time);

			const corr = (error? 0 : 1);

			this.items[this.trial].resp = response;
			this.items[this.trial].corr = corr;
			this.items[this.trial].rt = response_time;

			this.block_feedback_correct += corr;

			if (this.pos_in_block >= 31) {
				this.item_visible = false;
				this.stim_visible = false;

				this.block_feedback = true;
				this.update_feedback_arcs();

				if (this.block >= 5) {
					this.end_test();
				}
				else {
					this.$parent.$refs.countDown.play(() => {
						this.next_trial();
					}, 7, 3);
				}
			}
			else {
				this.next_trial();
			}
		},
		next_trial() {
			setTimeout(() => {
				++this.trial;
				if (this.trial >= this.items.length) {
					this.end_test();
				}
			}, 150);
		},
		update_feedback_arcs() {
			const seconds = Math.trunc((Date.now() - this.block_start_time) / 1000);
			const fake_seconds = Math.max(seconds-5, 1);
			const degrees = Math.min(359.99, fake_seconds * 7);
			this.block_feedback_seconds = seconds;

			const num_correct = Math.max(0, Math.min(32, this.block_feedback_correct))
			const degrees_c = Math.min(359.99, num_correct * 11.25);

			setTimeout(() => {
				const clock = document.getElementById("clock");
				if (clock) clock.setAttribute("d", describeArc(-550, 0, 130, 0, degrees));

				const corr = document.getElementById("corr");
				if (corr) corr.setAttribute("d", describeArc(550, 0, 130, 0, degrees_c));
			}, 200);
		},
		setup() {
			this.create_lists();

			//store.commit('setTaskMode', 'playing');
			store.commit('setTaskMode', 'readyImmediateInstruct');
			store.commit("taskPhase", 0);
			this.trial = 0;
			this.input_disabled = false; // tillåt input

			this.block_start_time = Date.now(); // Onödig rad, men låt stå.
			this.block_feedback_correct = 0;    // Onödig rad, men låt stå.
			this.$parent.show_buttons = true;
			this.stim_visible = true;
		}
	},
	mounted() {
		/**
		 * Animerade instruktioner, med hand, och kommentarer: "7 är vowel", "2 är början".
		 * 
		 * Liten paus innan siffran visas? först box, sen siffra?
		 * 
		 * Paus mellan varje block med pauscirkel.
		*/

		this.setup();

		document.addEventListener('keydown', (event) => {
			var keyValue = event.key;
			//var codeValue = event.code;
			if (this.test_active) {
				if (keyValue == 'f')
					this.left_clicked();
				else if (keyValue == 'j')
					this.right_clicked();
			}
			//console.log("keyValue: " + keyValue);
			//console.log("codeValue: " + codeValue);
		}, false);
	}
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians)
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y
	].join(" ");

	return d;
}

</script>

<style lang="stylus">
	.mini-balloon-enter-active, .mini-balloon-leave-active {
		transition: all 0.75s;
	}
	.mini-balloon-enter, .mini-balloon-leave-to {
		opacity: 0;
		transform: translate(350px, 10px);
	}
</style>
