<template>
	<g>
		<defs>
			<g id="digbrick" transform="scale(0.45)">
				<path
					d="M -120 -60 L 0 -135 120 -60 120 67 0 135 -120 67 Z"
					fill="#ff9922dd"
					stroke="#00000008"
					stroke-width="10"
				/>
				<path d="M 0 0 L 0 -135 120 -60 120 67 Z" fill="#FFFFFF30" />
				<path d="M 0 0 L 120 67 0 135-120 67 Z" fill="#C0000020" />

				<text
					opacity="1.0"
					x="-45"
					y="15"
					class="txt"
					text-anchor="center"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="150"
					fill="#FFFFFF"
				>1</text>
			</g>
		</defs>

		<g id="digitsUpdatingIcon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<use xlink:href="#digbrick" x="0" y="0" />

			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.digitsUpdatingLabel}}</text>
		</g>
	</g>
</template>

<script>

export default {
	name: "digitsUpdatingIcon",
	idleTween: null,
};
</script>
