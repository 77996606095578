export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "fi":
					return {
						instruct0: "Tässä tehtävässä sinun pitää laskea helppoja laskuja ja samalla pitää mielessä kirjaimia.<br><br>Aloitetaan harjoittelemalla kirjainten muistamista.<br><br>",
						instruct1: "Näytölle ilmestyy kirjaimia yksi kerrallaan.<br><br>Yritä muistaa kaikki kirjaimet, jotka näytetään.",
						instruct2: "Nyt saat harjoitella laskutehtäviä. Ne voivat näyttää tältä:<br><br><b>1 + 2</b><br><br>Laske lasku päässälaskuna.<br><br>Paina “Jatka”, kun tiedät vastauksen laskuun.", // svara så snabbt som möjligt
						instruct3: "Tämän jälkeen saat ehdotuksen oikeaksi vastaukseksi, esimerkiksi <b>4</b>.<br><br> Joskus vastaus on oikein ja joskus väärin.<br><br>Onko 1 + 2 = 4?<br><br>Vastaa painamalla “Oikein” tai “Väärin” nappulaa.",
						instruct4: "Hyvä! Nyt saat harjoitella laskutehtäviä ja kirjainten muistamista samanaikaisesti.<br><br>Sinulla on vain vähän aikaa ratkaista laskutehtävä.",
						instruct5: "Tämä mittari näyttää oletko vastannut oikein vai väärin laskutehtäviin.<br><br>Yritä vastata oikein niin moneen tehtävään kuin mahdollista, jotta viisari pysyisi vihreällä alueella.",
						instruct6: "No niin, oletko valmis?<br><br>Yritä ratkaista lasku oikein ja muistaa kaikki kirjaimet.",
						instruct7: "Nyt olet tehnyt kaikki harjoitustehtävät!<br><br>Nyt tulevat varsinaiset tehtävät. Ne ovat samanlaisia kuin ne, joita juuri harjoittelit.<br><br>Yritä ratkaista lasku oikein ja muistaa kaikki kirjaimet.",
						letter_response_instr: "Paina kirjaimia siinä järjestyksessä missä ne näytettiin.",
						current_results: (lnum, lcorr, num_attempts, qnum, qcorr) => {
							var str = "";
							if (lnum > 0) {
								if (lcorr == lnum) {
									if (lnum > 2) {
										str += `Muistit <font color="#88FF88">kaikki ${lcorr} kirjainta</font>`;
									}
									else {
										str += `Muistit <font color="#88FF88">kaikki kirjaimet</font>`;
									}
								}
								else if (num_attempts == 0) {
									str += `Kaikki kirjaimet <font color="#FF8888">eivät olleet oikein</font>.<br><br>Yritä uudestaan!`;
								}
								else {
									str += `Kaikki kirjaimet <font color="#FF8888">eivät olleet oikein</font>`;
								}
								str += "<br><br>";
							}
							if (qnum > 0) {
								if (qcorr == 0) str += `<font color="#FF8888">Vastasit väärin joihinkin laskuista</font>`;
								else if (qcorr == 1) str += `Sait oikein <font color="#88FF88">1 laskun</font>`;
								else if (qcorr > 1) {
									if (qcorr == qnum && qnum > 2)
										str += `Sait oikein <font color="#88FF88">kaikki ${qcorr} laskua</font>`;
									else
										str += `Sait oikein <font color="#88FF88">${qcorr} laskua</font>`;
								}
							}
							return str;
						},
						too_slow: "Yritä nopeammin!",
						continue_: "Jatka →",
						true_: "Oikein",
						false_: "Väärin",
						correct: "Oikein",
						incorrect: "Väärin",
						practice: "Harjoitus",
						done: "Tehtävä suoritettu!",
						questions: {
							// measure speed
							measure: [
								["5 + 4", "9", true], ["3 + 4", "6", false], ["5 + 3", "8", true], ["6 + 3", "9", true],
								["7 + 2", "8", false], ["2 + 6", "9", false], ["2 + 5", "7", true], ["2 + 6", "7", false],
								["3 + 2", "5", true], ["1 + 7", "7", false], ["8 + 1", "8", false], ["2 + 7", "9", true],
								["6 + 2", "9", false], ["4 + 5", "9", true], ["4 + 3", "7", true], ["1 + 6", "8", false],
							],
							// Practice, both
							practice: [
								[["2 + 2", "4", true], ["8 + 1", "5", false]],
								[["4 + 5", "8", false], ["3 + 4", "7", true]],
								[["3 + 1", "4", true], ["1 + 7", "9", false]],
							],
							test: [
								// level 2
								[
									[["1 + 8", "9", true], ["3 + 4", "6", false]],
									[["2 + 5", "7", true], ["4 + 3", "6", false]],
								],
								// level 3
								[
									[["5 + 2", "7", true], ["1 + 8", "9", true], ["2 + 5", "9", false]],
									[["5 + 2", "7", true], ["6 + 1", "9", false], ["5 + 1", "6", true]],
								],
								// level 4
								[
									[["3 + 2", "7", false], ["5 + 1", "6", true], ["7 + 2", "9", true], ["2 + 5", "6", false]],
									[["6 + 3", "9", true], ["1 + 5", "8", false], ["3 + 6", "8", false], ["5 + 3", "8", true]],
								],
								// level 5
								[
									[["3 + 5", "8", true], ["4 + 3", "6", false], ["5 + 3", "8", true], ["3 + 4", "9", false], ["6 + 3", "9", true]],
									[["3 + 2", "4", false], ["1 + 3", "4", true], ["5 + 4", "9", true], ["2 + 4", "6", true], ["6 + 1", "9", false]],
								],
								// level 6
								[
									[["5 + 2", "8", false], ["1 + 2", "3", true], ["3 + 6", "8", false], ["2 + 5", "7", true], ["7 + 2", "9", true], ["2 + 4", "7", false]],
									[["3 + 4", "7", true], ["2 + 5", "8", false], ["1 + 6", "6", false], ["5 + 4", "9", true], ["3 + 1", "5", false], ["5 + 3", "8", true]],
								],
								// level 7
								[
									[["3 + 6", "9", true], ["3 + 2", "8", false], ["2 + 3", "4", false], ["3 + 6", "9", true], ["4 + 3", "7", true], ["2 + 5", "8", false], ["5 + 2", "9", false]],
									[["3 + 2", "8", false], ["2 + 3", "5", true], ["2 + 5", "6", false], ["3 + 4", "8", false], ["4 + 3", "7", true], ["1 + 7", "8", true], ["3 + 2", "7", false]],
								],
							]
						},
					};
				case "sv":
				default:
					return {
						instruct0: "I uppgiften som kommer snart ska du räkna enkla mattetal och samtidigt komma ihåg bokstäver.<br><br>Men vi börjar med att öva på att komma ihåg bokstäver.<br><br>",
						instruct1: "Bokstäver kommer att visas, en i taget.<br><br>Försök att komma ihåg alla bokstäver som visas.",
						instruct2: "Nu kommer du få öva på räkneuppgifterna. De kan se ut så här:<br><br><b>1 + 2</b><br><br>Räkna på en gång ut svaret i huvudet.<br><br>Tryck på ”Gå vidare” när du räknat ut svaret.", // svara så snabbt som möjligt
						instruct3: "Du får sedan ett förslag på svar, till exempel <b>4</b>.<br><br> Ibland är förslaget sant och ibland är det falskt.<br><br>Stämmer det att 1 + 2 är 4?<br><br>Svara genom att trycka på knapparna ”Sant” och ”Falskt”",
						instruct4: "Okej, bra! Du kommer nu få öva på både räkneuppgifter och att komma ihåg bokstäver på samma gång.<br><br>Du får bara en kort tid på dig att svara rätt på varje räkneuppgift.",
						instruct5: "Den här mätaren visar om du svarat rätt eller fel på räkneuppgifterna.<br><br>Försök att svara rätt på så många som möjligt så att visaren ligger i det gröna fältet.",
						instruct6: "Okej, är du redo?<br><br>Försök att både svara rätt och komma ihåg alla bokstäver.",
						instruct7: "Nu är övningarna slut!<br><br>Nu kommer de riktiga uppgifterna. De är likadana som de du just övat på.<br><br>Försök svara rätt och komma ihåg alla bokstäver.",
						letter_response_instr: "Tryck på bokstäverna i samma ordning som de visades",
						current_results: (lnum, lcorr, num_attempts, qnum, qcorr) => {
							var str = "";
							if (lnum > 0) {
								if (lcorr == lnum) {
									if (lnum > 2) {
										str += `Du kom ihåg <font color="#88FF88">alla ${lcorr} bokstäver</font>`;
									}
									else {
										str += `Du kom ihåg <font color="#88FF88">alla bokstäver</font>`;
									}
								}
								else if (num_attempts == 0) {
									str += `<font color="#FF8888">Inte alla bokstäver</font> blev rätt.<br><br>Försök en gång till!`;
								}
								else {
									str += `<font color="#FF8888">Inte alla bokstäver</font> blev rätt`;
								}
								str += "<br><br>";
							}
							if (qnum > 0) {
								if (qcorr == 0) str += `<font color="#FF8888">Du svarade inte rätt på några mattetal</font>`;
								else if (qcorr == 1) str += `Du svarade rätt på <font color="#88FF88">1 mattetal</font>`;
								else if (qcorr > 1) {
									if (qcorr == qnum && qnum > 2)
										str += `Du svarade rätt på <font color="#88FF88">alla ${qcorr} mattetal</font>`;
									else
										str += `Du svarade rätt på <font color="#88FF88">${qcorr} mattetal</font>`;
								}
							}
							return str;
						},
						too_slow: "För långsam!",
						continue_: "Gå vidare →",
						true_: "Sant",
						false_: "Falskt",
						correct: "Rätt",
						incorrect: "Fel",
						practice: "Övning",
						done: "Klart!",
						questions: {
							// measure speed
							measure: [
								["5 + 4", "9", true], ["3 + 4", "6", false], ["5 + 3", "8", true], ["6 + 3", "9", true],
								["7 + 2", "8", false], ["2 + 6", "9", false], ["2 + 5", "7", true], ["2 + 6", "7", false],
								["3 + 2", "5", true], ["1 + 7", "7", false], ["8 + 1", "8", false], ["2 + 7", "9", true],
								["6 + 2", "9", false], ["4 + 5", "9", true], ["4 + 3", "7", true], ["1 + 6", "8", false],
							],
							// Practice, both
							practice: [
								[["2 + 2", "4", true], ["8 + 1", "5", false]],
								[["4 + 5", "8", false], ["3 + 4", "7", true]],
								[["3 + 1", "4", true], ["1 + 7", "9", false]],
							],
							test: [
								// level 2
								[
									[["1 + 8", "9", true], ["3 + 4", "6", false]],
									[["2 + 5", "7", true], ["4 + 3", "6", false]],
								],
								// level 3
								[
									[["5 + 2", "7", true], ["1 + 8", "9", true], ["2 + 5", "9", false]],
									[["5 + 2", "7", true], ["6 + 1", "9", false], ["5 + 1", "6", true]],
								],
								// level 4
								[
									[["3 + 2", "7", false], ["5 + 1", "6", true], ["7 + 2", "9", true], ["2 + 5", "6", false]],
									[["6 + 3", "9", true], ["1 + 5", "8", false], ["3 + 6", "8", false], ["5 + 3", "8", true]],
								],
								// level 5
								[
									[["3 + 5", "8", true], ["4 + 3", "6", false], ["5 + 3", "8", true], ["3 + 4", "9", false], ["6 + 3", "9", true]],
									[["3 + 2", "4", false], ["1 + 3", "4", true], ["5 + 4", "9", true], ["2 + 4", "6", true], ["6 + 1", "9", false]],
								],
								// level 6
								[
									[["5 + 2", "8", false], ["1 + 2", "3", true], ["3 + 6", "8", false], ["2 + 5", "7", true], ["7 + 2", "9", true], ["2 + 4", "7", false]],
									[["3 + 4", "7", true], ["2 + 5", "8", false], ["1 + 6", "6", false], ["5 + 4", "9", true], ["3 + 1", "5", false], ["5 + 3", "8", true]],
								],
								// level 7
								[
									[["3 + 6", "9", true], ["3 + 2", "8", false], ["2 + 3", "4", false], ["3 + 6", "9", true], ["4 + 3", "7", true], ["2 + 5", "8", false], ["5 + 2", "9", false]],
									[["3 + 2", "8", false], ["2 + 3", "5", true], ["2 + 5", "6", false], ["3 + 4", "8", false], ["4 + 3", "7", true], ["1 + 7", "8", true], ["3 + 2", "7", false]],
								],
							]
						},
					};
			}
		}
	}
}


