<template>
	<g>
		<transition name="fade">
			<itemSet ref="itemSet" />
		</transition>
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		<play v-if="this.$store.state.taskContainer.taskPlaying" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_updating_digits/lang.js";
import instructions from "@/v/test_updating_digits/instructions.vue";
import play from "@/v/test_updating_digits/play.vue";
import itemSet from "@/v/test_updating_digits/itemSet.vue";

export default {
	name: "test_updating_digits",
	//genväg-alias:
	local: store.state.test_updating_digits,
	components: {
		instructions,
		play,
		itemSet,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	methods: {
		calculateParameters(level) {
			level = 1;
			return level;
		}
	},
	mounted() {
		store.commit("test_updating_digits_init_session");

		store.commit("test_updating_digits_reset_item_set");
		store.commit("test_updating_digits_show_item_set");
		store.commit("setTaskMode", "ready");
	}
};
</script>

<style lang="stylus"></style>
