export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
					return {
						odd_or_even: "Udda eller jämn?",
						low_or_high: "Låg eller hög?",
						odd: "Udda",
						even: "Jämn",
						low: "Låg",
						high: "Hög",
						seconds: "sekunder", //  123 seconds
						correct: "rätt", //  5/32 correct
						instructions: {
							t0_button: 'Tryck här för att se en instruktion ➤',
							t1: 'Siffror kommer visas, en i taget, i dessa fyra rutor',
							t2: 'Använd de här två knapparna på skärmen för att svara',
							t3: 'Om du har ett tangentbord så använd tangenterna F och J',
							t4: 'Olika svar behövs beroende på var siffran visas',
							t5: 'Om en siffra visas i någon av de två övre rutorna så <br>ska du avgöra om siffran är <font color="#000000"><b>udda</b></font> eller <font color="#000000"><b>jämn</b></font>',
							t6: 'Tryck på vänstra knappen om siffran är udda (1, 3, 7, 9)',
							t7: 'Tryck på högra knappen om siffran är jämn (2, 4, 6, 8)',
							t8: 'Om en siffra visas i någon av de två nedre rutorna så <br>ska du avgöra om siffran är <font color="#000000"><b>låg</b></font> eller <font color="#000000"><b>hög</b></font>',
							t9: 'Tryck på vänstra knappen om siffran är låg (1, 2, 3, 4)',
							t10: 'Tryck på högra knappen om siffran är hög (6, 7, 8, 9)',
							t11: 'Kom ihåg att trycka på rätt knapp när uppgiften skiftar:',
							t12: 'Försök svara så snabbt och korrekt som möjligt',
						},
					}
				case "fi":
					return {
						odd_or_even: "Pariton vai parillinen?",
						low_or_high: "Pieni vai suuri?",
						odd: "Pariton",
						even: "Parillinen",
						low: "Pieni",
						high: "Suuri",
						seconds: "sekuntia", //  123 seconds
						correct: "oikein", //  5/32 correct
						instructions: {
							t0_button: 'Klikkaa tästä ohjeeseen',
							t1: 'Lukuja näytetään yksi kerrallaan, missä tahansa näistä neljästä ruudusta',
							t2: 'Käytä näitä kahta näytöllä olevaa nappia vastataksesi',
							t3: 'Jos sinulla on näppäimistö, käytä F ja J näppäimiä',
							t4: 'Vastaukset riippuvat siitä, mihin luvut ilmestyvät',
							t5: 'Jos luku näytetään kummassa tahansa kahdesta ylimmästä ruudusta, <br>tehtävänäsi on määritellä onko se <font color="#000000"><b>pariton</b></font> vai <font color="#000000"><b>parillinen</b></font>',
							t6: 'Paina vasemman puoleista nappia, jos luku on pariton (1, 3, 7, 9)',
							t7: 'Paina oikean puoleista nappia, jos luku on parillinen (2, 4, 6, 8)',
							t8: 'Jos luku näytetään kummassa tahansa kahdesta alemmasta ruudusta, <br>tehtävänäsi on määritellä onko se <font color="#000000"><b>pieni</b></font> vai <font color="#000000"><b>suuri</b></font>',
							t9: 'Paina vasemman puoleista nappia, jos luku on pieni (1, 2, 3, 4)',
							t10: 'Paina oikean puoleista nappia, jos luku on suuri (6, 7, 8, 9)',
							t11: 'Muista painaa oikeaa nappia kun tehtävä vaihtuu:',
							t12: 'Pyri vastaamaan mahdollisimman nopeasti ja tarkasti',
						},
					}
				case "zh":
					return {
						odd_or_even: "单或双？",
						low_or_high: "小或大？",
						odd: "单",
						even: "双",
						low: "小",
						high: "大",
						seconds: "秒", //  123 seconds
						correct: "正确", //  5/32 correct
						instructions: {
							t0_button: '点击这里看示范',
							t1: '一些数字将一个接一个地显示在任何一个方格里',
							t2: '点击屏幕上的这两个按键来回答',
							t3: '如果您使用键盘，请使用F和J键',
							t4: '您的答案将取决于数字显示的位置',
							t5: '如果数字出现在上面的方格里<br>您将回答数字是<font color="#000000"><b>单数</b></font> 还是 <font color="#000000"><b>双数</b></font>',
							t6: '如果是单数，点击左按键(1, 3, 7, 9)',
							t7: '如果是双数，点击右按键(2, 4, 6, 8)',
							t8: '如果数字出现在下面的方格里<br>您将回答数字是<font color="#000000"><b>小数</b></font> 还是 <font color="#000000"><b>大数</b></font>',
							t9: '点击左按键如果是小数 (1, 2, 3, 4)',
							t10: '点击右按键如果是大数 (6, 7, 8, 9)',
							t11: '任务切换时，请注意点击正确的按键来回答：',
							t12: '尽量快速准确地做出回答',
						},
					}
				case "en":
				default:
					return {
						odd_or_even: "Odd or even?",
						low_or_high: "Low or high?",
						odd: "Odd",
						even: "Even",
						low: "Low",
						high: "High",
						seconds: "seconds", //  123 seconds
						correct: "correct", //  5/32 correct
						instructions: {
							t0_button: 'Press here to see an instruction ➤',
							t1: 'Digits will be presented, one at a time, in any of these four squares',
							t2: 'Use these two buttons on the screen to respond',
							t3: 'If you have a keyboard use the F and J keys',
							t4: 'Different responses are required depending on where the digits appear',
							t5: 'If a digit is presented in any of the two upper squares<br>your task is to determine whether it is <font color="#000000"><b>odd</b></font> or <font color="#000000"><b>even</b></font>',
							t6: 'Press the left button if the digit is odd (1, 3, 7, 9)',
							t7: 'Press the right button if the digit is even (2, 4, 6, 8)',
							t8: 'If a digit is presented in any of the two lower squares<br>your task is to determine whether it is <font color="#000000"><b>low</b></font> or <font color="#000000"><b>high</b></font>',
							t9: 'Press the left button if the digit is low (1, 2, 3, 4)',
							t10: 'Press the right button if the digit is high (6, 7, 8, 9)',
							t11: 'Remember to press the correct button when the task switches:',
							t12: 'Try to respond as quickly and accurately as possible',
						},
					}
			}
		}
	}
}
