const emptyGrid = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
const clickableGrid = [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]

export const ipark_updating_spatial = {
	state: {
		sessionScores: [],
		//-----------------------------------------
		// INSTRUCTIONS
		//-----------------------------------------
		instructionsPhase: -1,
		instructionsViewed: true,
		//-----------------------------------------
		// TRAINING
		//-----------------------------------------
		round: 0,
		level: 5,
		lastLevel: -1, // används för att skriva senaste nivån på skärmen
		numCorrect: 0,
		levelPerRound: [],
		numCorrectPerRound: [],
		//-----------------------------------------
		// GRID
		//-----------------------------------------
		showGrid: false,
		grid: emptyGrid.slice(0),
		acceptingClicks: false,
		clicks: [],
		list: [],
		response: [],
	},
	mutations: {
		// When adding **new** properties to an Object, you should either:
		//
		// • Use Vue.set(obj, 'newProp', 123), or
		//
		// • Replace that Object with a fresh one. For example,
		//   using the object spread syntax we can write it like this:
		//   state.obj = { ...state.obj, newProp: 123 }
		//-----------------------------------------
		// SESSION
		//-----------------------------------------
		ipark_updating_spatial_init(state, level) {
			state.level = level;
			state.round = 0;
			state.numCorrectPerRound = [];
		},
		//-----------------------------------------
		// INSTRUCTIONS
		//-----------------------------------------
		ipark_updating_spatial_setInstructionsViewed(state, viewed = true) {
			state.instructionsViewed = viewed;
		},
		taskPhase(state, nextPhase) {
			state.instructionsPhase = nextPhase;
		},
		//-----------------------------------------
		// TRAINING
		//-----------------------------------------
		ipark_updating_spatial_setNumCorrect(state, numCorrect) {
			state.numCorrect = numCorrect;
			var tmp = state.numCorrectPerRound.slice();
			tmp.push(numCorrect);
			state.numCorrectPerRound = tmp;
		},
		ipark_updating_spatial_setLevel(state, level) {
			state.level = level;
			var tmp = state.levelPerRound.slice();
			tmp.push(level);
			state.levelPerRound = tmp;
		},
		ipark_updating_spatial_setRound(state, newRound) {
			state.round = newRound;
		},
		ipark_updating_spatial_setList(state, newList) {
			state.list = newList;
			state.lastLevel = newList.length;
		},
		//-----------------------------------------
		// GRID
		//-----------------------------------------
		ipark_updating_spatial_showGrid(state, visible = true) {
			state.showGrid = visible;
		},
		hideGrid(state) {
			state.showGrid = false;
		},
		setGrid(state, newGrid) {
			state.grid = newGrid.slice(0);
		},
		ipark_updating_spatial_resetGrid(state) {
			state.grid = emptyGrid.slice(0);
		},
		ipark_updating_spatial_showInterludeGrid(state) {
			var newGrid = emptyGrid.slice(0);
			const response = state.response;

			for (var i = 0; i < response.length; ++i) {
				newGrid[response[i]] = 3;
			}
			state.grid = newGrid;
		},
		ipark_updating_spatial_setGridItem(state, { item: itemIndex, value: itemValue }) {
			/*const newGrid = Object.assign([...state.grid], {
				[itemIndex]: itemValue
			})
			state.grid = newGrid*/
			var newGrid = state.grid.slice(0);
			newGrid[itemIndex] = itemValue;
			state.grid = newGrid;
		},
		ipark_updating_spatial_presentItem(state, { index: listIndex, item: sqIndex }) {
			state.list_index = listIndex
			//const newGrid = Object.assign([...state.grid], { [sqIndex]: 1 })
			//state.grid = newGrid
			var newGrid = state.grid.slice(0);
			newGrid[sqIndex] = 1;
			state.grid = newGrid;
		},
		ipark_updating_spatial_setSquare(state, { sqIndex: sqIndex, mode: mode }) {
			/*const newGrid = Object.assign([...state.grid], {
				[sqIndex]: mode
			});
			state.grid = newGrid;*/
			var newGrid = state.grid.slice(0);
			newGrid[sqIndex] = mode;
			state.grid = newGrid;
		},
		ipark_updating_spatial_acceptClicks(state, yesNo) {
			state.acceptingClicks = yesNo;
			if (yesNo) {
				state.grid = clickableGrid.slice(0);
				state.response = [];
			}
		},
		ipark_updating_spatial_squareClicked(state, squareIndex) {
			var clicks = state.clicks.slice(0);
			clicks.push(squareIndex);
			state.clicks = clicks;


			const response = state.response;
			var newResponse;
			var newGrid;

			if (state.response.indexOf(squareIndex) < 0) {
				newResponse = response.slice(0);
				newResponse.push(squareIndex);
				newGrid = Object.assign([...state.grid], {
					[squareIndex]: 2
				});
			} else {
				newGrid = clickableGrid.slice(0); //emptyGrid.slice(0);
				newResponse = [];

				for (var i = 0; i < response.length; ++i) {
					if (response[i] == squareIndex) {
						break;
					}
					newGrid[response[i]] = 2;
					newResponse.push(response[i]);
				}
			}
			state.response = newResponse;
			state.grid = newGrid;
		}
	},
	actions: {
		spatialSpan_clickSquare({ commit, state, dispatch }, squareIndex) {
			if (state.acceptingClicks == false) {
				return;
			}

			if (state.response.length < 4) {
				commit('ipark_updating_spatial_squareClicked', squareIndex);
				if (state.response.length >= 4) {
					commit('ipark_updating_spatial_acceptClicks', false);
					dispatch('nextTaskPhase');
				}
			}
		}
	}
}
