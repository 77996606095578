<template>
	<g>
		<text
			v-if="odd_even"
			opacity="1.0"
			x="0"
			y="-330"
			class="txt"
			text-anchor="middle"
			dominant-baseline="auto"
			font-family="sans-serif"
			font-size="35"
			fill="#FFFFFF88"
		>Är bokstaven vokal eller konsonant?</text>
		<text
			v-if="low_high"
			opacity="1.0"
			x="0"
			y="330"
			class="txt"
			text-anchor="middle"
			dominant-baseline="hanging"
			font-family="sans-serif"
			font-size="35"
			fill="#FFFFFF88"
		>Är bokstaven tidig eller sen i alfabetet?</text>

		
		<timeline v-bind:stages="32" v-bind:pos="trial_in_block + 1" />

		<transition name="fade">
			<g v-if="block<6">
				<circle
					stroke="#00000020"
					stroke-width="10"
					fill="none"
					cx="0" cy="0" r="160"
				/>
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="none"
					x="-170" y="-170"
					width="340" height="340"
					rx="15"
				/>
			</g>
		</transition>
		<transition name="quickfade">
			<circle
				v-if="odd_even"
				stroke="#FFFFFF10"
				stroke-width="10"
				fill="#ff6666"
				cx="0" cy="0" r="150"
			/>
		</transition>
		<transition name="quickfade">
			<rect
				v-if="low_high"
				stroke="#FFFFFF10"
				stroke-width="10"
				fill="#6666ff"
				x="-150" y="-150"
				width="300" height="300"
				rx="15"
			/>
		</transition>

		<text
			opacity="1.0"
			x="0"
			y="0"
			class="txt"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="150"
			fill="#FFFFFF"
		>{{current_stimulus}}</text>


		<transition name="quickfade">
			<g v-if="block < 6" v-on:click="left_clicked">
				<path d="M -700 0 L -650 -100 -450 -100 -400 0 -450 100 -650 100 Z" fill="#00000020" />

				<text
					v-if="odd_even"
					opacity="1.0"
					x="-550"
					y="0"
					class="link"
					text-anchor="middle"
					dominant-baseline="middle"
				>Vokal</text>
				<text
					v-if="low_high"
					opacity="1.0"
					x="-550"
					y="0"
					class="link"
					text-anchor="middle"
					dominant-baseline="middle"
				>Tidig</text>
			</g>
		</transition>

		<transition name="quickfade">
			<g v-if="block < 6" v-on:click="right_clicked">
				<path d="M 700 0 L 650 -100 450 -100 400 0 450 100 650 100 Z" fill="#00000020"  />

				<text
					v-if="odd_even"
					opacity="1.0"
					x="550"
					y="0"
					class="link"
					text-anchor="middle"
					dominant-baseline="middle"
				>Konsonant</text>
				<text
					v-if="low_high"
					opacity="1.0"
					x="550"
					y="0"
					class="link"
					text-anchor="middle"
					dominant-baseline="middle"
				>Sen</text>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_shifting_predictable/lang.js";
import timeline from "@/v/timeline.vue";
//import gsap from "gsap";
//import play from "@/v/test_shifting_predictable/play.vue";

export default {
	name: "test_shifting_predictable",
	//genväg-alias:
	local: store.state.test_shifting_predictable,
	components: {
		timeline,
	},
	data: () => { return {
		trial: 0,
		items: [],
	}},
	//props: ['stim_param'],
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
		quadrant() {
			if (this.block == 0) {
				// Bara vokal / konsonant:
				return this.trial % 2;
			}
			else if (this.block == 1) {
				// Bara tidig / sen:
				return this.trial % 2 + 2;
			}
			else {
				// vokal / konsonant / tidig / sen
				return this.trial % 4;
			}
		},
		odd_even() {
			return this.quadrant < 2;
		},
		low_high() {
			return this.quadrant >= 2;
		},
		current_stimulus() {
			return this.items[this.trial]
		},
		block() {
			return Math.floor(this.trial / 32);
		},
		trial_in_block() {
			return this.trial % 32;
		},
		test_active() {
			return true;
		}
	},
	methods: {
		end_test() {
			store.commit('setTaskMode', 'clickToExit');
		},
		left_clicked() {
			++this.trial;
		},
		right_clicked() {
			++this.trial;
		}
	},
	mounted() {
		/**
		 * Animerade instruktioner, med hand, och kommentarer: "7 är udda", "2 är låg".
		 * 
		 * Liten paus innan siffran visas? först box, sen siffra?
		 * 
		 * Paus mellan varje block med pauscirkel.
		*/
		const letters = ['a', 'b', 'e', 'c', 'u', 'v', 'y', 'x'];

		store.commit('setTaskMode', 'playing');
		var i, v;
		for (i = 0; i < 6 * 32; ++i) {
			v = Math.floor(Math.random() * 7.9999);
			this.items.push(letters[v]);
		}
		/*var j, n;
		for (j = 1; j < 10; ++j) {
			n = 0;
			for (i = 0; i < 6 * 32; ++i) {
				if (this.items[i] == j) ++n;
			}
		}*/
		this.trial = 64;
	}
};
</script>

<style lang="stylus">
	.mini-balloon-enter-active, .mini-balloon-leave-active {
		transition: all 0.75s;
	}
	.mini-balloon-enter, .mini-balloon-leave-to {
		opacity: 0;
		transform: translate(350px, 10px);
	}
</style>
