<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="this.phase == 1 || this.phase == 2"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.task(4)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="this.phase == 3 || this.phase == 4"
				x="-790"
				y="-495"
				width="1580"
				height="99"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.respond(4)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<g v-if="this.phase > 6 || (this.phase == 0 && this.round > 0)">
				<foreignObject x="-600" y="-495" width="1200" height="99">
					<div class="txtbox">
						<span
							v-html="
								$parent.text.results.numCorrect(
									this.$store.state.finger_updating_digits
										.numCorrect,
									4
								)
							"
						/>
					</div>
				</foreignObject>
				<timeline v-bind:stages="5" v-bind:pos="this.round" />
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";

export default {
	name: "training",
	components: {
		timeline,
	},
	watch: {
		phase: function (/*newPhase*/) {
			this.update();
		},
	},
	data: () => {
		return {
			start_time: 0,
			round: 0,
			rounds: [],
			items_correct: 0,
			lists_correct: 0,
			num_to_recall: 0,
			level: 0,
			lengths: [],
			//placebo_mode: false,
			//crit_mode: false,
			round_answer_start_time: 0,
			demo: false,
		};
	},
	methods: {
		update() {
			switch (this.$store.state.taskContainer.taskPhase) {
				case 0:
				case 1: // Din uppgift...
					store.commit("finger_updating_digits_resetitemSet");
					//store.dispatch('nextTaskPhase', 2000);
					this.$parent.$parent.$refs.countDown.play(() =>
						store.commit("taskPhase", 2)
					);
					break;
				case 2: // Din uppgift...
					//sel.classList.remove("cursorHide");
					//sel.classList.add("cursorPointer");

					store.commit("finger_updating_digits_showitemSet");
					this.$parent.$refs.itemSet.presentList(
						this.lists[this.round],
						() => store.commit("taskPhase", 3)
					);
					break;
				case 3: // interlude
					store.dispatch("nextTaskPhase", 200);
					break;
				case 4: // respond
					//sel.classList.add("cursorPointer");
					//sel.classList.remove("cursorHide");
					store.commit("finger_updating_digits_acceptClicks", true);
					this.round_answer_start_time = Date.now();
					break;
				case 5:
					store.commit(
						"finger_updating_digits_showResponseItems",
						false
					);
					store.dispatch("nextTaskPhase", 2000);
					break;
				case 6: // show round results
					var result = this.$parent.$refs.itemSet.evalResponse();
					result.time = Date.now() - this.round_answer_start_time;
					this.rounds.push(result);
					this.items_correct += result.numC;
					if (result.numC == this.num_to_recall) this.lists_correct++;
					this.round++;
					store.dispatch("nextTaskPhase");
					break;
				case 7: // show round results, 2
					if (this.round < this.lists.length) {
						store.commit("showContinueButton");
					} else {
						setTimeout(() => store.commit("taskPhase", 9), 7000);
					}
					break;
				case 8:
					store.commit("setTaskMode", "playing");
					break;
				case 9: // done!
					if (this.demo) {
						store.commit("setTaskMode", "clickToExit");
					} else {
						this.saveSessionResults();
					}
					break;
			}
		},
		createLists() {
			const types = ["1", "2", "3", "4"];
			var lists = [];
			/*lists.push(this.createRandomList(types, this.lengths[0], true));
			lists.push(this.createRandomList(types, this.lengths[1], true));
			lists.push(this.createRandomList(types, this.lengths[2], true));
			lists.push(this.createRandomList(types, this.lengths[3], true));
			lists.push(this.createRandomList(types, this.lengths[4], true));*/
			for (var n = 0; n < this.lengths.length; ++n) {
				lists.push(this.createRandomList(types, this.lengths[n], true));
			}

			// shuffle
			var m = lists.length,
				t,
				i;
			while (m) {
				i = Math.floor(Math.random() * m--);
				t = lists[m];
				lists[m] = lists[i];
				lists[i] = t;
			}
			this.lists = lists;

			return lists;
		},
		/**
		 * Retrieve a specified quantity of elements from an array, at random.
		 *
		 * @param {Array} set of values to select from.
		 * @param {Number} quantity of elements to retrieve.
		 * @param {Boolean} allow the same number to be returned twice.
		 * @return {Array} random elements.
		 */
		createRandomList(arr, quant, allowDuplicates) {
			if (arr.length === 0) {
				throw new Error("Empty array");
			} else if (quant > arr.length && !allowDuplicates) {
				throw new Error("Quantity requested exceeds size of array");
			}

			if (allowDuplicates === true) {
				var result = [],
					i;
				var n0,
					n1 = -1;
				for (i = 0; i < quant; i++) {
					do {
						n0 = arr[Math.floor(Math.random() * arr.length)];
					} while (n0 == n1);
					result[i] = n0;
					n1 = n0;
				}
				return result;
			} else {
				return this.shuffle(arr).slice(0, quant);
			}
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			const done_num =
				this.$store.state.progress.updating_digits_num || 0;
			persist.set_progress_data("updating_digits_num", done_num + 1);

			//if (this.crit_mode) ...

			persist.log("results", {
				n: done_num,
				level: this.level,
				rounds: this.rounds,
				items_correct: this.items_correct,
				lists_correct: this.lists_correct,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
			});

			if (this.items_correct >= 16 && this.level < 2) {
				persist.set_progress_data(
					"updating_digits_level",
					this.level + 1
				);
			} else if (this.items_correct <= 4 && this.level > 0) {
				persist.set_progress_data(
					"updating_digits_level",
					this.level - 1
				);
			}

			persist
				.addToHistory("score", this.items_correct)
				.then((history) => {
					store.commit("setSessionScores", history);
					store.commit("setTaskMode", "sessionScore");
				});
		},
		set_list_lengths() {
			/*if (this.placebo_mode) {
				this.lengths = [4,4,4,4,4,4,4,4,4,4];
			}
			else {
				this.lengths = [5,6,7,8,9,10,11,12,13,14];
			}*/

			/*
			if (this.placebo_mode) {
				this.lengths = [4,4,4,4,4,4,4,4,4,4];
			}
			else if (this.crit_mode) {
				this.lengths = [5,6,7,8,9,10,11,12,13,14];
			}
			else {
				if (this.level == 0) {
					this.lengths = [4,5,6,6,7];
				}
				else if (this.level == 1) {
					this.lengths = [6,7,8,9,10];
				}
				else {
					const done = this.$store.state.progress.updating_digits_num || 0;
					this.lengths = done % 2 == 0? [5,7,9,11,13]: [6,8,10,12,14];
					// jämna sessionsnummer / udda sessionsnumer
				}
			}*/
			if (this.level == 0) {
				this.lengths = [4, 5, 6, 6, 7];
			} else if (this.level == 1) {
				this.lengths = [6, 7, 8, 9, 10];
			} else {
				const done =
					this.$store.state.progress.updating_digits_num || 0;
				this.lengths =
					done % 2 == 0 ? [5, 7, 9, 11, 13] : [6, 8, 10, 12, 14];
				// jämna sessionsnummer / udda sessionsnumer
			}
		},
	},
	computed: {
		phase: function () {
			return this.$store.state.taskContainer.taskPhase;
		},
	},
	mounted() {
		this.demo = this.$store.state.group == "demo";

		this.num_to_recall = 4;

		if (this.demo) {
			this.level = 0;
		} else {
			this.level = this.$store.state.progress.updating_digits_level || 0;
		}

		//this.placebo_mode = this.$store.state.condition == 'ctrl';
		this.set_list_lengths();
		this.createLists();

		store.commit("finger_updating_digits_resetitemSet");
		store.commit("finger_updating_digits_showitemSet");

		store.commit("finger_updating_digits_init", this.level);
		//store.commit("finger_updating_digits_init", {
		//	level: this.level, num_to_recall: this.num_to_recall});
		store.commit("setTaskMode", "playing");
		this.round = 0;
		this.start_time = Date.now();
		this.update();
	},
};
</script>
