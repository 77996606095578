<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="stage == 0"
				x="-600"
				y="-495"
				width="1200"
				height="160"
			>
				<div class="txtbox">
					<div class="link" v-on:click="begin()">
						<span v-html="$parent.text.instructions.t0_button" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="stage == 1"
				x="-790"
				y="-495"
				width="1580"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t1" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="stage == 2"
				x="-790"
				y="-495"
				width="1580"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t2" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="stage == 3"
				x="-790"
				y="-495"
				width="1580"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t3" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="stage == 4"
				x="-790"
				y="-495"
				width="1580"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t4" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="stage == 5"
				x="-790"
				y="-495"
				width="1580"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t5" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="stage == 6"
				x="-790"
				y="-495"
				width="1580"
				height="700"
			>
				<div class="txtbox">
					<span
						v-html="$parent.text.training.pick_colour_and_object"
					/>
				</div>
			</foreignObject>
		</transition>
		<hand v-show="handVisible" ref="hand" />
	</g>
</template>

<script>
import hand from "@/v/hand.vue";
import gsap from "gsap";

export default {
	name: "instructions",
	components: { hand },
	data: function () {
		return {
			handVisible: false,
			hand: null,
			stage: 0,
		};
	},
	methods: {
		begin() {
			//this.input_disabled = true; // förbjud input under instruktionen
			this.$store.commit("setTaskMode", "instructions");
			this.$store.commit("taskPhase", 1);
			this.handVisible = false;
			this.stage = -1;

			this.$parent.place_name = this.$parent.text.instructions.a_place;

			this.$parent.items = [];
			//this.$parent.items.push({ block: -2, quad: 0, stim: 1 });
			//this.$parent.items.push({ block: -2, quad: 1, stim: 2 });
			//this.$parent.items.push({ block: -2, quad: 2, stim: 3 });
			//this.$parent.items.push({ block: -2, quad: 3, stim: 4 });

			this.$parent.object_name1 =
				this.$parent.text.instructions.an_object;
			this.$parent.colour1 = this.$parent.colours[2];

			this.$parent.show_place = true;
			this.$parent.show_colour1 = true;
			this.$parent.show_colour2 = false;
			this.$parent.show_colour3 = false;
			this.$parent.show_colour4 = false;
			this.$parent.show_object1 = true;
			this.$parent.show_object2 = false;
			this.$parent.show_object3 = false;
			this.$parent.show_object4 = false;

			var t8 = gsap.timeline();
			t8.to(this, {
				delay: 2,
				onStart: () => {
					this.stage = 1;
				},
				duration: 6,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = -1;
				},
				duration: 1,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = 2;
				},
				duration: 10,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = -1;
				},
				duration: 1,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = 3;
				},
				duration: 12,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = -1;
				},
				duration: 1,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = 4;
				},
				duration: 12,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = -1;
				},
				duration: 1,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = 5;
				},
				duration: 12,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = -1;
				},
				duration: 1,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = 6;
					this.$parent.object_name1 = this.$parent.text.objects[2];
					this.$parent.object_name2 =
						this.$parent.text.instructions.an_object;
					this.$parent.object_name3 = this.$parent.text.objects[17];
					this.$parent.object_name4 = this.$parent.text.objects[320];
					this.$parent.colour1 = this.$parent.colours[2];
					this.$parent.colour2 = this.$parent.colours[3];
					this.$parent.colour3 = this.$parent.colours[4];
					this.$parent.colour4 = this.$parent.colours[6];
					this.$parent.show_colour1 = true;
					this.$parent.show_colour2 = true;
					this.$parent.show_colour3 = true;
					this.$parent.show_colour4 = true;
					this.$parent.show_object1 = true;
					this.$parent.show_object2 = true;
					this.$parent.show_object3 = true;
					this.$parent.show_object4 = true;
				},
				duration: 4,
			});
			//t8.to(this, { onStart: () => { this.stage = -1; }, duration: 1 });

			t8.to(this, {
				onStart: () => {
					//this.stage = 6;
					this.handVisible = true;
					this.$refs.hand.show(() => {
						this.hand = gsap.timeline();
						this.hand.to("#hand", {
							duration: 3,
							x: -330,
							y: -110,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
							},
						});
						this.hand.to("#hand", {
							delay: 1,
							duration: 1,
							onStart: () => {
								this.$parent.show_colour2 = false;
								this.$parent.show_colour3 = false;
								this.$parent.show_colour4 = false;
							},
						});
						this.hand.to("#hand", {
							delay: 1,
							duration: 2,
							x: 200,
							y: 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
							},
						});
						this.hand.to("#hand", {
							delay: 1,
							duration: 1,
							onStart: () => {
								this.$parent.show_object1 = false;
								this.$parent.show_object3 = false;
								this.$parent.show_object4 = false;
							},
						});
					});
				},
				duration: 12,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = -1;
					this.$refs.hand.hide();
				},
				duration: 7,
			});
			t8.to(this, {
				onStart: () => {
					this.stage = -1;
					this.handVisible = false;
				},
				duration: 2,
			});

			t8.to(this, {
				onStart: () => {
					this.stage = -1;
					this.$parent.setup();
				},
				duration: 1,
			});
		},
	},
	mounted() {
		this.stage = 0;
	},
};
</script>
