<template>
	<g>
		<transition name="fade"
			><g v-show="item == -2">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					:opacity="this.a_opacity"
					xlink:href="/img/aa.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					:opacity="this.b_opacity"
					xlink:href="/img/ab.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == -1">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					:opacity="this.a_opacity"
					xlink:href="/img/ba.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					:opacity="this.b_opacity"
					xlink:href="/img/bb.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 0">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					:opacity="this.a_opacity"
					xlink:href="/img/ca.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					:opacity="this.b_opacity"
					xlink:href="/img/cb.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 1">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/01a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/01b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 2">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/02a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/02b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 3">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/03a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/03b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 4">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/04a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/04b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 5">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/05a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/05b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 6">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/06a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/06b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 7">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/07a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/07b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 8">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/08a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/08b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 9">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/09a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/09b.jpg"
				/> </g
		></transition>

		<transition name="fade"
			><g v-show="item == 10">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/10a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/10b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 11">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/11a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/11b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 12">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/12a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/12b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 13">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/13a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/13b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 14">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/14a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/14b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 15">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/15a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/15b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 16">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/16a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/16b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 17">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/17a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/17b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 18">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/18a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/18b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 19">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/19a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/19b.jpg"
				/> </g
		></transition>

		<transition name="fade"
			><g v-show="item == 20">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/20a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/20b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 21">
				<image
					x="-500"
					y="-300"
					width="1000"
					height="400"
					xlink:href="/img/21a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/21b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 22">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/22a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/22b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 23">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/23a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/23b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 24">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/24a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/24b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 25">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/25a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/25b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 26">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/26a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/26b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 27">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/27a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/27b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 28">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/28a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/28b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 29">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/29a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/29b.jpg"
				/> </g
		></transition>

		<transition name="fade"
			><g v-show="item == 30">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/30a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/30b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 31">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/31a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/31b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 32">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/32a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/32b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 33">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/33a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/33b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 34">
				<image
					x="-200"
					y="-300"
					width="400"
					height="400"
					xlink:href="/img/34a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/34b.jpg"
				/> </g
		></transition>
		<transition name="fade"
			><g v-show="item == 35">
				<image
					x="-500"
					y="-300"
					width="1000"
					height="400"
					xlink:href="/img/35a.jpg"
				/>
				<image
					x="-500"
					y="150"
					width="1000"
					height="300"
					xlink:href="/img/35b.jpg"
				/> </g
		></transition>

		<g v-if="active_buttons">
			<rect
				x="-500"
				y="200"
				width="199"
				height="200"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(1)"
				class="clickable"
			/>
			<rect
				x="-300"
				y="200"
				width="199"
				height="200"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(2)"
				class="clickable"
			/>
			<rect
				x="-100"
				y="200"
				width="199"
				height="200"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(3)"
				class="clickable"
			/>
			<rect
				x="100"
				y="200"
				width="199"
				height="200"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(4)"
				class="clickable"
			/>
			<rect
				x="300"
				y="200"
				width="199"
				height="200"
				fill="#00000001"
				stroke="none"
				v-on:click="clicked(5)"
				class="clickable"
			/>
		</g>

		<transition name="quickfade">
			<g v-if="this.ins1"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.ins1" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins2"
				><foreignObject x="-400" y="120" width="800" height="200"
					><div class="readbox">
						<span v-html="text.ins2" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins3"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.ins3" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.ins4"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.ins4" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.look"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.look" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.fail_a"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.fail_a" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.fail_b"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.fail_b" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.fail_c"
				><foreignObject x="-500" y="-420" width="1000" height="200"
					><div class="readbox">
						<span v-html="text.fail_c" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.corr_a"
				><foreignObject x="-400" y="120" width="800" height="200"
					><div class="readbox">
						<span v-html="text.corr_a" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.corr_b1"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.corr_b1" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.corr_b2"
				><foreignObject x="-500" y="120" width="1000" height="200"
					><div class="readbox">
						<span v-html="text.corr_b2" /></div></foreignObject
			></g>
		</transition>
		<transition name="quickfade">
			<g v-if="this.corr_c"
				><foreignObject x="-500" y="80" width="1000" height="200"
					><div class="readbox">
						<span v-html="text.corr_c" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.point"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.point" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.next_failed"
				><foreignObject x="-400" y="-380" width="800" height="200"
					><div class="readbox">
						<span v-html="text.next_failed" /></div></foreignObject
			></g>
		</transition>

		<transition name="quickfade">
			<g v-if="this.done">
				<circle cx="0" cy="0" r="160" fill="#88FF88" stroke="none" />
				<text
					x="0"
					y="0"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="36"
					fill="#008800"
					>{{ text.done }}</text
				>
			</g>
		</transition>
		<hand v-show="handVisible" ref="hand" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_wisc4_mat/lang.js";
import hand from "@/v/hand.vue";
import persist from "@/persist.js";
import gsap from "gsap";

/*
		<filter id="blurMe">
			<feGaussianBlur in="SourceGraphic" stdDeviation="10" />
		</filter>


 		<transition name="quickfade">
			<rect x="-520" y="180" width="1040" height="240" fill="#FFFFFFcc" stroke="none" filter="url(#blurMe)" />
		</transition>

		<transition name="quickfade">
			<rect v-if="show_sq_bg" x="-220" y="-320" width="440" height="440" fill="#FFFFFFcc" stroke="none" filter="url(#blurMe)" />
		</transition>

		<transition name="quickfade">
			<rect v-if="show_rec_bg" x="-520" y="-200" width="1040" height="200" fill="#FFFFFFcc" stroke="none" filter="url(#blurMe)" />
		</transition>


		<rect x="-200" y="-300" width="200" height="200" fill="#FF000033" stroke="none" />
		<rect x="0" y="-300" width="200" height="200" fill="#FF880033" stroke="none" />
		<rect x="-200" y="-100" width="200" height="200" fill="#00FF0033" stroke="none" />
		<rect x="0" y="-100" width="200" height="200" fill="#0000FF33" stroke="none" />
*/

export default {
	name: "test_wisc4_mat",
	//genväg-alias:
	local: store.state.test_wisc4_mat,
	components: { hand },
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {
			done: false,
			item: -100,
			item_name: "?",
			handVisible: false,
			active_buttons: false,
			show_sq_bg: false,
			show_rec_bg: false,
			ins1: false,
			ins2: false,
			ins3: false,
			ins4: false,
			look: false,

			fail_a: false,
			fail_b: false,
			fail_c: false,

			corr_a: false,
			corr_b1: false,
			corr_b2: false,
			corr_c: false,
			point: false,
			next_failed: false,

			a_opacity: 0,
			b_opacity: 0,
			a_anim: 0,
			b_anim: 0,

			item_start_time: 0,
			num_failed_items: 0,

			results: {
				start_time: 0,
				total_time: 0,
				items: [],
			},
		};
	},
	watch: {
		item: function (/*new_item*/) {
			this.item_start_time = Date.now();
			this.active_buttons = false;
			this.handVisible = false;
			this.clearText();

			if (this.item == -2) this.item_name = "A";
			else if (this.item == -1) this.item_name = "B";
			else if (this.item == 0) this.item_name = "C";
			else this.item_name = this.item.toString();
			//console.log("--- new item: " + this.item_name);

			if (this.item == 21 || this.item == 35) {
				// annan utformning på dessa matriser
				this.show_rec_bg = true;
				this.show_sq_bg = false;
			} else if (this.item < 35) {
				this.show_rec_bg = false;
				this.show_sq_bg = true;
			}

			if (this.item < 1) {
				this.a_opacity = 1;
				this.b_opacity = 0.0;
				this.handVisible = true;
				this.$refs.hand.show(() => {
					var hand = gsap.timeline();
					hand.to("#hand", {
						delay: 0.5,
						duration: 2,
						x: -100,
						y: -200,
						ease: "power3.inOut",
						onComplete: () => {
							this.ins1 = true;
							//this.$refs.hand.click();
						},
					});
					hand.to("#hand", {
						duration: 1.5,
						x: 200,
						y: -200,
						ease: "power3.inOut",
					});
					hand.to("#hand", {
						duration: 1.5,
						x: -100,
						y: 70,
						ease: "power3.inOut",
					});
					hand.to("#hand", {
						duration: 1.5,
						x: 200,
						y: 70,
						ease: "power3.inOut",
					});
					hand.to("#hand", {
						duration: 1,
						onComplete: () => {
							this.ins1 = false;
							this.animateA(0.1);
							this.animateB(1);
						},
					});
					hand.to("#hand", {
						delay: 1,
						duration: 2,
						x: -450,
						y: 320,
						ease: "power3.inOut",
						onComplete: () => {
							this.ins2 = true;
						},
					});
					hand.to("#hand", {
						duration: 2,
						x: 490,
						y: 350,
						ease: "power3.inOut",
						onComplete: () => {},
					});
					hand.to("#hand", {
						duration: 2,
						onComplete: () => {
							this.ins2 = false;
							this.animateA(1);
							this.animateB(0.1);
						},
					});
					hand.to("#hand", {
						duration: 2,
						x: this.item == 0 ? 190 : 150,
						y: this.item == 0 ? -180 : 50,
						ease: "power3.inOut",
						onComplete: () => {
							this.ins3 = true;
							//this.animateA(1);
							//this.animateB(1);
						},
					});
					hand.to("#hand", {
						duration: 4,
						onComplete: () => {
							this.ins3 = false;
							this.$refs.hand.hide();
							this.active_buttons = true;
							this.animateB(1);
						},
					});
				});
			} else if (this.item > 35 && this.item < 1000) {
				this.clearText();
				this.test_is_over();
			} else {
				this.a_opacity = 1;
				this.b_opacity = 1;
				setTimeout(() => {
					this.active_buttons = true;
					this.animateB(1);
				}, 1000);
			}
		},
	},

	methods: {
		animateA(to) {
			var step = (to - this.a_opacity) / 50;
			this.a_anim = setInterval(() => {
				if (Math.abs(to - this.a_opacity) >= Math.abs(step)) {
					this.a_opacity += step;
				} else {
					clearInterval(this.a_anim);
					this.a_opacity = to;
				}
			}, 20);
		},
		animateB(to) {
			var step = (to - this.b_opacity) / 50;
			this.b_anim = setInterval(() => {
				if (Math.abs(to - this.b_opacity) >= Math.abs(step)) {
					this.b_opacity += step;
				} else {
					clearInterval(this.b_anim);
					this.b_opacity = to;
				}
			}, 20);
		},
		clearText() {
			this.look = false;
			this.ins1 = false;
			this.ins2 = false;
			this.ins3 = false;
			this.ins4 = false;
			this.fail_a = false;
			this.fail_b = false;
			this.fail_c = false;
			this.corr_a = false;
			this.corr_b1 = false;
			this.corr_b2 = false;
			this.corr_c = false;
			this.point = false;
			this.next_failed = false;
		},
		help_failed_a() {
			this.look = true;
			this.handVisible = true;
			this.$refs.hand.show(() => {
				var hand = gsap.timeline();
				hand.to("#hand", {
					delay: 2,
					duration: 2,
					x: 0,
					y: -100,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.fail_a = true;
					},
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1.5,
					x: -430,
					y: 330,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.corr_a = true;
					},
				});
				hand.to("#hand", {
					duration: 2,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1,
					x: 100,
					y: 50,
					ease: "power3.inOut",
					onComplete: () => {
						this.point = true;
						//this.animateA(1);
						//this.animateB(1);
					},
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
						this.next_failed = true;
						this.animateB(0);
					},
				});
				hand.to("#hand", {
					duration: 3,
					onComplete: () => {
						this.$refs.hand.hide();
						this.clearText();
						this.goto_next_item();
					},
				});
			});
		},
		help_failed_b() {
			this.look = true;
			this.handVisible = true;
			this.$refs.hand.show(() => {
				var hand = gsap.timeline();
				hand.to("#hand", {
					delay: 2,
					duration: 2,
					x: -130,
					y: -200,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.fail_b = true;
					},
				});
				hand.to("#hand", {
					duration: 1.5,
					x: 150,
					y: -200,
					ease: "power3.inOut",
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1.5,
					x: -100,
					y: 80,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.corr_b1 = true;
					},
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1.5,
					x: 460,
					y: 350,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.corr_b2 = true;
					},
				});
				hand.to("#hand", {
					duration: 5,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1,
					x: 100,
					y: 50,
					ease: "power3.inOut",
					onComplete: () => {
						this.point = true;
						//this.animateA(1);
						//this.animateB(1);
					},
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
						this.next_failed = true;
						this.animateB(0);
					},
				});
				hand.to("#hand", {
					duration: 3,
					onComplete: () => {
						this.clearText();
						this.$refs.hand.hide();
						this.goto_next_item();
					},
				});
			});
		},
		help_failed_c() {
			this.look = true;
			this.handVisible = true;
			this.$refs.hand.show(() => {
				var hand = gsap.timeline();
				hand.to("#hand", {
					delay: 2,
					duration: 2,
					x: -30,
					y: -130,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.fail_c = true;
					},
				});
				hand.to("#hand", {
					duration: 2,
					x: 30,
					y: -70,
					ease: "power3.inOut",
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1.5,
					x: 250,
					y: 340,
					ease: "power3.inOut",
					onComplete: () => {
						this.clearText();
						this.corr_c = true;
					},
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
					},
				});
				hand.to("#hand", {
					duration: 1,
					x: 140,
					y: -180,
					ease: "power3.inOut",
					onComplete: () => {
						this.point = true;
						//this.animateA(1);
						//this.animateB(1);
					},
				});
				hand.to("#hand", {
					duration: 4,
					onComplete: () => {
						this.clearText();
						this.next_failed = true;
						this.animateB(0);
					},
				});
				hand.to("#hand", {
					duration: 3,
					onComplete: () => {
						this.clearText();
						this.goto_next_item();
					},
				});
			});
		},

		clicked(r) {
			var item_rt = (Date.now() - this.item_start_time) / 1000;
			this.clearText();
			//console.log("clicked " + r);
			this.active_buttons = false;
			//this.log_question_response(true);
			var it = {
				n: this.item_name,
				rt: item_rt,
				r: r,
				c: 0,
			};

			switch (this.item) {
				case -2:
					if (r == 1) {
						this.ins4 = true;
						it.c = 1;
						setTimeout(() => {
							this.clearText();
							this.goto_next_item();
						}, 3000);
					} else {
						this.help_failed_a();
					}
					break;
				case -1:
					if (r == 5) {
						this.ins4 = true;
						it.c = 1;
						setTimeout(() => {
							this.clearText();
							this.goto_next_item();
						}, 3000);
					} else {
						this.help_failed_b();
					}
					break;
				case 0:
					if (r == 4) {
						it.c = 1;
						this.ins4 = true;
						setTimeout(() => {
							this.clearText();
							this.goto_next_item();
						}, 3000);
					} else {
						this.help_failed_c();
					}
					break;
				case 1:
					if (r == 2) it.c = 1;
					break;
				case 2:
					if (r == 3) it.c = 1;
					break;
				case 3:
					if (r == 4) it.c = 1;
					break;
				case 4:
					if (r == 2) it.c = 1;
					break;
				case 5:
					if (r == 5) it.c = 1;
					break;
				case 6:
					if (r == 1) it.c = 1;
					break;
				case 7:
					if (r == 4) it.c = 1;
					break;
				case 8:
					if (r == 4) it.c = 1;
					break;
				case 9:
					if (r == 3) it.c = 1;
					break;

				case 10:
					if (r == 2) it.c = 1;
					break;
				case 11:
					if (r == 1) it.c = 1;
					break;
				case 12:
					if (r == 5) it.c = 1;
					break;
				case 13:
					if (r == 1) it.c = 1;
					break;
				case 14:
					if (r == 4) it.c = 1;
					break;
				case 15:
					if (r == 1) it.c = 1;
					break;
				case 16:
					if (r == 2) it.c = 1;
					break;
				case 17:
					if (r == 5) it.c = 1;
					break;
				case 18:
					if (r == 4) it.c = 1;
					break;
				case 19:
					if (r == 2) it.c = 1;
					break;

				case 20:
					if (r == 3) it.c = 1;
					break;
				case 21:
					if (r == 4) it.c = 1;
					break;
				case 22:
					if (r == 3) it.c = 1;
					break;
				case 23:
					if (r == 5) it.c = 1;
					break;
				case 24:
					if (r == 3) it.c = 1;
					break;
				case 25:
					if (r == 4) it.c = 1;
					break;
				case 26:
					if (r == 2) it.c = 1;
					break;
				case 27:
					if (r == 5) it.c = 1;
					break;
				case 28:
					if (r == 1) it.c = 1;
					break;
				case 29:
					if (r == 2) it.c = 1;
					break;

				case 30:
					if (r == 1) it.c = 1;
					break;
				case 31:
					if (r == 2) it.c = 1;
					break;
				case 32:
					if (r == 3) it.c = 1;
					break;
				case 33:
					if (r == 4) it.c = 1;
					break;
				case 34:
					if (r == 1) it.c = 1;
					break;
				case 35:
					if (r == 5) it.c = 1;
					break;
			}

			if (it.c == 0) {
				this.num_failed_items += 1;
			} else {
				this.num_failed_items = 0;
			}

			//console.log(it);
			this.results.items.push(it);
			//console.log("failed: " + this.num_failed_items);

			if (this.item > 0) {
				if (this.num_failed_items > 3) {
					this.test_is_over();
				} else {
					this.goto_next_item();
				}
			}
		},
		goto_next_item() {
			if (this.item == 0) {
				//this.item = 4; // ålder 6-8 år
				this.item = 7; // ålder 9-11 år
				//this.item = 11; // ålder 12-16 år
			} else {
				this.item = this.item + 1;
			}
		},
		test_is_over() {
			this.clearText();
			this.done = true;
			//console.log("DONE!" );

			this.saveSessionResults();
			setTimeout(() => {
				//store.dispatch("gotoModule", "test_span_spatial_linear");
				store.dispatch("gotoModule", "home");
			}, 5000);
		},
		saveSessionResults() {
			this.results.total_time = Math.trunc(
				Date.now() - this.results.start_time
			);
			persist.log("results", this.results);
			persist.set_progress_data("mat", 1);
		},
	},
	mounted() {
		this.item = -2;
		this.results.start_time = Date.now();
	},
};
</script>