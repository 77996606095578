export const login = {
	state: {
		userName: "",
		password: "",
	},
	mutations: {
		login(state, { userName, password }) {
			state.userName = userName;
			state.password = password;
		}
	},
	actions: {
		login({ commit }, { userName, password }) {
			commit("login", { userName, password });
			/*
			var info = {
				timeOpened: new Date(),
				timezone: new Date().getTimezoneOffset() / 60,
				page: window.location.pathname,
				referrer: document.referrer,
				historyLength: history.length,
				navigatorAppName: navigator.appName,
				navigatorProduct: navigator.product,
				navigatorAppVersion: navigator.appVersion,
				navigatorUserAgent: navigator.userAgent,
				navigatorLanguage: navigator.language,
				navigatorOnLine: navigator.onLine,
				navigatorPlatform: navigator.platform,
				navigatorJavaEnabled: navigator.javaEnabled(),
				navigatorCookieEnabled: navigator.cookieEnabled,
				documentCookie: document.cookie,
				documentCookie2: decodeURIComponent(document.cookie.split(";")),
				//localStorage: localStorage
				screenWidth: screen.width,
				screenHeight: screen.height,
				documentWidth: document.width,
				documentHeight: document.height,
				innerWidth: innerWidth,
				innerHeight: innerHeight,
				screenAvailWidth: screen.availWidth,
				screenAvailHeight: screen.availHeight,
				screenColorDepth: screen.colorDepth,
				screenPixelDepth: screen.pixelDepth
			};*/
		},
	}
}
