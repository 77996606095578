export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "fi":
					return {
						instructions: {
							a: "Tässä näkyy 16 ruutua. Jotkut niistä tulevat <u>vilkkumaan</u>. Tehtäväsi on muistaa mitkä ruudut vilkkuivat ja missä järjestyksessä ne vilkkuivat.",
							b: "Paina tästä lukeaksesi ohjeet! ​➤​",
							c: "",
							d: "Paina mieleesi ne ruudut, jotka vilkkuvat",
							e: "3 oikein!",
							f: "Paina isoa nuolta aloittaaksesi tehtävän!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Paina mieleesi mikä ruutu vilkkuu';
								else if (num == 2) return 'Paina mieleesi molemmat ruudut, jotka vilkkuvat';
								else return `Paina mieleesi missä järjestyksessä kaikki ${num} ruutua vilkkuu`;
							},
							respond: (num) => {
								if (num == 1) return 'Paina sitä ruutua, joka vilkkui';
								else if (num == 2) return 'Paina molempia ruutuja, jotka vilkkuivat, samassa järjestyksessä';
								else return `Paina kaikkia ${num} ruutua samassa järjestyksessä`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Se ei ollut oikea ruutu';
									else return 'Oikein!';
								} else {
									if (num == 0) return `Et saanut yhtään oikein tällä kertaa.`;
									else if (num == tot) return `Sait kaikki oikein!`;
									else return `Sait ${num} / ${tot} oikein.`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Kierros ${num} / ${tot}`;
							}
						}
					};
				case "sv":
				default:
					return {
						taskName: "Rutan",
						instructions: {
							a: "Här visas 16 rutor. Några av dem kommer att <u>blinka</u>. Din uppgift är att minnas vilka rutor som blinkade och i vilken ordning de blinkade.",
							b: "Tryck här för att se en instruktion ➤",
							c: "Nu ska jag visa!",
							d: "Kom ihåg rutorna som blinkar",
							e: "3 rätt!",
							f: "Tryck på den stora pilen för att börja!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Kom ihåg vilken ruta som blinkar';
								else if (num == 2) return 'Kom ihåg båda rutorna som blinkar';
								else return `Kom ihåg i vilken ordning alla ${num} rutor blinkar`;
							},
							respond: (num) => {
								if (num == 1) return 'Tryck på rutan som blinkade';
								else if (num == 2) return 'Tryck på båda rutorna som blinkade, i samma ordning';
								else return `Tryck på alla ${num} rutor i samma ordning`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt ruta';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Omgång ${num} av ${tot}`;
							}
						}
					};
				/*case "en":
					return {
						instructions: {
							a: "16 squares are shown here. Some of them will <u>blink</u>. Your task is to remember which of the squares that blinked and in what order they blinked.",
							b: "Press here to see an instruction ➤",
							c: "Now I'm going to show you how this works!",
							d: "Remember the squares that blink",
							e: "Press the squares in the same order",
							f: "3 correct!",
							g: "Press the big arrow to begin!"
						}
					};*/
			}
		}
	}
}
