<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="phase == 0"
				x="-600"
				y="-495"
				width="1200"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.a" />
					<div
						class="link"
						v-on:click="$store.commit('taskPhase', 1)"
					>
						<span v-html="$parent.text.instructions.b" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 2"
				x="-600"
				y="-495"
				width="1200"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.c" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 4"
				x="-600"
				y="-495"
				width="1200"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.d" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 7 || phase == 8"
				x="-600"
				y="-495"
				width="1200"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.respond(3)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 11 || phase == 12"
				x="-600"
				y="-495"
				width="1200"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.e" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="
					phase == 0 &&
					this.$store.state.spatialSpan.instructionsViewed
				"
				x="-600"
				y="450"
				width="1200"
				height="100"
			>
				<div class="hint">
					<span v-html="$parent.text.instructions.f" />
				</div>
			</foreignObject>
		</transition>
		<hand v-show="handVisible" ref="hand" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import hand from "@/v/hand.vue";
import gsap from "gsap";

export default {
	name: "instructions",
	components: { hand },
	data: function () {
		return {
			handVisible: false,
		};
	},
	watch: {
		phase: function (/*newPhase*/) {
			this.update();
		},
	},
	methods: {
		update() {
			//const sel = document.getElementById("app");

			switch (this.$store.state.taskContainer.taskPhase) {
				case 0: // Din uppgift...
					//sel.classList.remove("cursorHide");
					//sel.classList.add("cursorPointer");

					store.commit("spatialSpan_resetGrid");
					store.commit("spatialSpan_showGrid");
					store.commit("setTaskMode", "ready");
					//store.dispatch('nextTaskPhase', 3000);
					break;
				case 1:
					store.commit("setTaskMode", "instructions");
					//sel.classList.remove("cursorPointer");
					//sel.classList.add("cursorHide");
					store.dispatch("nextTaskPhase", 500);
					break;
				case 2: // Vi börjar med ett exempel
					store.dispatch("nextTaskPhase", 3000);
					break;
				case 3:
					store.dispatch("nextTaskPhase", 1000);
					break;
				case 4: // Kom ihåg
					store.dispatch("nextTaskPhase", 5000);
					break;
				case 5:
					this.$parent.$parent.$refs.countDown.play(() =>
						store.commit("taskPhase", 6)
					);
					break;
				case 6:
					store.commit("spatialSpan_resetGrid");
					this.$parent.$refs.grid.presentList([3, 2, 1], () =>
						store.commit("taskPhase", 7)
					);
					break;
				case 7:
					store.commit("spatialSpan_resetGrid");
					this.handVisible = true;
					this.$refs.hand.show(() => {
						var hand = gsap.timeline();
						hand.to("#hand", {
							duration: 1.5,
							x: 290 + 90,
							y: -300 + 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
								store.commit("spatialSpan_setSquare", {
									sqIndex: 3,
									mode: 2,
								});
							},
						});
						hand.to("#hand", {
							delay: 1,
							duration: 1,
							x: 90 + 90,
							y: -310 + 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
								store.commit("spatialSpan_setSquare", {
									sqIndex: 2,
									mode: 2,
								});
							},
						});
						hand.to("#hand", {
							delay: 1,
							duration: 1,
							x: -90 + 90,
							y: -300 + 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
								store.commit("spatialSpan_setSquare", {
									sqIndex: 1,
									mode: 2,
								});
								store.commit("taskPhase", 8);
							},
						});
					});
					break;
				case 8:
					var t8 = gsap.timeline();
					t8.to(this, { delay: 1, onComplete: this.$refs.hand.hide });
					t8.to(this, {
						delay: 0.25,
						onComplete: () =>
							store.commit("spatialSpan_setSquare", {
								sqIndex: 3,
								mode: 3,
							}),
					});
					t8.to(this, {
						delay: 0.25,
						onComplete: () =>
							store.commit("spatialSpan_setSquare", {
								sqIndex: 2,
								mode: 3,
							}),
					});
					t8.to(this, {
						delay: 0.25,
						onComplete: () =>
							store.commit("spatialSpan_setSquare", {
								sqIndex: 1,
								mode: 3,
							}),
					});
					t8.to(this, {
						delay: 3,
						onComplete: () => store.commit("taskPhase", 9),
					});
					t8.play();
					break;
				case 9:
					store.dispatch("nextTaskPhase", 1000);
					break;
				case 10:
					var t9 = gsap.timeline();
					t9.to(this, {
						delay: 0.16,
						onComplete: () =>
							store.commit("spatialSpan_setSquare", {
								sqIndex: 3,
								mode: 4,
							}),
					});
					t9.to(this, {
						delay: 0.16,
						onComplete: () =>
							store.commit("spatialSpan_setSquare", {
								sqIndex: 2,
								mode: 4,
							}),
					});
					t9.to(this, {
						delay: 0.16,
						onComplete: () => {
							store.commit("spatialSpan_setSquare", {
								sqIndex: 1,
								mode: 4,
							});
							store.commit("taskPhase", 11);
						},
					});
					t9.play();
					break;
				case 11: // 3 rätt!
					store.dispatch("nextTaskPhase", 3000);
					break;
				case 12: // 3 rätt!
					store.commit("spatialSpan_setInstructionsViewed");
					store.commit("taskPhase", 0);
					store.commit("setTaskMode", "ready");
					break;
			}
		},
	},
	computed: {
		phase: function () {
			return this.$store.state.taskContainer.taskPhase;
		},
	},
	mounted() {
		this.update();
	},
};
</script>
