<template>
	<g>
		<defs>
			<path
				id="continueButtonArrow"
				d="M 0 -100 Q 0 -130, 30 -112 L 178 -9 Q 190 0, 178 9 L 30 112 Q 0 130 0 100 L 0 -100"
				fill="#F8F8F8F8"
				stroke="#00000033"
				stroke-width="6"
				transform-origin="top center"
			/>
		</defs>
		<g id="continueButton" opacity="1">
			<use x="-780" y="-50" xlink:href="#continueButtonArrow" />
			<text
				x="-705"
				y="-50"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="28"
				fill="#000000CC"
			>{{$shared.text.taskUI.continue}}</text>
		</g>
	</g>
</template>

<script>
import gsap from "gsap";

export default {
	name: "continueButton",
	idleTween: null,
	mounted() {
		const arrow = "#continueButtonArrow";
		if (this.idleTween != null) {
			this.idleTween.kill();
			this.idleTween = null;
		}

		this.idleTween = gsap.timeline({ repeat: 10, repeatDelay: 4 });

		this.idleTween.to(arrow, {
			x: 12,
			y: -3,
			scaleX: 1.02,
			scaleY: 1.02,
			duration: 1
		});
		this.idleTween.to(arrow, {
			x: 0,
			y: 0,
			scaleX: 1,
			scaleY: 1,
			duration: 1
		});
	}
};
</script>
