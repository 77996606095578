export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
			default:
				return {
					pageTitle: "Dagens träningsuppgifter",
					currentSession: (num) => { return `Träningssession ${num}` },
					numTasksToday: (num) => {
						if (num == 0) return `Alla träningsuppgifter för idag är gjorda!`;
						else if (num == 1) return `Idag finns en träningsuppgift att göra:`;
						else return `Idag finns dessa ${num} träningsuppgifter att göra:`;
					},
					spatialSpanLabel: "Rutan",
					coloursUpdatingLabel: "Färgen",
					spatialUpdatingLabel: "Bollen",
					lettersUpdatingLabel: "Bokstaven",
					digitsUpdatingLabel: "Siffran",
					keepTrackLabel: "Håll kollen",
					bartLabel: 'Ballongen',
				};
			}
		}
	}
}
