const emptyitemSet = [' ', ' ', ' ', ' ', ' '];

export const test_updating_digits = {
	state: {
		//-----------------------------------------
		// INSTRUCTIONS
		//-----------------------------------------
		instructionsPhase: -1,
		instructionsViewed: true,
		//-----------------------------------------
		// TRAINING
		//-----------------------------------------
		round: 0,
		level: 2,
		numToRecall: 4,
		lastLevel: -1, // används för att skriva senaste nivån på skärmen
		numCorrect: 0,
		levelPerRound: [],
		numCorrectPerRound: [],
		//-----------------------------------------
		// itemSet
		//-----------------------------------------
		itemTypes: ['1', '2', '3', '4'],
		itemMarks: ['','','',''],
		visItems: [],
		showResponseItems: false,
		showitemSet: false,
		itemSet: emptyitemSet,
		acceptingClicks: false,
		clicks: [],
		list: [],
		response: [],
	},
	mutations: {
		// When adding **new** properties to an Object, you should either:
		//
		// • Use Vue.set(obj, 'newProp', 123), or
		//
		// • Replace that Object with a fresh one. For example,
		//   using the object spread syntax we can write it like this:
		//   state.obj = { ...state.obj, newProp: 123 }
		//-----------------------------------------
		//-----------------------------------------
		// SESSION
		//-----------------------------------------
		test_updating_digits_init_session(state) {
			state.round = 0;
			state.instructionsViewed = false;
		},
		test_updating_digits_initLevel(state, {level}) {
			state.level = level;
		},
		//-----------------------------------------
		// INSTRUCTIONS
		//-----------------------------------------
		test_updating_digits_setInstructionsViewed(state, viewed = true) {
			state.instructionsViewed = viewed;
		},
		taskPhase(state, nextPhase) {
			state.instructionsPhase = nextPhase;
		},
		//-----------------------------------------
		// TRAINING
		//-----------------------------------------
		test_updating_digits_setNumCorrect(state, numCorrect) {
			state.numCorrect = numCorrect;
			var tmp = state.numCorrectPerRound.slice();
			tmp.push(numCorrect);
			state.numCorrectPerRound = tmp;
		},
		test_updating_digits_setLevel(state, level) {
			state.level = level;
			var tmp = state.levelPerRound.slice();
			tmp.push(level);
			state.levelPerRound = tmp;
		},
		test_updating_digits_setRound(state, newRound) {
			state.round = newRound;
		},
		test_updating_digits_setList(state, newList) {
			state.list = newList;
			state.lastLevel = newList.length;
		},
		//-----------------------------------------
		// itemSet
		//-----------------------------------------
		test_updating_digits_reset_item_set(state) {
			const arr = emptyitemSet.concat(state.itemTypes);
			state.visItems = arr; //emptyitemSet.slice();
		},
		test_updating_digits_setVisItem(state, { idx: itemIndex, value: itemValue }) {
			const newVisItems = Object.assign([...state.visItems], {
				[itemIndex]: itemValue
			})
			state.visItems = newVisItems;
		},
		test_updating_digits_markItem(state, { idx: itemIndex, value: itemValue }) {
			const newItemMarks = Object.assign([...state.itemMarks], {
				[itemIndex]: itemValue
			})
			state.itemMarks = newItemMarks;
		},
		//----------- response
		test_updating_digits_showResponseItems(state, visible = true) {
			state.showResponseItems = visible;
		},
		//-----------------------------------------

		test_updating_digits_show_item_set(state, visible = true) {
			state.showitemSet = visible;
		},
		hideitemSet(state) {
			state.showitemSet = false;
		},
		test_updating_digits_acceptClicks(state, yesNo) {
			state.acceptingClicks = yesNo;
			state.showResponseItems = yesNo;
		},
		test_updating_digits_itemClicked(state, { itemIndex }) {
			var clicks = state.clicks.slice(0);
			clicks.push(itemIndex);
			state.clicks = clicks;


			var newVisItems = state.visItems.slice();

			if (itemIndex >= 5 && itemIndex <= 8 && state.visItems.length < 13) {
				const re = state.itemTypes[itemIndex - 5];
				if (newVisItems[9] == null)
					newVisItems[9] = re;
				else if (newVisItems[10] == null)
					newVisItems[10] = re;
				else if (newVisItems[11] == null)
					newVisItems[11] = re;
				else if (newVisItems[12] == null)
					newVisItems[12] = re;
				state.visItems = newVisItems;
			} else if (itemIndex > 8 && state.visItems.length > 8) {
				newVisItems = state.visItems.slice(0, itemIndex);
				state.visItems = newVisItems;
			}


		}
	},
	actions: {
		test_updating_digits_clickItem({ commit, state, dispatch }, { itemIndex, roboClick = false }) {
			if (state.acceptingClicks == false && roboClick == false) {
				return;
			}
			commit('test_updating_digits_itemClicked', { itemIndex, roboClick });
			if (state.visItems.length - 9 >= 4) {
				commit('test_updating_digits_acceptClicks', false);
				if (!roboClick) {
					dispatch('nextTaskPhase');
				}
			}
		}
	}
}
