// Här samlas alla funktioner som har med kommunikation med servern att göra.
// Logga in, spara resultat, läs in nivåer och historik.
//
// Local storage används för detta och för att lagra meddelanden som ännu inte
// skickats eller bekräfats av servern.
//
// Funktionerna här ska kunna fungera som proxy mot servern
// så att programmet fungerar hyggligt ett tag UTAN kontakt med servern.

//import { store } from './store';

const superagent = require('superagent');

export default {
	vue: null,
	shared: null,
	SERVER: 'http://' + location.hostname + ':80',
	pwd: '',

	init(vue, shared) {
		this.vue = vue;
		this.shared = shared;

		//console.log("persist language: ");
		//console.log(shared);
		//console.log(this.shared.browser_lang);

		if (location.protocol !== 'https:') {
			//console.log('http')
			this.SERVER = 'http://' + location.hostname + ':80';
			//location.replace(`https:${location.href.substring(location.protocol.length)}`);
		}
		else {
			//console.log('https')
			this.SERVER = 'https://' + location.hostname + ':443';
		}
	},

	async login(name = null, password = null) {
		localStorage.clear();


		//console.log('persist login');
		//console.log(name);
		//console.log(password);
		if (name && password) {

			const now = new Date();
			const start = new Date(now.getFullYear(), 0, 0);
			const diff = now - start;
			const oneDay = 1000 * 60 * 60 * 24;
			const day = Math.floor(diff / oneDay);
			//console.log('Day of year: ' + day);
			if (password == 'majakka99') {
				name += (' ' + day);
			}

			this.pwd = password;
			this.vue.$store.commit('setPwd', password);

			const hash = await computeHash(name, password);

			this.vue.$store.commit('setLoginName', name);
			this.vue.$store.commit('setLoginHash', hash);

			localStorage.setItem('current_name', name);
			localStorage.setItem('current_hash', hash);
		}
		else {
			this.vue.$store.commit('setLoginName', localStorage.getItem('current_name') || '');
			this.vue.$store.commit('setLoginHash', localStorage.getItem('current_hash') || '');
		}

		this.vue.$store.commit('setLanguage', this.get_locally_saved('lang'));
		this.vue.$store.commit('setSession', this.get_locally_saved('session'));
		this.vue.$store.commit('setStudy', this.get_locally_saved('study'));
		this.vue.$store.commit('setCondition', this.get_locally_saved('condition'));
		this.vue.$store.commit('setGroup', this.get_locally_saved('group'));

		//this.restore_progress();

		if (this.vue.$store.state.loginName && this.vue.$store.state.loginHash) {
			if (navigator.onLine) {
				const ok = await this.connect_to_server();
				if (ok) {
					this.upload_all_log_entries();
				}
				//console.log('ret ok');
				return ok;
			}
			else {
				//console.log('ret true');
				return true;
			}
		}
		//console.log('ret false');
		return false; // goto login screen
	},

	async connect_to_server() {
		//console.log('connect_to_server!');
		const name = this.vue.$store.state.loginName;
		const hash = this.vue.$store.state.loginHash;
		const timezone = new Date().getTimezoneOffset() / 60;
		const wid = window.innerWidth;
		const hig = window.innerHeight;

		return await superagent.post(this.SERVER + '/login')
			.send({ name: name, hash: hash, pwd: this.pwd, timezone: timezone, width: wid, height: hig })
			.then(res => {
				//console.log('PAYLOAD:');
				//console.log(res.text);
				const payload = JSON.parse(res.text);
				//console.log(payload);
				if (payload.success) {
					if (payload.lang == '' || payload.lang == '?' || payload.lang == null || payload.lang == undefined)
						this.vue.$store.commit('setLanguage', this.shared.browser_lang);
					else
						this.vue.$store.commit('setLanguage', payload.lang);


					this.vue.$store.commit('setSession', payload.session);
					this.vue.$store.commit('setStudy', payload.study);
					this.vue.$store.commit('setCondition', payload.cond);
					this.vue.$store.commit('setGroup', payload.group);

					this.save_locally('session', payload.session);
					this.save_locally('study', payload.study);
					this.save_locally('condition', payload.cond);
					this.save_locally('group', payload.group);

					if (this.pwd == 'majakka99') {
						this.vue.$store.commit('setLanguage', 'fi');
					}

					/* eslint-disable no-console */
					//console.log('payload.lang', payload.lang);
					if (payload.lang != null && payload.lang != '') {
						var lx = payload.lang;
						if (lx == '??') {
							lx = (window.navigator.userLanguage || window.navigator.language).substring(0, 2);
							if (lx != 'en' && lx != 'zh') {
								lx = 'zh';
							}
						}
						//console.log('save_locally', lx);
						this.save_locally('lang', lx);
					}
					/* eslint-enable no-console */

					if (payload.progress && payload.progress.length > 0) {
						//console.log('PROGRESS:');
						//console.log(payload.progress);
						const progress = JSON.parse(payload.progress);
						//console.log(progress);
						Object.entries(progress).forEach(e => {
							//console.log(e);
							const prop = e[0];
							const value = e[1];
							var obj = {}
							obj[prop] = value;
							localStorage.setItem(name + '_progress_' + prop, JSON.stringify(obj));
							this.vue.$store.commit('setProgress', obj);
							this.vue.$store.commit('setLoginProgressSnapshot', obj);
						});
					}
					//console.log('connect_to_server true');
					return true;
				}
				else {
					//console.error('persist -- login: failure');
					return false;
				}
			}).catch((/*err*/) => {
				//console.error(err);
				//console.log('connect_to_server err');
				return false;
			});
	},

	logout() {
		this.vue.$store.commit('setLoginName', '');
		this.vue.$store.commit('setLoginHash', '');
		localStorage.removeItem('current_name');
		localStorage.removeItem('current_hash');
	},

	get_locally_saved(field) {
		const value = localStorage.getItem(this.vue.$store.state.loginName + '_' + field) || 0;
		return value;
	},

	save_locally(field, value) {
		//console.log('save_locally: ' + field + ' = ' + value);
		localStorage.setItem(this.vue.$store.state.loginName + '_' + field, value);
	},

	restore_progress() {
		const name = this.vue.$store.state.loginName;
		const head = name + '_progress_';
		const keys = localStorage.length;
		var index = 0;
		while (index < keys) {
			const key = localStorage.key(index);
			if (key.startsWith(head)) {
				const obj_str = localStorage.getItem(key);
				const obj = JSON.parse(obj_str);
				if (obj) {
					this.vue.$store.commit('setProgress', obj);
					this.vue.$store.commit('setLoginProgressSnapshot', obj);
				}
			}
			index += 1;
		}
	},

	async set_progress_data(prop, value) {
		const name = this.vue.$store.state.loginName;
		var obj = {};
		obj[prop] = value;
		localStorage.setItem(name + '_progress_' + prop, JSON.stringify(obj));

		this.vue.$store.commit('setProgress', obj);
		return this.log('set_progress', obj);
	},

	async log(topic, data) {
		const timezone = new Date().getTimezoneOffset() / 60;
		const key = this.vue.$store.state.loginName + '_log_' + Math.random();
		const id = {
			name: this.vue.$store.state.loginName,
			hash: this.vue.$store.state.loginHash,
			time_session: this.vue.$store.state.session,
			time_event: Date.now(),
			timezone: timezone,
			study: this.vue.$store.state.study,
			cond: this.vue.$store.state.condition,
			mod: this.vue.$store.state.module,
			topic: topic
		};
		localStorage.setItem(key, JSON.stringify({ id, data }));

		if (navigator.onLine) {
			await this.attempt_to_upload(key, id, data);
		}
	},

	async attempt_to_upload(key, id, data) {
		return superagent.post(this.SERVER + '/upload')
			.send({ id: id, data: data })
			.then(res => {
				const data = JSON.parse(res.text);
				const success = data.success;
				if (success) {
					localStorage.removeItem(key);
					return true;
				}
				else {
					return false;
				}
			}).catch((/*err*/) => {
				//console.error(err);
				return false;
			});
	},

	// behöver nån mekanism för att ladda upp alla objekt som väntar på uppladdning
	// -- med visuell feedback till deltagaren, så att han/hon kan vänta tills det är klart.

	async upload_all_log_entries() {
		const name = this.vue.$store.state.loginName;
		const head = name + '_log_';
		const keys = localStorage.length;
		var index = 0;
		while (index < keys) {
			const key = localStorage.key(index);
			if (key.startsWith(head)) {
				const obj = JSON.parse(localStorage.getItem(key));
				await this.attempt_to_upload(key, { id: obj.id, data: obj.data });
			}
			index += 1;
		}
	},

	async history(topic, mod = this.vue.$store.state.module) {
		const id = {
			name: this.vue.$store.state.loginName,
			hash: this.vue.$store.state.loginHash,
			mod: mod,
			topic: topic
		};
		const key = this.vue.$store.state.loginName + '_history_' + mod + '_' + topic;

		if (navigator.onLine) {
			return await superagent.post(this.SERVER + '/download')
				.send({ id: id })
				.then(res => {
					const data = JSON.parse(res.text);
					const success = data.success;
					if (success) {
						localStorage.setItem(key, JSON.stringify(data.result));
						return data.result;
					}
					else {
						//console.error('persist -- failed to download: ');
						return JSON.parse(localStorage.getItem(key)) || [];
					}
				}).catch((/*err*/) => {
					//console.error(err);
					return JSON.parse(localStorage.getItem(key)) || [];
				});
		}
		else {
			return JSON.parse(localStorage.getItem(key)) || [];
		}
	},


	async addToHistory(topic, value) {
		var h = await this.history(topic);
		h.push(value);
		await this.log(topic, value);
		return h;
	}
}


async function computeHash(name, password) { //, token = '') {
	return name.substring(0, 1) + password.substring(0, 1);
	/*const salt = "psymet.app";
	const str = salt.concat(name).concat(password);
	//const str = name.concat(concat(password, token), salt);
	const arr = new TextEncoder().encode(str);
	return await crypto.subtle.digest("SHA-256", arr).then(buf => {
		var view = new Uint32Array(buf);
		var hash = '';
		for (var i in view) {
			hash = hash.concat(view[i].toString(36));
		}
		return hash;
	});*/
}
