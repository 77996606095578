<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="true" x="-600" y="-495" width="1200" height="99">
				<div class="txtbox">
					iTEST
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<g transform="translate(-200, -200)" v-on:click="this.test_span_spatial">
				<spatialSpanIcon />
			</g>
		</transition>
		<transition name="fade">
			<g transform="translate(-200, 200)" v-on:click="this.test_updating_digits">
				<digitsUpdatingIcon/>
			</g>
		</transition>
		<transition name="fade">
			<g transform="translate(200, -200)">
				<bartIcon balloon_param="X"/>
			</g>
		</transition>
		<transition name="fade">
			<g transform="translate(200, 200)">
				<bartIcon balloon_param="Y"/>
			</g>
		</transition>
		<transition name="fade">
			<g transform="translate(670, -490)">
				<logoutButton />
			</g>
		</transition>
	</g>
</template>

<script>
import lang from "@/v/taskMenu/lang.js";
import spatialSpanIcon from "@/v/taskMenu/icons/spatialSpanIcon.vue";
import digitsUpdatingIcon from "@/v/taskMenu/icons/digitsUpdatingIcon.vue";
import bartIcon from "@/v/taskMenu/icons/bartIcon.vue";
import logoutButton from "@/v/taskMenu/logoutButton.vue";
import { store } from "@/store.js";

export default {
	name: "taskMenu",
	components: {
		spatialSpanIcon,
		digitsUpdatingIcon,
		bartIcon,
		logoutButton
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	methods: {
		test_span_spatial() {store.dispatch("gotoModule", "test_span_spatial")},
		test_updating_digits() {store.dispatch("gotoModule", "test_updating_digits")},
	},
};
</script>
