const num_cards = 60;

const set_a = [
	{ x: 2, y: 4 },
	{ x: 2, y: 6 },
	{ x: 2, y: 8 },
	{ x: 2, y: 10 },
	{ x: 2, y: 12 },
	{ x: 3, y: 3 },
	{ x: 3, y: 5 },
	{ x: 3, y: 7 },
	{ x: 3, y: 9 },
	{ x: 3, y: 11 },
	{ x: 4, y: 2 },
	{ x: 4, y: 4 },
	{ x: 4, y: 6 },
	{ x: 4, y: 8 },
	{ x: 4, y: 10 },
	{ x: 4, y: 12 },
	{ x: 5, y: 3 },
	{ x: 5, y: 5 },
	{ x: 5, y: 7 },
	{ x: 5, y: 9 },
	{ x: 5, y: 11 },
	{ x: 6, y: 2 },
	{ x: 6, y: 4 },
	{ x: 6, y: 6 },
	{ x: 6, y: 8 },
	{ x: 6, y: 10 },
	{ x: 6, y: 12 },
	{ x: 7, y: 3 },
	{ x: 7, y: 5 },
	{ x: 7, y: 7 },
	{ x: 7, y: 9 },
	{ x: 7, y: 11 },
	{ x: 8, y: 2 },
	{ x: 8, y: 4 },
	{ x: 8, y: 6 },
	{ x: 8, y: 8 },
	{ x: 8, y: 10 },
	{ x: 8, y: 12 },
	{ x: 9, y: 3 },
	{ x: 9, y: 5 },
	{ x: 9, y: 7 },
	{ x: 9, y: 9 },
	{ x: 9, y: 11 },
	{ x: 10, y: 2 },
	{ x: 10, y: 4 },
	{ x: 10, y: 6 },
	{ x: 10, y: 8 },
	{ x: 10, y: 10 },
	{ x: 10, y: 12 },
	{ x: 11, y: 3 },
	{ x: 11, y: 5 },
	{ x: 11, y: 7 },
	{ x: 11, y: 9 },
	{ x: 11, y: 11 },
	{ x: 12, y: 2 },
	{ x: 12, y: 4 },
	{ x: 12, y: 6 },
	{ x: 12, y: 8 },
	{ x: 12, y: 10 },
	{ x: 12, y: 12 },
];

const set_b = [
	{ x: 2, y: 3 },
	{ x: 2, y: 5 },
	{ x: 2, y: 7 },
	{ x: 2, y: 9 },
	{ x: 2, y: 11 },
	{ x: 3, y: 2 },
	{ x: 3, y: 4 },
	{ x: 3, y: 6 },
	{ x: 3, y: 8 },
	{ x: 3, y: 10 },
	{ x: 3, y: 12 },
	{ x: 4, y: 3 },
	{ x: 4, y: 5 },
	{ x: 4, y: 7 },
	{ x: 4, y: 9 },
	{ x: 4, y: 11 },
	{ x: 5, y: 2 },
	{ x: 5, y: 4 },
	{ x: 5, y: 6 },
	{ x: 5, y: 8 },
	{ x: 5, y: 10 },
	{ x: 5, y: 12 },
	{ x: 6, y: 3 },
	{ x: 6, y: 5 },
	{ x: 6, y: 7 },
	{ x: 6, y: 9 },
	{ x: 6, y: 11 },
	{ x: 7, y: 2 },
	{ x: 7, y: 4 },
	{ x: 7, y: 6 },
	{ x: 7, y: 8 },
	{ x: 7, y: 10 },
	{ x: 7, y: 12 },
	{ x: 8, y: 3 },
	{ x: 8, y: 5 },
	{ x: 8, y: 7 },
	{ x: 8, y: 9 },
	{ x: 8, y: 11 },
	{ x: 9, y: 2 },
	{ x: 9, y: 4 },
	{ x: 9, y: 6 },
	{ x: 9, y: 8 },
	{ x: 9, y: 10 },
	{ x: 9, y: 12 },
	{ x: 10, y: 3 },
	{ x: 10, y: 5 },
	{ x: 10, y: 7 },
	{ x: 10, y: 9 },
	{ x: 10, y: 11 },
	{ x: 11, y: 2 },
	{ x: 11, y: 4 },
	{ x: 11, y: 6 },
	{ x: 11, y: 8 },
	{ x: 11, y: 10 },
	{ x: 11, y: 12 },
	{ x: 12, y: 3 },
	{ x: 12, y: 5 },
	{ x: 12, y: 7 },
	{ x: 12, y: 9 },
	{ x: 12, y: 11 },
];

export function get_current_item_sdfa(block, group) {
	//console.log("get item(block:" + block + ", group:" + group + ")");
	if (group == "ab") {
		if (block == 0)
			return set_a[0];
		else
			return set_b[0];
	}
	else {
		if (block == 0)
			return set_b[0];
		else
			return set_a[0];
	}
}

export function new_deck() {
	var deck = [];
	for (var i = 0; i < num_cards; i++) {
		deck.push({
			idx: i,
			err: 1,
			rt: 9000 + Math.trunc(Math.random() * 999)
		})
	}
	return deck;
}

export function save_deck(deck) {
	var save_state = {
		idx: [],
		err: [],
		rt: []
	};
	for (var i = 0; i < num_cards; i++) {
		save_state.idx.push(deck[i].idx);
		save_state.err.push(deck[i].err);
		save_state.rt.push(deck[i].rt);
	}
	return save_state;
}

export function load_deck(save_state) {
	var deck = [];
	for (var i = 0; i < num_cards; i++) {
		deck.push({
			idx: save_state.idx[i],
			err: save_state.err[i],
			rt: save_state.rt[i]
		})
	}
	return deck;
}

export function sort_deck(deck) {
	deck.sort((x, y) => (y.rt + y.err * 60) - (x.rt + x.err * 60));
}

export function update(deck, correct, rt) {
	deck[0].err = correct ? 0 : 1;
	deck[0].rt = rt;
	sort_deck(deck);
}

export function get_current_item(deck, set) {
	var item;
	if (set == 'A')
		item = set_a[deck[0].idx];
	//return set_a[deck[0].idx];
	else
		//return set_b[deck[0].idx];
		item = set_b[deck[0].idx];

	/*if (deck[0].rt > 9000) {
		console.log("NY:   " + item.x + "·" + item.y);
	}
	else {
		if (deck[0].err)
			console.log("err:  " + item.x + "·" + item.y + "   " + deck[0].rt);
		else
			console.log("corr: " + item.x + "·" + item.y + "   " + deck[0].rt);
	}*/
	return item;
}