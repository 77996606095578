export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
			default:
				return {
					taskName: "Ballongen",
					instructions: {
						a: 'Du har 30 ballonger att pumpa. Varje gång du pumpar så blir ballongen lite större. Det kan också hända att ballongen exploderar.<br><br>Ju större ballongen blir desto fler poäng ger ballongen, men om ballongen exploderar så ger den inga poäng alls. Uppgiften går ut på att få så många poäng som möjligt.<br><br>När du inte vill pumpa en ballong mer så trycker du på knappen: <font color="#FFCC66">Spara ballongen</font>. Du får då poäng efter hur stor ballongen är.',
						b: "Tryck på den stora pilen för att börja!",
					},
				}
			}
		}
	}
}
