<template>
	<g>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#FE1"
			x="-470" y="-200"
			width="200" height="120"
			rx="15"
		/>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#F90"
			x="-470" y="-50"
			width="200" height="120"
			rx="15"
		/>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#F00"
			x="-470" y="100"
			width="200" height="120"
			rx="15"
		/>

		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#FCC"
			x="-220" y="-200"
			width="200" height="120"
			rx="15"
		/>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#6F6"
			x="-220" y="-50"
			width="200" height="120"
			rx="15"
		/>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#282"
			x="-220" y="100"
			width="200" height="120"
			rx="15"
		/>

		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#A5F"
			x="20" y="-200"
			width="200" height="120"
			rx="15"
		/>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#8BF"
			x="20" y="-50"
			width="200" height="120"
			rx="15"
		/>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#00B"
			x="20" y="100"
			width="200" height="120"
			rx="15"
		/>

		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#FFF"
			x="270" y="-200"
			width="200" height="120"
			rx="15"
		/>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#888"
			x="270" y="-50"
			width="200" height="120"
			rx="15"
		/>
		<rect
			stroke="#00000020"
			stroke-width="10"
			fill="#000"
			x="270" y="100"
			width="200" height="120"
			rx="15"
		/>
		<transition name="fade">
			<foreignObject x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.see_colours" />
				</div>
			</foreignObject>
		</transition>

		<g v-on:click="answer_yes()">
			<rect
				stroke="#00000055"
				stroke-width="6"
				fill="#FFFFFF55"
				x="-470" y="300"
				width="430" height="120"
				rx="15"
			/>
			<text
				x="-255"
				y="360"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="30"
				fill="#000000CC"
			>{{$parent.text.yes}}</text>
		</g>
		<g v-on:click="answer_no()">
			<rect
				stroke="#00000055"
				stroke-width="6"
				fill="#FFFFFF55"
				x="40" y="300"
				width="430" height="120"
				rx="15"
			/>
			<text
				x="255"
				y="360"
				class="txt"
				text-anchor="middle"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="30"
				fill="#000000CC"
			>{{$parent.text.no}}</text>
		</g>
	</g>
</template>

<script>
import persist from "@/persist.js";

export default {
	name: "colour_test",
	methods: {
		answer_yes() {
			this.$parent.begin();
			persist.set_progress_data('emr_colours', 1);
			this.$parent.show_colour_test = false;
		},
		answer_no() {
			this.$parent.begin();
			persist.set_progress_data('emr_colours', 2);
			this.$parent.show_colour_test = false;
		},
	},
};
</script>
