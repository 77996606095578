<template>
  <g id="hand" opacity="0">
    <circle
      id="clickCircle1"
      fill="none"
      stroke="#FF000055"
      stroke-width="0.7"
      cx="0"
      cy="0"
      :r="c1r"
    />

    <circle
      id="clickCircle2"
      fill="none"
      stroke="#FF000055"
      stroke-width="0.7"
      cx="0"
      cy="0"
      :r="c2r"
    />

    <path
      stroke="#000000"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="#FFFFFF"
      d="M-1,11.5c-0.3-0.4-0.6-1.1-1.2-2c-0.3-0.5-1.2-1.5-1.5-1.9
		c-0.2-0.4-0.2-0.6-0.1-1c0.1-0.6,0.7-1.1,1.4-1.1c0.5,0,1,0.4,1.4,0.7c0.2,0.2,0.5,0.6,0.7,0.8c0.2,0.2,0.2,0.3,0.4,0.5
		c0.2,0.3,0.3,0.5,0.2,0.1c-0.1-0.5-0.2-1.3-0.4-2.1c-0.1-0.6-0.2-0.7-0.3-1.1c-0.1-0.5-0.2-0.8-0.3-1.3c-0.1-0.3-0.2-1.1-0.3-1.5
		c-0.1-0.5-0.1-1.4,0.3-1.8c0.3-0.3,0.9-0.4,1.3-0.2c0.5,0.3,0.8,1,0.9,1.3c0.2,0.5,0.4,1.2,0.5,2c0.2,1,0.5,2.5,0.5,2.8
		c0-0.4-0.1-1.1,0-1.5c0.1-0.3,0.3-0.7,0.7-0.8c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0.1,0.6,0.3,0.8,0.5c0.4,0.6,0.4,1.9,0.4,1.8
		c0.1-0.4,0.1-1.2,0.3-1.6c0.1-0.2,0.5-0.4,0.7-0.5c0.3-0.1,0.7-0.1,1,0c0.2,0,0.6,0.3,0.7,0.5c0.2,0.3,0.3,1.3,0.4,1.7
		c0,0.1,0.1-0.4,0.3-0.7c0.4-0.6,1.8-0.8,1.9,0.6c0,0.7,0,0.6,0,1.1c0,0.5,0,0.8,0,1.2c0,0.4-0.1,1.3-0.2,1.7
		c-0.1,0.3-0.4,1-0.7,1.4c0,0-1.1,1.2-1.2,1.8c-0.1,0.6-0.1,0.6-0.1,1c0,0.4,0.1,0.9,0.1,0.9s-0.8,0.1-1.2,0c-0.4-0.1-0.9-0.8-1-1.1
		c-0.2-0.3-0.5-0.3-0.7,0c-0.2,0.4-0.7,1.1-1.1,1.1c-0.7,0.1-2.1,0-3.1,0c0,0,0.2-1-0.2-1.4c-0.3-0.3-0.8-0.8-1.1-1.1L-1,11.5z"
    />
    <line
      fill="none"
      stroke="#000000"
      stroke-width="0.7"
      stroke-linecap="round"
      x1="7"
      y1="12.4"
      x2="7"
      y2="9"
    />

    <line
      fill="none"
      stroke="#000000"
      stroke-width="0.7"
      stroke-linecap="round"
      x1="5"
      y1="12.4"
      x2="5"
      y2="9"
    />

    <line
      fill="none"
      stroke="#000000"
      stroke-width="0.7"
      stroke-linecap="round"
      x1="3"
      y1="12.4"
      x2="3"
      y2="9"
    />
  </g>
</template>

<script>
import gsap from "gsap";
const hand = "#hand";

export default {
	name: "hand",
	data: function() {
		return {
			visible: true,
			c1r: 0,
			c2r: 0
		};
	},
	methods: {
		show: function(callback) {
			this.c1r = 0;
			this.c2r = 0;

			this.visible = true;
			gsap.fromTo(
				"#hand",
				{ opacity: 0, x: 700, y: 500, scale: 30 },
				{
					opacity: 1,
					x: 580,
					y: 200,
					scale: 20,
					duration: 2,
					ease: "power3.inOut",
					onComplete: () => {
						if (callback != null) callback();
					}
				}
			);
		},
		hide: function(callback) {
			this.c1r = 0;
			this.c2r = 0;

			this.visible = true;
			gsap.to("#hand", {
				opacity: 0,
				x: 400,
				y: 500,
				duration: 1,
				ease: "power3.inOut",
				onComplete: () => {
					if (callback != null) callback();
				}
			});
		},
		click: function() {
			var htl;
			htl = gsap.timeline();
			htl.to(hand, {
				scaleX: 19.5,
				scaleY: 19.5,
				duration: 0.25
			});
			htl.to(hand, { scaleX: 20, scaleY: 20, duration: 0.25 });

			this.c1r = 0;
			this.c2r = 0;
			var c1tl;
			c1tl = gsap.timeline({
				repeat: 1,
				repeatDelay: 0,
				onComplete: () => (this.c1r = 0)
			});
			c1tl.to(this, { c1r: 3, duration: 0.5 });
			var c2tl;
			c2tl = gsap.timeline({
				repeat: 0,
				delay: 0.25,
				repeatDelay: 0,
				onComplete: () => (this.c2r = 0)
			});
			c2tl.to(this, { c2r: 3, duration: 0.5 });
		}
	}
};
</script>

<style lang="stylus">
#hand {
  transform-origin: 4px 1px;
}
</style>
