<template>
	<g>
		<transition name="fade">
			<grid ref="grid" v-if="this.$store.state.spatialSpan.showGrid" />
		</transition>
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		<training v-if="this.$store.state.taskContainer.taskPlaying" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/mind_ad/wm_maintenance/lang.js";
import instructions from "@/v/mind_ad/wm_maintenance/instructions.vue";
import training from "@/v/mind_ad/wm_maintenance/training.vue";
import grid from "@/v/mind_ad/wm_maintenance/grid.vue";


export default {
	name: "mind_ad_wm_maintenance",
	//genväg-alias:
	local: store.state.spatialSpan,
	components: {
		instructions,
		training,
		grid,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	mounted() {
		var level = this.$store.state.progress.wm_maintenance_level || 3;
		store.commit("spatialSpan_initLevel", level);

		store.commit('setTaskMode', 'ready');

		store.commit("spatialSpan_initSession");
		store.commit("taskPhase", 0);
		store.commit("taskPhase", 0);
		store.commit("spatialSpan_setInstructionsViewed", true);
		store.commit("spatialSpan_resetGrid");
		store.commit("spatialSpan_showGrid");
	}
};
</script>
