export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
			default:
				return {
					question: "Hur mentalt trött känner du dig just nu?",
					a1: "1. Inte alls",
					a2: "2. Lite",
					a3: "3. Måttligt",
					a4: "4. Mycket",
					a5: "5. Väldigt mycket",
				}
			}
		}
	}
}
