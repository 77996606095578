<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="phase == 0"
				x="-600"
				y="-495"
				width="1200"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.a(4)" />
					<div
						class="link"
						v-on:click="$store.commit('taskPhase', 1)"
					>
						<span v-html="$parent.text.instructions.b" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 6"
				x="-790"
				y="-495"
				width="1580"
				height="700"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.c" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 8"
				x="-790"
				y="-495"
				width="1580"
				height="400"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.d" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 11 || phase == 12"
				x="-790"
				y="-495"
				width="1580"
				height="400"
			>
				<div class="txtbox">
					<span v-html="$parent.text.forward.respond(4)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="phase == 15 || phase == 16"
				x="-790"
				y="-495"
				width="1580"
				height="400"
			>
				<div class="txtbox">
					<span v-html="$parent.text.instructions.e" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="
					phase == 0 &&
					this.$store.state.finger_updating_spatial.instructionsViewed
				"
				x="-600"
				y="450"
				width="1200"
				height="100"
			>
				<div class="hint">
					<span v-html="$parent.text.instructions.f" />
				</div>
			</foreignObject>
		</transition>
		<hand v-show="handVisible" ref="hand" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import hand from "@/v/hand.vue";
import gsap from "gsap";

export default {
	name: "instructions",
	components: { hand },
	data: function () {
		return {
			handVisible: false,
		};
	},
	watch: {
		phase: function (/*newPhase*/) {
			this.update();
		},
	},
	methods: {
		update() {
			const placebo = this.$store.state.condition == "ctrl";
			switch (this.$store.state.taskContainer.taskPhase) {
				case 0: // Din uppgift...
					//sel.classList.remove("cursorHide");
					//sel.classList.add("cursorPointer");

					store.commit("finger_updating_spatial_resetGrid");
					store.commit("finger_updating_spatial_showGrid");
					//store.dispatch('nextTaskPhase', 3000);
					store.commit("setTaskMode", "ready");
					break;
				case 1:
					store.commit("setTaskMode", "instructions");
					//sel.classList.remove("cursorPointer");
					//sel.classList.add("cursorHide");
					store.dispatch("nextTaskPhase", 2000);
					//store.commit("taskPhase", 10)
					break;
				case 2: //---
					//store.dispatch('nextTaskPhase', 1000);
					store.commit("taskPhase", 6);
					break;
				case 6: // d2: "Snart kommer 3 bollar att blinka",
					store.dispatch("nextTaskPhase", 5000);
					break;
				case 7: //---
					store.dispatch("nextTaskPhase", 1000);
					break;
				case 8: // d3: "Men jag ska bara återge de två sista",
					store.dispatch("nextTaskPhase", 4000);
					break;
				case 9:
					this.$parent.$parent.$refs.countDown.play(() =>
						store.commit("taskPhase", 10)
					);
					break;
				case 10:
					store.commit("finger_updating_spatial_resetGrid");
					if (placebo) {
						this.$parent.$refs.grid.presentList([6, 7, 3, 2], () =>
							store.commit("taskPhase", 11)
						);
					} else {
						this.$parent.$refs.grid.presentList(
							[5, 6, 7, 3, 2],
							() => store.commit("taskPhase", 11)
						);
					}
					break;
				case 11:
					store.commit("finger_updating_spatial_resetGrid");
					this.handVisible = true;
					this.$refs.hand.show(() => {
						var hand = gsap.timeline();
						hand.to("#hand", {
							delay: 0.5,
							duration: 3,
							x: 90 + 90,
							y: -100 + 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
								store.commit(
									"finger_updating_spatial_setSquare",
									{
										sqIndex: 6,
										mode: 2,
									}
								);
							},
						});
						hand.to("#hand", {
							delay: 1,
							duration: 2,
							x: 290 + 90,
							y: -120 + 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
								store.commit(
									"finger_updating_spatial_setSquare",
									{
										sqIndex: 7,
										mode: 2,
									}
								);
							},
						});
						hand.to("#hand", {
							delay: 1,
							duration: 2,
							x: 280 + 90,
							y: -290 + 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
								store.commit(
									"finger_updating_spatial_setSquare",
									{
										sqIndex: 3,
										mode: 2,
									}
								);
							},
						});
						hand.to("#hand", {
							delay: 1,
							duration: 2,
							x: 90 + 90,
							y: -300 + 40,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
								store.commit(
									"finger_updating_spatial_setSquare",
									{
										sqIndex: 2,
										mode: 2,
									}
								);
								store.commit("taskPhase", 12);
							},
						});
					});
					break;
				case 12:
					var t8 = gsap.timeline();
					t8.to(this, { delay: 1, onComplete: this.$refs.hand.hide });
					t8.to(this, {
						delay: 0.25,
						onComplete: () =>
							store.commit("finger_updating_spatial_setSquare", {
								sqIndex: 6,
								mode: 3,
							}),
					});
					t8.to(this, {
						delay: 0.25,
						onComplete: () =>
							store.commit("finger_updating_spatial_setSquare", {
								sqIndex: 7,
								mode: 3,
							}),
					});
					t8.to(this, {
						delay: 0.25,
						onComplete: () =>
							store.commit("finger_updating_spatial_setSquare", {
								sqIndex: 3,
								mode: 3,
							}),
					});
					t8.to(this, {
						delay: 0.25,
						onComplete: () =>
							store.commit("finger_updating_spatial_setSquare", {
								sqIndex: 2,
								mode: 3,
							}),
					});
					t8.to(this, {
						delay: 3,
						onComplete: () => store.commit("taskPhase", 13),
					});
					break;
				case 13:
					store.dispatch("nextTaskPhase", 1000);
					break;
				case 14:
					var t9 = gsap.timeline();
					t9.to(this, {
						delay: 0.16,
						onComplete: () =>
							store.commit("finger_updating_spatial_setSquare", {
								sqIndex: 6,
								mode: 4,
							}),
					});
					t9.to(this, {
						delay: 0.16,
						onComplete: () =>
							store.commit("finger_updating_spatial_setSquare", {
								sqIndex: 7,
								mode: 4,
							}),
					});
					t9.to(this, {
						delay: 0.16,
						onComplete: () =>
							store.commit("finger_updating_spatial_setSquare", {
								sqIndex: 3,
								mode: 4,
							}),
					});
					t9.to(this, {
						delay: 0.16,
						onComplete: () => {
							store.commit("finger_updating_spatial_setSquare", {
								sqIndex: 2,
								mode: 4,
							});
							store.commit("taskPhase", 15);
						},
					});
					break;
				case 15: // 4 rätt!
					store.dispatch("nextTaskPhase", 5000);
					break;
				case 16: // 4 rätt!
					store.commit("setTaskMode", "ready");
					break;
			}
		},
	},
	computed: {
		phase: function () {
			return this.$store.state.taskContainer.taskPhase;
		},
	},
	mounted() {
		this.update();
	},
};
</script>
