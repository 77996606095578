export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
					return {
						taskName: "Bollen",
						instructions: {
							a: (tot) => {
								if (tot == 1) return `Här visas 16 bollar. Några av dem kommer att <u>blinka</u>. Din uppgift är att minnas den <font color="#FFCC66">sista</font> bollen som blinkade.`;
								else return `Här visas 16 bollar. Några av dem kommer att <u>blinka</u>. Din uppgift är att minnas de <font color="#FFCC66">${tot} sista</font> bollarna som blinkade och i vilken ordning de blinkade.`;
							},
							b: "Tryck här för att se en instruktion ➤",
							c: "Snart kommer ett antal bollar att blinka",
							d: `Uppgiften är att återge de <font color="#FFCC66">4 sista</font>`,
							e: "4 rätt!",
							f: "Tryck på den stora pilen för att börja!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Kom ihåg den <font color="#FFCC66">sista</font> bollen som blinkar';
								else if (num == 2) return 'Kom ihåg de <font color="#FFCC66">4 sista</font> bollarna som blinkar';
								else return `Kom ihåg i vilken ordning de <font color="#FFCC66">${num} sista</font> bollarna blinkar`;
							},
							respond: (num) => {
								if (num == 1) return 'Tryck på bollen som blinkade sist';
								else return `Tryck på de <font color="#FFCC66">${num} sista</font> bollarna som blinkade, i samma ordning`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt boll';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == 4) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Omgång ${num} av ${tot}`;
							}
						}
					};
				case "zh":
					return {
						instructions: {
							a: (tot) => {
								return `这里有16颗弹珠。有些将会 <u>亮起</u>。请记住 <font color="#FFCC66">最后 ${tot}</font> 颗亮起的弹珠以及它们亮起的顺序。`;
							},
							b: "点击这里看示范！ ➤",
							c: "一些弹珠将会亮起",
							d: `请记住最后<font color="#FFCC66">4</font>颗`,
							e: "4颗正确！",
							f: "点击大箭头准备开始",
						},
						forward: {
							task: (num) => {
								return `请记住<font color="#FFCC66">最后 ${num}</font> 颗弹珠亮起的顺序`;
							},
							respond: (num) => {
								return `以同样的顺序点击最后 <font color="#FFCC66">${num}</font> 颗弹珠`;
							}
						},
						results: {
							numCorrect: (num) => {
								if (num == 0) return "这次都不正确";
								else if (num == 4) return "完美！";
								else return `${num}/4个正确`;
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `${num}/${tot}个回合`;
							}
						}
					};
				case "fi":
					return {
						instructions: {
							a: (tot) => {
								return `Tässä on 16 marmorikuulaa. Joihinkin niistä <u>syttyy valo</u>. Tehtävänäsi on muistaa <font color="#FFCC66">viimeiset ${tot}</font> marmorikuulaa joihin syttyi valo, ja missä järjestyksessä se tapahtui.`;
							},
							b: "Klikkaa tästä ohjeeseen ➤",
							c: "Pian osaan marmorikuulista syttyy valo",
							d: `Muista viimeiset <font color="#FFCC66">4</font>`,
							e: "4 oikein!",
							f: "Paina isoa nuolta aloittaaksesi",
						},
						forward: {
							task: (num) => {
								return `Muista järjestys, jossa <font color="#FFCC66">viimeiset ${num}</font> marmorikuulaa syttyivät`;
							},
							respond: (num) => {
								return `Napauta <font color="#FFCC66">${num}</font> viimeistä marmorikuulaa, samassa järjestyksessä`;
							}
						},
						results: {
							numCorrect: (num) => {
								if (num == 0) return "Ei oikein tällä kertaa";
								else if (num == 4) return "Täydellistä!";
								else return `${num} neljästä oikein`;
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Kierros ${num} / ${tot}`;
							}
						}
					};
				case "en":
				default:
					return {
						instructions: {
							a: (tot) => {
								return `Here are 16 marbles. Some of them will <u>light up</u>. Your task is to remember the <font color="#FFCC66">last ${tot}</font> marbles that lit up and in what order they did so.`;
							},
							b: "Press here to see an instruction ➤",
							c: "Soon a number of marbles will light up",
							d: `Remember the last <font color="#FFCC66">4</font>`,
							e: "4 correct!",
							f: "Press the big arrow to begin",
						},
						forward: {
							task: (num) => {
								return `Remember the order in which the <font color="#FFCC66">last ${num}</font> marbles light up`;
							},
							respond: (num) => {
								return `Tap on the last <font color="#FFCC66">${num}</font> marbles, in the same order`;
							}
						},
						results: {
							numCorrect: (num) => {
								if (num == 0) return "No correct this time";
								else if (num == 4) return "Perfect!";
								else return `${num} of 4 correct`;
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Round ${num} of ${tot}`;
							}
						}
					};
			}
		}
	}
}
