export const test_bart = {
	state: {
		sessionScores: [],
		explosions: [],
		//-----------------------------------------
		balloon_pumps: 0,
		//-----------------------------------------
		total_points: 0,
		balloons_done: 0,
		param: '?',
	},
	mutations: {
		// When adding **new** properties to an Object, you should either:
		//
		// • Use Vue.set(obj, 'newProp', 123), or
		//
		// • Replace that Object with a fresh one. For example,
		//   using the object spread syntax we can write it like this:
		//   state.obj = { ...state.obj, newProp: 123 }
		//-----------------------------------------
		// SESSION
		//-----------------------------------------
		test_bart_init_session(state) {
			state.balloons_done = 0;
			state.balloon_pumps = 0;
			state.total_points = 0;
			state.explosions = [];
		},
		test_bart_set_total_points(state, num) {
			state.total_points = num;
		},
		test_bart_set_pumps(state, num) {
			state.balloon_pumps = num;
		},
		test_bart_set_balloons_done(state, num) {
			state.balloons_done = num;
		},
		test_bart_params(state, param) {
			state.param = param;
			if (param == 'X') {
				state.explosions = [25,12,16, /***/ 27,25,30,13,15,3,25,12,16,18,29,18,23,4,11,20,22,20,28,5,23,13,14,27,10,16,12,14,10,18];
			}
			else if (param == 'Y') {
				state.explosions = [25,12,16, /***/ 16,10,12,18,27,25,12,30,13,22,18,3,29,15,14,10,27,16,14,23,20,18,4,11,25,20,23,28,5,13];
			}
			else {
				state.explosions = [];
			}
		},
	},
	actions: {
	}
}
//#88AABBBB;