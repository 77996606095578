export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						taskName: "Färgen",
						instructions: {
							a: (tot) => {
								if (tot == 1) return `Färger kommer att visas, en i taget. Din uppgift är att minnas och återge den <font color="#FFCC66">sista</font> färgen.`;
								else return `Färger kommer att visas, en i taget. Din uppgift är att minnas och återge de <font color="#FFCC66">${tot} sista</font> färgerna.`;
							},
							b: "Tryck här för att se en instruktion ➤",
							c: "Nu ska jag visa!",
							d1: 'I det här exemplet ska <font color="#FFCC66">4 färger</font> återges',
							d2: "Snart kommer ett antal färger visas",
							d3: `Uppgiften är att återge de <font color="#FFCC66">4 sista</font>`,
							e: "4 rätt!",
							f: "Tryck på den stora pilen för att börja!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Kom ihåg den <font color="#FFCC66">sista</font> färgen';
								else return `Kom ihåg de <font color="#FFCC66">${num} sista</font> färgerna`;
							},
							respond: (num) => {
								if (num == 1) return 'Vilken färg var det som visades sist?';
								else return `Återge de <font color="#FFCC66">${num} sista</font> färgerna, i samma ordning`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt färg';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Omgång ${num} av ${tot}`;
							}
						}
					};
			}
		}
	}
}
