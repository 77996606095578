export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						vowel_or_consonant: "Vokal eller konsonant?",
						early_or_late: "Början eller slutet?",
						vowel: "Vokal",
						consonant: "Konsonant",
						early: "Början",
						late: "Slutet",
						seconds: "sekunder", //  123 seconds
						correct: "rätt", //  5/32 correct
						instructions: {
							t0_button: 'Tryck här för att se en instruktion ➤',
							t1: 'Mitt på skärmen kommer bokstäver att visas, en i taget',
							t2: 'Använd de här två knapparna på skärmen för att svara',
							t3: 'Om du har ett tangentbord så använd tangenterna F och J',
							t4: 'Olika svar behövs beroende på om en cirkel eller fyrkant visas',
							t5: 'Om en orange cirkel visas så <br>ska du avgöra om bokstaven är en <font color="#000000"><b>vokal</b></font> eller en <font color="#000000"><b>konsonant</b></font>',
							t6: 'Tryck på vänstra knappen om bokstaven en vokal (A, E, U, Y)',
							t7: 'Tryck på högra knappen om bokstaven är en konsonant (B, D, X, Z)',
							t8: 'Om en blå fyrkant visas så <br>ska du avgöra om bokstaven kommer i <font color="#000000"><b>början</b></font> eller i <font color="#000000"><b>slutet</b></font> av alfabetet',
							t9: 'Tryck på vänstra knappen om bokstaven kommer i början (A, B, D, E)',
							t10: 'Tryck på högra knappen om bokstaven kommer i slutet (U, X, Y, Z)',
							t11: 'Kom ihåg att trycka på rätt knapp när uppgiften skiftar',
							t12: 'Försök svara så snabbt och korrekt som möjligt',
						},
					}
			}
		}
	}
}
