export default {
	getLocalisedText(language) {
		switch (language) {
			case "sv":
				return {
					logout: "Logga ut",
					taskUI: {
						start: "Starta",
						continue: "Fortsätt",
						exit: "Avsluta",
						todaysScore: (score) => {
							return `Du fick sammanlagt ${score} poäng`;
						},
						todaysMistakes: (num) => {
							return `Antal misstag: ${num}`;
						},
						bestScore: "Det är ditt bästa resultat hittills! 🥳",
						lower_is_better: "Ju lägre desto bättre",
						higher_is_better: "Ju högre desto bättre",
					},
				};
			case "fi":
				return {
					logout: "Kirjaudu ulos",
					taskUI: {
						start: "Aloita",
						continue: "Jatka",
						exit: "Poistu",
						todaysScore: (score) => {
							return `Päivän tulos: ${score}`;
						},
						todaysMistakes: (num) => {
							return `Virheiden määrä tänään：${num}`;
						},
						bestScore: "🥳",
						lower_is_better: "Mitä vähemmän sen parempi",
						higher_is_better: "Mitä korkeampi sen parempi",
					},
				};
			case "zh":
				return {
					logout: "退出",
					taskUI: {
						start: "开始",
						continue: "继续",
						exit: "回到主页",
						todaysScore: (score) => {
							return `今天的分数：${score}`;
						},
						todaysMistakes: (num) => {
							return `今天的错误次数：${num}`;
						},
						lower_is_better: "越低越好",
						higher_is_better: "越高越好",
					},
				};
			case "en":
			default:
				return {
					logout: "Log out",
					taskUI: {
						start: "Begin",
						continue: "Continue",
						exit: "Exit",
						todaysScore: (score) => {
							return `Today's score: ${score}`;
						},
						todaysMistakes: (num) => {
							return `Number of mistakes today: ${num}`;
						},
						lower_is_better: "The lower the better",
						higher_is_better: "The higher the better",
					},
				};
		}
	}
}
