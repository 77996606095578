<template>
	<g>
		<transition name="fade">
			<grid ref="grid" v-if="this.$store.state.mind_ad_updating_spatial.showGrid" />
		</transition>
		<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		<training v-if="this.$store.state.taskContainer.taskPlaying" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/mind_ad/updating_spatial/lang.js";
import instructions from "@/v/mind_ad/updating_spatial/instructions.vue";
import training from "@/v/mind_ad/updating_spatial/training.vue";
import grid from "@/v/mind_ad/updating_spatial/grid.vue";

export default {
	name: "mind_ad_updating_spatial",
	//genväg-alias:
	local: store.state.mind_ad_updating_spatial,
	components: {
		instructions,
		training,
		grid,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		}
	},
	mounted() {
		/*
		const level = this.$store.state.progress.updating_spatial_level || 0;
		const params = this.calculateParameters(level);
		const numToRecall = 4;
		store.commit("mind_ad_updating_spatial_initLevel", {level, numToRecall});
		store.commit("taskPhase", 0);
		store.commit("mind_ad_updating_spatial_resetGrid");
		store.commit("mind_ad_updating_spatial_showGrid");
		*/
		store.commit("taskPhase", 0);
		store.commit('setTaskMode', 'ready');
	}
};
</script>

<style lang="stylus"></style>
