<template>
	<g>
		<g id="scoreHistory">
			<line x1="-510" y1="-300" x2="510" y2="-300" stroke="#00000040" stroke-width="2" />
			<line x1="-510" y1="-250" x2="510" y2="-250" stroke="#00000010" stroke-width="2" />
			<line x1="-510" y1="-200" x2="510" y2="-200" stroke="#00000040" stroke-width="2" />
			<line x1="-510" y1="-150" x2="510" y2="-150" stroke="#00000010" stroke-width="2" />
			<line x1="-510" y1="-100" x2="510" y2="-100" stroke="#00000040" stroke-width="2" />
			<line x1="-510" y1="-50" x2="510" y2="-50" stroke="#00000010" stroke-width="2" />
			<line
				x1="-510"
				y1="0"
				x2="510"
				y2="0"
				stroke="#000000"
				stroke-width="10"
				stroke-linecap="round"
			/>
			<g v-for="(score, idx) in scores" :key="idx">
				<g v-if="idx==scores.length-1">
					<rect
						rx="10"
						fill="#88FF88"
						stroke="#00000040"
						stroke-width="4"
						:x="-500+idx*50"
						:y="-300*score"
						width="45"
						:height="300*score"
					/>
					<path
						d="M -50 50 L 0 0 50 50 30 50 30 100 -30 100 -30 50 -50 50"
						fill="#88FF88AA"
						stroke="#00000040"
						stroke-width="4"
						:transform="translate(-480 + idx*50,20)"
					/>
					<foreignObject x="-500" y="150" width="1000" height="300">
						<div class="txtbox">
							{{$shared.text.taskUI.todaysScore(todaysScore)}}
						</div>
					</foreignObject>
				</g>
				<rect
					v-else
					rx="10"
					fill="#FFFFFF88"
					:x="-500+idx*50"
					:y="-300*score"
					width="40"
					:height="300*score"
				/>
			</g>
		</g>
	</g>
</template>

<script>
export default {
	name: "scoreHistory",
	props: {
		scoreListName: String
	},
	methods: {
		translate(x, y) {
			return "translate(" + x + "," + y + ")";
		},
		max(arr) {
			var max = -Infinity;
			var val;
			for (var i = 0; i < arr.length; i++) {
				val = +arr[i];
				if (max < val) {
					max = val;
				}
				// Math.max() returnerar NaN om nåt element inte är ett tal!
				if (val !== val) {
					return NaN;
				}
			}
			return max;
		}
	},
	computed: {
		scores: function() {
			const last20 = this.$store.state.taskContainer.sessionScores.slice(-20);
			const highest = Math.min(144, this.max(last20)); // 144 = 9 round *
			var normalizedScores = [];
			for (const s in last20) {
				const norm = Math.min(1.0, last20[s] / highest);
				normalizedScores.push(norm);
			}

			return normalizedScores;
		},
		todaysScore: function() {
			const lastScore = this.$store.state.taskContainer.sessionScores.slice(-20);
			return lastScore.slice(-1)[0];
		},
	},
	mounted() {
		//store.commit("setSessionScores", [1,2,3,4,5]);
	}
};
</script>
