<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="show_help" x="-600" y="-495" width="1200" height="700">
				<div class="txtbox">
					<span v-html="text.instructions.a" />
				</div>
			</foreignObject>
		</transition>

		<image 
			v-if="test_active"
			id="current_balloon" :x="balloon_x" :y="balloon_y" :width="balloon_width" :height="balloon_height" xlink:href="/img/balloon.png" />

		<transition-group name="mini-balloon" tag="g">
			<image v-for="n in b_row1" :key="n" :x="-775 + (n-1)*27" :y="400" width="36" height="27" xlink:href="/img/balloon.png" />
		</transition-group>
		
		<transition-group name="mini-balloon" tag="g">
			<image v-for="n in b_row2" :key="n+15" :x="-775 + (n-1)*27" :y="360" width="36" height="27" xlink:href="/img/balloon.png" />
		</transition-group>

		<text
			v-if="balloon_exploded && test_active"
			opacity="1.0"
			x="0"
			y="0"
			class="txt"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="50"
			fill="#FFFFFF"
		>Ballongen exploderade</text>

		<text
			opacity="1.0"
			x="600"
			y="400"
			class="txt"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="150"
			fill="#FFFFFF"
		>{{Math.floor(this.points)}}</text>
		<text
			opacity="1.0"
			x="600"
			y="470"
			class="txt"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="50"
			fill="#FFFFFF"
		>Dina poäng</text>

		<transition name="fade">
			<g v-if="test_active">
				<path d="M -120 370 L 120 370 180 440 120 510 -120 510 -180 440 Z" fill="#FFFFFF60" v-on:click="pump_clicked" />
				<text
					v-if="balloons_left > 0"
					opacity="1.0"
					x="-775"
					y="470"
					class="txt"
					text-anchor="start"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="50"
					fill="#FFFFFF"
				>Ballonger kvar</text>
			</g>
		</transition>


		<transition name="quickfade">
			<g v-if="active_balloon && test_active">
				<text
					opacity="1.0"
					x="0"
					y="410"
					class="link"
					text-anchor="middle"
					dominant-baseline="middle"
					v-on:click="pump_clicked"
				>Tryck här</text>
				<text
					opacity="1.0"
					x="0"
					y="460"
					class="link"
					text-anchor="middle"
					dominant-baseline="middle"
					v-on:click="pump_clicked"
				>för att pumpa</text>
				<rect x="400" y="150" width="375" height="100" rx="15" fill="#FFFFFF60" v-on:click="save_clicked"/>
				<text
					opacity="1.0"
					x="750"
					y="200"
					class="link"
					text-anchor="end"
					dominant-baseline="middle"
					v-on:click="save_clicked"
				>Spara ballongen ⇩</text>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/test_bart/lang.js";
import gsap from "gsap";
import persist from "@/persist.js";
//import play from "@/v/test_bart/play.vue";

export default {
	name: "test_bart",
	//genväg-alias:
	local: store.state.test_bart,
	components: {
		//play,
	},
	data: () => { return {
		pumps: 0.0,
		points: 0,
		balloon_size: 0.01,
		show_help: true,
		active_balloon: true,
		balloon_exploded: false,
		result_pumps: [],
		result_exploded: [],
		start_time: 0,
		pumps_to_exposion: [],
	}},
	props: ['balloon_param'],
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
		balloon_width() {
			return 618 * this.balloon_size;
		},
		balloon_height() {
			return 835 * this.balloon_size;
		},
		balloon_x() {
			return -this.balloon_width * 0.5;
		},
		balloon_y() {
			return 370 - this.balloon_height;
		},
		b_row1() {
			const b = this.balloons_left; //this.$store.state.test_bart.balloons_left;
			return Math.min(b, 16);
		},
		b_row2() {
			const b = this.balloons_left; //this.$store.state.test_bart.balloons_left;
			return Math.max(0, b - 16);
		},
		balloons_left() {
			return Math.max(0, 32 - this.$store.state.test_bart.balloons_done);
		},
		test_active() {
			return this.$store.state.test_bart.balloons_done < 33;
		}
	},
	methods: {
		end_test() {
			var sum_pumps = 0;
			var sum_saved_pumps = 0;
			var num_saved_balloons = 0;
			var num_exploded_balloons = 0;
			
			var b;
			for (b = 3; b < this.result_pumps.length; ++b) {
				sum_pumps += this.result_pumps[b];
				if (this.result_exploded[b]) {
					num_exploded_balloons += 1;
				} else {
					num_saved_balloons += 1;
					sum_saved_pumps += this.result_pumps[b];
				}
			}
			const avg_p = sum_pumps / this.result_pumps.length;
			const aanp = sum_saved_pumps / num_saved_balloons; // Adjusted Avg Num Pumps
			const score = this.$store.state.test_bart.total_points;
			const total_time = Date.now() - this.start_time;

			persist.log('results', {
				sum_pumps: sum_pumps,
				sum_saved_pumps: sum_saved_pumps,
				num_saved_balloons: num_saved_balloons,
				num_exploded_balloons: num_exploded_balloons,
				avg_p: avg_p,
				aanp: aanp,
				score: score,
				pumps: this.result_pumps,
				explosions: this.result_exploded,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				condition: this.balloon_param,
			});


			store.commit('setTaskMode', 'clickToExit');
		},
		reset_balloon() {
			store.commit("test_bart_set_pumps", 0);
			this.pumps = 0;
			this.balloon_exploded = false;

			this.active_balloon = true;
			this.balloon_size = Math.log(5.0) / Math.log(8) - 0.6;

			gsap.to("#current_balloon", 0, {x: 0, onComplete: () => {
				this.active_balloon = true;
				gsap.to("#current_balloon", 0.5, {delay: 0.5, opacity: 1});
			}});
		},
		next_balloon(exploded) {
			this.result_exploded.push(exploded? 1: 0);
			this.result_pumps.push(this.$store.state.test_bart.balloon_pumps);
			store.commit("test_bart_set_balloons_done", this.$store.state.test_bart.balloons_done + 1);
			if (this.test_active) {
				this.reset_balloon();
			}
			else {
				this.end_test();
			}
		},
		pump_clicked() {
			if (this.test_active && this.active_balloon)  {
				if (this.start_time == 0) this.start_time = Date.now();
				const pumps = this.$store.state.test_bart.balloon_pumps + 1;
				store.commit("test_bart_set_pumps", pumps);

				var do_explode = false;
				const exp = this.pumps_to_exposion;
				const done = this.$store.state.test_bart.balloons_done;
				if (exp == null || exp.length == 0) {
					do_explode = Math.random() <= 1.0 / Math.max(1, 31 - pumps);
				}
				else if(done < exp.length) {
					do_explode = (pumps >= exp[done]);
				}
				else {
					do_explode = true;
				}

				if (do_explode) {
					this.active_balloon = false;
					this.balloon_exploded = true;
					this.balloon_size = 0.01;
					gsap.to("#current_balloon", 0, {opacity: 0.0});
					setTimeout(() => {
						this.next_balloon(true);
					}, 3000);
				}
				else {
					gsap.to(this, 0.25, {pumps: pumps, onUpdate: () => {
						this.balloon_size = Math.log(5.0 + this.pumps) / Math.log(8) - 0.6;
					}});
				}
			}
			if (this.show_help) {
				store.commit('setTaskMode', 'playing');
				this.show_help = false;
			}
		},
		save_clicked() {
			if (this.show_help) {
				store.commit('setTaskMode', 'playing');
				this.show_help = false;
			}
			
			if (this.test_active && this.active_balloon)  {
				if (this.start_time == 0) this.start_time = Date.now();
				this.active_balloon = false;

				const new_points = 1 + this.$store.state.test_bart.total_points + this.$store.state.test_bart.balloon_pumps;
				this.points = this.$store.state.test_bart.total_points;
				store.commit("test_bart_set_total_points", new_points);
				gsap.to(this, 0.5, {points: new_points});

				gsap.to("#current_balloon", 1, {x: 700, opacity: 0, onComplete: () => {
					this.next_balloon(false);
				}});
			}
		}
	},
	mounted() {
		store.commit('setTaskMode', 'readyImmediate');

		store.commit("test_bart_init_session");
		this.active_balloon = true;

		this.reset_balloon();
		if (this.$store.state.test_bart.param == 'X') {
			this.pumps_to_exposion = [25,12,16, /***/ 27,25,30,13,15,3,25,12,16,18,29,18,23,4,11,20,22,20,28,5,23,13,14,27,10,16,12,14,10,18];
		} else {
			this.pumps_to_exposion = [25,12,16, /***/ 16,10,12,18,27,25,12,30,13,22,18,3,29,15,14,10,27,16,14,23,20,18,4,11,25,20,23,28,5,13];
		}
	}
};
</script>

<style lang="stylus">
	.mini-balloon-enter-active, .mini-balloon-leave-active {
		transition: all 0.75s;
	}
	.mini-balloon-enter, .mini-balloon-leave-to {
		opacity: 0;
		transform: translate(350px, 10px);
	}
</style>
