<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="phase == 0" x="-600" y="-495" width="1200" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.a" />
					<div class="link" v-on:click="$store.commit('taskPhase', 1)">
						<span v-html="$parent.text.instructions.b" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 1" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.c" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 3" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.d(this.items[0].word, this.catNames[0])" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 6" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.e(this.items[1].word)" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 9" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.d(this.items[2].word, this.catNames[1])" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 12" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.d(this.items[3].word, this.catNames[0])" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase >= 14" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.f" />
				</div>
			</foreignObject>
		</transition>
		<hand v-show="handVisible" ref="hand" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import Timer from "advanced-timer";
import hand from "@/v/hand.vue";
import gsap from "gsap";

export default {
	name: "instructions",
	components: { hand },
	data: function() {
		return {
			play: null,
			handVisible: false,
			categories: null,
			catNames: null,
			cat0: null,
			cat1: null,
			cat2: null,
			items: null,
		};
	},
	watch: {
		phase: function(/*newPhase*/) {
			this.update();
		}
	},
	methods: {
		createList() {
			this.categories = this.$parent.text.categories;
			this.catNames = [this.categories[0].categoryName, this.categories[1].categoryName];
			this.cat0 = this.categories[0];
			this.cat1 = this.categories[1];
			this.cat2 = this.categories[2];
			this.items = [
				{category: 0, word: this.cat0.words[0]},
				{category: 2, word: this.cat2.words[0]},
				{category: 1, word: this.cat1.words[1]},
				{category: 0, word: this.cat0.words[1]}
			];
			this.play.init_list(this.catNames, this.items);
			
		},
		type_word(input, word) {
			var x = 1;
			const elem = document.getElementById(input);
			var timer = new Timer(400).repeat(word.length).action(() => {
				const typed = word.substring(0, x++);
				elem.value = typed;
			});
			timer.start();
		},
		update() {
			const pre_delay = 2000;
			const text_delay = 5000;
			const present_delay = 2000;
			const anim_delay = 1500;
			const placebo = this.$store.state.condition == 'ctrl';
			
			switch (this.$store.state.taskContainer.taskPhase) {
			case 0: // Din uppgift...
				//sel.classList.remove("cursorHide");
				//sel.classList.add("cursorPointer");
				store.commit("setTaskMode", "ready");
				break;
			case 1:
				store.commit("setTaskMode", "instructions");
				this.createList();
				//store.commit("taskPhase", 14);
				store.dispatch('nextTaskPhase', 5000);
				break;
			case 2:
				// Pause
				store.dispatch('nextTaskPhase', pre_delay);
				break;
			case 3:
				// Ordet X passar i lådan Y
				this.play.present_item(this.items[0]);
				store.dispatch('nextTaskPhase', text_delay + present_delay);
				break;
			case 4:
				// Animera X
				this.play.animate_word(this.items[0]);
				store.dispatch('nextTaskPhase',  anim_delay);
				break;
			case 5:
				// Pause
				store.dispatch('nextTaskPhase', pre_delay);
				break;
			case 6:
				// Ordet X passar inte i någon låda
				this.play.present_item(this.items[1]);
				store.dispatch('nextTaskPhase', text_delay + present_delay);
				break;
			case 7:
				// Animera X
				this.play.animate_word(this.items[1]);
				store.dispatch('nextTaskPhase',  anim_delay);
				break;
			case 8:
				// Pause
				store.dispatch('nextTaskPhase', pre_delay);
				break;
			case 9:
				// Ordet X passar i lådan Y
				this.play.present_item(this.items[2]);
				store.dispatch('nextTaskPhase', text_delay + present_delay);
				break;
			case 10:
				// Animera X
				this.play.animate_word(this.items[2]);
				store.dispatch('nextTaskPhase',  anim_delay);
				break;
			case 11:
				// Pause
				if (placebo) {
					store.commit('taskPhase', 14);
				}
				else {
					store.dispatch('nextTaskPhase', pre_delay);					
				}
				break;
			case 12:
				// Ordet X passar i lådan Y?
				this.play.present_item(this.items[3]);
				store.dispatch('nextTaskPhase', text_delay + present_delay);
				break;
			case 13:
				// Animera X
				this.play.animate_word(this.items[3]);
				store.dispatch('nextTaskPhase',  anim_delay);
				break;
			case 14:
				this.play.set_cats_state('asking');
				store.dispatch('nextTaskPhase',  2000);
				break;
			case 15:
				this.handVisible = true;
				this.$refs.hand.show(() => {
					var hand = gsap.timeline();
					hand.to("#hand", {
						delay: 1,
						duration: 2,
						x: this.play.boxPos(0),
						y: -200,
						ease: "power3.inOut",
						onComplete: () => {
							this.$refs.hand.click();
						}
					});
					hand.to("#hand", {
						delay: 1,
						duration: 2,
						x: this.play.boxPos(0)+200, //0,
						y: -200,
						opacity: 0,
						ease: "power3.inOut",
						onComplete: () => {
							store.dispatch('nextTaskPhase', 1000);
						}
					});
				});
				//store.dispatch('nextTaskPhase', 500);
				break;
			case 16:
				this.play.set_cats_state('asking');
				if (placebo) {
					this.type_word('input0', this.items[0].word);
				} else {
					this.type_word('input0', this.items[3].word);
				}
				store.dispatch('nextTaskPhase', 5000);
				break;
			case 17:
				var hand = gsap.timeline();
				hand.to("#hand", {
					duration: 2,
					x: this.play.boxPos(1),
					y: -200,
					opacity: 1,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
					}
				});
				hand.to("#hand", {
					delay: 1,
					duration: 2,
					x: this.play.boxPos(1) + 200,
					y: -200,
					opacity: 0,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.hide();
						store.dispatch('nextTaskPhase', 1000);
					}
				});
				break;
			case 18:
				this.play.set_cats_state('asking');
				this.type_word('input1', this.items[2].word);
				store.dispatch('nextTaskPhase', 5000);
				break;
			case 19:
				this.play.set_cats_state('asking');
				this.play.boxes[0].state = 'correct';
				this.play.boxes[1].state = 'correct';
				store.dispatch('nextTaskPhase', 10000);
				break;
			default:
				store.commit("taskPhase", 0);
				store.commit("setTaskMode", "ready");
				this.play.nextList();
				break;
			}
		}
	},
	computed: {
		phase: function() {
			return this.$store.state.taskContainer.taskPhase;
		}
	},
	mounted() {
		this.update();
		this.play = this.$parent.$refs.play;
	}
};
</script>
