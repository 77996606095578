export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
					return {
						instructions: {
							t0: (number) => { return `Vänd på dessa kort och hitta alla <font color="#FFCC66">${number}</font> dolda par` },
							t0_button: 'Tryck här för att se en instruktion ➤',
							t2: 'Här ligger 6 kort',
							t3: 'Tryck på ett kort för att se dess symbol',
							t4: 'Tryck på ett annat kort',
							t5: 'Dessa två symboler är olika. Fortsätt leta!',
							t6: 'Välj ett nytt kort',
							t7: 'Den här symbolen har jag sett tidigare! Var var den?',
							t10: 'Välj ett nytt kort',
							t11: 'Och ett till...',
							t12: 'Jag har sett stjärnan tidigare!',
							t13: 'Här är den...',
							t14: 'Och den andra...',
							t17: 'Alla par är funna!'
						},
						points: 'Poäng: ',
						time: 'Tid: ',
					}
				case "fi": {
					return {
						instructions: {
							t0: (number) => { return `Käännä kortteja ja etsi <font color="#FFCC66">${number}</font> piilotettua paria` },
							t0_button: 'Klikkaa tästä ohjeeseen',
							t2: 'Tässä on 6 korttia',
							t3: 'Napauta korttia paljastaaksesi symbolin',
							t4: 'Napauta toista korttia',
							t5: 'Nämä kaksi symbolia eivät ole samanlaiset. Jatka etsimistä!',
							t6: 'Valitse uusi kortti',
							t7: 'Olen nähnyt tämän symbolin aiemmin! Missä se oli?',
							t10: 'Valitse uusi kortti',
							t11: 'Ja toinen',
							t12: 'Olen nähnyt tähden aiemmin!',
							t13: 'Tässä se on...',
							t14: 'Ja toinen...',
							t17: 'Kaikki parit löydetty!'
						},
						points: 'Pisteet: ',
						time: 'Aika: ',
					}
				}
				case "zh":
					return {
						instructions: {
							t0: (number) => { return `将卡片翻过来，寻找<font color="#FFCC66">${number}</font>对相同的卡片` },
							t0_button: '看示范点击这里',
							t2: '这里有6张卡片',
							t3: '点击卡片来翻看图案',
							t4: '再翻看另一张',
							t5: '这两张的图案不相同，请继续寻找',
							t6: '翻看新一张',
							t7: '这个图案在哪张卡片上出现过呢？',
							t10: '翻看另一张',
							t11: '再翻看另一张',
							t12: '这个星形出现过！',
							t13: '是这张…',
							t14: '和这张',
							t17: '配对完成！'
						},
						points: '得分: ',
						time: '時間: ',
					}
				case "en":
				default:
					return {
						instructions: {
							t0: (number) => { return `Turn these cards and find the <font color="#FFCC66">${number}</font> hidden pairs` },
							t0_button: 'Press here to see an instruction ➤',
							t2: 'Here are 6 cards',
							t3: 'Tap on a card to reveal its symbol',
							t4: 'Tap on another card',
							t5: 'These two symbols do not match. Keep searching!',
							t6: 'Pick a new card',
							t7: 'I have seen this symbol before! Where was it?',
							t10: 'Pick a new card',
							t11: 'And another one',
							t12: 'I have seen the star before!',
							t13: 'Here it is...',
							t14: 'And the other one...',
							t17: 'Found all the pairs!'
						},
						points: 'Points: ',
						time: 'Time: ',
					}
			}
		}
	}
}
