<template>
	<g class="button" v-on:click="$parent.tile_clicked(idx)">
		<rect rx="10" fill="#00000044" x="-5" y="5" width="180" height="180" />
		<rect :id="'sq' + idx + 'bg'" rx="10" fill="#CCCCCC" x="0" y="0" width="180" height="180" />
		<rect rx="5" fill="#0000000a" x="5" y="10" width="15" height="165" />
		<rect rx="5" fill="#FFFFFF2a" x="25" y="5" width="150" height="15" />
		<rect rx="5" fill="#FFFFFF2a" x="160" y="5" width="15" height="165" />
		<rect rx="5" fill="#0000000a" x="5" y="160" width="150" height="15" />

		<g :id="'sq' + idx + 'star'" opacity="0.0" >
			<path d="M 99 74 C 138 75 138 75 109 96 C 119 130 119 130 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74 C 90 40 90 40 102 74" stroke="#00000022" stroke-width="8" fill="#FFCC66"/>
			<path d="M 78 74 C 90 40 90 40 102 74 C 138 75 138 75 109 96" stroke="none" fill="#FFFFAA2C"/>
			<path d="M 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74" stroke="none" fill="#0000000C"/>
		</g>
	</g>
</template>

<script>
import gsap from "gsap";

export default {
	name: "sq",
	props: ['val', 'idx'],
	watch: {
		val: function(newVal /*, oldVal*/) {
			const sel = "#sq" + this.idx + "bg";
			const star = "#sq" + this.idx + "star";

			var tl;
			switch (newVal) {
			case 1: // blink
				tl = gsap.timeline();
				tl.to(sel, { fill: "#CC8822", duration: 0.2 });
				tl.to(sel, { fill: "#99BBCC", delay: 0.6, duration: 0.2 });
				break;
			case 2: // selected
				tl = gsap.timeline();
				tl.to(sel, { fill: "#55808E", duration: 0.2 });
				tl.to(sel, { fill: "#35606E", duration: 0.2 });
				break;
			case 3: // selected + inactive
				gsap.to(sel, { fill: "#35606E", duration: 1 });
				break;
			case 4: // correct
				tl = gsap.timeline();
				tl.to(sel, { fill: "#FFFFFF", duration: 0.1 });
				tl.to(sel, { fill: "#55AA55", delay: 0.1, duration: 2 });
				gsap.to(star, { opacity: "1", delay: 0.2, duration: 1.5 });
				break;
			case 5: // incorrect
				tl = gsap.timeline();
				tl.to(sel, { fill: "#000000", duration: 0.1 });
				tl.to(sel, { fill: "#884444", delay: 0.1, duration: 0.5 });
				break;
			case 6: // clickable + non-selected
				gsap.to(sel, { fill: "#CCCCCC", duration: 0.1 });
				break;
			case 7: // inactive, slowly
				gsap.to(sel, { fill: "#99BBCC", duration: 2.0 });
				gsap.to(star, { opacity: "0", duration: 2.0 });
				break;
			case 8: // hide
				gsap.to("#sq" + this.idx, { opacity: "0", duration: 2.0 });
				gsap.to(star, { opacity: "0", duration: 2.0 });
				break;
			default: // inactive
				gsap.to(sel, { fill: "#99BBCC", duration: 0.2 });
				gsap.to(star, { opacity: "0", duration: 0.1 });
			}
		},
	}
};
</script>
