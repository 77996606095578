<template>
	<g>
		<g v-if="test_active">
			<text
				v-if="odd_even"
				opacity="1.0"
				x="0"
				y="-320"
				class="txt"
				text-anchor="middle"
				dominant-baseline="auto"
				font-family="sans-serif"
				font-size="35"
				fill="#FFFFFFCC"
			>{{this.text.odd_or_even}}</text>
			<text
				v-if="low_high"
				opacity="1.0"
				x="0"
				y="320"
				class="txt"
				text-anchor="middle"
				dominant-baseline="hanging"
				font-family="sans-serif"
				font-size="35"
				fill="#FFFFFFCC"
			>{{this.text.low_or_high}}</text>

			
			<timeline v-if="block >= 0" v-bind:stages="32" v-bind:pos="pos_in_block + 1" />

			<transition name="fade">
				<g v-if="block<6">
					<rect
						stroke="#00000020"
						stroke-width="10"
						fill="none"
						x="-300" y="-300"
						width="600" height="600"
						rx="15"
					/>
					<line
						stroke="#00000020"
						stroke-width="12"
						stroke-dasharray="10, 9"
						x1="-290" y1="0" x2="290" y2="0"
					/>
					<line
						stroke="#00000020"
						stroke-width="12"
						stroke-dasharray="10, 9"
						x1="0" y1="-290" x2="0" y2="290"
					/>
				</g>
			</transition>

			<g v-if="item_visible">
				<transition name="quickfade">
					<rect
						v-if="quadrant == 0"
						stroke="#FFFFFF10"
						stroke-width="10"
						fill="#ff9922DD"
						x="-300" y="-300"
						width="300" height="300"
						rx="15"
					/>
				</transition>
				<transition name="quickfade">
					<rect
						v-if="quadrant == 1"
						stroke="#FFFFFF10"
						stroke-width="10"
						fill="#ff9922DD"
						x="0" y="-300"
						width="300" height="300"
						rx="15"
					/>
				</transition>
				<transition name="quickfade">
					<rect
						v-if="quadrant == 2"
						stroke="#FFFFFF10"
						stroke-width="10"
						fill="#2299ffDD"
						x="0" y="0"
						width="300" height="300"
						rx="15"
					/>
				</transition>
				<transition name="quickfade">
					<rect
						v-if="quadrant == 3"
						stroke="#FFFFFF10"
						stroke-width="10"
						fill="#2299ffDD"
						x="-300" y="0"
						width="300" height="300"
						rx="15"
					/>
				</transition>

				<transition name="quickfade">
					<text
						v-if="quadrant == 0"
						opacity="1.0"
						x="-150"
						y="-140"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="150"
						fill="#FFFFFF"
					>{{stimulus}}</text>
				</transition>
				<transition name="quickfade">
					<text
						v-if="quadrant == 1"
						opacity="1.0"
						x="150"
						y="-140"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="150"
						fill="#FFFFFF"
					>{{stimulus}}</text>
				</transition>
				<transition name="quickfade">
					<text
						v-if="quadrant == 2"
						opacity="1.0"
						x="150"
						y="160"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="150"
						fill="#FFFFFF"
					>{{stimulus}}</text>
				</transition>
				<transition name="quickfade">
					<text
						v-if="quadrant == 3"
						opacity="1.0"
						x="-150"
						y="160"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="150"
						fill="#FFFFFF"
					>{{stimulus}}</text>
				</transition>

				<transition name="quickfade">
					<g v-if="show_buttons && !block_feedback" v-on:click="left_clicked">
						<path d="M -700 0 L -650 -100 -450 -100 -400 0 -450 100 -650 100 Z" fill="#00000020" />

						<text
							v-if="odd_even"
							opacity="1.0"
							x="-550"
							y="0"
							class="txt"
							font-size="32px"
							font-family="sans-serif"
							fill="#FFFFFF"
							text-anchor="middle"
							dominant-baseline="middle"
						>{{this.text.odd}}</text>
						<text
							v-if="low_high"
							opacity="1.0"
							x="-550"
							y="0"
							class="txt"
							font-size="32px"
							font-family="sans-serif"
							fill="#FFFFFF"
							text-anchor="middle"
							dominant-baseline="middle"
						>{{this.text.low}}</text>
					</g>
				</transition>

				<transition name="quickfade">
					<g v-if="show_buttons && !block_feedback" v-on:click="right_clicked">
						<path d="M 700 0 L 650 -100 450 -100 400 0 450 100 650 100 Z" fill="#00000020"  />

						<text
							v-if="odd_even"
							opacity="1.0"
							x="550"
							y="0"
							class="txt"
							font-size="32px"
							font-family="sans-serif"
							fill="#FFFFFF"
							text-anchor="middle"
							dominant-baseline="middle"
						>{{this.text.even}}</text>
						<text
							v-if="low_high"
							opacity="1.0"
							x="550"
							y="0"
							class="txt"
							font-size="32px"
							font-family="sans-serif"
							fill="#FFFFFF"
							text-anchor="middle"
							dominant-baseline="middle"
						>{{this.text.high}}</text>
					</g>
				</transition>
			</g>
		</g>
		<transition name="fade">
			<instructions v-if="this.$store.state.taskContainer.showInstructions" />
		</transition>
		<transition name="quickfade">
			<g v-if="block_feedback">
				<path id="clock" fill="none" stroke="#FFFFFFCC" stroke-width="10" />
				<circle cx="-550" cy="0" r="150" fill="none" stroke="#00000020" stroke-width="10" />
				<circle cx="-550" cy="0" r="130" fill="none" stroke="#00000020" stroke-width="20" stroke-dasharray="10, 9" />
				
				<text
					opacity="1.0"
					x="-550"
					y="0"
					font-family="sans-serif"
					font-size="80"
					fill="#000000"
					text-anchor="middle"
					dominant-baseline="middle"
				>{{this.block_feedback_seconds}}</text>
				<text
					opacity="1.0"
					x="-550"
					y="40"
					font-family="sans-serif"
					font-size="25"
					fill="#000000"
					text-anchor="middle"
					dominant-baseline="middle"
				>{{this.text.seconds}}</text>

				<circle cx="550" cy="0" r="150" fill="none" stroke="#00000020" stroke-width="10" />
				<path id="corr" fill="none" stroke="#FFFFFFCC" stroke-width="10" />
				<text
					opacity="1.0"
					x="550"
					y="0"
					font-family="sans-serif"
					font-size="80"
					fill="#000000"
					text-anchor="middle"
					dominant-baseline="middle"
				>{{this.block_feedback_correct}} / 32</text>
				<text
					opacity="1.0"
					x="550"
					y="40"
					font-family="sans-serif"
					font-size="25"
					fill="#000000"
					text-anchor="middle"
					dominant-baseline="middle"
				>{{this.text.correct}}</text>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import stat from "@/stat.js";
import lang from "@/v/mind_ad/shifting_predictable/lang.js";
import instructions from "@/v/mind_ad/shifting_predictable/instructions.vue";
import timeline from "@/v/timeline.vue";
import persist from "@/persist.js";

//import gsap from "gsap";
//import play from "@/v/test_shifting_predictable/play.vue";


/*
feedback på felaktig respons:
			<rect
				v-if="item_error"
				stroke="none"
				fill="#00000010"
				x="-300" y="-300"
				width="600" height="600"
				rx="15"
			/>
*/

/*
	setTimeout(
		() =>
			this.$parent.$parent.$refs.countDown.play(() =>
				store.commit("taskPhase", 2)
			),
		5000 // skifta: 5s paus mellan varje block
	);
*/

//Ska alla block vara 33 långa?

export default {
	name: "mind_ad_shifting_predictable",
	//genväg-alias:
	local: store.state.mind_ad_shifting_predictable,
	components: {
		timeline,
		instructions,
	},
	//props: ['stim_param'],
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
		item() {
			return this.items[this.trial] || {
				block: -1,
				task: '',
				n: -2,
				quad: -1,
				stim: '0',
				shift: 0,
				comp: 0,
				neg: 0,
				cresp: '',
			};
			//return this.items[this.trial] || [-1, '', -2, -1, 99, 0, 0, 0, '', '', ''];
		},
		block() {
			return this.item.block;
		},
		task() {
			return this.item.task;
		},
		item_n() {
			return this.item.n;
		},
		quadrant() {
			return this.item.quad;
		},
		stimulus() {
			return this.item.stim;
		},
		is_shift() {
			return this.item.shift;
		},
		is_comp() {
			return this.item.comp;
		},
		is_neg() {
			return this.item.neg;
		},
		corr_resp() {
			return this.item.cresp;
		},
		//side() {
		//	return this.item[9] || '?';
		//},
		desc() {
			return this.item.desc || '---';
			//return this.item[10] || '?';
		},
		odd_even() {
			return this.task == "oe";
		},
		low_high() {
			return this.task == "lh";
		},
		pos_in_block() {
			return this.item_n % 32;
		},
		test_active() {
			return this.trial < this.items.length;
		}
	},
	watch: {
		item: function() {
			
			this.item_response_blocked = true;

			if (this.block > 0 && (this.item_n == -1 || (this.item_n >= 32 && this.item_n % 32 == 0))) {
				this.item_visible = false;
				setTimeout(() => {
					this.item_error = false;
					this.block_feedback = true;	
					this.update_feedback_arcs();
				}, 100);

				this.$parent.$refs.countDown.play(() => {
					this.item_visible = true;
					this.item_response_blocked = false;
					this.item_start_time = Date.now();
					this.block_feedback = false;
				}, 7, 3);
			}
			else {
				this.item_start_time = Date.now();
				this.item_visible = true;
				setTimeout(() => {
					this.item_error = false;
					this.item_response_blocked = false;
				}, 100);
			}
		},
		/*item_visible: function() {
			console.log('ready? ' + this.item_visible);
		}*/
	},
	data: () => { return {
		show_buttons: true,
		input_disabled: false,
		items: [],
		trial: 0,
		item_visible: false,
		item_error: false,
		item_response_blocked: false,
		item_start_time: 0,
		start_time: 0,
		block_start_time: 0,
		block_feedback: false,
		block_feedback_seconds: 123,
		block_feedback_correct: 0,
		session_score: 0,
		balanced_list: [
			1,3,7,9,1,3,7,9,1,3,7,9,1,3,7,9,
			2,4,6,8,2,4,6,8,2,4,6,8,2,4,6,8,
		],
		right_side_pairs: [
			[[[1,1],[2,1],[3,1],[4,1],   [1,3],[2,3],[3,3],[4,3]], "R", 1, "A: compatible negative priming"],
			[[[6,6],[7,6],[8,6],[9,6],   [6,8],[7,8],[8,8],[9,8]], "R", 1, "B: compatible negative priming"],
			[[[1,2],[2,2],[3,2],[4,2],   [1,4],[2,4],[3,4],[4,4]], "R", 1, "C: incompatible negative priming"],
			[[[6,7],[7,7],[8,7],[9,7],   [6,9],[7,9],[8,9],[9,9]], "R", 1, "D: incompatible negative priming"],
			[[[6,1],[7,1],[8,1],[9,1],   [6,3],[7,3],[8,3],[9,3]], "R", 0, "E: compatible without negative priming"],
			[[[1,6],[2,6],[3,6],[4,6],   [1,8],[2,8],[3,8],[4,8]], "R", 0, "F: compatible without negative priming"],
			[[[6,2],[7,2],[8,2],[9,2],   [6,4],[7,4],[8,4],[9,4]], "R", 0, "G: incompatible without negative priming"],
			[[[1,7],[2,7],[3,7],[4,7],   [1,9],[2,9],[3,9],[4,9]], "R", 0, "H: incompatible without negative priming"],
		],
		left_side_pairs: [
			[[[1,1],[3,1],[7,1],[9,1],   [1,3],[3,3],[7,3],[9,3]], "L", 1, "A: compatible negative priming"],
			[[[2,6],[4,6],[6,6],[8,6],   [2,8],[4,8],[6,8],[8,8]], "L", 1, "B: compatible negative priming"],
			[[[1,7],[3,7],[7,7],[9,7],   [1,9],[3,9],[7,9],[9,9]], "L", 1, "C: incompatible negative priming"],
			[[[2,2],[4,2],[6,2],[8,2],   [2,4],[4,4],[6,4],[8,4]], "L", 1, "D: incompatible negative priming"],
			[[[2,1],[4,1],[6,1],[8,1],   [2,3],[4,3],[6,3],[8,3]], "L", 0, "E: compatible without negative priming"],
			[[[1,6],[3,6],[7,6],[9,6],   [1,8],[3,8],[7,8],[9,8]], "L", 0, "F: compatible without negative priming"],
			[[[2,7],[4,7],[6,7],[8,7],   [2,9],[4,9],[6,9],[8,9]], "L", 0, "G: incompatible without negative priming"],
			[[[1,2],[3,2],[7,2],[9,2],   [1,4],[3,4],[7,4],[9,4]], "L", 0, "H: incompatible without negative priming"],
		],
	}},
	methods: {
		create_lists() {
			// Initiera tom lista
			this.items = [];
			var i;
			var rnd;

			//
			//----------------------------------------------------------------------------
			//
			// 32 st udda/jämna, lika många av varje:
			var odds_n_evens = this.balanced_list.slice();
			stat.shuffle(odds_n_evens);

			rnd = this.random_digit();
			this.items.push({
				block: 0,
				task: 'oe',
				n: -1,
				quad: 0,
				stim: rnd,
				shift: 0,
				comp: this.is_compatible(rnd),
				neg: 0,
				cresp: this.correct_response_for(rnd, 'oe'),
			});
			for (i = 0; i < 32; ++i)
				this.items.push({
					block: 0,
					task: 'oe',
					n: i,
					quad: (1+i)%2,
					stim: odds_n_evens[i],
					shift: 0,
					comp: this.is_compatible(odds_n_evens[i]),
					neg: 0,
					cresp: this.correct_response_for(odds_n_evens[i], 'oe'),
				});
			
			//
			//----------------------------------------------------------------------------
			//
			// 32 st låga/höga, lika många av varje:
			var lows_n_highs = this.balanced_list.slice();
			stat.shuffle(lows_n_highs);

			rnd = this.random_digit();
			this.items.push({
				block: 1,
				task: 'lh',
				n: -1,
				quad: 2,
				stim: rnd,
				shift: 0,
				comp: this.is_compatible(rnd),
				neg: 0,
				cresp: this.correct_response_for(rnd, 'lh'),
			});
			for (i = 0; i < 32; ++i)
				this.items.push({
					block: 1,
					task: 'lh',
					n: i,
					quad: 2+(1+i)%2,
					stim: lows_n_highs[i],
					shift: 0,
					comp: this.is_compatible(lows_n_highs[i]),
					neg: 0,
					cresp: this.correct_response_for(lows_n_highs[i], 'lh'),
				});
			//
			//----------------------------------------------------------------------------
			//
			// 1 trial + 4 block om 32 trials, udda/jämna/låga/höga
			//

			var rsp = this.right_side_pairs.slice();
			var lsp = this.left_side_pairs.slice();
			var row;
			// randomisera tillgängliga par att välja bland inom varje rad i tabellen:
			for (row = 0; row < 8; ++row) {
				stat.shuffle(rsp[row][0]);
				stat.shuffle(lsp[row][0]);
			}

			// Plocka 4 par från varje rad. Slumpa ordningen av valet av rad:
			var lrows = [];
			var rrows = [];
			var n;
			for (row = 0; row < 8; ++row)
				for (n = 0; n < 4; ++n) {
					lrows.push(row);
					rrows.push(row);
				}
			stat.shuffle(lrows);
			stat.shuffle(rrows);

			rnd = this.random_digit();
			this.items.push({
				block: 2,
				task: 'oe',
				n: -1,
				quad: 0,
				stim: rnd,
				shift: 0,
				comp: this.is_compatible(rnd),
				neg: 0,
				cresp: this.correct_response_for(rnd, 'oe'),
			});



			// 128 udda/jämna/låga/höga, plocka ur randomiserad lista:

			var pair, block;
			i = 0;
			
			for (n = 0; n < 32; ++n) {
				block = 2 + Math.trunc(n/8);
				//row = n % 8;

				// Right side:
				row = rrows.pop();
				pair = rsp[row][0].pop();
				this.items.push({
					block: block,
					task: 'oe',
					n: i++,
					quad: 1,
					stim: pair[0],
					shift: 0,
					comp: this.is_compatible(pair[0]),
					neg: 0, //rsp[row][2],
					cresp: this.correct_response_for(pair[0], 'oe'),
					desc: rsp[row][3]
				});
				this.items.push({
					block: block,
					task: 'lh',
					n: i++,
					quad: 2,
					stim: pair[1],
					shift: 1,
					comp: this.is_compatible(pair[1]),
					neg: rsp[row][2],
					cresp: this.correct_response_for(pair[1], 'lh'),
					desc: rsp[row][3]
				});

				// Left side:
				row = lrows.pop();
				pair = lsp[row][0].pop();
				this.items.push({
					block: block,
					task: 'lh',
					n: i++,
					quad: 3,
					stim: pair[0],
					shift: 0,
					comp: this.is_compatible(pair[0]),
					neg: 0, //lsp[row][2],
					cresp: this.correct_response_for(pair[0], 'lh'),
					desc: lsp[row][3]
				});
				this.items.push({
					block: block,
					task: 'oe',
					n: i++,
					quad: 0,
					stim: pair[1],
					shift: 1,
					comp: this.is_compatible(pair[1]),
					neg: lsp[row][2],
					cresp: this.correct_response_for(pair[1], 'oe'),
					desc: lsp[row][3]
				});
			}

			/*for (var xx = 0; xx < this.items.length; ++xx) {
				console.log(xx
					+ "\t" + this.items[xx].block
					+ "\t" + this.items[xx].task
					+ "\t" + this.items[xx].n
					+ "\t" + this.items[xx].quad
					+ "\t" + this.items[xx].stim
					+ "\t" + this.items[xx].shift
					+ "\t" + this.items[xx].comp
					+ "\t" + this.items[xx].neg
					+ "\t" + this.items[xx].cresp
					+ "\t" + this.items[xx].desc
				);
			}*/
		},
		is_compatible(digit) {
			return (digit == 1 || digit == 3 || digit == 6 || digit == 8)? 1 : 0;
		},
		correct_response_for(digit, task) {
			if (task == "oe")
				return digit % 2 == 1? "O" : "E";
			else
				return digit < 5? "L" : "H";
		},
		random_digit() {
			return [1,2,3,4,6,7,8,9][Math.trunc(Math.random()*8)];
		},
		basic_stats(a) {
			var i;
			var result = {
				//num: a.length,
			};
			var rts = [];
			var num_correct = 0;

			// Behandla enbart items som är korrekt besvarade och har rt > 200 ms:
			for (i = 0; i < a.length; ++i) {
				if (a[i].corr) {
					num_correct++;
					if (a[i].rt > 200) {
						rts.push(a[i].rt);
					}
				}
			}
			result.num_c = num_correct;

			// Måste ha ett antal rätt (4?) för att räkna användbart medel och standardavvikelse
			if (rts.length > 3) {
				var rt_mean  = stat.mean(rts);
				var rt_sd = stat.standardDev(rts);
				var rts_ok = [];
				var ok = [];
				for (i = 0; i < a.length; ++i) {
					// Ignorera items som har fel svar eller har rt >= 200 ms eller har rt > 3 sd över medel.
					if (a[i].corr && a[i].rt > 200 && a[i].rt <= rt_mean + 3 * rt_sd) {
						ok.push(a[i]);
						rts_ok.push(a[i].rt);
					}
				}
				// fråga Anna om vilka data som ska användas:
				// ska vi filtrera items med rt < 100? och mer än 3 sd?
				// ska vi beräkna nytt medel och sd efter filtrering?
				result.rt_mean = stat.mean(rts_ok);
				result.rt_sd = stat.standardDev(rts_ok);
				result.num_ok = rts_ok.length;
				result.ok = ok;
			}
			else {
				result.rt_mean = -999999;
				result.rt_sd = -99999;
				result.num_ok = 0;
				result.ok = [];
			}
			
			return result;
		},
		split_at_shift(a) {
			var r = {
				shift: [],
				non_shift: [],
			};
			a.forEach(e => {
				if (e.shift == 1)
					r.shift.push(e);
				else
					r.non_shift.push(e);
			});
			return r;
		},
		split_at_comp(a) {
			var r = {
				comp: [],
				non_comp: [],
			};
			a.forEach(e => {
				if (e.comp == 1)
					r.comp.push(e);
				else
					r.non_comp.push(e);
			});
			return r;
		},
		split_at_neg(a) {
			var r = {
				neg: [],
				non_neg: [],
			};
			a.forEach(e => {
				if (e.neg == 1)
					r.neg.push(e);
				else
					r.non_neg.push(e);
			});
			return r;
		},
		end_test() {
			this.show_buttons = false;
			// feedback för sista blocket
			this.block_feedback = true;
			this.update_feedback_arcs();

			setTimeout(() => {
				this.block_feedback = false;
				store.commit('setTaskMode', 'clickToExit');
				this.saveSessionResults();
			}, 10000);
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			// Ta bort onödig information:
			this.items.forEach(e => {
				delete e.desc;
			});

			// Block 0: udda/jämna
			//console.log('---- OE ----');
			const oe = this.basic_stats(this.items.slice(1, 33));
			//console.log(oe);

			// Block 1: low/high
			//console.log('---- LH ----');
			const lh = this.basic_stats(this.items.slice(34, 66));
			//console.log(lh);

			// Block 2+: udda/jämna/låga/höga
			//console.log('---- OE/LH ----');
			const mix = this.basic_stats(this.items.slice(67));
			//console.log(mix);

			const {shift, non_shift} = this.split_at_shift(mix.ok);
			const shift_stats = this.basic_stats(shift);
			const non_shift_stats = this.basic_stats(non_shift);
			//console.log('SHIFT:');
			//console.log(shift_stats);
			//console.log('NON-SHIFT:');
			//console.log(non_shift_stats);


			const {comp: shift_comp, non_comp: shift_non_comp} = this.split_at_comp(shift);
			const shift_comp_stats = this.basic_stats(shift_comp);
			const shift_non_comp_stats = this.basic_stats(shift_non_comp);
			//console.log('SHIFT & COMPATIBLE:');
			//console.log(shift_comp_stats);
			//console.log('SHIFT & NON-COMPATIBLE:');
			//console.log(shift_non_comp_stats);


			/*const {comp: non_shift_comp, non_comp: non_shift_non_comp} = this.split_at_comp(non_shift);
			console.log('NON-SHIFT & COMPATIBLE:');
			console.log(this.basic_stats(non_shift_comp));
			console.log('NON-SHIFT & NON-COMPATIBLE:');
			console.log(this.basic_stats(non_shift_non_comp));*/


			// ta bort detaljer... de finns ju kvar i this.items[]
			delete oe.ok;
			delete lh.ok;
			delete mix.ok;
			delete shift_stats.ok;
			delete non_shift_stats.ok;
			delete shift_comp_stats.ok;
			delete shift_non_comp_stats.ok;


			const speed = 1000 / Math.min(5000, Math.max(200, mix.rt_mean));
			const percent_correct = mix.num_ok / this.items.length;
			const score = Math.trunc(100 * speed * percent_correct);

			this.session_score = score;


			const done_num = this.$store.state.progress.shifting_num || 0;
			persist.set_progress_data('shifting_num', done_num + 1);

			persist.log('results', {
				score: this.session_score,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				oe: oe,
				lh: lh,
				mix: mix,
				shift: shift_stats,
				non_shift: non_shift_stats,
				shift_comp: shift_comp_stats,
				shift_non_comp: shift_non_comp_stats,
				x: this.items,
			});

			persist.addToHistory('score', this.session_score).then(history => {
				store.commit("setSessionScores", history);
				store.commit('setTaskMode', 'sessionScore');
			});
		},
		left_clicked() {
			const response = this.task == "oe"? 'O' : 'L';
			this.log_response(response);
		},
		right_clicked() {
			const response = this.task == "oe"? 'E' : 'H';
			this.log_response(response);
		},
		log_response(response) {
			if (this.input_disabled)
				return;
			if (this.trial >= this.items.length || this.item_response_blocked)
				return;

			if (this.trial == 1) {
				this.start_time = Date.now();
				store.commit("setTaskMode", "playing");
			}

			if (this.pos_in_block == 0) {
				this.block_feedback_correct = 0;
				this.block_start_time = Date.now();
			}
			if (this.pos_in_block >= 31) {
				this.block_feedback = true;
				this.update_feedback_arcs();
			}


			this.item_response_blocked = true;

			const response_time = Date.now() - this.item_start_time;
			const error = this.corr_resp != response;

			//console.log(this.trial + ': ' + response + (error?' ERR ':' OK  ') + response_time);

			const corr = (error? 0 : 1);

			this.items[this.trial].resp = response;
			this.items[this.trial].corr = corr;
			this.items[this.trial].rt = response_time;

			this.block_feedback_correct += corr;

			this.item_error = error;
			if (error) {
				setTimeout(() => {
					this.item_error = false;
				}, 100);
			}

			setTimeout(() => {
				++this.trial;
				if (this.trial >= this.items.length) this.end_test();
			}, 150);
		},
		update_feedback_arcs() {
			const clock = document.getElementById("clock");
			const seconds = Math.trunc((Date.now() - this.block_start_time) / 1000);
			const fake_seconds = Math.max(seconds-5, 1);
			const degrees = Math.min(359.99, fake_seconds * 7);
			this.block_feedback_seconds = seconds;
			if (clock) clock.setAttribute("d", describeArc(-550, 0, 130, 0, degrees));

			const corr = document.getElementById("corr");
			const num_correct = Math.max(0, Math.min(32, this.block_feedback_correct))
			const degrees_c = Math.min(359.99, num_correct * 11.25);
			if (corr) corr.setAttribute("d", describeArc(550, 0, 130, 0, degrees_c));
		},
		setup() {
			this.create_lists();

			//store.commit('setTaskMode', 'playing');
			store.commit('setTaskMode', 'readyImmediateInstruct');
			store.commit("taskPhase", 0);
			this.trial = 0;
			this.input_disabled = false; // tillåt input

			this.block_start_time = Date.now(); // Onödig rad, men låt stå.
			this.block_feedback_correct = 0;    // Onödig rad, men låt stå.
			this.$parent.show_buttons = true;
		}
	},
	mounted() {
		/**
		 * Animerade instruktioner, med hand, och kommentarer: "7 är udda", "2 är låg".
		 * 
		 * Liten paus innan siffran visas? först box, sen siffra?
		 * 
		 * Paus mellan varje block med pauscirkel.
		*/

		this.setup();

		document.addEventListener('keydown', (event) => {
			var keyValue = event.key;
			//var codeValue = event.code;
			if (this.test_active) {
				if (keyValue == 'f')
					this.left_clicked();
				else if (keyValue == 'j')
					this.right_clicked();
			}
			//console.log("keyValue: " + keyValue);
			//console.log("codeValue: " + codeValue);
		}, false);
	}
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians)
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y
	].join(" ");

	return d;
}

</script>

<style lang="stylus">
	.mini-balloon-enter-active, .mini-balloon-leave-active {
		transition: all 0.75s;
	}
	.mini-balloon-enter, .mini-balloon-leave-to {
		opacity: 0;
		transform: translate(350px, 10px);
	}
</style>
