const emptyitemSet = [' ', ' ', ' ', ' ', ' '];

export const reco_updating_letters = {
	state: {
		sessionScores: [],
		//-----------------------------------------
		// INSTRUCTIONS
		//-----------------------------------------
		instructionsPhase: -1,
		instructionsViewed: true,
		//-----------------------------------------
		// TRAINING
		//-----------------------------------------
		round: 0,
		level: 5,
		lastLevel: -1, // används för att skriva senaste nivån på skärmen
		numCorrect: 0,
		levelPerRound: [],
		numCorrectPerRound: [],
		//-----------------------------------------
		// itemSet
		//-----------------------------------------
		taskType: 0,
		stimulusMaterial: 0,
		itemTypes: ['A', 'B', 'C', 'D'],
		itemMarks: ['','','',''],
		visItems: [],
		showResponseItems: false,
		showitemSet: false,
		itemSet: emptyitemSet,
		acceptingClicks: false,
		clicks: [],
		list: [],
		response: [],
	},
	mutations: {
		// When adding **new** properties to an Object, you should either:
		//
		// • Use Vue.set(obj, 'newProp', 123), or
		//
		// • Replace that Object with a fresh one. For example,
		//   using the object spread syntax we can write it like this:
		//   state.obj = { ...state.obj, newProp: 123 }
		//-----------------------------------------
		//-----------------------------------------
		// SESSION
		//-----------------------------------------
		reco_updating_letters_selectTaskType(state, type) {
			state.taskType = type;
			state.itemTypes = ['A', 'B', 'C', 'D'];
		},
		reco_updating_letters_init(state, level) {
			state.level = level;
			state.round = 0;
			state.numCorrectPerRound = [];
		},
		//-----------------------------------------
		// INSTRUCTIONS
		//-----------------------------------------
		reco_updating_letters_setInstructionsViewed(state, viewed = true) {
			state.instructionsViewed = viewed;
		},
		taskPhase(state, nextPhase) {
			state.instructionsPhase = nextPhase;
		},
		//-----------------------------------------
		// TRAINING
		//-----------------------------------------
		reco_updating_letters_setNumCorrect(state, numCorrect) {
			state.numCorrect = numCorrect;
			var tmp = state.numCorrectPerRound.slice();
			tmp.push(numCorrect);
			state.numCorrectPerRound = tmp;
		},
		reco_updating_letters_setLevel(state, level) {
			state.level = level;
			var tmp = state.levelPerRound.slice();
			tmp.push(level);
			state.levelPerRound = tmp;
		},
		reco_updating_letters_setRound(state, newRound) {
			state.round = newRound;
		},
		reco_updating_letters_setList(state, newList) {
			state.list = newList;
			state.lastLevel = newList.length;
		},
		//-----------------------------------------
		// itemSet
		//-----------------------------------------
		reco_updating_letters_resetitemSet(state) {
			const arr = emptyitemSet.concat(state.itemTypes);
			state.visItems = arr; //emptyitemSet.slice();
		},
		reco_updating_letters_setVisItem(state, { idx: itemIndex, value: itemValue }) {
			const newVisItems = Object.assign([...state.visItems], {
				[itemIndex]: itemValue
			})
			state.visItems = newVisItems;
		},
		reco_updating_letters_markItem(state, { idx: itemIndex, value: itemValue }) {
			const newItemMarks = Object.assign([...state.itemMarks], {
				[itemIndex]: itemValue
			})
			state.itemMarks = newItemMarks;
		},
		//----------- response
		reco_updating_letters_showResponseItems(state, visible = true) {
			state.showResponseItems = visible;
		},
		//-----------------------------------------

		reco_updating_letters_showitemSet(state, visible = true) {
			state.showitemSet = visible;
		},
		hideitemSet(state) {
			state.showitemSet = false;
		},
		reco_updating_letters_acceptClicks(state, yesNo) {
			state.acceptingClicks = yesNo;
			state.showResponseItems = yesNo;
		},
		reco_updating_letters_itemClicked(state, { itemIndex }) {
			var clicks = state.clicks.slice(0);
			clicks.push(itemIndex);
			state.clicks = clicks;


			var newVisItems = state.visItems.slice();

			if (itemIndex >= 5 && itemIndex <= 8 && state.visItems.length < 13) {
				const re = state.itemTypes[itemIndex - 5];
				if (newVisItems[9] == null)
					newVisItems[9] = re;
				else if (newVisItems[10] == null)
					newVisItems[10] = re;
				else if (newVisItems[11] == null)
					newVisItems[11] = re;
				else if (newVisItems[12] == null)
					newVisItems[12] = re;
				state.visItems = newVisItems;
			} else if (itemIndex > 8 && state.visItems.length > 8) {
				newVisItems = state.visItems.slice(0, itemIndex);
				state.visItems = newVisItems;
			}


		}
	},
	actions: {
		reco_updating_letters_clickItem({ commit, state, dispatch }, { itemIndex, roboClick = false }) {
			if (state.acceptingClicks == false && roboClick == false) {
				return;
			}
			commit('reco_updating_letters_itemClicked', { itemIndex, roboClick });
			if (state.visItems.length - 9 >= 4) {
				commit('reco_updating_letters_acceptClicks', false);
				if (!roboClick) {
					dispatch('nextTaskPhase');
				}
			}
		}
	}
}
