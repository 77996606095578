export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
			case "sv":
			default:
				return {
					question: "Hur bedömer du ditt allmänna hälsotillstånd idag?",
					a1: "1. Mycket dåligt",
					a2: "2. Dåligt",
					a3: "3. Någorlunda",
					a4: "4. Bra",
					a5: "5. Mycket bra",
				}	
			}
		}
	}
}
