<template>
	<g>
		<transition name="fade">
			<g transform="translate(670, -490)">
				<logoutButton />
			</g>
		</transition>
	</g>
</template>

<script>
/*
		<transition name="fade">
			<foreignObject
				v-if="true"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox" v-html="this.text.menu_help" />
			</foreignObject>
		</transition>

		<transition name="fade">
			<g
				transform="translate(-300, -140)"
				v-on:click="this.click_span_complex_a"
			>
				<span_complex_a_icon />
			</g>
		</transition>

		<transition name="fade">
			<g
				transform="translate(-300, 140)"
				v-on:click="this.click_span_complex_b"
			>
				<span_complex_b_icon />
			</g>
		</transition>

*/

import { store } from "@/store.js";
import lang from "@/v/anx/menu/lang.js";
//import persist from "@/persist.js";

//import span_complex_a_icon from "@/v/taskMenu/icons/span_complex_a_icon.vue";
//import span_complex_b_icon from "@/v/taskMenu/icons/span_complex_b_icon.vue";

//import span_complex_math_a_icon from "@/v/taskMenu/icons/span_complex_math_a_icon.vue";
//import span_complex_math_b_icon from "@/v/taskMenu/icons/span_complex_math_b_icon.vue";

//import spatialSpanIcon from "@/v/taskMenu/icons/spatialSpanIcon.vue";
//import lettersUpdatingIcon from "@/v/taskMenu/icons/lettersUpdatingIcon.vue";

//import wm_maintenance_icon from "@/v/taskMenu/icons/wm_maintenance_icon.vue";
//import keepTrackIcon from "@/v/taskMenu/icons/keepTrackIcon.vue";

import logoutButton from "@/v/taskMenu/logoutButton.vue";
//import persist from "@/persist.js";

/*
		<transition name="fade">
			<g transform="translate(0, -140)" v-on:click="this.click_span_complex_math_a">
				<span_complex_math_a_icon/>
			</g>
		</transition>

		<transition name="fade">
			<g transform="translate(0, 140)" v-on:click="this.click_span_complex_math_b">
				<span_complex_math_b_icon/>
			</g>
		</transition>

*/

/*
		<transition name="fade">
			<g transform="translate(0, -140)" v-on:click="this.click_span_spatial_linear">
				<spatialSpanIcon />
			</g>
		</transition>

		<transition name="fade">
			<g transform="translate(300, -140)" v-on:click="this.click_span_letters">
				<lettersUpdatingIcon/>
			</g>
		</transition>

*/

export default {
	name: "menu_anx",
	components: {
		//span_complex_a_icon,
		//span_complex_b_icon,
		//span_complex_math_a_icon,
		//span_complex_math_b_icon,
		//spatialSpanIcon,
		//lettersUpdatingIcon,
		//wm_maintenance_icon,
		logoutButton,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {};
	},
	methods: {
		/*
		click_span_complex_a() {
			store.commit("set_test_set", "A");
			store.dispatch("gotoModule", "test_operation_span_verbal");
		},
		click_span_complex_b() {
			store.commit("set_test_set", "B");
			store.dispatch("gotoModule", "test_operation_span_verbal");
		},
		click_span_complex_math_a() {
			store.commit("set_test_set", "A");
			store.dispatch("gotoModule", "test_operation_span_math");
		},
		click_span_complex_math_b() {
			store.commit("set_test_set", "B");
			store.dispatch("gotoModule", "test_operation_span_math");
		},
		click_complex_math() {
			store.dispatch("gotoModule", "test_operation_span_math");
		},
		click_span_spatial_linear() {
			store.dispatch("gotoModule", "test_span_spatial_linear");
		},
		click_span_letters() {
			store.dispatch("gotoModule", "test_span_letters");
		},
		*/
		//click_updating_letters() {
		//	if (this.show_updating_letters) store.dispatch("gotoModule", "reco_updating_letters")},
		//click_wm_maintenance() {
		//	if (this.show_wm_maintenance) store.dispatch("gotoModule", "reco_wm_maintenance")},
	},
	mounted() {
		// 1 - test_operation_span_verbal
		// 2 - test_wisc4_mat
		// 3 - test_span_spatial_linear
		// 4 - test_span_letters
		// 5 - test_operation_span_math
		// 6 - test_span_digits

		//persist.set_progress_data('verbal', 1);

		const done_verbal = this.$store.state.progress.verbal || 0;
		const done_mat = this.$store.state.progress.mat || 0;
		const done_spatial = this.$store.state.progress.spatial || 0;
		const done_letters = this.$store.state.progress.letters || 0;
		const done_math = this.$store.state.progress.math || 0;
		const done_digits = this.$store.state.progress.digits || 0;

		//console.log(this.$store.state.pwd);

		//store.dispatch("gotoModule", "test_operation_span_math");

		if (this.$store.state.pwd != "majakka99") {
			if (done_verbal != 1) {
				store.dispatch("gotoModule", "test_operation_span_verbal");
				return;
			}
		}
		if (done_mat != 1) {
			store.dispatch("gotoModule", "test_wisc4_mat");
			return;
		}
		if (done_spatial != 1) {
			store.dispatch("gotoModule", "test_span_spatial_linear");
			return;
		}
		if (done_letters != 1) {
			store.dispatch("gotoModule", "test_span_letters");
			return;
		}
		if (done_math != 1) {
			store.dispatch("gotoModule", "test_operation_span_math");
			return;
		}
		if (done_digits != 1) {
			store.dispatch("gotoModule", "test_span_digits");
			return;
		}

		//--------------------

		//store.dispatch("gotoModule", "test_operation_span_verbal");
		//--------------------

		//store.dispatch("gotoModule", "test_span_spatial_linear");

		if (this.$store.state.pwd != "majakka99") {
			window.location.replace("https://survey.umu.se/matte1");
			return;
		}

		//store.dispatch("gotoModule", "test_wisc4_mat");
	},
};
</script>
