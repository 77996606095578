<template>
	<g>
		<transition name="fade">
			<foreignObject v-if="phase == 0" x="-600" y="-495" width="1200" height="160">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t0($parent.num_cards/2)" />
					<div class="link" v-on:click="$store.commit('taskPhase', 1)">
						<span v-html="$parent.text.instructions.t0_button" />
					</div>
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 2" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t2" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 3" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t3" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 4" x="-790" y="-495" width="1580" height="700">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t4" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 5" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t5" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 6" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t6" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 7" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t7" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 10" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t10" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 11" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t11" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 12" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t12" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 13" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t13" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 14" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t14" />
				</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject v-if="phase == 17" x="-790" y="-495" width="1580" height="400">
				<div class="txtbox">
					<span v-html="$parent.text.instructions.t17" />
				</div>
			</foreignObject>
		</transition>
		<hand v-show="handVisible" ref="hand" />
	</g>
</template>

<script>
import { store } from "@/store.js";
import hand from "@/v/hand.vue";
import gsap from "gsap";

export default {
	name: "instructions",
	components: { hand },
	data: function() {
		return {
			store_true_num_cards: 1,
			temp_num_cards: 6,
			handVisible: false,
			hand: null,
		};
	},
	watch: {
		phase: function(/*newPhase*/) {
			this.update();
		}
	},
	methods: {
		update() {
			switch (this.$store.state.taskContainer.taskPhase) {
			case 0: // Din uppgift...
				break;
			case 1:
				// Spara och återställ igen efter instruktionen!
				this.store_true_num_cards = this.$parent.num_cards;

				store.commit('setTaskMode', 'instructions');
				//store.commit("taskPhase", 10);
				//store.dispatch('nextTaskPhase', 1000);
				store.dispatch('nextTaskPhase', this.$parent.shuffle_cards() * 1000);
				break;
			case 2: // c: "Nu ska jag visa!",				
				this.$parent.init_cards(this.temp_num_cards);
				for (var i = 0; i < this.temp_num_cards; ++i) {
					this.$parent.cards[i].show(true);
					this.$parent.cards[i].found = false;
					this.$parent.cards[i].is_up = false;
				}
				this.$parent.card_a = null;
				this.$parent.card_b = null;
				this.$parent.num_cards_found = 0;
				this.$parent.accept_clicks = false;

				for (i = 0; i < this.temp_num_cards; ++i) {
					this.$parent.cards[i].sign = Math.trunc(i * 0.5);
				}
				store.dispatch('nextTaskPhase', 5000);
				break;
			case 3:
				setTimeout(() => {
					this.handVisible = true;
					this.$refs.hand.show(() => {
						this.hand = gsap.timeline();
						this.hand.to("#hand", {
							delay: 0,
							duration: 2,
							x: this.$parent.x_location(0) + 50,
							y: this.$parent.y_location(0) + 60,
							ease: "power3.inOut",
							onComplete: () => {
								this.$refs.hand.click();
								this.$parent.cards[0].turn(true);
								store.dispatch('nextTaskPhase', 1000);
							}
						});
					});
				}, 3000);
				break;
			case 4:
				this.hand = gsap.timeline();
				this.hand.to("#hand", {
					delay: 4,
					duration: 3,
					x: this.$parent.x_location(2) + 50,
					y: this.$parent.y_location(2) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[2].turn(true);
						store.dispatch('nextTaskPhase', 500);
					}
				});
				break;
			case 5:
				store.dispatch('nextTaskPhase', 7000);
				break;
			case 6:
				this.hand = gsap.timeline();
				this.hand.to("#hand", {
					delay: 3,
					duration: 2,
					x: this.$parent.x_location(1) + 50,
					y: this.$parent.y_location(1) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[0].turn(false);
						this.$parent.cards[2].turn(false);
						this.$parent.cards[1].turn(true);
						store.dispatch('nextTaskPhase', 500);
					}
				});
				break;
			case 7:
				this.hand = gsap.timeline();
				this.hand.to("#hand", {
					delay: 5,
					duration: 2,
					x: this.$parent.x_location(0) + 50,
					y: this.$parent.y_location(0) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[0].turn(true);
						store.dispatch('nextTaskPhase', 500);
					}
				});
				break;
			case 8:
				store.dispatch('nextTaskPhase', 2000);
				break;
			case 9:
				this.$parent.cards[0].mark_as_found();
				this.$parent.cards[1].mark_as_found();
				store.dispatch('nextTaskPhase', 2000);
				break;
			case 10:
				this.hand = gsap.timeline();
				this.hand.to("#hand", {
					delay: 3,
					duration: 2,
					x: this.$parent.x_location(4) + 50,
					y: this.$parent.y_location(4) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[4].turn(true);
						store.dispatch('nextTaskPhase', 500);
					}
				});
				break;
			case 11:
				this.hand = gsap.timeline();
				this.hand.to("#hand", {
					delay: 3,
					duration: 2,
					x: this.$parent.x_location(3) + 50,
					y: this.$parent.y_location(3) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[3].turn(true);
						store.dispatch('nextTaskPhase', 500);
					}
				});
				break;
			case 12:
				store.dispatch('nextTaskPhase', 4000);
				break;
			case 13:
				this.hand = gsap.timeline();
				this.hand.to("#hand", {
					delay: 1,
					duration: 4,
					x: this.$parent.x_location(2) + 50,
					y: this.$parent.y_location(2) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[3].turn(false);
						this.$parent.cards[4].turn(false);
						this.$parent.cards[2].turn(true);
						store.dispatch('nextTaskPhase', 500);
					}
				});
				break;
			case 14:
				this.hand = gsap.timeline();
				this.hand.to("#hand", {
					delay: 4,
					duration: 2,
					x: this.$parent.x_location(3) + 50,
					y: this.$parent.y_location(3) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[3].turn(true);
						store.dispatch('nextTaskPhase', 500);
					}
				});
				break;
			case 15:
				setTimeout(() => {
					this.$parent.cards[2].mark_as_found();
					this.$parent.cards[3].mark_as_found();
				}, 500);
				store.dispatch('nextTaskPhase', 3000);
				break;
			case 16:
				this.hand = gsap.timeline();
				this.hand.to("#hand", {
					delay: 1,
					duration: 2,
					x: this.$parent.x_location(4) + 50,
					y: this.$parent.y_location(4) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[4].turn(true);
					}
				});
				this.hand.to("#hand", {
					delay: 1,
					duration: 1,
					x: this.$parent.x_location(5) + 50,
					y: this.$parent.y_location(5) + 60,
					ease: "power3.inOut",
					onComplete: () => {
						this.$refs.hand.click();
						this.$parent.cards[5].turn(true);
						store.dispatch('nextTaskPhase', 2000);
					}
				});
				break;
			case 17:
				this.$parent.cards[4].mark_as_found();
				this.$parent.cards[5].mark_as_found();
				this.handVisible = false;
				store.dispatch('nextTaskPhase', 10000);
				break;
			case 18:
				this.$parent.num_cards = this.store_true_num_cards;
				this.$store.commit("setTaskMode", "readyImmediateInstruct");
				this.$store.commit('taskPhase', 0);
				setTimeout(() => {
					//this.$store.commit('setTaskMode', 'playing');
					this.$parent.new_round();
				}, 1000 * this.$parent.shuffle_cards());
				break;
			}
		}
	},
	computed: {
		phase: function() {
			return this.$store.state.taskContainer.taskPhase;
		}
	},
	mounted() {
		//this.$store.commit('taskPhase', 1);
		this.update();
	}
};

</script>
