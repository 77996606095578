<template>
	<g>
		<g id="wm_maintenance_icon">
			<rect
				rx="10"
				fill="#FFFFFF55"
				stroke="#FFFFFF"
				stroke-width="6"
				x="-95"
				y="-95"
				width="190"
				height="190"
			/>

			<rect
				rx="8"
				fill="#ff9922dd"
				stroke="#CC660088"
				stroke-width="3"
				x="-75"
				y="-75"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-22.5"
				y="-75"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="30"
				y="-75"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-75"
				y="-22.5"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#ff9922dd"
				stroke="#CC660088"
				stroke-width="3"
				x="-22.5"
				y="-22.5"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="30"
				y="-22.5"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-75"
				y="30"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#DDDDDDFF"
				stroke="#33333333"
				stroke-width="3"
				x="-22.5"
				y="30"
				width="45"
				height="45"
			/>
			<rect
				rx="8"
				fill="#ff9922dd"
				stroke="#CC660088"
				stroke-width="3"
				x="30"
				y="30"
				width="45"
				height="45"
			/>

			<text
				x="-95"
				y="125"
				class="txt"
				text-anchor="start"
				dominant-baseline="middle"
				font-family="sans-serif"
				font-size="32"
				fill="#000000f"
			>{{$parent.text.wm_maintenance_label}}</text>
		</g>
	</g>
</template>

<script>
export default {
	name: "wm_maintenance_icon",
	idleTween: null,
};
</script>
