export default {
	methods: {
		getLocalisedText() {
			switch (this.$store.state.language) {
				case "sv":
				default:
					return {
						taskName: "Rutan",
						instructions: {
							a: "Här visas 16 rutor. Några av dem kommer att <u>blinka</u>. Din uppgift är att minnas vilka rutor som blinkade och i vilken ordning de blinkade.",
							b: "Tryck här för att se en instruktion ➤",
							c: "Nu ska jag visa!",
							d: "Kom ihåg rutorna som blinkar",
							e: "3 rätt!",
							f: "Tryck på den stora pilen för att börja!",
						},
						forward: {
							task: (num) => {
								if (num == 1) return 'Kom ihåg vilken ruta som blinkar';
								else if (num == 2) return 'Kom ihåg båda rutorna som blinkar';
								else return `Kom ihåg i vilken ordning alla ${num} rutor blinkar`;
							},
							respond: (num) => {
								if (num == 1) return 'Tryck på rutan som blinkade';
								else if (num == 2) return 'Tryck på båda rutorna som blinkade, i samma ordning';
								else return `Tryck på alla ${num} rutor i samma ordning`;
							}
						},
						results: {
							numCorrect: (num, tot) => {
								if (tot == 1) {
									if (num == 0) return 'Det var inte rätt ruta';
									else return 'Rätt!';
								} else {
									if (num == 0) return `Du hade inga rätt den här gången.`;
									else if (num == tot) return `Du hade alla rätt!`;
									else return `Du hade ${num} av ${tot} rätt.`;
								}
							},
						},
						training: {
							roundNum: (num, tot) => {
								return `Omgång ${num} av ${tot}`;
							}
						}
					};
			}
		}
	}
}
