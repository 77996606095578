<template>
	<g class="button" v-on:click="clicked">
		<clipPath :id="'hexclip' + idx">
			<path d="M -120 -60 L 0 -135 120 -60 120 67 0 135 -120 67 Z" />
		</clipPath>

		<path
			:id="'sym' + idx + 'bg'"
			d="M -120 -60 L 0 -135 120 -60 120 67 0 135 -120 67 Z"
			fill="#00AAAA"
			stroke="#00000008"
			stroke-width="10"
		/>
		<path d="M 0 0 L 0 -135 120 -60 120 67 Z" fill="#FFFFFF15" />
		<path d="M 0 0 L 120 67 0 135-120 67 Z" fill="#00000004" />

		<circle r="90" cx="120" cy="-60" fill="#FFFFFF04" :clip-path="'url(#hexclip'+idx+')'" />
		<circle r="90" cx="-120" cy="-60" fill="#88888804" :clip-path="'url(#hexclip'+idx+')'" />
		<circle r="90" cx="0" cy="135" fill="#00000004" :clip-path="'url(#hexclip'+idx+')'" />

		<circle r="90" cx="0" cy="-135" fill="#FFFFAA08" :clip-path="'url(#hexclip'+idx+')'" />
		<circle r="90" cx="120" cy="60" fill="#FFAAFF08" :clip-path="'url(#hexclip'+idx+')'" />
		<circle r="90" cx="-120" cy="60" fill="#AAFFAA08" :clip-path="'url(#hexclip'+idx+')'" />

		<text
			:id="'sym' + idx + 'value'"
			opacity="1.0"
			x="0"
			y="15"
			class="txt"
			text-anchor="middle"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="150"
			fill="#FFFFFF"
			stroke="none"
		>{{this.$store.state.finger_updating_digits.visItems[idx]}}</text>

	</g>
</template>

<script>
import { store } from "@/store.js";
import gsap from "gsap";

//{{this.txt}}

export default {
	name: "sym",
	props: {
		idx: {
			type: Number,
			default: -135
		}
	},
	methods: {
		clicked: function(/*event*/) {
			store.dispatch("finger_updating_digits_clickItem", {
				itemIndex: this.idx,
				roboClick: false
			});
		},
		changeState: function(newState) {
			const sel = "#sym" + this.idx + "bg";

			var tl;
			switch (newState) {
			case 1: // blink
				tl = gsap.timeline();
				tl.to(sel, { fill: "#CC8822", duration: 0.1 });
				tl.to(sel, { fill: "#99BBCC", delay: 0.6, duration: 0.2 });
				break;
			case 2: // selected
				tl = gsap.timeline();
				tl.to(sel, { fill: "#55808E", duration: 0.2 });
				tl.to(sel, { fill: "#35606E", duration: 0.2 });
				break;
			case 3: // selected + inactive
				gsap.to(sel, { fill: "#35606E", duration: 1 });
				break;
			case 4: // correct
				tl = gsap.timeline();
				tl.to(sel, { fill: "#FFFFFF", duration: 0.1 });
				tl.to(sel, { fill: "#55AA55", delay: 0.1, duration: 2 });
				break;
			case 5: // incorrect
				tl = gsap.timeline();
				tl.to(sel, { fill: "#000000", duration: 0.1 });
				tl.to(sel, { fill: "#884444", delay: 0.1, duration: 0.5 });
				break;
			case 6: // clickable + non-selected
				gsap.to(sel, { fill: "#CCCCCC", duration: 0.1 });
				break;
			case 'white':
				gsap.to(sel, { fill: "#FFFFFF88", duration: 0.1 });
				break;
			case 'black':
				gsap.to(sel, { fill: "#000000CC", duration: 0.1 });
				break;
			case 'orange':
				gsap.to(sel, { fill: "#FFA000CC", duration: 0.1 });
				break;
			case 'blue':
				gsap.to(sel, { fill: "#0055FFCC", duration: 0.1 });
				break;
			default:
				gsap.to(sel, { fill: "#99BBCC", duration: 0.2 });
			}
		}
	},
	computed: {
		itemSet() {
			return store.state.finger_updating_digits.itemSet;
		}
	},
	watch: {
		itemSet: function(newitemSet, previtemSet) {
			if (newitemSet[this.idx] != previtemSet[this.idx]) {
				this.changeState(newitemSet[this.idx], previtemSet[this.idx]);
			}
		}
	},
	mounted() {
		this.changeState(0, -1);
	}
};
</script>
