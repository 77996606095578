var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[_c('transition',{attrs:{"name":"fade"}},[(_vm.phase == 0)?_c('foreignObject',{attrs:{"x":"-600","y":"-495","width":"1200","height":"700"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$parent.text.instructions.a)}}),_c('div',{staticClass:"link",on:{"click":function($event){return _vm.$store.commit('taskPhase', 1)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$parent.text.instructions.b)}})])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.phase == 1)?_c('foreignObject',{attrs:{"x":"-790","y":"-495","width":"1580","height":"700"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$parent.text.instructions.c)}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.phase == 3)?_c('foreignObject',{attrs:{"x":"-790","y":"-495","width":"1580","height":"700"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(
						_vm.$parent.text.instructions.d(
							this.items[0].word,
							this.catNames[0]
						)
					)}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.phase == 6)?_c('foreignObject',{attrs:{"x":"-790","y":"-495","width":"1580","height":"700"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$parent.text.instructions.e(this.items[1].word))}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.phase == 9)?_c('foreignObject',{attrs:{"x":"-790","y":"-495","width":"1580","height":"700"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(
						_vm.$parent.text.instructions.d(
							this.items[2].word,
							this.catNames[1]
						)
					)}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.phase == 12)?_c('foreignObject',{attrs:{"x":"-790","y":"-495","width":"1580","height":"700"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(
						_vm.$parent.text.instructions.d(
							this.items[3].word,
							this.catNames[0]
						)
					)}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.phase >= 14 && _vm.phase < 20)?_c('foreignObject',{attrs:{"x":"-790","y":"-495","width":"1580","height":"700"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$parent.text.instructions.f)}})])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.phase == 22)?_c('foreignObject',{attrs:{"x":"-600","y":"-495","width":"1200","height":"700"}},[_c('div',{staticClass:"txtbox"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$parent.text.instructions.g)}})])]):_vm._e()]),_c('hand',{directives:[{name:"show",rawName:"v-show",value:(_vm.handVisible),expression:"handVisible"}],ref:"hand"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }